// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GPAManhattanPlotGroupBorder__groupLine__1TlHZ{stroke:var(--border-color);stroke-width:1px}.GPAManhattanPlotGroupBorder__groupLabelText__30TB3{font-size:.875rem;transform:translateY(.3rem);-webkit-user-select:none;user-select:none}.GPAManhattanPlotGroupBorder__groupOdd__2WEDN{fill:var(--plot-group-odd-color)}.GPAManhattanPlotGroupBorder__groupEven__2guoe{fill:var(--plot-group-even-color)}", "",{"version":3,"sources":["webpack://./shared/src/plots/components/GPAManhattanPlot/components/GPAManhattanPlotGroupBorder/GPAManhattanPlotGroupBorder.css"],"names":[],"mappings":"AAAA,+CACE,0BAA2B,CAC3B,gBACF,CAEA,oDACE,iBAAmB,CAEnB,2BAA6B,CAD7B,wBAAiB,CAAjB,gBAEF,CAEA,8CACE,gCACF,CAEA,+CACE,iCACF","sourcesContent":[".groupLine {\n  stroke: var(--border-color);\n  stroke-width: 1px;\n}\n\n.groupLabelText {\n  font-size: 0.875rem;\n  user-select: none;\n  transform: translateY(0.3rem);\n}\n\n.groupOdd {\n  fill: var(--plot-group-odd-color);\n}\n\n.groupEven {\n  fill: var(--plot-group-even-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"groupLine": "GPAManhattanPlotGroupBorder__groupLine__1TlHZ",
	"groupLabelText": "GPAManhattanPlotGroupBorder__groupLabelText__30TB3",
	"groupOdd": "GPAManhattanPlotGroupBorder__groupOdd__2WEDN",
	"groupEven": "GPAManhattanPlotGroupBorder__groupEven__2guoe"
};
export default ___CSS_LOADER_EXPORT___;
