import { LEFT_AXIS_SCALE_WIDTH, CANVAS_PADDING_WIDTH, DATA_POINT_RADIUS, } from '../../consts';
/**
 * Gets current width of plot calculated from data counts
 *
 * @param data - Plot's data instance
 * @param dataPointStep - Current step between data points
 * @param [groupsCount] - Number of visible groups
 * @param [pointsCount] - Number of visible points
 * @returns Dynamic width of the plot
 */
export function widthCounter(data, dataPointStep, groupsCount = data.visibleGroupsCount, pointsCount = data.visiblePointsCount) {
    return (LEFT_AXIS_SCALE_WIDTH
        + CANVAS_PADDING_WIDTH
        + groupsCount * 2 * DATA_POINT_RADIUS
        + pointsCount * dataPointStep);
}
