import { EConsequenceType, EImpactLevel } from '../../../plots/types';
export const HIGH_IMPACT_LEVELS_BY_TYPE = {
    [EConsequenceType.ExonLossVariant]: EImpactLevel.High,
    [EConsequenceType.FrameShiftVariant]: EImpactLevel.High,
    [EConsequenceType.StartLost]: EImpactLevel.High,
    [EConsequenceType.StopGained]: EImpactLevel.High,
    [EConsequenceType.StopLost]: EImpactLevel.High,
    [EConsequenceType.SpliceAcceptorVariant]: EImpactLevel.High,
    [EConsequenceType.SpliceDonorVariant]: EImpactLevel.High,
    [EConsequenceType.GeneFusion]: EImpactLevel.High,
    [EConsequenceType.BidirectionalGeneFusion]: EImpactLevel.High,
    [EConsequenceType.RareAminoAcidVariant]: EImpactLevel.High,
    [EConsequenceType.TranscriptAblation]: EImpactLevel.High,
    [EConsequenceType.TranscriptAmplification]: EImpactLevel.High,
    [EConsequenceType.Duplication]: EImpactLevel.High,
    [EConsequenceType.Inversion]: EImpactLevel.High,
    [EConsequenceType.FeatureAblation]: EImpactLevel.High,
    [EConsequenceType.RearrangedAtDNALevel]: EImpactLevel.High,
    [EConsequenceType.ProteinProteinContact]: EImpactLevel.High,
    [EConsequenceType.StructuralInteractionVariant]: EImpactLevel.High,
};
export const MODERATE_IMPACT_LEVELS_BY_TYPE = {
    [EConsequenceType.ConservativeInframeDeletion]: EImpactLevel.Moderate,
    [EConsequenceType.ConservativeInframeInsertion]: EImpactLevel.Moderate,
    [EConsequenceType.DisruptiveInframeInsertion]: EImpactLevel.Moderate,
    [EConsequenceType.DisruptiveInframeDeletion]: EImpactLevel.Moderate,
    [EConsequenceType.MissenseVariantAndSpliceRegionVariant]: EImpactLevel.Moderate,
    [EConsequenceType.MissenseVariant]: EImpactLevel.Moderate,
    [EConsequenceType.ProteinAlteringVariant]: EImpactLevel.Moderate,
    [EConsequenceType.FivePrimeUtrTruncation]: EImpactLevel.Moderate,
    [EConsequenceType.InframeInsertion]: EImpactLevel.Moderate,
    [EConsequenceType.InframeDeletion]: EImpactLevel.Moderate,
    [EConsequenceType.RegulatoryRegionAblation]: EImpactLevel.Moderate,
    [EConsequenceType.ThreePrimeUTRTruncation]: EImpactLevel.Moderate,
};
export const MODIFIER_IMPACT_LEVELS_BY_TYPE = {
    [EConsequenceType.ThreePrimeUtrVariant]: EImpactLevel.Modifier,
    [EConsequenceType.FivePrimeUtrVariant]: EImpactLevel.Modifier,
    [EConsequenceType.IntragenicVariant]: EImpactLevel.Modifier,
    [EConsequenceType.ExonVariant]: EImpactLevel.Modifier,
    [EConsequenceType.FeatureElongation]: EImpactLevel.Modifier,
    [EConsequenceType.FeatureTruncation]: EImpactLevel.Modifier,
    [EConsequenceType.GeneVariant]: EImpactLevel.Modifier,
    [EConsequenceType.NMDTranscriptVariant]: EImpactLevel.Modifier,
    [EConsequenceType.NonCodingTranscriptExonVariant]: EImpactLevel.Modifier,
    [EConsequenceType.NonCodingTranscriptVariant]: EImpactLevel.Modifier,
    [EConsequenceType.RegulatoryRegionAmplification]: EImpactLevel.Modifier,
    [EConsequenceType.RegulatoryRegionVariant]: EImpactLevel.Modifier,
    [EConsequenceType.TranscriptVariant]: EImpactLevel.Modifier,
    [EConsequenceType.SequenceFeatureAndExonLossVariant]: EImpactLevel.Modifier,
    [EConsequenceType.ConservedIntronVariant]: EImpactLevel.Modifier,
    [EConsequenceType.IntronVariant]: EImpactLevel.Modifier,
    [EConsequenceType.DownStreamGeneVariant]: EImpactLevel.Modifier,
    [EConsequenceType.UpstreamGeneVariant]: EImpactLevel.Modifier,
    [EConsequenceType.ConservedIntergenicVariant]: EImpactLevel.Modifier,
    [EConsequenceType.IntergenicRegion]: EImpactLevel.Modifier,
    [EConsequenceType.MatureMiRNAVariant]: EImpactLevel.Modifier,
    [EConsequenceType.TFBSAblation]: EImpactLevel.Modifier,
    [EConsequenceType.MiRNA]: EImpactLevel.Modifier,
    [EConsequenceType.TFBindingSiteVariant]: EImpactLevel.Modifier,
    [EConsequenceType.TFBSAmplification]: EImpactLevel.Modifier,
};
export const LOW_IMPACT_LEVELS_BY_TYPE = {
    [EConsequenceType.SynonymousVariant]: EImpactLevel.Low,
    [EConsequenceType.SynonymounsVariantAndSpliceRegionVariant]: EImpactLevel.Low,
    [EConsequenceType.FivePrimeUtrPrematureStartCodonGainVariant]: EImpactLevel.Low,
    [EConsequenceType.StopRetainedVariant]: EImpactLevel.Low,
    [EConsequenceType.InitiatorCodonVariant]: EImpactLevel.Low,
    [EConsequenceType.StopRetainedVariantAndSpliceRegionVariant]: EImpactLevel.Low,
    [EConsequenceType.SpliceRegionVariant]: EImpactLevel.Low,
    [EConsequenceType.CodingSequenceVariant]: EImpactLevel.Low,
    [EConsequenceType.StartRetainedVariant]: EImpactLevel.Low,
    [EConsequenceType.IncompleteTerminalCodonVariant]: EImpactLevel.Low,
    [EConsequenceType.Chromosome]: EImpactLevel.Low,
};
export const HIGH_IMPACT_LEVELS_LIST = Object.keys(HIGH_IMPACT_LEVELS_BY_TYPE);
export const MODERATE_IMPACT_LEVELS_LIST = Object.keys(MODERATE_IMPACT_LEVELS_BY_TYPE);
export const MODIFIER_IMPACT_LEVELS_LIST = Object.keys(MODIFIER_IMPACT_LEVELS_BY_TYPE);
export const LOW_IMPACT_LEVELS_LIST = Object.keys(LOW_IMPACT_LEVELS_BY_TYPE);
export const IMPACT_LEVELS_BY_TYPE = Object.assign(Object.assign(Object.assign(Object.assign({}, HIGH_IMPACT_LEVELS_BY_TYPE), MODERATE_IMPACT_LEVELS_BY_TYPE), MODIFIER_IMPACT_LEVELS_BY_TYPE), LOW_IMPACT_LEVELS_BY_TYPE);
