// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ExportButton__exportBtn__kpTqF{border-radius:4px;font-weight:700}.ExportButton__buttonDisabled__BsR-m{opacity:.75}", "",{"version":3,"sources":["webpack://./shared/src/components/ExportButton/ExportButton.css"],"names":[],"mappings":"AAAA,gCACE,iBAAkB,CAClB,eACF,CAEA,qCACE,WACF","sourcesContent":[".exportBtn {\n  border-radius: 4px;\n  font-weight: 700;\n}\n\n.buttonDisabled {\n  opacity: 0.75;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"exportBtn": "ExportButton__exportBtn__kpTqF",
	"buttonDisabled": "ExportButton__buttonDisabled__BsR-m"
};
export default ___CSS_LOADER_EXPORT___;
