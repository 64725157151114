import { useCallback, useEffect, useRef, } from 'react';
export const useClickOutside = (callback) => {
    const elementRef = useRef();
    const prevHandlerRef = useRef();
    const handleDocumentClick = useCallback((event) => {
        const clickTarget = event.target;
        const boxElement = elementRef.current;
        if (boxElement && !boxElement.contains(clickTarget) && callback) {
            callback();
        }
    }, [callback]);
    useEffect(() => {
        if (prevHandlerRef.current) {
            document.removeEventListener('click', prevHandlerRef.current, true);
        }
        document.addEventListener('click', handleDocumentClick, true);
        prevHandlerRef.current = handleDocumentClick;
        return () => document.removeEventListener('click', handleDocumentClick);
    }, [handleDocumentClick]);
    return elementRef;
};
