import { DEFAULT_CORRECTION_X, CORRECTION_X_FOR_LONG_LABELS, CRITICAL_LABEL_LENGTH, } from '../../consts';
/**
 * Calculates correction for an X-coordinate of the plot label.
 * The step of correction is 0.5rem(8px)
 * @param labelLength - number: length of the label text
 * @returns - number: correction  X coordinates with step 0.5rem for each one symbol
 * It depends on the number difference of labelLength and criticalLabelLength
 */
export const getXCorrection = (labelLength) => {
    if (!labelLength) {
        return DEFAULT_CORRECTION_X;
    }
    if (labelLength >= CRITICAL_LABEL_LENGTH) {
        return ((labelLength - CRITICAL_LABEL_LENGTH)
            * CORRECTION_X_FOR_LONG_LABELS)
            + CORRECTION_X_FOR_LONG_LABELS;
    }
    return DEFAULT_CORRECTION_X;
};
