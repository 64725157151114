import { jsx as _jsx } from "react/jsx-runtime";
import React, { memo } from 'react';
import { DEFAULT_GROUP_LABEL_FONT_SIZE, LEFT_AXIS_SCALE_WIDTH, PLOT_LEFT_PADDING, PLOT_TOP_PADDING, } from '../../consts';
import { EDataPointOrder } from '../../types';
import ManhattanPlotGroupBorder from '../ManhattanPlotGroupBorder/ManhattanPlotGroupBorder';
import ManhattanPlotLayer from '../ManhattanPlotLayer/ManhattanPlotLayer';
const ManhattanPlotGroupsLabels = (props) => {
    const { data, groupsDimensions, groupLabelFontSize = DEFAULT_GROUP_LABEL_FONT_SIZE, height, useInlineStyles, renderGroupBorder, dataPointStep, forExport, } = props;
    const plotLayerProps = !forExport && {
        leftPadding: (renderGroupBorder
            ? -LEFT_AXIS_SCALE_WIDTH
            : PLOT_LEFT_PADDING - LEFT_AXIS_SCALE_WIDTH),
        topPadding: -PLOT_TOP_PADDING,
    };
    return (_jsx(ManhattanPlotLayer, Object.assign({}, plotLayerProps, { children: data.getGroups().map(((dataGroup, index, groups) => {
            var _a;
            const { widthOffset = 0, heightOffset = 0, } = (_a = groupsDimensions[index]) !== null && _a !== void 0 ? _a : {};
            const dataPointOrder = index % 2 === 0
                ? EDataPointOrder.Even
                : EDataPointOrder.Odd;
            return (renderGroupBorder
                ? (_jsx(React.Fragment, { children: renderGroupBorder({
                        widthOffset,
                        index,
                        groupCount: groups.length,
                        height,
                        length: dataGroup.length,
                        dataPointOrder,
                        groupLabel: dataGroup.groupLabel,
                        dataPointStep,
                        data: dataGroup.getPoints(),
                        scaleRange: [1, 1],
                        useInlineStyles: forExport,
                    }) }, dataGroup.groupId))
                : (_jsx(ManhattanPlotGroupBorder, { index: index, groupCount: groups.length, widthOffset: widthOffset, heightOffset: heightOffset, groupLabelFontSize: groupLabelFontSize, height: height, groupLabel: dataGroup.groupLabel, groupTooltipLabel: dataGroup.groupName, useInlineStyles: useInlineStyles || forExport }, dataGroup.groupId)));
        })) }), void 0));
};
/** Since memo loses generic props */
export default memo(ManhattanPlotGroupsLabels);
