export const SHOW_LABEL = 'Show more';
export const HIDE_LABEL = 'Show less';
export var EAnglePosition;
(function (EAnglePosition) {
    EAnglePosition["START"] = "start";
    EAnglePosition["END"] = "end";
})(EAnglePosition || (EAnglePosition = {}));
export var EAngleDirection;
(function (EAngleDirection) {
    EAngleDirection["UP"] = "up";
    EAngleDirection["DOWN"] = "down";
})(EAngleDirection || (EAngleDirection = {}));
export const MAX_HEIGHT_CHILDREN = 56;
