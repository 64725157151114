import { useState, useMemo } from 'react';
const getInitColumns = (initColumns) => initColumns.map((column) => (Object.assign(Object.assign({}, column), { hidden: false })));
/**
 * Returns columns with actual order and visibility for the table rendering,
 * columns for settings component rendering
 * and callback functions
 *
 * @param data - initial metadata used to render columns
 * @returns API for Table config which includes:
 *  - columns - for TableSettings component rendering
 *  - visibleColumns - for Table rendering
 *  - onConfigChange - callback for change settings handle
 *  - onConfigUnHide - callback for unhide hidden columns
 *  - onConfigReset - callback for return to initial values
 */
const useTableConfig = (data) => {
    const initColumns = useMemo(() => getInitColumns(data), [data]);
    const [columns, setColumns] = useState(initColumns);
    const visibleColumns = useMemo(() => columns.filter(({ hidden }) => !hidden), [columns]);
    const onConfigChange = (updatedColumns) => {
        setColumns(updatedColumns);
    };
    const onConfigUnHide = (updatedColumns) => {
        setColumns(getInitColumns(updatedColumns));
    };
    const onConfigReset = () => {
        setColumns(initColumns);
    };
    return {
        columns,
        visibleColumns,
        onConfigChange,
        onConfigUnHide,
        onConfigReset,
    };
};
export default useTableConfig;
