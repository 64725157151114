import { SCALE_LABEL_FONT_SIZE } from '../../consts';
const inlineStyles = {
    line: {
        stroke: '#ccd7e0',
    },
    text: {
        fontSize: `${SCALE_LABEL_FONT_SIZE}px`,
    },
};
export default inlineStyles;
