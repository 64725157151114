import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, memo } from 'react';
import classnames from 'classnames';
import stylesCSS from './LegendColumn.css';
const LegendColumn = (props) => {
    const { data, styles, startIndex = 0, } = props;
    const items = useMemo(() => (data.map(({ groupLabel, groupName }, index) => {
        var _a, _b;
        let itemLabelClassName;
        if ((startIndex + index) % 2 === 0) {
            itemLabelClassName = ((_a = styles === null || styles === void 0 ? void 0 : styles.evenItemLabel) === null || _a === void 0 ? void 0 : _a.className) || stylesCSS.evenItemLabel;
        }
        else {
            itemLabelClassName = ((_b = styles === null || styles === void 0 ? void 0 : styles.oddItemLabel) === null || _b === void 0 ? void 0 : _b.className) || stylesCSS.oddItemLabel;
        }
        itemLabelClassName = classnames(itemLabelClassName, stylesCSS.label);
        return (_jsxs("li", { children: [_jsx("div", Object.assign({ className: itemLabelClassName }, { children: groupLabel }), void 0), _jsx("div", Object.assign({ className: stylesCSS.name }, { children: groupName }), void 0)] }, groupLabel));
    })), [
        data,
        startIndex,
        styles,
    ]);
    return (_jsx("ul", Object.assign({ className: stylesCSS.wrapper, "data-testid": "LegendColumn" }, { children: items }), void 0));
};
export default memo(LegendColumn);
