import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import classnames from 'classnames';
import CrossIcon from '../../assets/icons/tagClose.svg';
import styles from './Tag.css';
function TagBase(props) {
    const { value, label, disabled = false, onRemove, className, } = props;
    const handleClick = (event) => {
        event.stopPropagation();
        if (onRemove) {
            onRemove(value, event);
        }
    };
    return (_jsxs("div", Object.assign({ className: classnames(styles.layout, className, { [styles.disabled]: disabled }) }, { children: [label, onRemove && (_jsx(CrossIcon, { className: classnames(styles.icon), role: "button", tabIndex: 0, onClick: handleClick }, void 0))] }), void 0));
}
export const Tag = memo(TagBase);
