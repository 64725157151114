// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModelTypeWithDescription__container__1e5r4{display:flex;flex-direction:column;gap:1rem}", "",{"version":3,"sources":["webpack://./shared/src/components/ModelTypeWithDescription/ModelTypeWithDescription.css"],"names":[],"mappings":"AAAA,4CACE,YAAa,CACb,qBAAsB,CACtB,QACF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ModelTypeWithDescription__container__1e5r4"
};
export default ___CSS_LOADER_EXPORT___;
