import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback } from 'react';
import { CheckBoxListItem } from '../CheckBoxListItem/CheckBoxListItem';
import { RangeListItem } from '../RangeListItem/RangeListItem';
import styles from './OpacityOrganizer.css';
export const OpacityOrganizer = memo(function EyeList({ datasets, datasetNameMapper, onOpacityChange, }) {
    const handleOpacityChange = useCallback((id, value) => {
        if (onOpacityChange) {
            onOpacityChange(id, value);
        }
    }, [onOpacityChange]);
    const handleCheckboxChange = useCallback((id, checked) => {
        if (onOpacityChange) {
            onOpacityChange(id, checked ? 100 : 0);
        }
    }, [onOpacityChange]);
    return (_jsx("ul", Object.assign({ className: styles.container }, { children: Object.entries(datasets).map(([id, value]) => (_jsxs("li", Object.assign({ className: styles.opacityBlock }, { children: [_jsx(CheckBoxListItem, { "data-testid": `check_${id}`, id: `check_${id}`, checked: !!value, label: datasetNameMapper[id], handleChange: handleCheckboxChange, withEye: true }, void 0), _jsx(RangeListItem, { "data-testid": `range_${id}`, id: `range_${id}`, value: value, label: "", handleChange: handleOpacityChange, isColorGrey: value === 0 }, void 0)] }), id))) }), void 0));
});
