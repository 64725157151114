const inlineStyles = {
    path: {
        strokeWidth: '1px',
        stroke: '#242b36',
        fill: 'transparent',
        userSelect: 'none',
        pointerEvents: 'none',
    },
};
export default inlineStyles;
