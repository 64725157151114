// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ImpactDescriptionWrapper__wrapper__2pBY_{display:inline-flex;flex-direction:row;font-size:.675rem;font-weight:500;line-height:1.25rem}.ImpactDescriptionWrapper__wrapper__2pBY_:not(:first-child){margin-left:1rem}", "",{"version":3,"sources":["webpack://./shared/src/components/ImpactDescriptions/components/ImpactDescriptionWrapper/ImpactDescriptionWrapper.css"],"names":[],"mappings":"AAAA,0CACE,mBAAoB,CACpB,kBAAmB,CAEnB,iBAAmB,CACnB,eAAgB,CAFhB,mBAGF,CAEA,4DACE,gBACF","sourcesContent":[".wrapper {\n  display: inline-flex;\n  flex-direction: row;\n  line-height: 1.25rem;\n  font-size: 0.675rem;\n  font-weight: 500;\n}\n\n.wrapper:not(:first-child) {\n  margin-left: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "ImpactDescriptionWrapper__wrapper__2pBY_"
};
export default ___CSS_LOADER_EXPORT___;
