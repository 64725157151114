import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { useResizeContainer } from '../../../../hooks';
import LollipopPlotSVG from '../LollipopPlotSVG';
import styles from './LollipopPlotContainer.css';
const LollipopPlotContainer = memo(function LollipopPlotContainer(props) {
    const { axis, data, width: plotWidth, height, maxTickValues, getTooltipContent, useInlineStyles, } = props;
    const { containerWidth, containerRef, } = useResizeContainer();
    const width = plotWidth || containerWidth;
    // If width is set, it should override resize behavior
    const plotContainerStyle = plotWidth
        ? { width: plotWidth }
        : undefined;
    return (_jsx("div", Object.assign({ className: styles.innerContainer, style: plotContainerStyle, ref: containerRef }, { children: _jsx(LollipopPlotSVG, { axis: axis, data: data, height: height, width: width, maxTickValues: maxTickValues, getTooltipContent: getTooltipContent, useInlineStyles: useInlineStyles }, void 0) }), void 0));
});
export default LollipopPlotContainer;
