// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LinePlotDataPointTooltip__tooltip__-CrNr{padding-right:1.25rem;width:18.75rem}.LinePlotDataPointTooltip__tooltipLink__3GKcB{color:var(--link-color);font-weight:500;text-decoration:none}", "",{"version":3,"sources":["webpack://./shared/src/plots/components/LinePlot/components/LinePlotDataPointTooltip/LinePlotDataPointTooltip.css"],"names":[],"mappings":"AAAA,0CAEE,qBAAsB,CADtB,cAEF,CAEA,8CACE,uBAAwB,CAExB,eAAgB,CADhB,oBAEF","sourcesContent":[".tooltip {\n  width: 18.75rem;\n  padding-right: 1.25rem;\n}\n\n.tooltipLink {\n  color: var(--link-color);\n  text-decoration: none;\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": "LinePlotDataPointTooltip__tooltip__-CrNr",
	"tooltipLink": "LinePlotDataPointTooltip__tooltipLink__3GKcB"
};
export default ___CSS_LOADER_EXPORT___;
