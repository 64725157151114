import { EDataPointShape } from '../../../../types';
import ManhattanPointCircle from './components/ManhattanPlotCircle/ManhattanPlotCircle';
import ManhattanPlotTriangleUp from './components/ManhattanPlotTriangleUp/ManhattanPlotTriangleUp';
import ManhattanPlotTriangleDown from './components/ManhattanPlotTriangleDown/ManhattanPlotTriangleDown';
export const dataPointFactory = (dataPointShape) => {
    if (dataPointShape === EDataPointShape.TriangleUp) {
        return ManhattanPlotTriangleUp;
    }
    if (dataPointShape === EDataPointShape.TriangleDown) {
        return ManhattanPlotTriangleDown;
    }
    if (dataPointShape === EDataPointShape.Circle) {
        return ManhattanPointCircle;
    }
    throw new Error('Data point shape is incorrect or not provided!');
};
