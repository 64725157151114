// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OpacityOrganizer__container__xNF3E{display:flex;flex-wrap:wrap;font-size:.875rem;gap:3.75rem;line-height:1.5rem;list-style:none;margin:0;padding:0}.OpacityOrganizer__opacityBlock__27bGX{display:inline}.OpacityOrganizer__opacityBlock__27bGX:first-child{margin-left:0}", "",{"version":3,"sources":["webpack://./shared/src/plots/components/ManhattanPlot/components/PlotSettings/components/OpacityOrganizer/OpacityOrganizer.css"],"names":[],"mappings":"AAAA,oCACE,YAAa,CAMb,cAAe,CALf,iBAAmB,CAMnB,WAAY,CALZ,kBAAmB,CAGnB,eAAgB,CAFhB,QAAS,CACT,SAIF,CAEA,uCACE,cACF,CAEA,mDACE,aACF","sourcesContent":[".container {\n  display: flex;\n  font-size: 0.875rem;\n  line-height: 1.5rem;\n  margin: 0;\n  padding: 0;\n  list-style: none;\n  flex-wrap: wrap;\n  gap: 3.75rem;\n}\n\n.opacityBlock {\n  display: inline;\n}\n\n.opacityBlock:first-child {\n  margin-left: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "OpacityOrganizer__container__xNF3E",
	"opacityBlock": "OpacityOrganizer__opacityBlock__27bGX"
};
export default ___CSS_LOADER_EXPORT___;
