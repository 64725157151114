// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CountdownTimer__content__3Po1N{display:flex;flex-direction:column;text-align:center}.CountdownTimer__description__2yruG{font-size:2rem;font-weight:500;margin:0 auto;padding-left:.6rem}.CountdownTimer__countdown__3Ps34{display:flex;flex-direction:row;justify-content:center}", "",{"version":3,"sources":["webpack://./shared/src/components/CountdownTimer/CountdownTimer.css"],"names":[],"mappings":"AAAA,gCACE,YAAa,CACb,qBAAsB,CACtB,iBACF,CAEA,oCAEE,cAAe,CACf,eAAgB,CAFhB,aAAc,CAGd,kBACF,CAEA,kCACE,YAAa,CACb,kBAAmB,CACnB,sBACF","sourcesContent":[".content {\n  display: flex;\n  flex-direction: column;\n  text-align: center;\n}\n\n.description {\n  margin: 0 auto;\n  font-size: 2rem;\n  font-weight: 500;\n  padding-left: 0.6rem;\n}\n\n.countdown {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "CountdownTimer__content__3Po1N",
	"description": "CountdownTimer__description__2yruG",
	"countdown": "CountdownTimer__countdown__3Ps34"
};
export default ___CSS_LOADER_EXPORT___;
