import { ELevelResult } from '../../../../../../types';
import { SIGNIFICANT_DATA_POINT_PVALUE_INDICATOR } from '../../consts';
import { EDataPointType } from '../../types';
/**
 * Gets a type of data point depends on levelResult and pvalue
 *
 * @param levelResult - LevelResult value of data point
 * @param pvalue - Pvalue value of data point
 * @returns The type of data point
 */
export const getDataPointType = (levelResult, pvalue) => {
    if (levelResult === ELevelResult.Gene) {
        return pvalue < SIGNIFICANT_DATA_POINT_PVALUE_INDICATOR
            ? EDataPointType.SignificantGLR
            : EDataPointType.NotSignificantGLR;
    }
    return pvalue < SIGNIFICANT_DATA_POINT_PVALUE_INDICATOR
        ? EDataPointType.SignificantVLR
        : EDataPointType.NotSignificantVLR;
};
