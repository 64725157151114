// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PlotAxisScrollable__axisYContainer__5FiZ7{pointer-events:none;position:fixed;z-index:var(--plot-layout-z-index)}.PlotAxisScrollable__axisXContainer__1Z85s{z-index:var(--plot-y-axis-z-index)}.PlotAxisScrollable__axisBorder__2oP7t,.PlotAxisScrollable__axisXContainer__1Z85s{pointer-events:none;position:absolute}.PlotAxisScrollable__axisBorder__2oP7t{z-index:var(--plot-layout-z-index)}.PlotAxisScrollable__axisBackground__3qZQV{pointer-events:all}.PlotAxisScrollable__axisBackground__3qZQV,.PlotAxisScrollable__axisYHiding__20SrO{fill:var(--content-background-color)}", "",{"version":3,"sources":["webpack://./shared/src/plots/components/ScatterPlots/components/PlotAxisScrollable/PlotAxisScrollable.css"],"names":[],"mappings":"AAAA,2CAEE,mBAAoB,CADpB,cAAe,CAEf,kCACF,CAEA,2CAGE,kCACF,CAEA,kFAJE,mBAAoB,CADpB,iBASF,CAJA,uCAGE,kCACF,CAEA,2CAEE,kBACF,CAEA,mFAJE,oCAMF","sourcesContent":[".axisYContainer {\n  position: fixed;\n  pointer-events: none;\n  z-index: var(--plot-layout-z-index);\n}\n\n.axisXContainer {\n  position: absolute;\n  pointer-events: none;\n  z-index: var(--plot-y-axis-z-index);\n}\n\n.axisBorder {\n  position: absolute;\n  pointer-events: none;\n  z-index: var(--plot-layout-z-index);\n}\n\n.axisBackground {\n  fill: var(--content-background-color);\n  pointer-events: all;\n}\n\n.axisYHiding {\n  fill: var(--content-background-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"axisYContainer": "PlotAxisScrollable__axisYContainer__5FiZ7",
	"axisXContainer": "PlotAxisScrollable__axisXContainer__1Z85s",
	"axisBorder": "PlotAxisScrollable__axisBorder__2oP7t",
	"axisBackground": "PlotAxisScrollable__axisBackground__3qZQV",
	"axisYHiding": "PlotAxisScrollable__axisYHiding__20SrO"
};
export default ___CSS_LOADER_EXPORT___;
