import { useEffect } from 'react';
/**
 * Hook invokes `resizeCallback` after waiting for `delay` from window resize event
 *
 * @param resizeCallback - Callback to invoke after event
 * @param delay - Delay to wait before invoking the function
 */
export function useWindowResize(resizeCallback, delay) {
    useEffect(() => {
        let timer;
        const handleResize = () => {
            window.clearTimeout(timer);
            timer = window.setTimeout(resizeCallback, delay);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [resizeCallback, delay]);
}
