import { useCallback, useMemo, useRef, useState, } from 'react';
export const useTableHeaderSticky = () => {
    var _a;
    const filtersSectionRef = useRef(null);
    const scrollThreshold = ((_a = filtersSectionRef === null || filtersSectionRef === void 0 ? void 0 : filtersSectionRef.current) === null || _a === void 0 ? void 0 : _a.clientHeight) || 0;
    const [hasStickyShadow, setShadowAllowed] = useState(false);
    const onStickyChange = useCallback((isSticky) => {
        setShadowAllowed(!isSticky);
    }, [setShadowAllowed]);
    return useMemo(() => ({
        filtersSectionRef,
        scrollThreshold,
        hasStickyShadow,
        onStickyChange,
    }), [filtersSectionRef, scrollThreshold, hasStickyShadow, onStickyChange]);
};
