import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classnames from 'classnames';
import InfoIcon from '../../../../assets/icons/infoFilled.svg';
import { EImpactLevel } from '../../../../plots/types';
import { ETooltipAction, ETooltipPlacement, ETooltipAlign, } from '../../../Tooltip';
import { InfoTooltip } from '../../../InfoTooltip';
import ImpactDescriptionWrapper from '../ImpactDescriptionWrapper';
import styles from './ImpactDescription.css';
const ImpactDescription = ({ id, items, impactLevel, impactName = '', children, }) => {
    const position = impactLevel === EImpactLevel.Low
        ? ETooltipPlacement.BottomRight
        : ETooltipPlacement.BottomLeft;
    const align = impactLevel === EImpactLevel.Low ? ETooltipAlign.End : ETooltipAlign.Start;
    const iconStyle = classnames(styles.icon, {
        [styles.highImpactIcon]: impactLevel === EImpactLevel.High,
        [styles.moderateImpactIcon]: impactLevel === EImpactLevel.Moderate,
        [styles.lowImpactIcon]: impactLevel === EImpactLevel.Low,
    });
    const tipStyle = classnames(styles.tip, {
        [styles.longTip]: impactLevel === EImpactLevel.High,
        [styles.shortTip]: impactLevel !== EImpactLevel.High,
    });
    return (_jsxs(ImpactDescriptionWrapper, { children: [_jsx(InfoTooltip, Object.assign({ id: id, position: position, align: align, targetEvent: ETooltipAction.Hover, className: iconStyle, icon: _jsx(InfoIcon, { "data-testid": id }, void 0) }, { children: _jsxs("div", Object.assign({ className: tipStyle }, { children: ["Variants with the following predicted consequences are classified as", ` '${impactName}':`, _jsx("ul", Object.assign({ className: styles.list }, { children: items.map((item) => (_jsx("li", { children: item }, item))) }), void 0)] }), void 0) }), void 0), children] }, void 0));
};
export default ImpactDescription;
