// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TabContent__tabContent__1xbD-{display:none;margin:0}.TabContent__activeTabContent__1vpq8{display:block}", "",{"version":3,"sources":["webpack://./shared/src/components/Tabs/components/TabContent/TabContent.css"],"names":[],"mappings":"AAAA,+BACE,YAAa,CACb,QACF,CAEA,qCACE,aACF","sourcesContent":[".tabContent {\n  display: none;\n  margin: 0;\n}\n\n.activeTabContent {\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabContent": "TabContent__tabContent__1xbD-",
	"activeTabContent": "TabContent__activeTabContent__1vpq8"
};
export default ___CSS_LOADER_EXPORT___;
