var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { forwardRef, memo, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState, } from 'react';
import { renderDefaultOption, getStyles } from './utils';
import { getDefaultOption } from '../../utils';
import { useIterateOptions } from './hooks';
import { useClickOutside } from '../../hooks';
import DefaultSearchIcon from '../../assets/icons/search.svg';
import CloseIcon from '../../assets/icons/cross.svg';
import { ESearchBoxType } from './consts';
import { EKeyCode } from '../../consts';
import DefaultOptionList from './components/DefaultOptionList';
import { Spinner } from '../Spinner';
import { EInputTextType, TextField } from '../TextField';
function SearchBoxFieldBase(props, ref) {
    const { value, onChange, onBlur, onKeyDown, inputValue: inputValueParent = null, onInputChange: setInputValueParent = null, options = [], recentOptions = [], placeholder = '', noOptionsMessage, loading = false, disabled = false, enablePreview = false, getOptionLabel, getOptionValue, getOptionDisabled, renderOption = renderDefaultOption, filterOptions = getDefaultOption, optionListComponent: OptionListComponent = DefaultOptionList, searchIcon: SearchIcon = DefaultSearchIcon, type = ESearchBoxType.Field } = props, otherProps = __rest(props, ["value", "onChange", "onBlur", "onKeyDown", "inputValue", "onInputChange", "options", "recentOptions", "placeholder", "noOptionsMessage", "loading", "disabled", "enablePreview", "getOptionLabel", "getOptionValue", "getOptionDisabled", "renderOption", "filterOptions", "optionListComponent", "searchIcon", "type"]);
    const inputRef = useRef(null);
    const [isOpen, setOpen] = useState(false);
    const [isFocused, setFocused] = useState(false);
    const styles = getStyles(Object.assign({ type, isFocused }, otherProps));
    const [previewInputValue, setPreviewInputValue] = useState(null);
    const [inputValueLocal, setInputValueLocal] = useState('');
    const inputValue = inputValueParent !== null && inputValueParent !== void 0 ? inputValueParent : inputValueLocal;
    const setInputValue = setInputValueParent || setInputValueLocal;
    const filteredOptions = useMemo(() => filterOptions(options, inputValue, getOptionLabel), [filterOptions, getOptionLabel, inputValue, options]);
    const iteratorOptions = inputValue.length === 0 ? recentOptions : filteredOptions;
    const enabledOptions = useMemo(() => (getOptionDisabled
        ? iteratorOptions.filter((option) => !getOptionDisabled(option))
        : iteratorOptions), [getOptionDisabled, iteratorOptions]);
    const iterator = useIterateOptions({ options: enabledOptions, getOptionValue });
    const handleChange = useCallback((option) => onChange && onChange(option), [onChange]);
    const handleBlur = useCallback((nextInputValue) => onBlur && onBlur(nextInputValue), [onBlur]);
    const handleKeyDown = useCallback((event, nextOption) => onKeyDown
        && onKeyDown({
            event, nextOption, inputValue, filteredOptions,
        }), [filteredOptions, inputValue, onKeyDown]);
    const handleChangePreview = useCallback((next) => enablePreview && setPreviewInputValue(next), [enablePreview]);
    const handleFocus = useCallback(() => {
        setFocused(true);
        setOpen(true);
    }, []);
    const handleClickAway = useCallback(() => {
        if (isFocused) {
            iterator.reset();
            setOpen(false);
            setFocused(false);
            handleBlur(previewInputValue !== null && previewInputValue !== void 0 ? previewInputValue : inputValue);
        }
    }, [handleBlur, inputValue, isFocused, iterator, previewInputValue]);
    const boxRef = useClickOutside(handleClickAway);
    const handleChangeInputValue = useCallback((e) => {
        const nextInputValue = e.target.value;
        iterator.reset();
        setOpen(true);
        setInputValue(nextInputValue);
        handleChangePreview(null);
        if (nextInputValue.length === 0)
            handleChange(null);
    }, [handleChange, handleChangePreview, iterator, setInputValue]);
    const handleClickOption = useCallback((option) => {
        iterator.reset();
        setOpen(false);
        setFocused(false);
        handleChangePreview(null);
        handleChange(option);
    }, [handleChange, handleChangePreview, iterator]);
    const handleClearIconClick = useCallback((event) => {
        const inputElement = inputRef.current;
        event.stopPropagation();
        if (inputElement)
            inputElement.blur();
        iterator.reset();
        setOpen(false);
        setFocused(false);
        setInputValue('');
        handleChangePreview(null);
        handleChange(null);
    }, [iterator, setInputValue, handleChangePreview, handleChange]);
    const handleInputKeyDown = useCallback((event) => {
        const { code } = event;
        switch (code) {
            case EKeyCode.Enter: {
                event.preventDefault();
                const inputElement = inputRef.current;
                const nextOption = iterator.option;
                if (nextOption && !loading && inputElement) {
                    inputElement.blur();
                    iterator.reset();
                    setFocused(false);
                    handleChange(nextOption);
                }
                setOpen(false);
                handleChangePreview(null);
                break;
            }
            case EKeyCode.Esc: {
                event.preventDefault();
                setOpen(false);
                break;
            }
            case EKeyCode.DownArrow: {
                event.preventDefault();
                if (!isOpen) {
                    setOpen(true);
                    break;
                }
                const nextOption = iterator.next();
                if (nextOption) {
                    handleChangePreview(getOptionLabel(nextOption));
                }
                break;
            }
            case EKeyCode.UpArrow: {
                event.preventDefault();
                if (!isOpen) {
                    setOpen(true);
                    break;
                }
                const nextOption = iterator.previous();
                if (nextOption) {
                    handleChangePreview(getOptionLabel(nextOption));
                }
                break;
            }
            case EKeyCode.Tab: {
                handleClickAway();
                break;
            }
            default:
        }
        handleKeyDown(event, iterator.option);
    }, [
        getOptionLabel,
        handleChange,
        handleChangePreview,
        handleClickAway,
        handleKeyDown,
        isOpen,
        iterator,
        loading,
    ]);
    useLayoutEffect(() => {
        if (value) {
            setInputValue(getOptionLabel(value));
        }
        handleChangePreview(null);
    }, [value]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (disabled) {
            setFocused(false);
            setOpen(false);
        }
    }, [disabled]);
    const renderListOption = useCallback((option) => renderOption({
        option,
        getOptionValue,
        getOptionLabel,
        onClick: handleClickOption,
        onMouseOver: iterator.setOption,
        pattern: inputValue,
        id: getOptionValue(option),
        isSelected: value ? getOptionValue(value) === getOptionValue(option) : false,
        isDisabled: getOptionDisabled ? getOptionDisabled(option) : false,
        isHighlighted: iterator.option
            ? getOptionValue(option) === getOptionValue(iterator.option)
            : false,
        className: styles.option,
        selectedClassName: styles.selected,
        highlightedClassName: styles.highlighted,
        disabledClassName: styles.disabled,
    }), [
        getOptionDisabled,
        getOptionLabel,
        getOptionValue,
        handleClickOption,
        inputValue,
        iterator.option,
        iterator.setOption,
        renderOption,
        styles.disabled,
        styles.highlighted,
        styles.option,
        styles.selected,
        value,
    ]);
    const renderList = useCallback((nodes) => (_jsx(OptionListComponent, Object.assign({ options: filteredOptions, onMouseOut: iterator.reset, className: styles.options }, { children: nodes }), void 0)), [OptionListComponent, filteredOptions, iterator.reset, styles.options]);
    return (_jsx("div", Object.assign({ ref: ref, "data-testid": "search-box", className: styles.layout }, { children: _jsxs("div", Object.assign({ ref: boxRef, className: styles.box }, { children: [_jsxs("div", Object.assign({ className: styles.field }, { children: [_jsx(SearchIcon, { className: styles.iconSearch }, void 0), _jsx(TextField, { ref: inputRef, type: EInputTextType.Search, value: previewInputValue !== null && previewInputValue !== void 0 ? previewInputValue : inputValue, onFocus: handleFocus, onChange: handleChangeInputValue, onKeyDown: handleInputKeyDown, className: styles.input, disabled: disabled, placeholder: placeholder, "aria-label": placeholder, "data-testid": "search-box-input" }, void 0), loading && (_jsx("div", Object.assign({ className: styles.spinnerContainer }, { children: _jsx(Spinner, { className: styles.spinner, testId: "search-box-spinner" }, void 0) }), void 0)), isFocused
                            && inputValue.trim().length > 0
                            && !loading
                            && (_jsx(CloseIcon, { onClick: handleClearIconClick, className: styles.iconClear, "data-testid": "search-box-clear" }, void 0))] }), void 0), isFocused
                    && isOpen
                    && inputValue.trim().length > 0
                    && filteredOptions.length > 0
                    && renderList(filteredOptions.map(renderListOption)), isFocused
                    && isOpen
                    && inputValue.trim().length === 0
                    && recentOptions.length > 0
                    && renderList(recentOptions.map(renderListOption)), isFocused
                    && isOpen
                    && !loading
                    && noOptionsMessage
                    && inputValue.trim().length > 0
                    && filteredOptions.length === 0
                    && renderList(_jsx("li", Object.assign({ className: styles.noOptions, "data-testid": "search-box-no-options" }, { children: noOptionsMessage }), void 0))] }), void 0) }), void 0));
}
export default memo(forwardRef(SearchBoxFieldBase));
