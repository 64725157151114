// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ManhattanPlotThresholdsArea__thresholdArea__GWhOE{fill:var(--plot-threshold-area-color)}", "",{"version":3,"sources":["webpack://./shared/src/plots/components/ManhattanPlot/components/ManhattanPlotThresholdsArea/ManhattanPlotThresholdsArea.css"],"names":[],"mappings":"AAAA,mDACE,qCACF","sourcesContent":[".thresholdArea {\n  fill: var(--plot-threshold-area-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"thresholdArea": "ManhattanPlotThresholdsArea__thresholdArea__GWhOE"
};
export default ___CSS_LOADER_EXPORT___;
