/**
 * Calculates position of rectangle with defined sides ratio
 * that contains the rectangle with passed position in the center.
 *
 * @param position position of selected area rectangle
 * @param ratio target sides ratio
 * @returns calculated rectangle position
 */
export const calculatePositionWithRatio = (position, ratio = 1) => {
    if (position.height === 0 || position.width === 0) {
        return position;
    }
    const actualRatio = position.width / position.height;
    const updatedPosition = Object.assign({}, position);
    if (ratio >= actualRatio) {
        updatedPosition.left = position.left - (position.height * ratio - position.width) / 2;
        updatedPosition.width = position.height * ratio;
    }
    else {
        updatedPosition.top = position.top - (position.width / ratio - position.height) / 2;
        updatedPosition.height = position.width / ratio;
    }
    return updatedPosition;
};
