export const DEFAULT_PLOT_WIDTH = 660;
export const DEFAULT_PLOT_HEIGHT = 660;
export const DEFAULT_FONT_SIZE = 14;
export const DEFAULT_FONT_FAMILY = `${DEFAULT_FONT_SIZE}px Roboto`;
export const DEFAULT_AXIS_LABEL_FONT_SIZE = 12;
export const DEFAULT_AXIS_LABEL_FONT_FAMILY = `${DEFAULT_AXIS_LABEL_FONT_SIZE}px Roboto`;
export const DEFAULT_X_AXIS_LABEL_HEIGHT = DEFAULT_AXIS_LABEL_FONT_SIZE + 2;
export const DEFAULT_Y_AXIS_LABEL_HEIGHT = DEFAULT_AXIS_LABEL_FONT_SIZE + 4;
export const DEFAULT_Y_AXIS_SCALE_PADDING = 2;
export const DEFAULT_Y_AXIS_WIDTH = 59;
export const MIN_Y_AXIS_SCALE_LINE_GAP = 20;
export const DEFAULT_X_AXIS_HEIGHT = 42;
export const MIN_X_AXIS_SCALE_LINE_HEIGHT = 22;
export const DEFAULT_DATA_ZONE_TOP_PADDING = 20;
export const DEFAULT_DATA_ZONE_RIGHT_PADDING = 20;
export const DEFAULT_DATA_ZONE_BOTTOM_PADDING = 20;
export const DEFAULT_DATA_ZONE_LEFT_PADDING = 20;
export const DEFAULT_BORDER_WIDTH = 1;
export const DEFAULT_AXIS_BORDER_WIDTH = 3;
export const DEFAULT_DATA_POINT_RADIUS = 6;
export const SCROLLBAR_WIDTH = 16;
export const SYNTHETIC_SCROLL_MARGIN = 8;
export const LEGEND_BOTTOM_MARGIN = 25;
export const LEGEND_TOP_MARGIN = 17;
export const OUTER_CONTAINER_RIGHT_PADDING = 6;
export const Y_SCROLLBAR_TOP_PADDING = 16;
export const Y_SCROLLBAR_BOTTOM_PADDING = 48;
export const X_SCROLLBAR_RIGHT_PADDING = 24;
