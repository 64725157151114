// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DefinitionModelList__list__OXWcy{line-height:1.125rem;margin:0;padding-left:22px}", "",{"version":3,"sources":["webpack://./shared/src/components/DefinitionModelList/DefinitionModelList.css"],"names":[],"mappings":"AAAA,kCAGE,oBAAqB,CAFrB,QAAS,CACT,iBAEF","sourcesContent":[".list {\n  margin: 0;\n  padding-left: 22px;\n  line-height: 1.125rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "DefinitionModelList__list__OXWcy"
};
export default ___CSS_LOADER_EXPORT___;
