import { DEFAULT_DATA_ZONE_TOP_PADDING, DEFAULT_DATA_ZONE_RIGHT_PADDING, DEFAULT_DATA_ZONE_BOTTOM_PADDING, DEFAULT_DATA_ZONE_LEFT_PADDING, } from '../../consts';
/**
 * Calculates geometry rect for plot's data zone
 *
 * This zone would be used for rendering data points
 *
 * @param XAxisZoneRect - Geometry rect of X axis zone
 * @param YAxisZoneRect - Geometry rect of Y axis zone
 * @returns Geometry rect
 */
export const calculateDataZoneRect = (XAxisZoneRect, YAxisZoneRect) => {
    const x = YAxisZoneRect.x + YAxisZoneRect.width + DEFAULT_DATA_ZONE_LEFT_PADDING;
    const y = YAxisZoneRect.y + DEFAULT_DATA_ZONE_TOP_PADDING;
    const height = XAxisZoneRect.y - DEFAULT_DATA_ZONE_BOTTOM_PADDING - y;
    const width = (XAxisZoneRect.width
        - DEFAULT_DATA_ZONE_LEFT_PADDING
        - DEFAULT_DATA_ZONE_RIGHT_PADDING);
    return {
        x,
        y,
        height,
        width,
    };
};
