import { LEFT_AXIS_SCALE_WIDTH, CANVAS_PADDING_WIDTH, DATA_POINT_RADIUS, DATA_POINT_STEP, } from '../../consts';
/**
 * Calculates step between two data points
 * If `containerWidth` arguments is set, calculates step to fit the data into container
 *
 * @param data - Plot's data instance
 * @param containerWidth - Container width to fit data items into
 * @param [defaultDataStep] - Data step which will be used if there are no data
 * @returns Step between data points
 */
export function dataStepCounter(data, containerWidth, defaultDataStep = DATA_POINT_STEP) {
    if (data.visiblePointsCount === 0) {
        return defaultDataStep;
    }
    const STATIC_WIDTH = LEFT_AXIS_SCALE_WIDTH + CANVAS_PADDING_WIDTH;
    const dataGroupsPadding = 2 * data.visibleGroupsCount * DATA_POINT_RADIUS;
    return (containerWidth - dataGroupsPadding - STATIC_WIDTH) / (data.visiblePointsCount);
}
