// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Spinner__spinner__2SZUp{animation:Spinner__spin__aKFV6 2s linear infinite}.Spinner__exportBtnSpinner__3AfTj{height:1.25rem;width:1.25rem}.Spinner__exportBtnSpinner__3AfTj path{fill:var(--button-background-color)}.Spinner__searchBoxSpinner__Ttylr{height:1.325rem;width:1.325rem}.Spinner__searchBoxSpinner__Ttylr path{fill:var(--placeholder-color)}@keyframes Spinner__spin__aKFV6{0%{transform:rotate(0deg)}to{transform:rotate(1turn)}}", "",{"version":3,"sources":["webpack://./shared/src/components/Spinner/Spinner.css"],"names":[],"mappings":"AAAA,yBACE,iDACF,CAEA,kCAEE,cAAe,CADf,aAMF,CAHE,uCACE,mCACF,CAGF,kCAEE,eAAgB,CADhB,cAMF,CAHE,uCACE,6BACF,CAGF,gCACE,GAAK,sBAAyB,CAC9B,GAAO,uBAA2B,CACpC","sourcesContent":[".spinner {\n  animation: spin 2s linear infinite;\n}\n\n.exportBtnSpinner {\n  width: 1.25rem;\n  height: 1.25rem;\n\n  & path {\n    fill: var(--button-background-color);\n  }\n}\n\n.searchBoxSpinner {\n  width: 1.325rem;\n  height: 1.325rem;\n\n  & path {\n    fill: var(--placeholder-color);\n  }\n}\n\n@keyframes spin {\n  0% { transform: rotate(0deg); }\n  100% { transform: rotate(360deg); }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner": "Spinner__spinner__2SZUp",
	"spin": "Spinner__spin__aKFV6",
	"exportBtnSpinner": "Spinner__exportBtnSpinner__3AfTj",
	"searchBoxSpinner": "Spinner__searchBoxSpinner__Ttylr"
};
export default ___CSS_LOADER_EXPORT___;
