import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, Fragment } from 'react';
import { getDataPointCoords } from '../../utils';
import { LinePlotThreshold } from '../LinePlotThreshold/LinePlotThreshold';
import { LinePlotThresholdIcon } from '../LinePlotThresholdIcon/LinePlotThresholdIcon';
export const LinePlotThresholds = memo(function LinePlotThresholds({ rect, dataRect, iconsRect, data, maxScaleValue, forExport, }) {
    const thresholds = data.map(({ id, value, color, description, }) => {
        const { x, y } = getDataPointCoords({ x: value, y: value }, maxScaleValue, dataRect, true);
        return (_jsxs(Fragment, { children: [_jsx(LinePlotThreshold, { x1: rect.x, x2: rect.x + rect.width, y: y, color: color, label: forExport ? description : undefined }, void 0), !forExport && description && (_jsx(LinePlotThresholdIcon, { x: x, y: y, width: iconsRect.width, color: color, description: description }, void 0))] }, `threshold_${id}`));
    });
    return (_jsx(_Fragment, { children: thresholds }, void 0));
});
