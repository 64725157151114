import { roundNumber } from '../../../../../../utils';
const getLabels = ({ lowerThreshold, upperThreshold, step, }) => {
    const labels = [];
    for (let i = lowerThreshold; i < upperThreshold; i += step) {
        labels.push(roundNumber(i, 2).toString());
    }
    return labels;
};
export const getScaleLabels = ({ min, max, step, }) => {
    let labels = [];
    if (max < min) {
        throw new Error(`FES Plot labels "max is less than min: ${max} < ${min}"`);
    }
    if (max <= 0 || min >= 0) {
        labels = getLabels({ lowerThreshold: min, upperThreshold: max, step });
    }
    else {
        const lowerZeroLabels = getLabels({ lowerThreshold: min, upperThreshold: 0, step });
        const upperZeroLabels = getLabels({ lowerThreshold: step, upperThreshold: max, step });
        labels = [...lowerZeroLabels, '0', ...upperZeroLabels];
    }
    labels.push(max.toString());
    return labels;
};
