import { Component } from 'react';
// This component uses class form because of `componentDidCatch` lifecycle method
export class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
        };
    }
    static getDerivedStateFromError() {
        return { hasError: true };
    }
    componentDidCatch(error, errorInfo) {
        const { logErrors = true } = this.props;
        if (logErrors) {
            const { logger } = this.props;
            logger.error(error);
            logger.error(errorInfo);
        }
    }
    render() {
        const { hasError } = this.state;
        const { children, errorPlaceholder, } = this.props;
        if (hasError && errorPlaceholder) {
            return errorPlaceholder;
        }
        return children;
    }
}
