const inlineStyles = {
    evenDomain: {
        fill: '#3c1053',
    },
    evenDomainLabel: {
        fill: '#ffffff',
        fontSize: '14px',
        transform: 'translate(-5px, 5px)',
    },
    oddDomain: {
        fill: '#c4d600',
    },
    oddDomainLabel: {
        fill: '#242b36',
        fontSize: '14px',
        transform: 'translate(-5px, 5x)',
    },
};
export default inlineStyles;
