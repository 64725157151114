/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useCallback, useMemo } from 'react';
import { DEFAULT_GROUP } from '../../consts';
const useMultiSelect = ({ value, options, onChange, getOptionValue, }) => {
    const valueMap = useMemo(() => {
        const map = new Map();
        value.forEach((option) => map.set(getOptionValue(option), option));
        return map;
    }, [getOptionValue, value]);
    const groupedItems = useMemo(() => {
        const [option] = options;
        if (option && option.children) {
            return options;
        }
        return [{
                id: DEFAULT_GROUP.id,
                label: DEFAULT_GROUP.name,
                children: options,
            }];
    }, [options]);
    const updateMap = useCallback((item, checked) => {
        const itemId = getOptionValue(item);
        if (checked) {
            valueMap.set(itemId, item);
        }
        else {
            valueMap.delete(itemId);
        }
    }, [valueMap, getOptionValue]);
    const handleSelect = useCallback((newOption, checked) => {
        if (newOption.children) {
            newOption.children.forEach((childItem) => updateMap(childItem, checked));
        }
        else {
            updateMap(newOption, checked);
        }
        onChange(Array.from(valueMap.values()));
    }, [onChange, valueMap, updateMap]);
    const handleSelectAll = useCallback(() => {
        onChange(groupedItems.map(({ children }) => children).flat());
    }, [onChange, groupedItems]);
    const handleRemove = useCallback((option) => {
        handleSelect(option, false);
    }, [handleSelect]);
    const handleRemoveAll = useCallback(() => {
        onChange([]);
    }, [onChange]);
    const countOfItems = useMemo(() => (groupedItems.reduce((acc, { children }) => (acc + children.length), 0)), [groupedItems]);
    return useMemo(() => ({
        valueMap,
        handleSelect,
        handleSelectAll,
        handleRemove,
        handleRemoveAll,
        groupedItems,
        countOfItems,
    }), [
        handleRemove,
        handleRemoveAll,
        handleSelect,
        handleSelectAll,
        valueMap,
        groupedItems,
        countOfItems,
    ]);
};
export default useMultiSelect;
