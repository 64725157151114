import { AXIS_LABEL_FONT_SIZE, THRESHOLD_AXIS_RECT_HEIGHT } from '../../consts';
const inlineStyles = {
    thresholdLabel: {
        fontSize: `${AXIS_LABEL_FONT_SIZE}px`,
        transform: 'translateY(-2px)',
        fill: '#fff',
    },
    thresholdRect: {
        height: `${THRESHOLD_AXIS_RECT_HEIGHT}px`,
        fill: '#830051',
    },
    thresholdIcon: {
        fill: '#ffffff80',
    },
};
export default inlineStyles;
