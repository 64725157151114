import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback, useEffect, useState, } from 'react';
import classNames from 'classnames';
import { Button } from '../../../../../Button';
import { SettingsListItem } from '../SettingsListItem/SettingsListItem';
import { COLUMNS_LABEL, HIDE_LABEL, RESET_LABEL, TABLE_LABEL, UNHIDE_LABEL, } from './consts';
import styles from '../../TableSettings.css';
import { MIN_COLUMNS_NUMBER_FOR_SCROLL } from '../../consts';
export const SettingsPanel = memo(function SettingsPanel({ columns, wrapperRef, onConfigChange, onConfigUnHide, onConfigReset, handleClose, initColumnsList, isHiddenColumns, }) {
    const [columnsList, setColumnsList] = useState(columns);
    useEffect(() => {
        setColumnsList(columns);
    }, [columns]);
    useEffect(() => {
        const handler = (e) => {
            var _a;
            const target = e.target;
            if (handleClose && !((_a = wrapperRef.current) === null || _a === void 0 ? void 0 : _a.contains(target))) {
                handleClose();
            }
        };
        document.addEventListener('click', handler, true);
        return () => {
            document.removeEventListener('click', handler, true);
        };
    }, [handleClose, wrapperRef]);
    const handleChange = useCallback((id, check) => {
        const index = parseInt(id, 10);
        const updatedArr = columnsList.map((column, i) => {
            if (i === index) {
                return (Object.assign(Object.assign({}, column), { hidden: !check }));
            }
            return column;
        });
        if (onConfigChange) {
            onConfigChange(updatedArr);
        }
    }, [columnsList, onConfigChange]);
    const handleMove = useCallback((dragIndex, hoverIndex) => {
        const updatedArr = [...columnsList];
        const [dragCard] = updatedArr.splice(dragIndex, 1);
        updatedArr.splice(hoverIndex, 0, dragCard);
        setColumnsList(updatedArr);
    }, [columnsList]);
    const handleDrop = useCallback(() => {
        if (onConfigChange) {
            onConfigChange(columnsList);
        }
    }, [columnsList, onConfigChange]);
    const handleUnHideButton = useCallback(() => {
        if (!isHiddenColumns && onConfigUnHide) {
            onConfigUnHide(columnsList);
        }
        if (isHiddenColumns && onConfigChange) {
            const updatedArr = columnsList.map((column) => (Object.assign(Object.assign({}, column), { hidden: !column.fixed })));
            onConfigChange(updatedArr);
        }
    }, [onConfigUnHide, columnsList, isHiddenColumns, onConfigChange]);
    const handleResetButton = useCallback(() => {
        if (onConfigReset) {
            onConfigReset();
        }
    }, [onConfigReset]);
    const isDisabledButton = JSON.stringify(columnsList) === initColumnsList;
    const columnOrganizerClass = classNames(styles.columnsOrganizer, {
        [styles.scrollableColumnsOrganizer]: columnsList.length > MIN_COLUMNS_NUMBER_FOR_SCROLL,
    });
    return (_jsxs("div", Object.assign({ className: styles.menu }, { children: [_jsx("p", { children: TABLE_LABEL }, void 0), _jsxs("div", Object.assign({ className: styles.tableOrganizer }, { children: [_jsx(Button, Object.assign({ testId: "Reset.BTN", onClick: handleResetButton, className: styles.button, disabled: isDisabledButton }, { children: RESET_LABEL }), void 0), _jsx(Button, Object.assign({ testId: "Unhide.BTN", onClick: handleUnHideButton, className: styles.button }, { children: isHiddenColumns ? HIDE_LABEL : UNHIDE_LABEL }), void 0)] }), void 0), _jsx("p", { children: COLUMNS_LABEL }, void 0), _jsx("ul", Object.assign({ className: columnOrganizerClass, "data-testid": "columnsOrganizer" }, { children: columnsList.map((column, index) => (_jsx(SettingsListItem, { "data-testid": column.accessor, id: column.accessor, column: column, index: index, handleChange: handleChange, handleMove: handleMove, handleDrop: handleDrop }, column.accessor))) }), void 0)] }), void 0));
});
