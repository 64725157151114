import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback } from 'react';
import { DEFAULT_PLOT_HEIGHT, DEFAULT_PLOT_WIDTH } from '../consts';
import { PlotBorders, PlotAxisX, PlotAxisY } from '../components';
import { useZoneConstructor } from './hooks';
import FESPlotData from './components/FESPlotData/FESPlotData';
import { getAxisScaleLineStyles } from './utils';
const FESPlot = ({ data, height = DEFAULT_PLOT_HEIGHT, width = DEFAULT_PLOT_WIDTH, plotSettings, axisTitles, tooltipContent, forExport = false, dashedLineLabel, labelYFormatter, }) => {
    const { axisZone, XAxisLabelZone, YAxisLabelZone, XAxisZone, YAxisZone, dataZone, } = useZoneConstructor(height, width, data, plotSettings, axisTitles, labelYFormatter);
    const calculateAxisScaleLineStyles = useCallback((label, orientation) => getAxisScaleLineStyles(label, orientation, dashedLineLabel), [dashedLineLabel]);
    return (_jsx("svg", Object.assign({ height: height, width: width, viewBox: `0 0 ${width} ${height}`, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsxs("g", Object.assign({ height: height, width: width, transform: "translate(0)" }, { children: [_jsx(PlotBorders, { axisZone: axisZone }, void 0), _jsx(PlotAxisX, { axisZone: XAxisZone, axisLabelZone: XAxisLabelZone }, void 0), _jsx(PlotAxisY, { axisZone: YAxisZone, axisLabelZone: YAxisLabelZone, inlineStylesGetter: calculateAxisScaleLineStyles }, void 0), _jsx(FESPlotData, { dataZone: dataZone, tooltipContent: tooltipContent, forExport: forExport }, void 0)] }), void 0) }), void 0));
};
export default memo(FESPlot);
