import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import classnames from 'classnames';
import { DOMAINS_BORDER_PADDING, LOLLIPOP_RADIUS, Y_CANVAS_PADDING } from '../../consts';
import { LollipopVariantType } from '../../types';
import styles from './LollipopPlotDataLine.css';
import inlineStyles from './LollipopPlotDataLine.style';
const LollipopPlotDataLine = memo(function LollipopPlotDataLine(props) {
    const { type = LollipopVariantType.Active, data, positionX, positionY, getPositionOnAxisX, getPositionOnAxisY, useInlineStyles, } = props;
    const xRelative = getPositionOnAxisX(data.codon);
    const x = positionX + xRelative;
    const yRelative = getPositionOnAxisY(data.count);
    const y1 = positionY;
    const y2 = (positionY
        - yRelative
        - DOMAINS_BORDER_PADDING
        - Y_CANVAS_PADDING
        + LOLLIPOP_RADIUS);
    let lineStyle;
    if (!useInlineStyles) {
        lineStyle = undefined;
    }
    else if (type === LollipopVariantType.Active) {
        lineStyle = inlineStyles.line;
    }
    else if (type === LollipopVariantType.Inactive) {
        lineStyle = inlineStyles.lineInactive;
    }
    const lineClassName = classnames({
        [styles.line]: type === LollipopVariantType.Active,
        [styles.lineInactive]: type === LollipopVariantType.Inactive,
    });
    return (_jsx("line", { className: lineClassName, style: lineStyle, x1: x, x2: x, y1: y1, y2: y2 }, void 0));
});
export default LollipopPlotDataLine;
