import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import classnames from 'classnames';
import { Button } from '../Button';
import { RESET_FILTERS_BUTTON_LABEL } from './consts';
import RotateSyncIcon from '../../assets/icons/rotateSync.svg';
import styles from './ResetFiltersIconButton.css';
const ResetFiltersIconButton = ({ onClick, className, disabled, }) => (_jsxs(Button, Object.assign({ onClick: onClick, className: classnames(className, styles.resetFiltersIconButton), testId: "resetFiltersIconButton", disabled: disabled }, { children: [_jsx(RotateSyncIcon, { disabled: disabled, className: styles.icon }, void 0), _jsx("div", { children: RESET_FILTERS_BUTTON_LABEL }, void 0)] }), void 0));
const MemoizedResetFiltersIconButton = memo(ResetFiltersIconButton);
export { MemoizedResetFiltersIconButton as ResetFiltersIconButton };
