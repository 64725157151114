// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MultiSelectFieldItem__option__2gpBb{color:var(--font-color);font-size:.875rem;padding:0 .375rem}.MultiSelectFieldItem__active__w_OKY{background-color:var(--dropdown-hover)}.MultiSelectFieldItem__checkbox__2WbqR{display:flex;margin:0}.MultiSelectFieldItem__checkbox__2WbqR>label{flex-grow:2;padding:.375rem 0}.MultiSelectFieldItem__input__1WdWP{top:.375rem;transform:translateY(0)}", "",{"version":3,"sources":["webpack://./shared/src/components/MultiSelectField/components/MultiSelectFieldItem/MultiSelectFieldItem.css"],"names":[],"mappings":"AAAA,qCACE,uBAAwB,CAExB,iBAAmB,CADnB,iBAEF,CAEA,qCACE,sCACF,CAEA,uCACE,YAAa,CACb,QAMF,CAJE,6CAEE,WAAY,CADZ,iBAEF,CAGF,oCACE,WAAa,CACb,uBACF","sourcesContent":[".option {\n  color: var(--font-color);\n  padding: 0 0.375rem;\n  font-size: 0.875rem;\n}\n\n.active {\n  background-color: var(--dropdown-hover);\n}\n\n.checkbox {\n  display: flex;\n  margin: 0;\n\n  & > label {\n    padding: 0.375rem 0;\n    flex-grow: 2;\n  }\n}\n\n.input {\n  top: 0.375rem;\n  transform: translateY(0);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"option": "MultiSelectFieldItem__option__2gpBb",
	"active": "MultiSelectFieldItem__active__w_OKY",
	"checkbox": "MultiSelectFieldItem__checkbox__2WbqR",
	"input": "MultiSelectFieldItem__input__1WdWP"
};
export default ___CSS_LOADER_EXPORT___;
