import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useMemo, } from 'react';
import classnames from 'classnames';
import { useChunkedData } from '../../../../../hooks';
import { useTooltip } from '../../../../hooks';
import { DATA_POINT_STEP, DEFAULT_DATA_GROUP_CHUNK_SIZE, TOOLTIP_ID, PLOT_TOP_PADDING, } from '../../consts';
import { EDataPointOrder, } from '../../types';
import ManhattanPlotDataGroup from '../ManhattanPlotDataGroup/ManhattanPlotDataGroup';
import ManhattanPlotLayer from '../ManhattanPlotLayer/ManhattanPlotLayer';
import ManhattanPlotDataPointTooltip from '../ManhattanPlotDataPointTooltip/ManhattanPlotDataPointTooltip';
import styles from './ManhattanPlotData.css';
const ManhattanPlotData = (props) => {
    const { data, height, scaleRange, groupsDimensions, dataLimits, dataPointStep = DATA_POINT_STEP, useInlineStyles, withChunkedData = false, getTooltipContent, getDataPointShape, datasets, levelResultsCollection, traitsTypesCollection, hasPlotSettings, topOffset = 0, } = props;
    const [chunkedData, isInProgress] = useChunkedData(data.getGroups(), DEFAULT_DATA_GROUP_CHUNK_SIZE, !withChunkedData);
    const { tooltipId, tooltipAPI } = useTooltip(TOOLTIP_ID);
    const { handleTooltipShowWithDelay: handleTooltipShow, handleTooltipHideWithDelay: handleTooltipHide, } = tooltipAPI;
    const dataGroups = useMemo(() => (chunkedData.map((dataGroup, index) => {
        var _a;
        const key = dataGroup.groupId;
        const { widthOffset = 0, } = (_a = groupsDimensions[index]) !== null && _a !== void 0 ? _a : {};
        const dataPointOrder = index % 2 === 0
            ? EDataPointOrder.Even
            : EDataPointOrder.Odd;
        return (_jsx(ManhattanPlotDataGroup, { dataPointOrder: dataPointOrder, widthOffset: widthOffset, height: height, data: dataGroup.getPoints(), dataLimits: dataLimits, scaleRange: scaleRange, dataPointStep: dataPointStep, useInlineStyles: useInlineStyles, handleTooltipShow: handleTooltipShow, handleTooltipHide: handleTooltipHide, getDataPointShape: getDataPointShape, datasets: datasets, levelResultsCollection: levelResultsCollection, traitsTypesCollection: traitsTypesCollection, hasPlotSettings: hasPlotSettings }, key));
    })), [
        datasets,
        levelResultsCollection,
        traitsTypesCollection,
        chunkedData,
        groupsDimensions,
        height,
        scaleRange,
        useInlineStyles,
        dataPointStep,
        dataLimits,
        handleTooltipShow,
        handleTooltipHide,
        getDataPointShape,
        hasPlotSettings,
    ]);
    const layerClassName = classnames({
        [styles.isInProgress]: isInProgress,
    });
    const topPadding = PLOT_TOP_PADDING - topOffset;
    return (_jsxs(ManhattanPlotLayer, Object.assign({ className: layerClassName, topPadding: topPadding }, { children: [_jsx(ManhattanPlotDataPointTooltip, { tooltipId: tooltipId, tooltipContent: getTooltipContent, tooltipAPI: tooltipAPI }, void 0), dataGroups] }), void 0));
};
/** Since memo loses generic props */
export default memo(ManhattanPlotData);
