import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo, memo } from 'react';
import { Tooltip, ETooltipPlacement, ETooltipAction, } from '../../../../../components/Tooltip';
import { getPortalContainer } from '../../../../../utils';
export const LinePlotPlotThresholdTooltip = memo(function LinePlotPlotThresholdTooltip(props) {
    const { children, description } = props;
    const offset = useMemo(() => [10, 0], []);
    const rootContainer = useMemo(() => getPortalContainer(), []);
    return (_jsx(Tooltip, Object.assign({ tooltipContent: description, action: ETooltipAction.Hover, placement: ETooltipPlacement.TopCenter, container: rootContainer, offset: offset }, { children: children }), void 0));
});
