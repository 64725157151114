// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DefaultContactText__layout__3eYTp{color:var(--font-color);font-family:arial,sans-serif;font-size:1.25rem;font-weight:400;line-height:1.75rem;margin:0 0 1.5rem;text-align:center}.DefaultContactText__emailLink__3BhOp{color:var(--link-color);text-decoration:none}.DefaultContactText__emailLink__3BhOp:hover{text-decoration:underline}", "",{"version":3,"sources":["webpack://./shared/src/components/ErrorPage/components/DefaultContactText/DefaultContactText.css"],"names":[],"mappings":"AAAA,mCAME,uBAAwB,CAHxB,4BAAgC,CAFhC,iBAAkB,CAGlB,eAAgB,CAFhB,mBAAoB,CAGpB,iBAAkB,CAElB,iBACF,CAEA,sCACE,uBAAwB,CACxB,oBACF,CAEA,4CACE,yBACF","sourcesContent":[".layout {\n  font-size: 1.25rem;\n  line-height: 1.75rem;\n  font-family: 'arial', sans-serif;\n  font-weight: 400;\n  margin: 0 0 1.5rem;\n  color: var(--font-color);\n  text-align: center;\n}\n\n.emailLink {\n  color: var(--link-color);\n  text-decoration: none;\n}\n\n.emailLink:hover {\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": "DefaultContactText__layout__3eYTp",
	"emailLink": "DefaultContactText__emailLink__3BhOp"
};
export default ___CSS_LOADER_EXPORT___;
