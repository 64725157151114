import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useTooltip } from '../../../../../hooks';
import { TOOLTIP_ID } from '../../consts';
import FESPlotTooltip from '../FESPlotTooltip/FESPlotTooltip';
import FESPlotDataPoint from '../FESPlotDataPoint/FESPlotDataPoint';
const FESPlotData = ({ dataZone: { value: data, }, tooltipContent, forExport, }) => {
    const { tooltipId, tooltipAPI } = useTooltip(TOOLTIP_ID);
    let handleTooltipShow;
    let handleTooltipHide;
    if (!forExport) {
        const { handleTooltipShowWithDelay, handleTooltipHideWithDelay, } = tooltipAPI;
        handleTooltipShow = handleTooltipShowWithDelay;
        handleTooltipHide = handleTooltipHideWithDelay;
    }
    return (_jsxs(_Fragment, { children: [!forExport && (_jsx(FESPlotTooltip, { tooltipId: tooltipId, tooltipContent: tooltipContent, tooltipAPI: tooltipAPI }, void 0)), data
                .sort((a, b) => (a.value.levelResult < b.value.levelResult ? 1 : -1))
                .map((dataPointZone) => (_jsx(FESPlotDataPoint, { dataPointZone: dataPointZone, handleTooltipShow: handleTooltipShow, handleTooltipHide: handleTooltipHide }, dataPointZone.value.id)))] }, void 0));
};
export default memo(FESPlotData);
