import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import classnames from 'classnames';
import { InfoTooltip } from '../../../InfoTooltip/InfoTooltip';
import { Spinner } from '../../../Spinner';
import { ETooltipPlacement, ETooltipAction } from '../../../Tooltip/consts';
import InfoIconDisabled from '../../../../assets/icons/info.svg';
import { EIconType } from '../../consts';
import styles from './ExportButtonIcon.css';
import spinnerStyles from '../../../Spinner/Spinner.css';
const defaultTitle = (_jsxs("span", { children: [_jsx("strong", { children: "Plot: " }, void 0), "filtered data in", ' ', _jsx("i", { children: "svg" }, void 0), _jsx("br", {}, void 0), _jsx("strong", { children: "Table: " }, void 0), "filtered data in", ' ', _jsx("i", { children: "csv" }, void 0)] }, void 0));
export const ExportButtonIcon = memo(function ExportButtonIcon(props) {
    const { iconType, title = defaultTitle, spinnerClassName, } = props;
    if (iconType === EIconType.Disabled) {
        return (_jsx("span", Object.assign({ className: styles.disabled }, { children: _jsx(InfoIconDisabled, {}, void 0) }), void 0));
    }
    if (iconType === EIconType.IsLoading) {
        return (_jsx("span", Object.assign({ className: styles.info }, { children: _jsx(Spinner, { className: classnames(spinnerStyles.exportBtnSpinner, spinnerClassName) }, void 0) }), void 0));
    }
    return (_jsx("span", Object.assign({ className: styles.info }, { children: _jsx(InfoTooltip, Object.assign({ targetEvent: ETooltipAction.Hover, position: ETooltipPlacement.BottomCenter, id: "export", overlayClassName: styles.infoIcon }, { children: title }), void 0) }), void 0));
});
