/* eslint-disable @typescript-eslint/indent */
/**
 * Creates element's zone data structure
 *
 * These zones are used by plot's components to render primitives
 * and other composite parts
 *
 * @param rect - Element's zone position rectangle
 * @param value - Element's zone associated value
 * @returns Element's zone data structure
 */
export const createZone = (rect, value) => ({
    rect,
    value,
});
