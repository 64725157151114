import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { DEFAULT_AXIS_BORDER_WIDTH } from '../../consts';
import styles from './LollipopPlotAxisLine.css';
import inlineStyles from './LollipopPlotAxisLine.style';
const LollipopPlotAxisLine = memo(function LollipopPlotAxisLine(props) {
    const { x1, x2, y1, y2, width = DEFAULT_AXIS_BORDER_WIDTH, useInlineStyles, } = props;
    const borderStyle = useInlineStyles
        ? inlineStyles.border
        : undefined;
    return (_jsx("line", { className: styles.border, style: borderStyle, x1: x1, x2: x2, y1: y1, y2: y2, width: width }, void 0));
});
export default LollipopPlotAxisLine;
