/**
 * Util generates array of numbers starting for the given range with the given step
 * Range includes lower boundary and does not include upper one (i.e. [A, B))
 *
 * If all arguments are ommited, an empty array will be returned
 * If only one argument is set, then it will return range from 0 to the given argument
 *
 * Range arguments are safe ordered
 * (i.e. they will be swapped to remain correct orderingrelevant to the given step)
 *
 * @param [from] - Range start value
 * @param [to] - Range stop value
 * @param [step = 1] - Range step
 * @returns Array of numbers from range
 */
export function range(from, to, step = 1) {
    if (step === 0) {
        throw new Error('Range step cannot be 0');
    }
    let actualFrom = from !== null && from !== void 0 ? from : 0;
    let actualTo = to;
    if (actualTo === undefined) {
        [actualTo, actualFrom] = [actualFrom, 0];
    }
    if (actualFrom === actualTo) {
        return [];
    }
    if ((step > 0 && actualFrom > actualTo) || (step < 0 && actualFrom < actualTo)) {
        [actualTo, actualFrom] = [actualFrom, actualTo];
    }
    return Array.from({ length: Math.ceil((actualTo - actualFrom) / step) }, (_, index) => actualFrom + index * step);
}
