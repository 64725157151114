// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Legend__sectionWrapper__1y5XS{box-shadow:none}.Legend__headerContent__3XVTx,.Legend__sectionHeaderContainer__23ClA{padding:0}.Legend__legendTitle__2SugS{line-height:18px;padding:1rem 5rem}.Legend__itemList__3FACZ{border-top:none;padding-top:0}.Legend__icon__1weq-{margin-right:5rem}", "",{"version":3,"sources":["webpack://./shared/src/plots/components/Legend/Legend.css"],"names":[],"mappings":"AAAA,+BACE,eACF,CAEA,qEAEE,SACF,CAEA,4BACE,gBAAiB,CACjB,iBACF,CAEA,yBAEE,eAAgB,CADhB,aAEF,CAEA,qBACE,iBACF","sourcesContent":[".sectionWrapper {\n  box-shadow: none;\n}\n\n.sectionHeaderContainer,\n.headerContent {\n  padding: 0;\n}\n\n.legendTitle {\n  line-height: 18px;\n  padding: 1rem 5rem;\n}\n\n.itemList {\n  padding-top: 0;\n  border-top: none;\n}\n\n.icon {\n  margin-right: 5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectionWrapper": "Legend__sectionWrapper__1y5XS",
	"headerContent": "Legend__headerContent__3XVTx",
	"sectionHeaderContainer": "Legend__sectionHeaderContainer__23ClA",
	"legendTitle": "Legend__legendTitle__2SugS",
	"itemList": "Legend__itemList__3FACZ",
	"icon": "Legend__icon__1weq-"
};
export default ___CSS_LOADER_EXPORT___;
