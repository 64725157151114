import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import classnames from 'classnames';
import styles from './Section.css';
export var ESectionType;
(function (ESectionType) {
    ESectionType["Minimized"] = "Minimized";
    ESectionType["Default"] = "Default";
})(ESectionType || (ESectionType = {}));
export const Section = memo(function Section(props) {
    const { children, className, type = ESectionType.Default, testId, } = props;
    const sectionClassName = classnames(styles.layout, {
        [styles.minimized]: type === ESectionType.Minimized,
        [styles.default]: type === ESectionType.Default,
    }, className);
    return (_jsx("section", Object.assign({ className: sectionClassName, "data-testid": testId }, { children: children }), void 0));
});
