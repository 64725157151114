// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GlobalFiltersDivider__divider__3GwK2{border-bottom:1px solid var(--content-shadow-color);margin:0 calc(var(--global-filters-horizontal-padding)*-1);width:calc(100% + var(--global-filters-horizontal-padding)*2)}", "",{"version":3,"sources":["webpack://./shared/src/components/GlobalFiltersDivider/GlobalFiltersDivider.css"],"names":[],"mappings":"AAAA,sCAGE,mDAAoD,CADpD,0DAA6D,CAD7D,6DAGF","sourcesContent":[".divider {\n  width: calc(100% + calc(2 * var(--global-filters-horizontal-padding)));\n  margin: 0 calc(-1 * var(--global-filters-horizontal-padding));\n  border-bottom: 1px solid var(--content-shadow-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"divider": "GlobalFiltersDivider__divider__3GwK2"
};
export default ___CSS_LOADER_EXPORT___;
