// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TooltipLinkItem__item__QTnKD{line-height:1.2rem;margin-bottom:.25rem}.TooltipLinkItem__value__2gUD4{word-wrap:break-word;vertical-align:middle}.TooltipLinkItem__link__3WqUl{margin-right:.625rem;white-space:nowrap}", "",{"version":3,"sources":["webpack://./shared/src/components/Tooltip/components/TooltipLinkItem/TooltipLinkItem.css"],"names":[],"mappings":"AAAA,8BACE,kBAAmB,CACnB,oBACF,CAEA,+BACE,oBAAqB,CACrB,qBACF,CAEA,8BACE,oBAAsB,CACtB,kBACF","sourcesContent":[".item {\n  line-height: 1.2rem;\n  margin-bottom: 0.25rem;\n}\n\n.value {\n  word-wrap: break-word;\n  vertical-align: middle;\n}\n\n.link {\n  margin-right: 0.625rem;\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "TooltipLinkItem__item__QTnKD",
	"value": "TooltipLinkItem__value__2gUD4",
	"link": "TooltipLinkItem__link__3WqUl"
};
export default ___CSS_LOADER_EXPORT___;
