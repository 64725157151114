export const geneLinkSort = (rowA, rowB, columnId) => {
    var _a, _b, _c, _d;
    const labelA = rowA.values[columnId]
        || ((_b = (_a = rowA.original) === null || _a === void 0 ? void 0 : _a.gene) === null || _b === void 0 ? void 0 : _b.name)
        || '';
    const labelB = rowB.values[columnId]
        || ((_d = (_c = rowB.original) === null || _c === void 0 ? void 0 : _c.gene) === null || _d === void 0 ? void 0 : _d.name)
        || '';
    return labelA.localeCompare(labelB);
};
