export * from './handlers';
export * from './models';
export * from './geometry';
export var EImpactLevel;
(function (EImpactLevel) {
    EImpactLevel["High"] = "high_impact";
    EImpactLevel["Moderate"] = "moderate_impact";
    EImpactLevel["Low"] = "low_impact";
    EImpactLevel["Modifier"] = "modifier_impact";
})(EImpactLevel || (EImpactLevel = {}));
export var EDataPointShape;
(function (EDataPointShape) {
    EDataPointShape[EDataPointShape["Circle"] = 0] = "Circle";
    EDataPointShape[EDataPointShape["TriangleUp"] = 1] = "TriangleUp";
    EDataPointShape[EDataPointShape["TriangleDown"] = 2] = "TriangleDown";
    EDataPointShape[EDataPointShape["Square"] = 3] = "Square";
})(EDataPointShape || (EDataPointShape = {}));
export var EPlotOrientation;
(function (EPlotOrientation) {
    EPlotOrientation[EPlotOrientation["Horizontal"] = 0] = "Horizontal";
    EPlotOrientation[EPlotOrientation["Vertical"] = 1] = "Vertical";
})(EPlotOrientation || (EPlotOrientation = {}));
export var EConsequenceType;
(function (EConsequenceType) {
    EConsequenceType["ExonLossVariant"] = "exon_loss_variant";
    EConsequenceType["FrameShiftVariant"] = "frameshift_variant";
    EConsequenceType["StartLost"] = "start_lost";
    EConsequenceType["StopGained"] = "stop_gained";
    EConsequenceType["StopLost"] = "stop_lost";
    EConsequenceType["SpliceAcceptorVariant"] = "splice_acceptor_variant";
    EConsequenceType["SpliceDonorVariant"] = "splice_donor_variant";
    EConsequenceType["GeneFusion"] = "gene_fusion";
    EConsequenceType["BidirectionalGeneFusion"] = "bidirectional_gene_fusion";
    EConsequenceType["RareAminoAcidVariant"] = "rare_amino_acid_variant";
    EConsequenceType["TranscriptAblation"] = "transcript_ablation";
    EConsequenceType["ConservativeInframeDeletion"] = "conservative_inframe_deletion";
    EConsequenceType["ConservativeInframeInsertion"] = "conservative_inframe_insertion";
    EConsequenceType["DisruptiveInframeInsertion"] = "disruptive_inframe_insertion";
    EConsequenceType["DisruptiveInframeDeletion"] = "disruptive_inframe_deletion";
    EConsequenceType["MissenseVariantAndSpliceRegionVariant"] = "missense_variant&splice_region_variant";
    EConsequenceType["MissenseVariant"] = "missense_variant";
    EConsequenceType["ProteinAlteringVariant"] = "protein_altering_variant";
    EConsequenceType["SynonymousVariant"] = "synonymous_variant";
    EConsequenceType["SpliceRegionVariant"] = "splice_region_variant";
    EConsequenceType["ThreePrimeUtrVariant"] = "3_prime_UTR_variant";
    EConsequenceType["FivePrimeUtrVariant"] = "5_prime_UTR_variant";
    EConsequenceType["SynonymounsVariantAndSpliceRegionVariant"] = "synonymous_variant&splice_region_variant";
    EConsequenceType["FivePrimeUtrPrematureStartCodonGainVariant"] = "5_prime_UTR_premature_start_codon_gain_variant";
    EConsequenceType["StopRetainedVariant"] = "stop_retained_variant";
    EConsequenceType["InitiatorCodonVariant"] = "initiator_codon_variant";
    EConsequenceType["StopRetainedVariantAndSpliceRegionVariant"] = "stop_retained_variant&splice_region_variant";
    EConsequenceType["IntragenicVariant"] = "intragenic_variant";
    EConsequenceType["FivePrimeUtrTruncation"] = "5_prime_UTR_truncation";
    EConsequenceType["TranscriptAmplification"] = "transcript_amplification";
    EConsequenceType["Duplication"] = "duplication";
    EConsequenceType["Inversion"] = "inversion";
    EConsequenceType["FeatureAblation"] = "feature_ablation";
    EConsequenceType["RearrangedAtDNALevel"] = "rearranged_at_DNA_level";
    EConsequenceType["ProteinProteinContact"] = "protein_protein_contact";
    EConsequenceType["StructuralInteractionVariant"] = "structural_interaction_variant";
    EConsequenceType["InframeInsertion"] = "inframe_insertion";
    EConsequenceType["InframeDeletion"] = "inframe_deletion";
    EConsequenceType["RegulatoryRegionAblation"] = "regulatory_region_ablation";
    EConsequenceType["ThreePrimeUTRTruncation"] = "3_prime_UTR_truncation";
    EConsequenceType["CodingSequenceVariant"] = "coding_sequence_variant";
    EConsequenceType["StartRetainedVariant"] = "start_retained_variant";
    EConsequenceType["IncompleteTerminalCodonVariant"] = "incomplete_terminal_codon_variant";
    EConsequenceType["Chromosome"] = "chromosome";
    EConsequenceType["ExonVariant"] = "exon_variant";
    EConsequenceType["FeatureElongation"] = "feature_elongation";
    EConsequenceType["FeatureTruncation"] = "feature_truncation";
    EConsequenceType["GeneVariant"] = "gene_variant";
    EConsequenceType["NMDTranscriptVariant"] = "NMD_transcript_variant";
    EConsequenceType["NonCodingTranscriptExonVariant"] = "non_coding_transcript_exon_variant";
    EConsequenceType["NonCodingTranscriptVariant"] = "non_coding_transcript_variant";
    EConsequenceType["RegulatoryRegionAmplification"] = "regulatory_region_amplification";
    EConsequenceType["RegulatoryRegionVariant"] = "regulatory_region_variant";
    EConsequenceType["TranscriptVariant"] = "transcript_variant";
    EConsequenceType["SequenceFeatureAndExonLossVariant"] = "sequence_feature&exon_loss_variant";
    EConsequenceType["ConservedIntronVariant"] = "conserved_intron_variant";
    EConsequenceType["IntronVariant"] = "intron_variant";
    EConsequenceType["DownStreamGeneVariant"] = "downstream_gene_variant";
    EConsequenceType["UpstreamGeneVariant"] = "upstream_gene_variant";
    EConsequenceType["ConservedIntergenicVariant"] = "conserved_intergenic_variant";
    EConsequenceType["IntergenicRegion"] = "intergenic_region";
    EConsequenceType["MatureMiRNAVariant"] = "mature_miRNA_variant";
    EConsequenceType["TFBSAblation"] = "TFBS_ablation";
    EConsequenceType["MiRNA"] = "miRNA";
    EConsequenceType["TFBindingSiteVariant"] = "TF_binding_site_variant";
    EConsequenceType["TFBSAmplification"] = "TFBS_amplification";
})(EConsequenceType || (EConsequenceType = {}));
