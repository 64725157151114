/**
 * Gets max possible value from data points collection
 *
 * @param maxValue - maximum
 * @returns max value
 */
export const getMaxDataPointValues = (maxValue) => {
    if (maxValue <= 1) {
        return Math.ceil(maxValue * 10) / 10;
    }
    if (maxValue <= 2) {
        const tmp = Math.ceil(maxValue * 10);
        return tmp % 2 === 1 ? (tmp + 1) / 10 : tmp / 10;
    }
    if (maxValue <= 10) {
        return Math.ceil(maxValue);
    }
    if (maxValue <= 20) {
        return Math.ceil(maxValue) % 2
            ? Math.ceil(maxValue) + 1
            : Math.ceil(maxValue);
    }
    if (maxValue < 100) {
        const tmp = Math.ceil(maxValue).toString();
        return (parseInt(tmp[0], 10) + 1) * Math.pow(10, (tmp.length - 1));
    }
    const tmp = (Math.ceil(maxValue).toString());
    return parseInt(tmp.slice(0, 2), 10) % 2
        ? (parseInt(tmp.slice(0, 2), 10) + 1) * Math.pow(10, (tmp.length - 2))
        : (parseInt(tmp.slice(0, 2), 10) + 2) * Math.pow(10, (tmp.length - 2));
};
