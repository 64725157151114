import { timeConstants, radixParameter } from './consts';
import { getTotalMs } from '../getTotalMs/getTotalMs';
/**
 * Calculates values(days, hours, minutes, seconds) from the number of milliseconds
 *
 * @param time - A moment in the future
 * @param addedTime - The amount of time for automatic extension
 * @returns - Object (days, hours, minutes, seconds): string
 */
export const getFormattedTime = (timeMs, addedTime) => {
    const totalMs = getTotalMs(parseInt(timeMs, radixParameter), parseInt(addedTime, radixParameter));
    const { msInSecond, secInMinute, minInHour, hoursInDay, } = timeConstants;
    const seconds = `${Math.floor((totalMs / msInSecond) % secInMinute)}`;
    const minutes = `${Math.floor((totalMs / msInSecond / secInMinute) % minInHour)}`;
    const hours = `${Math.floor((totalMs / (msInSecond * secInMinute * minInHour)) % hoursInDay)}`;
    const days = `${Math.floor(totalMs / (msInSecond * secInMinute * minInHour * hoursInDay))}`;
    return {
        totalMs,
        days,
        hours,
        minutes,
        seconds,
    };
};
