import { useRef, useState, useCallback, useEffect, } from 'react';
import { useWindowResize } from '../../../hooks';
import { DEFAULT_RESIZE_TIMEOUT } from '../../components/ManhattanPlot/consts';
/**
 * Hook recalculates SVG container width after window resize
 *
 * @returns
 *  - Updated container width
 *  - Ref to track container width
 */
export function useResizeContainer() {
    const containerRef = useRef(null);
    const [containerWidth, setContainerWidth] = useState(0);
    const adjustWidth = useCallback(() => {
        const element = containerRef.current;
        /**
         * Avoid corner case when container's width becomes zero
         * (e.g. container became hidden / removed from the render tree)
         */
        if (element && element.clientWidth > 0) {
            setContainerWidth(element.clientWidth);
        }
    }, []);
    useEffect(() => {
        adjustWidth();
    }, [adjustWidth]);
    useWindowResize(adjustWidth, DEFAULT_RESIZE_TIMEOUT);
    return {
        containerWidth,
        containerRef,
    };
}
