// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Container__container__3lsTa{display:flex;flex-direction:column}.Container__label__3MQWe{font-size:.75rem;font-weight:500;margin-bottom:.5rem}.Container__leftPadding__1YK5w{margin-left:4.75rem}", "",{"version":3,"sources":["webpack://./shared/src/plots/components/ManhattanPlot/components/PlotSettings/components/Container/Container.css"],"names":[],"mappings":"AAAA,6BACE,YAAa,CACb,qBACF,CAEA,yBACE,gBAAkB,CAClB,eAAgB,CAChB,mBACF,CAEA,+BACE,mBACF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n}\n\n.label {\n  font-size: 0.75rem;\n  font-weight: 500;\n  margin-bottom: 0.5rem;\n}\n\n.leftPadding {\n  margin-left: 4.75rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Container__container__3lsTa",
	"label": "Container__label__3MQWe",
	"leftPadding": "Container__leftPadding__1YK5w"
};
export default ___CSS_LOADER_EXPORT___;
