// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LollipopPlotAxisLabel__label__2NNeQ{fill:var(--lollipop-plot-axis-label-color);font-size:.75rem;font-weight:500}", "",{"version":3,"sources":["webpack://./shared/src/plots/components/LollipopPlot/components/LollipopPlotAxisLabel/LollipopPlotAxisLabel.css"],"names":[],"mappings":"AAAA,qCAGE,0CAA2C,CAF3C,gBAAkB,CAClB,eAEF","sourcesContent":[".label {\n  font-size: 0.75rem;\n  font-weight: 500;\n  fill: var(--lollipop-plot-axis-label-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "LollipopPlotAxisLabel__label__2NNeQ"
};
export default ___CSS_LOADER_EXPORT___;
