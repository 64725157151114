import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, useMemo, Fragment } from 'react';
import classnames from 'classnames';
import styles from './LollipopPlotAxisScale.css';
import inlineStyles from './LollipopPlotAxisScale.style';
const LollipopPlotAxisScale = memo(function LollipopPlotAxisScale(props) {
    const { scalePosition = [], textAnchor = 'middle', useInlineStyles, } = props;
    const baseTextStyle = useInlineStyles
        ? inlineStyles.text
        : undefined;
    const baseLineStyle = useInlineStyles
        ? inlineStyles.line
        : undefined;
    const scaleItems = useMemo(() => scalePosition.map((scalePositionItem) => {
        const { textPosition: { x, y }, linePosition: { x1, x2, y1, y2, }, textClassName: scaleTextClassName, textStyle: scaleTextStyle, lineClassName: scaleLineClassName, lineStyle: scaleLineStyle, label, } = scalePositionItem;
        const textClassName = classnames(styles.text, scaleTextClassName);
        const lineClassName = classnames(styles.line, scaleLineClassName);
        const textStyle = (useInlineStyles && scaleTextStyle)
            ? Object.assign(Object.assign({}, baseTextStyle), scaleTextStyle) : baseTextStyle;
        const lineStyle = (useInlineStyles && scaleLineStyle)
            ? Object.assign(Object.assign({}, baseLineStyle), scaleLineStyle) : baseLineStyle;
        return (_jsxs(Fragment, { children: [_jsx("line", { className: lineClassName, style: lineStyle, x1: x1, x2: x2, y1: y1, y2: y2 }, void 0), label && (_jsx("text", Object.assign({ className: textClassName, style: textStyle, x: x, y: y, textAnchor: textAnchor }, { children: label }), void 0))] }, `scale_${x}_${y}`));
    }), [
        scalePosition,
        textAnchor,
        useInlineStyles,
        baseTextStyle,
        baseLineStyle,
    ]);
    return (_jsx(_Fragment, { children: scaleItems }, void 0));
});
export default LollipopPlotAxisScale;
