/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// eslint-disable-next-line import/no-extraneous-dependencies
import Papa from 'papaparse';
import { getValueByPath } from '../getValueByPath';
import { logger } from '..';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getTransformedData = (tableData, headers) => (tableData.map((el) => (headers.reduce((acc, { columnId, exportName }) => {
    const value = getValueByPath(el, columnId);
    if (value === undefined) {
        logger.warn(`${columnId} path was not found in exported table item`);
    }
    return (Object.assign(Object.assign({}, acc), { [exportName]: value }));
}, {}))));
/**
 * Download table data in csv format
 *
 * @param tableData - Array of data to be exported
 * @param headers - Matching headers, must contain 2 fields:
 * - columnId - For match column with tableData,
 * - exportName - Column title for export file
 */
export const transformCSVToString = (tableData, headers) => (new Promise((res, rej) => {
    const transformedTableData = getTransformedData(tableData, headers);
    try {
        const dataToCsv = Papa.unparse(transformedTableData);
        return res(dataToCsv);
    }
    catch (e) {
        return rej(e);
    }
}));
