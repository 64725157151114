export class PlotDomain {
    constructor(domainData, domainLabel) {
        this.groupId = domainData.pfamDomainId;
        this.groupName = domainData.name;
        this.groupLabel = domainLabel || this.groupName;
        this.intervals = [
            {
                codonStart: domainData.pfamDomainStart,
                codonEnd: domainData.pfamDomainEnd,
            },
        ];
    }
    static fromJSON(data) {
        const pfamDomainData = {
            name: data.groupName,
            pfamDomainId: data.groupId,
            pfamAccession: data.groupId,
            pfamDomainStart: data.intervals[0].codonStart,
            pfamDomainEnd: data.intervals[0].codonEnd,
        };
        const domain = new PlotDomain(pfamDomainData, data.groupLabel);
        domain.intervals = data.intervals;
        return domain;
    }
    addIntervals(intervals) {
        this.intervals = [...this.intervals, ...intervals];
        return this;
    }
    /**
     * Get a PlotDomain object (used in the portal to avoid non-seriazable values)
     *
     * @static
     * @param domainData - Response data from API
     * @param domainLabel - Label for PlotDomain
     * @returns PlotDomain object
     */
    static create(domainData, domainLabel) {
        return {
            groupId: domainData.pfamDomainId,
            groupName: domainData.name,
            groupLabel: domainLabel || domainData.name,
            intervals: [
                {
                    codonStart: domainData.pfamDomainStart,
                    codonEnd: domainData.pfamDomainEnd,
                },
            ],
        };
    }
    /**
     * Adds intervals to PlotDomain object (used in the portal to avoid non-seriazable values)
     *
     * @static
     * @param plotDomain - PlotDomain object
     * @param intervals - intervals for PlotDomain
     * @returns PlotDomain object
     */
    static addIntervals(plotDomain, intervals) {
        return Object.assign(Object.assign({}, plotDomain), { intervals: [
                ...plotDomain.intervals,
                ...intervals,
            ] });
    }
}
