import { ETraitsTypes } from '../../types';
export const P_VALUE_THRESHOLD = 0.005;
export const CASES_THRESHOLD = 100;
/**
 * Checks if there are variants links by specific criteria
 *
 * @param traitsType - Traits type
 * @param pvalue - Number value of pvalue
 * @param cases - Number of cases (for binary traits)
 * @returns boolean value
 */
export const hasQVVariantsLinks = (traitsType, pvalue, isPublicVersion, cases) => {
    const isPassedPvalueThreshold = pvalue <= P_VALUE_THRESHOLD;
    if (!isPublicVersion) {
        return isPassedPvalueThreshold;
    }
    if (traitsType === ETraitsTypes.Binary) {
        return !!cases && cases > CASES_THRESHOLD && isPassedPvalueThreshold;
    }
    return isPassedPvalueThreshold;
};
