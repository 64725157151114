export const getDataPointCoords = ({ x, y }, { x: maxX, y: maxY }, rect, useFixedXSum = false) => {
    const intervalX = maxX ? (rect.width / maxX) : 0;
    const intervalY = maxY ? (rect.height / maxY) : 0;
    return {
        x: useFixedXSum
            ? rect.x + rect.width
            : rect.x + x * intervalX,
        y: rect.y + rect.height - y * intervalY,
    };
};
