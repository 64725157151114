import { EPlotOrientation } from '../../../../../types';
import { inlineStyles } from '../../../components/PlotAxisScale/inlineStyles';
/**
 * Gets inline styles of axis scale line depends on the label and orientation
 *
 * @param label - the text label of axis line
 * @param orientation - axis line orientation
 * @returns styles for axis line
 */
export const getAxisScaleLineStyles = (label, orientation, dashedLineLabel) => {
    if (orientation === EPlotOrientation.Horizontal
        && dashedLineLabel
        && (label === dashedLineLabel)) {
        return inlineStyles.dashedLine;
    }
    return inlineStyles.defaultLine;
};
