import convertToNumber from '../convertToNumber/convertToNumber';
/**
 * Table rows sorter for number values
 *
 * @param rowA - Firstly comparing row
 * @param rowB - Secondary comparing row
 * @param columnId - Id of the current column to compare
 * @returns Number indicating if rows should be swapped or not
 */
export default function numericSort(rowA, rowB, columnId) {
    const valueA = convertToNumber(rowA.values[columnId], true);
    const valueB = convertToNumber(rowB.values[columnId], true);
    return valueA - valueB;
}
