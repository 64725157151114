import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useEffect, useState, } from 'react';
import classnames from 'classnames';
import styles from './ExportTypeToggler.css';
const ExportTypeToggler = (props) => {
    const { label, fileFormat, setFileDownload, isActive: isActiveProp = false, isDisabled = false, children, } = props;
    const [isActive, setActive] = useState(isActiveProp);
    useEffect(() => {
        setActive(isActiveProp);
    }, [isActiveProp]);
    const handleChange = () => {
        setActive((prevIsActive) => !prevIsActive);
        if (setFileDownload) {
            setFileDownload(!isActive);
        }
    };
    const exportTypeWrapper = classnames(styles.exportTypeWrapper, {
        [styles.disableToggler]: isDisabled,
    });
    return (_jsxs("div", Object.assign({ className: exportTypeWrapper }, { children: [_jsxs("div", Object.assign({ className: styles.exportType }, { children: [_jsx("div", Object.assign({ className: styles.label }, { children: label }), void 0), _jsx("input", { id: "toggler-type", name: "toggler-type", type: "checkbox", className: styles.togglerPoint, checked: isActive, disabled: isDisabled, onChange: handleChange }, void 0)] }), void 0), _jsxs("div", Object.assign({ className: styles.label }, { children: ["Format:", ' ', _jsx("span", Object.assign({ className: styles.fileFormat }, { children: fileFormat }), void 0)] }), void 0), children] }), void 0));
};
export default memo(ExportTypeToggler);
