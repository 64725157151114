export const inlineStyles = {
    pointOdd: {
        fill: 'rgb(131, 0, 81)',
        fillOpacity: 0.3,
        stroke: '#830051',
        strokeWidth: '1px',
    },
    pointEven: {
        fill: 'rgb(196, 214, 0)',
        fillOpacity: 0.3,
        stroke: '#C4D600',
        strokeWidth: '1px',
    },
    triangleCircle: {
        visibility: 'hidden',
    },
};
