export const DEFAULT_PLOT_HEIGHT = 480;
export const DEFAULT_PLOT_WIDTH = 1024;
export const PLOT_TOP_PADDING = 10;
export const PLOT_RIGHT_PADDING = 25;
export const PLOT_BOTTOM_PADDING = 10;
export const PLOT_LEFT_PADDING = 20;
export const SCALE_LABEL_FONT_SIZE = 14;
export const SCALE_LABEL_FONT_FAMILY = `${SCALE_LABEL_FONT_SIZE}px Roboto`;
export const AXIS_LABEL_FONT_SIZE = 12;
export const AXIS_LABEL_FONT_FAMILY = `${AXIS_LABEL_FONT_SIZE}px Roboto`;
export const AXIS_BORDER_WIDTH = 4;
export const X_AXIS_BORDER_MIN_PADDING = 45;
export const Y_AXIS_BORDER_MIN_PADDING = 42;
export const X_SCALE_LABEL_PADDING = 5;
export const Y_SCALE_LABEL_PADDING = 5;
export const Y_SCALE_TICK_MIN_WIDTH = 5;
export const X_SCALE_TICK_MIN_WIDTH = 5;
export const X_AXIS_LABEL_PADDING = 5;
export const Y_AXIS_LABEL_PADDING = 15;
/**
 * Without the first and the last ones
 */
export const X_SCALE_MAX_TICKS = 16;
export const Y_SCALE_MAX_TICKS = 5;
export const X_SCALE_SUBTICKS = 0;
export const Y_SCALE_SUBTICKS = 4;
/**
 * Paddings between axis and data canvas
 * Gives additional distance between axis and scale start points
 */
export const Y_CANVAS_PADDING = 40;
export const X_CANVAS_PADDING = 40;
export const DATA_POINT_RADIUS = 4;
export const THRESHOLD_ICON_MIN_WIDTH = 10;
export const THRESHOLD_BORDER_WIDTH = 2;
export const TOOLTIP_ID = 'LinePlotTooltip';
/**
 * Allowed tick intervals for X axis
 */
export const X_SCALE_TICK_INTERVALS = [
    50,
    100,
    200,
    250,
    500,
    1000,
    2500,
    5000,
    10000,
    25000,
];
/**
 * Allowed tick intervals for Y axis
 */
export const Y_SCALE_TICK_INTERVALS = [
    0.1,
    0.2,
    0.5,
    1,
    2,
    5,
    10,
    20,
    50,
    100,
];
export const Y_SCALE_MIN_TICK_DISTANCE = 0;
export const X_SCALE_MIN_TICK_DISTANCE = 10;
