import { getTextWidth } from '../../../../../utils';
import { DEFAULT_LAMBDA_INNER_HORIZONTAL_PADDING, DEFAULT_LAMBDA_INNER_VERTICAL_PADDING, DEFAULT_LAMBDA_OUTER_TOP_PADDING, DEFAULT_LAMBDA_OUTER_LEFT_PADDING, DEFAULT_LAMBDA_FONT_FAMILY, DEFAULT_LAMBDA_FONT_SIZE, } from '../../consts';
/**
 * Calculates geometry rect for lambda
 *
 * @param YAxisZoneRect - Geometry rect of Y axis zone
 * @param lambda - Formatted lambda value text
 * @returns Geometry rect
 */
export const calculateLambdaRect = (YAxisZoneRect, lambda) => {
    const x = YAxisZoneRect.x + YAxisZoneRect.width + DEFAULT_LAMBDA_OUTER_LEFT_PADDING;
    const y = YAxisZoneRect.y + DEFAULT_LAMBDA_OUTER_TOP_PADDING;
    const height = DEFAULT_LAMBDA_FONT_SIZE + 2 * DEFAULT_LAMBDA_INNER_VERTICAL_PADDING;
    const width = (getTextWidth(lambda, DEFAULT_LAMBDA_FONT_FAMILY)
        + 2 * DEFAULT_LAMBDA_INNER_HORIZONTAL_PADDING);
    return {
        x,
        y,
        height,
        width,
    };
};
