import { TableWidths } from '../types';
export const SORT_BY_PVALUE = [
    {
        id: 'pvalue',
        desc: false,
    },
];
export const DEFAULT_PAGE_SIZES = [10, 25, 50, 100];
export const PHENOTYPE_LINK_COLUMN_WIDTH = 300;
export const COMMON_TABLE_COLUMN_WIDTHS = {
    AAF: TableWidths.WIDTH_120,
    ALLELE_ID: TableWidths.WIDTH_120,
    ALLELE_ORIGIN: TableWidths.WIDTH_120,
    AMINO_ACID_CHANGE: TableWidths.WIDTH_120,
    ANCESTRY: TableWidths.WIDTH_120,
    CASE_AAF: TableWidths.WIDTH_120,
    CATEGORY: TableWidths.WIDTH_160,
    CDNA_CHANGE: TableWidths.WIDTH_160,
    CLASS: TableWidths.WIDTH_96,
    CLINICAL_SIGNIFICANCE: TableWidths.WIDTH_160,
    COLLAPSING_MODEL: TableWidths.WIDTH_120,
    CONSEQUENCE_TYPE: TableWidths.WIDTH_160,
    CONTEXT: TableWidths.WIDTH_120,
    CONTROL_AAF: TableWidths.WIDTH_120,
    DATASET: TableWidths.WIDTH_160,
    DIRECTION_OF_EFFECT: TableWidths.WIDTH_120,
    EFFECT_SIZE: TableWidths.WIDTH_120,
    EFFECT_SIZE_LCI: TableWidths.WIDTH_120,
    EFFECT_SIZE_STANDARD_ERROR: TableWidths.WIDTH_160,
    EFFECT_SIZE_UCI: TableWidths.WIDTH_120,
    EXON_RANK: TableWidths.WIDTH_120,
    GENE: TableWidths.WIDTH_120,
    JOURNAL: TableWidths.WIDTH_120,
    MAC: TableWidths.WIDTH_120,
    MAPPED_TRAIT: TableWidths.WIDTH_120,
    META_P_VALUE: TableWidths.WIDTH_160,
    MODEL: TableWidths.WIDTH_120,
    NUMBER_OF_AA_CASES: TableWidths.WIDTH_120,
    NUMBER_OF_AA_CONTROLS: TableWidths.WIDTH_120,
    NUMBER_OF_AA_GENOTYPES: TableWidths.WIDTH_120,
    NUMBER_OF_AB_CASES: TableWidths.WIDTH_120,
    NUMBER_OF_AB_CONTROLS: TableWidths.WIDTH_120,
    NUMBER_OF_AB_GENOTYPES: TableWidths.WIDTH_120,
    NUMBER_OF_BB_CASES: TableWidths.WIDTH_120,
    NUMBER_OF_BB_CONTROLS: TableWidths.WIDTH_120,
    NUMBER_OF_BB_GENOTYPES: TableWidths.WIDTH_120,
    NUMBER_OF_CASES: TableWidths.WIDTH_120,
    NUMBER_OF_CASES_WITH_QV: TableWidths.WIDTH_120,
    NUMBER_OF_CONTROLS: TableWidths.WIDTH_120,
    NUMBER_OF_CONTROLS_WITH_QV: TableWidths.WIDTH_160,
    NUMBER_OF_SAMPLES: TableWidths.WIDTH_120,
    NUMBER_OF_PARTICIPANTS: TableWidths.WIDTH_120,
    NUMBER_WITH_QV: TableWidths.WIDTH_120,
    NUMBER_WITHOUT_QV: TableWidths.WIDTH_120,
    ODDS_RATIO: TableWidths.WIDTH_120,
    ODDS_RATIO_LCI: TableWidths.WIDTH_120,
    ODDS_RATIO_UCI: TableWidths.WIDTH_120,
    P_VALUE: TableWidths.WIDTH_120,
    PERCENT_AB_OR_BB_CASES: TableWidths.WIDTH_120,
    PERCENT_AB_OR_BB_CONTROLS: TableWidths.WIDTH_120,
    PERCENT_AB_OR_BB_GENOTYPES: TableWidths.WIDTH_120,
    PERCENT_BB_CASES: TableWidths.WIDTH_120,
    PERCENT_BB_CONTROLS: TableWidths.WIDTH_120,
    PERCENT_BB_GENOTYPES: TableWidths.WIDTH_120,
    PERCENT_CASES_WITH_QV: TableWidths.WIDTH_120,
    PERCENT_CONTROLS_OF_QV: TableWidths.WIDTH_120,
    PHENOTYPE: TableWidths.WIDTH_160,
    PHENOTYPE_VARIANT_DASHBOARD: TableWidths.WIDTH_320,
    PMID: TableWidths.WIDTH_120,
    PUBMED_ID: TableWidths.WIDTH_120,
    RSID: TableWidths.WIDTH_160,
    TRANSCRIPT: TableWidths.WIDTH_160,
    TYPE: TableWidths.WIDTH_160,
    QV_CARRIER_MEDIAN: TableWidths.WIDTH_120,
    QV_NON_CARRIER_MEDIAN: TableWidths.WIDTH_160,
    VARIANT: TableWidths.WIDTH_160,
    VARIANT_TYPE: TableWidths.WIDTH_96,
    VARIANTS_LINK: TableWidths.WIDTH_120,
    YEAR: TableWidths.WIDTH_96,
};
