/**
 * Checks if a given point is placed inside rectangle area
 *
 * @param point - [X, Y] coordinates of a point to check
 * @param area - [top, right, bottom, left] area to check
 * @returns If a point is placed inside the given rectangle area
 */
export function insideArea(point, area) {
    return ((point[0] <= area[1] && point[0] >= area[3])
        && (point[1] >= area[0] && point[1] <= area[2]));
}
