// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ManhattanPlotAxisScaleLines__axisScaleLine__1yEsn{stroke:var(--border-color);stroke-width:1px}", "",{"version":3,"sources":["webpack://./shared/src/plots/components/ManhattanPlot/components/ManhattanPlotAxisScaleLines/ManhattanPlotAxisScaleLines.css"],"names":[],"mappings":"AAAA,mDACE,0BAA2B,CAC3B,gBACF","sourcesContent":[".axisScaleLine {\n  stroke: var(--border-color);\n  stroke-width: 1px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"axisScaleLine": "ManhattanPlotAxisScaleLines__axisScaleLine__1yEsn"
};
export default ___CSS_LOADER_EXPORT___;
