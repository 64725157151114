const inlineStyles = {
    arrow: {
        stroke: '#99afc1',
        strokeLinecap: 'round',
        strokeWidth: 1.5,
        strokeLinejoin: 'round',
        fill: 'none',
    },
};
export default inlineStyles;
