/**
 * Get fileName extension
 *
 * @param fileName - file name
 * @returns File extension with dot character
 */
export const getFileNameExtension = (fileName) => {
    const hasFileExtenstion = /[.]\w+$/.exec(fileName);
    if (hasFileExtenstion) {
        const matches = /[^.]+$/.exec(fileName);
        return matches && matches[0];
    }
    return null;
};
