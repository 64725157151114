export var ETooltipPlacement;
(function (ETooltipPlacement) {
    ETooltipPlacement[ETooltipPlacement["TopLeft"] = 0] = "TopLeft";
    ETooltipPlacement[ETooltipPlacement["TopCenter"] = 1] = "TopCenter";
    ETooltipPlacement[ETooltipPlacement["TopRight"] = 2] = "TopRight";
    ETooltipPlacement[ETooltipPlacement["RightTop"] = 3] = "RightTop";
    ETooltipPlacement[ETooltipPlacement["RightCenter"] = 4] = "RightCenter";
    ETooltipPlacement[ETooltipPlacement["RightBottom"] = 5] = "RightBottom";
    ETooltipPlacement[ETooltipPlacement["BottomRight"] = 6] = "BottomRight";
    ETooltipPlacement[ETooltipPlacement["BottomCenter"] = 7] = "BottomCenter";
    ETooltipPlacement[ETooltipPlacement["BottomLeft"] = 8] = "BottomLeft";
    ETooltipPlacement[ETooltipPlacement["LeftBottom"] = 9] = "LeftBottom";
    ETooltipPlacement[ETooltipPlacement["LeftCenter"] = 10] = "LeftCenter";
    ETooltipPlacement[ETooltipPlacement["LeftTop"] = 11] = "LeftTop";
})(ETooltipPlacement || (ETooltipPlacement = {}));
export var ETooltipPlacementType;
(function (ETooltipPlacementType) {
    ETooltipPlacementType[ETooltipPlacementType["Horizontal"] = 0] = "Horizontal";
    ETooltipPlacementType[ETooltipPlacementType["Vertical"] = 1] = "Vertical";
})(ETooltipPlacementType || (ETooltipPlacementType = {}));
export var ETooltipPlacementDirection;
(function (ETooltipPlacementDirection) {
    ETooltipPlacementDirection[ETooltipPlacementDirection["Top"] = 0] = "Top";
    ETooltipPlacementDirection[ETooltipPlacementDirection["TopRight"] = 1] = "TopRight";
    ETooltipPlacementDirection[ETooltipPlacementDirection["Right"] = 2] = "Right";
    ETooltipPlacementDirection[ETooltipPlacementDirection["BottomRight"] = 3] = "BottomRight";
    ETooltipPlacementDirection[ETooltipPlacementDirection["Bottom"] = 4] = "Bottom";
    ETooltipPlacementDirection[ETooltipPlacementDirection["BottomLeft"] = 5] = "BottomLeft";
    ETooltipPlacementDirection[ETooltipPlacementDirection["Left"] = 6] = "Left";
    ETooltipPlacementDirection[ETooltipPlacementDirection["TopLeft"] = 7] = "TopLeft";
})(ETooltipPlacementDirection || (ETooltipPlacementDirection = {}));
export var ETooltipAlign;
(function (ETooltipAlign) {
    ETooltipAlign[ETooltipAlign["Start"] = 0] = "Start";
    ETooltipAlign[ETooltipAlign["Center"] = 1] = "Center";
    ETooltipAlign[ETooltipAlign["End"] = 2] = "End";
})(ETooltipAlign || (ETooltipAlign = {}));
export var ETooltipAction;
(function (ETooltipAction) {
    ETooltipAction[ETooltipAction["Click"] = 0] = "Click";
    ETooltipAction[ETooltipAction["Hover"] = 1] = "Hover";
})(ETooltipAction || (ETooltipAction = {}));
export const DEFAULT_SHOW_DELAY = 100;
export const DEFAULT_HIDE_DELAY = 500;
