import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { LEFT_AXIS_SCALE_WIDTH, TOP_LABEL_HEIGHT, BOTTOM_AXIS_SCALE_HEIGHT, AXIS_BORDER_WIDTH, } from '../../consts';
import { ManhattanScaleType } from '../../types';
import ManhattanPlotLayer from '../ManhattanPlotLayer/ManhattanPlotLayer';
import styles from './ManhattanPlotThresholdsArea.css';
import { inlineStyles } from './ManhattanPlotThresholdsArea.style';
const ManhattanPlotThresholdsArea = (props) => {
    const { scale, height, width, useInlineStyles = false, } = props;
    const upperThreshold = scale.find(({ type }) => type === ManhattanScaleType.UpperThreshold);
    const upperThresholdY = (upperThreshold === null || upperThreshold === void 0 ? void 0 : upperThreshold.y) || TOP_LABEL_HEIGHT;
    const lowerThreshold = scale.find(({ type }) => type === ManhattanScaleType.LowerThreshold);
    const lowerThresholdY = (lowerThreshold === null || lowerThreshold === void 0 ? void 0 : lowerThreshold.y) || (height - BOTTOM_AXIS_SCALE_HEIGHT);
    if (!upperThreshold && !lowerThreshold) {
        return null;
    }
    /* Limit area width to be not less than 0 */
    const areaWidth = Math.max(0, width - LEFT_AXIS_SCALE_WIDTH - AXIS_BORDER_WIDTH);
    return (_jsx(ManhattanPlotLayer, { children: _jsx("rect", Object.assign({ className: styles.thresholdArea }, useInlineStyles && {
            style: inlineStyles.thresholdArea,
        }, { x: LEFT_AXIS_SCALE_WIDTH + AXIS_BORDER_WIDTH, y: upperThresholdY, width: areaWidth, height: lowerThresholdY - upperThresholdY, "data-testid": "plotThresholdsArea" }), void 0) }, void 0));
};
export default memo(ManhattanPlotThresholdsArea);
