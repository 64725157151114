// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ErrorPage__layout__3GMb6{justify-content:center;min-height:100vh}.ErrorPage__content__2ddN9,.ErrorPage__layout__3GMb6{display:flex;flex-direction:column}.ErrorPage__content__2ddN9{align-items:center;justify-content:space-between;padding:5rem}.ErrorPage__statusCode__1I6Uj{color:var(--primary-color);font-size:3.5rem;line-height:4rem;margin:0 0 2rem}.ErrorPage__primaryMessage__1vJsc{color:var(--font-color);font-size:2.5rem;line-height:3rem;margin:0 0 1.5rem;max-width:47.5rem;text-align:center}.ErrorPage__primaryMessage__1vJsc,.ErrorPage__statusCode__1I6Uj{font-weight:700}.ErrorPage__errorImage__kH-fW{margin-left:4rem}", "",{"version":3,"sources":["webpack://./shared/src/components/ErrorPage/ErrorPage.css"],"names":[],"mappings":"AAAA,0BAGE,sBAAuB,CACvB,gBACF,CAEA,qDANE,YAAa,CACb,qBAWF,CANA,2BAIE,kBAAmB,CADnB,6BAA8B,CAE9B,YACF,CAEA,8BAGE,0BAA2B,CAF3B,gBAAiB,CACjB,gBAAiB,CAEjB,eACF,CAEA,kCAKE,uBAAwB,CAJxB,gBAAiB,CACjB,gBAAiB,CACjB,iBAAkB,CAClB,iBAAkB,CAElB,iBACF,CAEA,gEAEE,eACF,CAEA,8BAKE,gBACF","sourcesContent":[".layout {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  min-height: 100vh;\n}\n\n.content {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: center;\n  padding: 5rem;\n}\n\n.statusCode {\n  font-size: 3.5rem;\n  line-height: 4rem;\n  color: var(--primary-color);\n  margin: 0 0 2rem;\n}\n\n.primaryMessage {\n  font-size: 2.5rem;\n  line-height: 3rem;\n  margin: 0 0 1.5rem;\n  max-width: 47.5rem;\n  color: var(--font-color);\n  text-align: center;\n}\n\n.statusCode,\n.primaryMessage {\n  font-weight: 700;\n}\n\n.errorImage {\n  /*\n    Workaround to center an image\n    @TODO: Replace SVG with left margined one\n  */\n  margin-left: 4rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": "ErrorPage__layout__3GMb6",
	"content": "ErrorPage__content__2ddN9",
	"statusCode": "ErrorPage__statusCode__1I6Uj",
	"primaryMessage": "ErrorPage__primaryMessage__1vJsc",
	"errorImage": "ErrorPage__errorImage__kH-fW"
};
export default ___CSS_LOADER_EXPORT___;
