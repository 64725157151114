import { useMemo } from 'react';
/**
 * Creates DeferredPromise helper object
 *
 * @private
 *
 * @returns Deferred object holding a promise
 */
function deferPromise() {
    const deferred = {
        promise: Promise.resolve(),
        resolve: () => { },
        reject: () => { },
    };
    deferred.promise = new Promise((resolve, reject) => {
        deferred.resolve = resolve;
        deferred.reject = reject;
    });
    return deferred;
}
/**
 * Debounces given function
 *
 * @param funcValue - Function which will be debounced
 * @param delay - Delay before executing the function
 * @returns Debounced function
 */
export function debounce(funcValue, delay) {
    let timer;
    let deferred = null;
    return function debouncedFunction(...args) {
        if (deferred) {
            window.clearTimeout(timer);
        }
        else {
            deferred = deferPromise();
        }
        timer = window.setTimeout(() => {
            window.clearTimeout(timer);
            if (!deferred) {
                return;
            }
            Promise.resolve()
                .then(() => funcValue.apply(this, args))
                .then(deferred.resolve, deferred.reject);
            deferred = null;
        }, delay);
        return deferred.promise;
    };
}
/**
 * Hook for adding debounce behavior on function
 *
 * @param funcValue - Function which should be debounced
 * @param delay - Delay before executing the function
 * @returns Debounced function
 */
export function useDebounce(funcValue, delay) {
    return useMemo(() => debounce(funcValue, delay), [funcValue, delay]);
}
