import { useRef, useState, useCallback, useLayoutEffect, useMemo, useEffect, } from 'react';
import { limitByRange } from '../../../../../utils';
import { scrolledElementsCounter as defaultScrolledElementsCounter, scrolledPositionCounter as defaultScrolledPositionCounter, widthCounter as defaultWidthCounter, dataStepCounter as defaultDataStepCounter, } from '../../utils';
import { useAutoZoom } from '../useAutoZoom/useAutoZoom';
import { DATA_POINT_STEP, PLOT_LEFT_PADDING, LEFT_AXIS_SCALE_WIDTH, AXIS_BORDER_WIDTH, MIN_ZOOM_FACTOR, MAX_ZOOM_FACTOR, PLOT_TOP_PADDING, TOP_LABEL_HEIGHT, LABEL_Y_PADDING_TOP, Y_ZOOMING_FACTOR, } from '../../consts';
/**
 * Hook provides zooming functionality for the plot
 *
 * @param data - Plot's data
 * @param containerWidth - Width of plot's container
 * @param [dataPointStep] - Step between data points
 * @param [config] - Zoom configuration object
 * @param [counters] - Set of counter functions used to calculate plot's metrics
 * @param [zoomCounters] - Set of counter functions used to calculate zoom feature related metrics
 * @returns API for zooming which includes:
 * - calculated step between two data points;
 * - zoom factor for selected user area;
 * - flag indicating if zooming is available from controls;
 * - callback for changing zoom factor;
 * - callback for changing zoom factor of selected area;
 * - allowed zoom step to be used by zoom controls;
 * - minimum allowed area zoom factor;
 * - maximum allowed area zoom factor;
 * - ref to scroll container.
 */
export function useZoom({ data, containerWidth, height, dataPointStep = DATA_POINT_STEP, config = {}, counters = {}, zoomCounters = {}, labelsRef, }) {
    const { initZoomFactors = [1, 1], minZoomFactors = [MIN_ZOOM_FACTOR, 1], maxZoomFactors = [MAX_ZOOM_FACTOR, 1], zoomStep = 0.25, withZoom = true, withAutoZoom = true, } = config;
    const scrolledDataPointsRef = useRef([0, 0, 0]);
    const scrollDataYRef = useRef(0);
    const dataContainerRef = useRef(null);
    const { widthCounter = defaultWidthCounter, } = counters;
    const { dataStepCounter = defaultDataStepCounter, scrolledElementsCounter = defaultScrolledElementsCounter, scrolledPositionCounter = defaultScrolledPositionCounter, } = zoomCounters;
    const [minXAxisZoomFactor, minYAxisZoomFactor] = minZoomFactors;
    const [maxXAxisZoomFactor, maxYAxisZoomFactor] = maxZoomFactors;
    const [zoomFactors, setZoomFactors] = useState(initZoomFactors);
    /**
     * Area zoom factor indicates zooming/scaling of an area instead of a single axis
     * Currently, unused due to only X axis zooming
     */
    const areaZoomFactor = zoomFactors[0] * zoomFactors[1];
    let minAreaZoomFactor;
    if (minXAxisZoomFactor && minYAxisZoomFactor) {
        minAreaZoomFactor = minXAxisZoomFactor * minYAxisZoomFactor;
    }
    let maxAreaZoomFactor;
    if (maxXAxisZoomFactor && maxYAxisZoomFactor) {
        maxAreaZoomFactor = maxXAxisZoomFactor * maxYAxisZoomFactor;
    }
    /**
     * Validates that updated zoom factors fit given [minFactor, maxFactor] range
     * and sets such in state
     *
     * If updated factors do not fit given range, corresponding limits are set instead
     * for each of axis independently
     *
     * @param [XAxisZoomFactor, YAxisZoomFactor] - Pair of updated zoom factors
     */
    const setLimitedZoomFactors = useCallback((newZoomFactors) => {
        let [XAxisZoomFactor, YAxisZoomFactor] = newZoomFactors;
        XAxisZoomFactor = limitByRange(XAxisZoomFactor, minXAxisZoomFactor, maxXAxisZoomFactor);
        YAxisZoomFactor = limitByRange(YAxisZoomFactor, minYAxisZoomFactor, maxYAxisZoomFactor);
        setZoomFactors([XAxisZoomFactor, YAxisZoomFactor]);
    }, [
        minXAxisZoomFactor,
        maxXAxisZoomFactor,
        minYAxisZoomFactor,
        maxYAxisZoomFactor,
    ]);
    /**
     * Updates zoom factor without handling scroll position
     * For details related to scroll position see below effect
     *
     * @param updatedZoomFactorX - Updated zoom factor X
     * @param updatedZoomFactorY - Updated zoom factor Y
     */
    const updateZoomFactor = useCallback((updatedZoomFactorX, updatedZoomFactorY) => {
        // Currently set only X axis factor
        setLimitedZoomFactors([updatedZoomFactorX, updatedZoomFactorY]);
    }, [setLimitedZoomFactors]);
    /**
     * Allows or blocks zooming out (decreasing zoom factor) or in (increasing) independently
     */
    const allowZooming = useMemo(() => {
        if (data.visiblePointsCount === 0) {
            return [false, false];
        }
        const allowZoomingResult = [true, true];
        if (zoomFactors[0] === minXAxisZoomFactor) {
            allowZoomingResult[0] = false;
        }
        if (zoomFactors[0] === maxXAxisZoomFactor) {
            allowZoomingResult[1] = false;
        }
        return allowZoomingResult;
    }, [
        data,
        zoomFactors,
        minXAxisZoomFactor,
        maxXAxisZoomFactor,
    ]);
    /**
     * Recalculates zoom factor to feet updated data by container width
     */
    const basicDataPointStep = useAutoZoom(data, containerWidth, updateZoomFactor, dataPointStep, dataStepCounter);
    /**
     * Calculates step between data points according to current zoom factor
     */
    let zoomedDataPointStep = dataPointStep;
    if (withZoom && areaZoomFactor) {
        zoomedDataPointStep = areaZoomFactor * (withAutoZoom
            ? basicDataPointStep
            : zoomedDataPointStep);
    }
    const [dataHeight, setDataHeight] = useState(height);
    const resetDataHeight = useCallback(() => {
        setDataHeight(height);
    }, [setDataHeight, height]);
    /**
     * Callback for changing zoom factor via UI controls
     * This callback also stores number of currently scrolled data points
     *  to restore the scroll after zoom change
     *
     * @param updatedZoomFactor - Zoom factor to update with
     */
    const handleZoomChange = useCallback((updatedZoomFactor) => {
        var _a, _b;
        const savedScrollOffset = ((_a = dataContainerRef.current) === null || _a === void 0 ? void 0 : _a.scrollLeft) || 0;
        const nextDataHeight = (height - PLOT_TOP_PADDING - TOP_LABEL_HEIGHT - LABEL_Y_PADDING_TOP) * updatedZoomFactor;
        const savedVerticalScrollOffset = ((_b = dataContainerRef.current) === null || _b === void 0 ? void 0 : _b.scrollTop) || 0;
        const actualFactorChange = updatedZoomFactor / zoomFactors[0];
        const areaLeftPosition = updatedZoomFactor > 1
            ? (containerWidth * (1 / zoomFactors[0] - 1 / updatedZoomFactor)) / 2
            : 0;
        scrolledDataPointsRef.current = scrolledElementsCounter(data, zoomedDataPointStep, areaLeftPosition + savedScrollOffset);
        scrollDataYRef.current = (savedVerticalScrollOffset
            + ((1 - zoomFactors[0] / updatedZoomFactor) * height) / 2) * actualFactorChange;
        setDataHeight(nextDataHeight > height ? nextDataHeight : height);
        updateZoomFactor(updatedZoomFactor, 1);
    }, [
        data,
        height,
        zoomedDataPointStep,
        updateZoomFactor,
        scrolledElementsCounter,
        containerWidth,
        zoomFactors,
    ]);
    /**
     * Callback for changing zoom factors via user area select
     *
     * @param updatedZoomArea - Geometry of selected area to zoom
     */
    const handleZoomAreaChange = useCallback((updatedZoomArea) => {
        var _a, _b;
        const savedScrollOffset = ((_a = dataContainerRef.current) === null || _a === void 0 ? void 0 : _a.scrollLeft) || 0;
        const savedVerticalScrollOffset = ((_b = dataContainerRef.current) === null || _b === void 0 ? void 0 : _b.scrollTop) || 0;
        const areaLeftPosition = (updatedZoomArea.left
            - PLOT_LEFT_PADDING
            - LEFT_AXIS_SCALE_WIDTH
            - AXIS_BORDER_WIDTH);
        scrolledDataPointsRef.current = scrolledElementsCounter(data, zoomedDataPointStep, areaLeftPosition + savedScrollOffset);
        const currentWidth = zoomFactors[0] < 1
            ? widthCounter(data, zoomedDataPointStep)
            : containerWidth * zoomFactors[0];
        const actualFactorChange = containerWidth / updatedZoomArea.width;
        const updatedZoomFactor = currentWidth / updatedZoomArea.width;
        const nextDataHeight = (height - PLOT_TOP_PADDING - TOP_LABEL_HEIGHT - LABEL_Y_PADDING_TOP) * updatedZoomFactor;
        setDataHeight(nextDataHeight);
        scrollDataYRef.current = (savedVerticalScrollOffset
            + (updatedZoomArea.top - PLOT_TOP_PADDING - TOP_LABEL_HEIGHT) * Y_ZOOMING_FACTOR) * actualFactorChange;
        const updatedZoomFactors = [
            updatedZoomFactor,
            1,
        ];
        setLimitedZoomFactors(updatedZoomFactors);
    }, [
        zoomFactors,
        data,
        zoomedDataPointStep,
        setLimitedZoomFactors,
        containerWidth,
        widthCounter,
        scrolledElementsCounter,
        height,
    ]);
    /**
     * Resets scrolledDataPointsRef.current when screen was resized
     */
    useEffect(() => {
        scrolledDataPointsRef.current = [0, 0, 0];
    }, [containerWidth]);
    /**
     * Resets zoomFactors when plot dada was changed
     */
    useEffect(() => {
        if (labelsRef === null || labelsRef === void 0 ? void 0 : labelsRef.current) {
            // eslint-disable-next-line no-param-reassign
            labelsRef.current.style.left = '0';
        }
        handleZoomChange(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, labelsRef]);
    /**
     * Resets scrolledDataPointsRef.current when data changed
     */
    useLayoutEffect(() => {
        scrolledDataPointsRef.current = [0, 0, 0];
    }, [data]);
    /**
     * Restores scroll position after zoom change
     * It uses previously stored number of scrolled data points
     *  to recalculate scroll position on zoomed plot
     */
    useLayoutEffect(() => {
        var _a;
        // No actions if container is not ready yet or set
        if (!dataContainerRef.current) {
            return;
        }
        // No actions if zoom feature is disabled
        if (!withZoom) {
            return;
        }
        dataContainerRef.current.scrollTop = (_a = scrollDataYRef.current) !== null && _a !== void 0 ? _a : 0;
        // No actions if there were no data points scrolled before zoom change
        if (scrolledDataPointsRef.current[0] === 0) {
            return;
        }
        const calculatedScrollLeft = scrolledPositionCounter(zoomedDataPointStep, scrolledDataPointsRef.current);
        dataContainerRef.current.scrollLeft = calculatedScrollLeft;
        if (labelsRef && labelsRef.current && dataContainerRef.current) {
            // eslint-disable-next-line no-param-reassign
            labelsRef.current.style.left = `-${calculatedScrollLeft}`;
        }
    }, [
        data,
        labelsRef,
        zoomedDataPointStep,
        areaZoomFactor,
        withZoom,
        scrolledPositionCounter,
    ]);
    return useMemo(() => ({
        dataPointStep: zoomedDataPointStep,
        zoomStep,
        minAreaZoomFactor,
        maxAreaZoomFactor,
        areaZoomFactor,
        allowZooming,
        handleZoomChange,
        handleZoomAreaChange,
        dataContainerRef,
        dataHeight,
        resetDataHeight,
    }), [
        zoomedDataPointStep,
        zoomStep,
        minAreaZoomFactor,
        maxAreaZoomFactor,
        allowZooming,
        areaZoomFactor,
        handleZoomChange,
        handleZoomAreaChange,
        dataContainerRef,
        dataHeight,
        resetDataHeight,
    ]);
}
