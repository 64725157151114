// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModelWithDescription__container__3pNPK{font-size:.875rem}.ModelWithDescription__name__V3AUd{font-weight:700}.ModelWithDescription__description__3D5Zy,.ModelWithDescription__name__V3AUd{line-height:1.125rem}.ModelWithDescription__description__3D5Zy{font-weight:400}", "",{"version":3,"sources":["webpack://./shared/src/components/ModelWithDescription/ModelWithDescription.css"],"names":[],"mappings":"AAAA,wCACE,iBACF,CAEA,mCACE,eAEF,CAEA,6EAHE,oBAMF,CAHA,0CACE,eAEF","sourcesContent":[".container {\n  font-size: 0.875rem;\n}\n\n.name {\n  font-weight: 700;\n  line-height: 1.125rem;\n}\n\n.description {\n  font-weight: 400;\n  line-height: 1.125rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ModelWithDescription__container__3pNPK",
	"name": "ModelWithDescription__name__V3AUd",
	"description": "ModelWithDescription__description__3D5Zy"
};
export default ___CSS_LOADER_EXPORT___;
