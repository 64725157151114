import { DEFAULT_SVG_FONT } from '../../consts';
import getTextWidth from '../getTextWidth/getTextWidth';
/**
 * Wraps text on parts to fit maximum width limit
 *
 * @param text - Text to wrap
 * @param maxWidth - Width limit to fit in the content
 * @param [font] - Font used to draw text
 * @returns Array of wrapped text parts
 */
export default function getWrappedText(text, maxWidth, font = DEFAULT_SVG_FONT) {
    const textParts = text.split(' ').filter((part) => part);
    const textPartsWidth = textParts.map((textPart) => getTextWidth(textPart, font));
    let currentWidth = 0;
    let currentPartIndex = 0;
    let currentText = '';
    const wrappedText = [];
    while (currentPartIndex < textParts.length) {
        const nextText = `${currentText} ${textParts[currentPartIndex]}`.trim();
        const nextWidth = getTextWidth(nextText, font);
        if (nextWidth > maxWidth) {
            if (currentWidth > 0 && currentPartIndex > 0) {
                wrappedText.push(currentText);
                currentText = '';
                currentWidth = 0;
            }
            else {
                const currentPart = textParts[currentPartIndex];
                const ratio = maxWidth / textPartsWidth[currentPartIndex];
                const splitLength = Math.floor(currentPart.length * ratio);
                const firstHalf = currentPart.substr(0, splitLength);
                const secondHalf = currentPart.substr(splitLength);
                const firstHalfWidth = getTextWidth(firstHalf, font);
                const secondHalfWidth = getTextWidth(secondHalf, font);
                textParts.splice(currentPartIndex, 1, firstHalf, secondHalf);
                textPartsWidth.splice(currentPartIndex, 1, firstHalfWidth, secondHalfWidth);
            }
        }
        else {
            currentPartIndex += 1;
            currentWidth = nextWidth;
            currentText = nextText;
        }
    }
    wrappedText.push(currentText);
    return wrappedText;
}
