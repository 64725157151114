export const STORY_ROOT_TITLE = 'Shared';
export const CGR_INFORMATICS_SUPPORT_EMAIL = 'CGR-Informatics-Support@astrazeneca.com';
export const DEFAULT_SCIENTIFIC_THRESHOLD_VALUE = 1000;
export const DEFAULT_NUMBER_FRACTION_DIGITS = 2;
export const MAX_NUMERIC_P_VALUE = 0.01;
/**
 * Defines codes for keyboard keys
 */
export var EKeyCode;
(function (EKeyCode) {
    EKeyCode["Enter"] = "Enter";
    EKeyCode["NumpadEnter"] = "NumpadEnter";
    EKeyCode["Esc"] = "Escape";
    EKeyCode["UpArrow"] = "ArrowUp";
    EKeyCode["DownArrow"] = "ArrowDown";
    EKeyCode["Tab"] = "Tab";
    EKeyCode["KeyV"] = "KeyV";
    EKeyCode["CapsLock"] = "CapsLock";
})(EKeyCode || (EKeyCode = {}));
/**
 * Roman numerals Array collection for conversion utils
 */
export const ROMAN_NUMERALS_ARRAY = [
    ['M', 1000],
    ['CM', 900],
    ['D', 500],
    ['CD', 400],
    ['C', 100],
    ['XC', 90],
    ['L', 50],
    ['XL', 40],
    ['X', 10],
    ['IX', 9],
    ['V', 5],
    ['IV', 4],
    ['I', 1],
];
/**
 * Roman numerals collection for convection utils
 */
export const ROMAN_NUMERALS = ROMAN_NUMERALS_ARRAY.reduce((acc, current) => {
    const [roman, decimal] = current;
    acc[roman] = decimal;
    return acc;
}, {});
