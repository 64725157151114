import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Tooltip } from '../../../../../../components';
import { TOOLTIP_ID } from '../../consts';
import styles from './FESPlotTooltip.css';
const FESPlotTooltip = ({ tooltipContent, tooltipAPI, tooltipId = `${TOOLTIP_ID}:${uuidv4()}`, }) => {
    const offset = useMemo(() => [15, 0], []);
    if (!tooltipContent)
        return null;
    return (_jsx(Tooltip, { id: tooltipId, className: styles.tooltip, tooltipContent: tooltipContent, offset: offset, customTooltipAPI: tooltipAPI }, void 0));
};
export default memo(FESPlotTooltip);
