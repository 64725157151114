import { useCallback, useMemo } from 'react';
import { createZone, calculateXAxisZoneRect, calculateYAxisZoneWidth, calculateXAxisScale, calculateYAxisScale, calculateYAxisLabelRect, calculateYAxisZoneRect, calculateXAxisLabelRect, calculateDataZoneRect, calculateAxisZoneRect, } from '../../../utils';
import { getTextWidth } from '../../../../../utils';
import { DEFAULT_FONT_FAMILY } from '../../../consts';
import { scientificNumberFormatter } from '../../../../../../utils/formatters';
import { calculateScaleValues, calculateDataPoints, getDataPointConfig, } from '../../utils';
import { MIN_SCIENTIFIC_VALUE_THRESHOLD } from '../../consts';
/**
 * Creates zones for plot's elements
 *
 * @param plotHeight - Height of the plot
 * @param plotWidth - Width of the plot
 * @param data - Plot's data
 * @param plotSettings - Plot settings
 * @param axisTitles - Titles for axis
 * @param labelYFormatter - Optional formatter for y line labels
 * @returns Plot's zones
 */
export const useZoneConstructor = (plotHeight, plotWidth, data, plotSettings, axisTitles = {}, labelYFormatter) => {
    const { XAxisTitle, YAxisTitle } = axisTitles;
    const axisScaleValuesX = useMemo(() => calculateScaleValues(plotSettings.minX, plotSettings.maxX), [plotSettings.minX, plotSettings.maxX]);
    const axisScaleValuesY = useMemo(() => calculateScaleValues(plotSettings.minY, plotSettings.maxY), [plotSettings.minY, plotSettings.maxY]);
    const maxAxisScaleValueX = axisScaleValuesX[axisScaleValuesX.length - 1];
    const maxAxisScaleValueY = axisScaleValuesY[axisScaleValuesY.length - 1];
    const minAxisScaleValueX = axisScaleValuesX[0];
    const minAxisScaleValueY = axisScaleValuesY[0];
    const maxAxisScaleValuesWidthX = getTextWidth(`${maxAxisScaleValueX}`, DEFAULT_FONT_FAMILY);
    const maxAxisScaleValuesWidthY = getTextWidth(`${maxAxisScaleValueY}`, DEFAULT_FONT_FAMILY);
    const YAxisZoneWidth = calculateYAxisZoneWidth(maxAxisScaleValuesWidthY);
    const YAxisLabelRect = useMemo(() => calculateYAxisLabelRect(YAxisZoneWidth, YAxisTitle), [YAxisTitle, YAxisZoneWidth]);
    const YAxisLabelZone = useMemo(() => {
        if (!YAxisLabelRect || typeof YAxisTitle !== 'string') {
            return null;
        }
        return createZone(YAxisLabelRect, YAxisTitle);
    }, [YAxisLabelRect, YAxisTitle]);
    const XAxisZoneRect = useMemo(() => calculateXAxisZoneRect(plotHeight, plotWidth, YAxisZoneWidth, maxAxisScaleValuesWidthX), [
        plotHeight,
        plotWidth,
        YAxisZoneWidth,
        maxAxisScaleValuesWidthX,
    ]);
    const YAxisZoneRect = useMemo(() => calculateYAxisZoneRect(plotHeight, XAxisZoneRect, YAxisLabelRect, YAxisZoneWidth), [
        plotHeight,
        XAxisZoneRect,
        YAxisLabelRect,
        YAxisZoneWidth,
    ]);
    const XAxisLabelRect = useMemo(() => calculateXAxisLabelRect(XAxisZoneRect, XAxisTitle), [XAxisTitle, XAxisZoneRect]);
    const XAxisLabelZone = useMemo(() => {
        if (!XAxisLabelRect || typeof XAxisTitle !== 'string') {
            return null;
        }
        return createZone(XAxisLabelRect, XAxisTitle);
    }, [XAxisLabelRect, XAxisTitle]);
    const labelXFormatter = useCallback((scaleValue) => {
        var _a;
        return (_a = scientificNumberFormatter({
            value: Math.pow(10, scaleValue),
            minFractionDigits: 0,
            maxFractionDigits: 3,
            minScientificValueThreshold: MIN_SCIENTIFIC_VALUE_THRESHOLD,
        })) !== null && _a !== void 0 ? _a : `${scaleValue}`;
    }, []);
    const XAxisScale = useMemo(() => calculateXAxisScale(axisScaleValuesX, XAxisZoneRect, YAxisZoneRect, plotHeight, labelXFormatter), [
        axisScaleValuesX,
        XAxisZoneRect,
        YAxisZoneRect,
        plotHeight,
        labelXFormatter,
    ]);
    const YAxisScale = useMemo(() => calculateYAxisScale(axisScaleValuesY, YAxisZoneRect, plotWidth, labelYFormatter), [axisScaleValuesY, YAxisZoneRect, plotWidth, labelYFormatter]);
    const XAxisZone = useMemo(() => createZone(XAxisZoneRect, XAxisScale), [XAxisZoneRect, XAxisScale]);
    const YAxisZone = useMemo(() => createZone(YAxisZoneRect, YAxisScale), [YAxisZoneRect, YAxisScale]);
    const axisZoneRect = useMemo(() => calculateAxisZoneRect(XAxisZoneRect, YAxisZoneRect), [XAxisZoneRect, YAxisZoneRect]);
    const axisZone = useMemo(() => createZone(axisZoneRect, null), [axisZoneRect]);
    const dataZoneRect = useMemo(() => calculateDataZoneRect(XAxisZoneRect, YAxisZoneRect), [
        XAxisZoneRect,
        YAxisZoneRect,
    ]);
    const dataPoints = useMemo(() => calculateDataPoints(dataZoneRect, data, maxAxisScaleValueX, minAxisScaleValueX, maxAxisScaleValueY, minAxisScaleValueY, getDataPointConfig), [
        dataZoneRect,
        data,
        maxAxisScaleValueX,
        minAxisScaleValueX,
        maxAxisScaleValueY,
        minAxisScaleValueY,
    ]);
    const dataZone = useMemo(() => createZone(dataZoneRect, dataPoints), [dataZoneRect, dataPoints]);
    return useMemo(() => ({
        axisZone,
        XAxisLabelZone,
        YAxisLabelZone,
        XAxisZone,
        YAxisZone,
        dataZone,
    }), [
        axisZone,
        XAxisLabelZone,
        YAxisLabelZone,
        XAxisZone,
        YAxisZone,
        dataZone,
    ]);
};
