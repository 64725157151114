export var EPlot;
(function (EPlot) {
    EPlot["Histogram"] = "Histogram";
    EPlot["Manhattan"] = "Manhattan";
    EPlot["Network"] = "Network";
    EPlot["FES"] = "FES";
})(EPlot || (EPlot = {}));
var EAreaPosition;
(function (EAreaPosition) {
    EAreaPosition["fixed"] = "fixed";
    EAreaPosition["absolute"] = "absolute";
})(EAreaPosition || (EAreaPosition = {}));
export const selectAreaPosition = {
    [EPlot.Histogram]: EAreaPosition.absolute,
    [EPlot.Manhattan]: EAreaPosition.absolute,
    [EPlot.Network]: EAreaPosition.fixed,
    [EPlot.FES]: EAreaPosition.fixed,
};
