import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import classnames from 'classnames';
import Angle from '../../../../assets/icons/dropdown.svg';
import { EAnglePosition, EAngleDirection } from '../../consts';
import styles from './AngleButton.css';
export const AngleButton = memo(function AngleButton({ label, onClick, anglePosition = EAnglePosition.END, angleDirection = EAngleDirection.DOWN, }) {
    const layout = classnames(styles.layout, {
        [styles.reverse]: anglePosition === EAnglePosition.START,
        [styles.rotate]: angleDirection === EAngleDirection.UP,
    });
    return (_jsxs("button", Object.assign({ className: layout, onClick: onClick, type: "button" }, { children: [label, _jsx(Angle, { "data-testid": "angle-icon", className: styles.icon }, void 0)] }), void 0));
});
