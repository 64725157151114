import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, memo } from 'react';
import { DEFAULT_AXIS_BORDER_WIDTH, DEFAULT_DATA_ZONE_BOTTOM_PADDING, DEFAULT_FONT_SIZE, DEFAULT_X_AXIS_HEIGHT, } from '../../consts';
import { inlineStyles } from '../PlotAxisScale/inlineStyles';
import styles from './ScaleLabelsX.css';
const defaultInlineStylesGetter = () => inlineStyles.defaultLine;
const ScaleLabelsX = ({ axisZone, size, inlineStylesGetter = defaultInlineStylesGetter, labelsRef, }) => {
    const { value: axisZoneScale, } = axisZone;
    return (_jsxs("svg", Object.assign({ ref: labelsRef, height: size.height, width: size.width, viewBox: `0 0 ${size.width} ${size.height}`, xmlns: "http://www.w3.org/2000/svg", className: styles.axisXLabels }, { children: [_jsx("rect", { x: 0, y: size.height - DEFAULT_X_AXIS_HEIGHT - DEFAULT_AXIS_BORDER_WIDTH, width: size.width, height: DEFAULT_X_AXIS_HEIGHT + DEFAULT_AXIS_BORDER_WIDTH, className: styles.axisHiding }, void 0), axisZoneScale.map(({ label: { value: labelText, rect: labelRect, }, line: { rect: lineRect, }, }) => (_jsxs(Fragment, { children: [_jsx("line", { x1: lineRect.x, y1: size.height - DEFAULT_X_AXIS_HEIGHT + DEFAULT_DATA_ZONE_BOTTOM_PADDING, x2: lineRect.x, y2: size.height - DEFAULT_X_AXIS_HEIGHT, style: inlineStylesGetter(labelText) }, void 0), _jsx("text", Object.assign({ x: labelRect.x, y: size.height, textAnchor: "middle", style: {
                            fontSize: DEFAULT_FONT_SIZE,
                            color: 'red',
                        } }, { children: labelText }), void 0)] }, `scale_${labelRect.x}_${labelRect.y}`)))] }), void 0));
};
export default memo(ScaleLabelsX);
