import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { getDataPointCoords } from '../../utils';
import inlineStyles from './LinePlotDataPath.styles';
export const LinePlotDataPath = memo(function LinePlotDataPath({ rect, data, maxScaleValue, color, }) {
    let firstPoint = { x: 0, y: 0 };
    const pathParts = data
        .map((dataPoint, index) => {
        const { x, y } = getDataPointCoords(dataPoint, maxScaleValue, rect);
        if (x > rect.width + rect.x) {
            return '';
        }
        if (index === 0) {
            firstPoint = { x, y };
            return `M ${x} ${y}`;
        }
        return `L ${x} ${y}`;
    });
    const path = pathParts
        .concat(`M ${firstPoint.x} ${firstPoint.y}`)
        .concat('z')
        .join(' ');
    return (_jsx("path", { d: path, style: color ? Object.assign(Object.assign({}, inlineStyles.path), { stroke: color }) : inlineStyles.path }, void 0));
});
