import { jsx as _jsx } from "react/jsx-runtime";
import { useState, memo, createContext, useEffect, useCallback, useMemo, } from 'react';
import classnames from 'classnames';
import styles from './Tabs.css';
export const Context = createContext({
    handleClick: () => { },
    activeTabId: 0,
});
export const Tabs = memo(function Tabs(props) {
    const { children, onChange, className, tabActive = 0, tabContentClassName, activeTabContentClassName, keepTabsRendered = false, } = props;
    const [activeTabId, setTabActive] = useState(tabActive);
    const handleClick = useCallback((index) => {
        setTabActive(index);
        if (onChange) {
            onChange(index);
        }
    }, [onChange]);
    useEffect(() => setTabActive(tabActive), [tabActive]);
    const contextValue = useMemo(() => ({
        handleClick,
        activeTabId,
        keepTabsRendered,
        tabContentClassName,
        activeTabContentClassName,
    }), [handleClick, activeTabId, activeTabContentClassName, keepTabsRendered, tabContentClassName]);
    return (_jsx(Context.Provider, Object.assign({ value: contextValue }, { children: _jsx("div", Object.assign({ className: classnames(styles.tabs, className) }, { children: children }), void 0) }), void 0));
});
