import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, Fragment } from 'react';
import { EPlotOrientation } from '../../../../types';
import { DEFAULT_FONT_SIZE } from '../../consts';
import { inlineStyles } from './inlineStyles';
const defaultInlineStylesGetter = () => inlineStyles.defaultLine;
const PlotAxisScale = ({ axisScale, orientation = EPlotOrientation.Horizontal, inlineStylesGetter = defaultInlineStylesGetter, scroll, }) => (_jsx(_Fragment, { children: axisScale.map(({ label: { value: labelText, rect: labelRect, }, line: { rect: lineRect, }, }) => {
        let display = true;
        let lineX1 = lineRect.x;
        let lineY1 = lineRect.y;
        let lineX2 = lineRect.x + lineRect.width;
        let lineY2 = lineRect.y;
        let labelTextAnchor = 'start';
        if (scroll && scroll >= lineX1) {
            display = false;
        }
        if (orientation === EPlotOrientation.Vertical) {
            lineX1 = lineRect.x;
            lineY1 = lineRect.y;
            lineX2 = lineRect.x;
            lineY2 = lineRect.y + lineRect.height;
            labelTextAnchor = 'middle';
        }
        const lineStyles = inlineStylesGetter(labelText, orientation);
        return (_jsxs(Fragment, { children: [display && (_jsx("line", { x1: lineX1, y1: lineY1, x2: lineX2, y2: lineY2, style: lineStyles }, void 0)), _jsx("text", Object.assign({ x: labelRect.x, y: labelRect.y, textAnchor: labelTextAnchor, style: {
                        fontSize: DEFAULT_FONT_SIZE,
                    } }, { children: labelText }), void 0)] }, `scale_${labelRect.x}_${labelRect.y}`));
    }) }, void 0));
export default memo(PlotAxisScale);
