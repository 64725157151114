// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DefaultOptionList__layout__3FdNI{background-color:var(--background-light-color);border-top:1px solid var(--border-color);display:flex;flex-direction:column;flex-wrap:nowrap;margin:0;max-height:19.375rem;overflow-x:hidden;overflow-y:auto;padding:.75rem 0;width:100%;z-index:var(--search-field-z-index)}", "",{"version":3,"sources":["webpack://./shared/src/components/SearchBoxField/components/DefaultOptionList/DefaultOptionList.css"],"names":[],"mappings":"AAAA,kCAUE,8CAA+C,CAC/C,wCAAyC,CARzC,YAAa,CACb,qBAAsB,CACtB,gBAAiB,CAEjB,QAAS,CALT,oBAAqB,CAMrB,iBAAkB,CAClB,eAAgB,CAHhB,gBAAkB,CALlB,UAAW,CAWX,mCACF","sourcesContent":[".layout {\n  width: 100%;\n  max-height: 19.375rem;\n  display: flex;\n  flex-direction: column;\n  flex-wrap: nowrap;\n  padding: 0.75rem 0;\n  margin: 0;\n  overflow-x: hidden;\n  overflow-y: auto;\n  background-color: var(--background-light-color);\n  border-top: 1px solid var(--border-color);\n  z-index: var(--search-field-z-index);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": "DefaultOptionList__layout__3FdNI"
};
export default ___CSS_LOADER_EXPORT___;
