import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, } from 'react';
import classnames from 'classnames';
import { Tag } from '../Tag';
import { useHiddenTags } from './hooks';
import { singlePluralConvert } from '../../utils';
import styles from './TagList.css';
function TagListBase(props) {
    const { value, onRemove, getOptionValue, getOptionLabel, getMaxWidth, placeholder, disabled = false, label = '', hideTags = true, visibleTagsWrapperRef, className, childNode, showLabel, } = props;
    const { hiddenTags, visibleTags, containerRef, visibleTagsRef, hiddenTagsRef, labelRef, childNodeRef, } = useHiddenTags({ value, disabled: !hideTags, getMaxWidth });
    const isEmptyValue = !Array.isArray(value) || value.length === 0;
    return (_jsxs("div", Object.assign({ ref: containerRef, className: classnames(styles.layout, className) }, { children: [isEmptyValue && (_jsx("span", Object.assign({ className: classnames(styles.placeholder, {
                    [styles.disabled]: disabled,
                }) }, { children: placeholder }), void 0)), _jsx("div", Object.assign({ ref: visibleTagsWrapperRef, className: styles.visibleTagsWrapper }, { children: !isEmptyValue && (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ ref: visibleTagsRef, className: styles.tags, "data-testid": "visible-tags" }, { children: visibleTags === null || visibleTags === void 0 ? void 0 : visibleTags.map((option) => (_jsx(Tag, { value: option, onRemove: onRemove, label: getOptionLabel(option), disabled: disabled, className: styles.tag }, getOptionValue(option)))) }), void 0), _jsx("span", Object.assign({ className: classnames(styles.placeholder, {
                                [styles.disabled]: disabled,
                            }), "data-testid": "tags-label" }, { children: hiddenTags.length > 0
                                ? `+${hiddenTags.length} ${singlePluralConvert(label, hiddenTags.length)}`
                                : showLabel && label }), void 0)] }, void 0)) }), void 0), _jsx("div", Object.assign({ ref: hiddenTagsRef, className: classnames(styles.tags, styles.hidden) }, { children: value.map((option) => (_jsx(Tag, { value: option, onRemove: onRemove, label: getOptionLabel(option), disabled: disabled, className: styles.tag }, getOptionValue(option)))) }), void 0), _jsx("div", Object.assign({ className: styles.hidden }, { children: _jsx("div", Object.assign({ ref: labelRef, className: styles.placeholder }, { children: `+${hiddenTags.length} ${singlePluralConvert(label, hiddenTags.length)}` }), void 0) }), void 0), _jsx("div", Object.assign({ ref: childNodeRef }, { children: childNode }), void 0)] }), void 0));
}
export const TagList = memo(TagListBase);
