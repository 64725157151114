/**
 * Returns a given number if it fits the [minValue, maxValue] range
 *  or the nearest bound of that range otherwise
 *
 * @throws Error if `minValue` > `maxValue`
 *
 * @param value - Value to limit by a range
 * @param [minValue] - Lower boundary of a range
 * @param [maxValue] - Upper boundary of a range
 * @returns Limited number
 */
export function limitByRange(value, minValue, maxValue) {
    let returnValue = value;
    if (typeof minValue === 'number'
        && typeof maxValue === 'number'
        && minValue > maxValue) {
        throw new Error(`maxValue is less than minValue: ${maxValue} < ${minValue}`);
    }
    if (typeof minValue === 'number' && value < minValue) {
        returnValue = minValue;
    }
    if (typeof maxValue === 'number' && value > maxValue) {
        returnValue = maxValue;
    }
    return returnValue;
}
