// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StickySection__layout__12-b7{position:sticky;top:0;z-index:var(--sticky-dropdown-z-index)}.StickySection__shadow__2X5nk{border-bottom-color:#0000;box-shadow:0 11px 25px 0 var(--dropdown-shadow-color)}", "",{"version":3,"sources":["webpack://./shared/src/components/StickySection/StickySection.css"],"names":[],"mappings":"AAAA,8BACE,eAAgB,CAChB,KAAM,CACN,sCACF,CAEA,8BACE,yBAAgC,CAChC,qDACF","sourcesContent":[".layout {\n  position: sticky;\n  top: 0;\n  z-index: var(--sticky-dropdown-z-index);\n}\n\n.shadow {\n  border-bottom-color: transparent;\n  box-shadow: 0 11px 25px 0 var(--dropdown-shadow-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": "StickySection__layout__12-b7",
	"shadow": "StickySection__shadow__2X5nk"
};
export default ___CSS_LOADER_EXPORT___;
