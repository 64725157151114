// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AngleButton__layout__3WsTf{align-items:center;background:none;border:none;color:var(--primary-color);cursor:pointer;display:inline-flex;padding:0}.AngleButton__layout__3WsTf svg{margin-left:.125rem}.AngleButton__reverse__1RYUR{flex-direction:row-reverse}.AngleButton__reverse__1RYUR svg{margin-left:0;margin-right:.125rem}.AngleButton__rotate__26gNl svg{transform:rotate(180deg)}.AngleButton__icon__1ENTI{fill:var(--primary-color)}.AngleButton__layout__3WsTf:hover{color:var(--primary-hover-color)}.AngleButton__layout__3WsTf:hover svg{fill:var(--primary-hover-color)}", "",{"version":3,"sources":["webpack://./shared/src/components/ExpandedText/components/AngleLabel/AngleButton.css"],"names":[],"mappings":"AAAA,4BAEE,kBAAmB,CAEnB,eAAgB,CADhB,WAAY,CAGZ,0BAA2B,CAC3B,cAAe,CANf,mBAAoB,CAIpB,SAGF,CAEA,gCACE,mBACF,CAEA,6BACE,0BACF,CAEA,iCACE,aAAc,CACd,oBACF,CAEA,gCACE,wBACF,CAEA,0BACE,yBACF,CAEA,kCACE,gCAKF,CAHE,sCACE,+BACF","sourcesContent":[".layout {\n  display: inline-flex;\n  align-items: center;\n  border: none;\n  background: none;\n  padding: 0;\n  color: var(--primary-color);\n  cursor: pointer;\n}\n\n.layout svg {\n  margin-left: 0.125rem;\n}\n\n.reverse {\n  flex-direction: row-reverse;\n}\n\n.reverse svg {\n  margin-left: 0;\n  margin-right: 0.125rem;\n}\n\n.rotate svg {\n  transform: rotate(180deg);\n}\n\n.icon {\n  fill: var(--primary-color);\n}\n\n.layout:hover {\n  color: var(--primary-hover-color);\n\n  & svg {\n    fill: var(--primary-hover-color);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": "AngleButton__layout__3WsTf",
	"reverse": "AngleButton__reverse__1RYUR",
	"rotate": "AngleButton__rotate__26gNl",
	"icon": "AngleButton__icon__1ENTI"
};
export default ___CSS_LOADER_EXPORT___;
