// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ExternalLink__textFieldLink__3L0bh{color:var(--link-color);text-decoration:underline}.ExternalLink__textFieldLink__3L0bh:hover{color:var(--link-hover-color)}.ExternalLink__navLink__36xIy{color:var(--link-color);text-decoration:none}.ExternalLink__navLink__36xIy:hover{color:var(--link-hover-color);text-decoration:underline}", "",{"version":3,"sources":["webpack://./shared/src/components/ExternalLink/ExternalLink.css"],"names":[],"mappings":"AAAA,oCAEE,uBAAwB,CADxB,yBAMF,CAHE,0CACE,6BACF,CAGF,8BAEE,uBAAwB,CADxB,oBAOF,CAJE,oCACE,6BAA8B,CAC9B,yBACF","sourcesContent":[".textFieldLink {\n  text-decoration: underline;\n  color: var(--link-color);\n\n  &:hover {\n    color: var(--link-hover-color);\n  }\n}\n\n.navLink {\n  text-decoration: none;\n  color: var(--link-color);\n\n  &:hover {\n    color: var(--link-hover-color);\n    text-decoration: underline;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textFieldLink": "ExternalLink__textFieldLink__3L0bh",
	"navLink": "ExternalLink__navLink__36xIy"
};
export default ___CSS_LOADER_EXPORT___;
