import { useCallback, useEffect, useState } from 'react';
export const useElementResize = (props) => {
    const { target } = props;
    const [observer, setObserver] = useState(null);
    const [resizeEntry, setResizeEntry] = useState(null);
    const handleResize = useCallback(([entry]) => {
        setResizeEntry(entry);
    }, []);
    useEffect(() => {
        setObserver(new ResizeObserver(handleResize));
    }, [handleResize]);
    useEffect(() => {
        if (observer && target) {
            observer.observe(target);
        }
        return () => {
            if (observer && target) {
                observer.unobserve(target);
            }
        };
    }, [observer, target]);
    return resizeEntry;
};
