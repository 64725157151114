import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import { useTooltip } from '../../../../hooks';
import { Y_AXIS_BORDER_WIDTH, X_CANVAS_PADDING, X_AXIS_BORDER_WIDTH, DOMAINS_HEIGHT, DOMAINS_BORDER_PADDING, TOOLTIP_PLOT_ID, } from '../../consts';
import LollipopPlotDataLine from '../LollipopPlotDataLine';
import LollipopPlotDataPoint from '../LollipopPlotDataPoint';
import LollipopPlotTooltip from '../LollipopPlotTooltip';
import styles from './LollipopPlotData.css';
const LollipopPlotData = memo(function LollipopPlotData(props) {
    const { type, data, axisXPositionY, axisYPositionX, getPositionOnAxisX, getPositionOnAxisY, getTooltipContent, useInlineStyles, } = props;
    const { tooltipId, tooltipAPI } = useTooltip(TOOLTIP_PLOT_ID);
    const { handleTooltipShowWithDelay: handleTooltipShow, handleTooltipHideWithDelay: handleTooltipHide, } = tooltipAPI;
    const positionX = (axisYPositionX
        + Y_AXIS_BORDER_WIDTH / 2
        + X_CANVAS_PADDING);
    const positionY = (axisXPositionY
        - X_AXIS_BORDER_WIDTH / 2
        - DOMAINS_HEIGHT
        + DOMAINS_BORDER_PADDING);
    const dataLines = useMemo(() => data.map((item) => (_jsx(LollipopPlotDataLine, { type: type, data: item, positionX: positionX, positionY: positionY, getPositionOnAxisX: getPositionOnAxisX, getPositionOnAxisY: getPositionOnAxisY, useInlineStyles: useInlineStyles }, item.id))), [
        data,
        type,
        getPositionOnAxisX,
        getPositionOnAxisY,
        positionX,
        positionY,
        useInlineStyles,
    ]);
    const dataPoints = useMemo(() => data.map((item) => (_jsx(LollipopPlotDataPoint, { type: type, data: item, positionX: positionX, positionY: positionY, getPositionOnAxisX: getPositionOnAxisX, getPositionOnAxisY: getPositionOnAxisY, handleTooltipShow: handleTooltipShow, handleTooltipHide: handleTooltipHide, useInlineStyles: useInlineStyles }, item.id))), [
        data,
        type,
        getPositionOnAxisX,
        getPositionOnAxisY,
        handleTooltipShow,
        handleTooltipHide,
        positionX,
        positionY,
        useInlineStyles,
    ]);
    return (_jsxs(_Fragment, { children: [_jsx(LollipopPlotTooltip, { className: styles.tooltip, tooltipId: tooltipId, tooltipContent: getTooltipContent, tooltipAPI: tooltipAPI }, void 0), dataLines, dataPoints] }, void 0));
});
export default LollipopPlotData;
