import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback } from 'react';
import { Toggler } from '../Toggler';
import { InfoTooltip } from '../InfoTooltip';
import { ETooltipAction, ETooltipAlign, ETooltipPlacement } from '../Tooltip';
import { ELEMENT_PREFIX, MOST_SIGNIFICANT_MODEL_LABEL, MOST_SIGNIFICANT_MODEL_TOOLTIP, MOST_SIGNIFICANT_MODEL_TOGGLER_ID, } from './consts';
import styles from './FilterByMostSignificantModel.css';
const FilterByMostSignificantModel = ({ value, levelResult, onChange, }) => {
    const tooltipLabel = MOST_SIGNIFICANT_MODEL_TOOLTIP[levelResult];
    const handleToggleChange = useCallback((newValue) => onChange(newValue), [onChange]);
    return (_jsxs("div", Object.assign({ className: styles.container }, { children: [_jsx("div", Object.assign({ className: styles.togglerContainer }, { children: _jsx(Toggler, { id: MOST_SIGNIFICANT_MODEL_TOGGLER_ID, "data-testid": MOST_SIGNIFICANT_MODEL_TOGGLER_ID, name: MOST_SIGNIFICANT_MODEL_LABEL, isActive: value, onToggle: handleToggleChange, className: styles.toggler }, void 0) }), void 0), _jsx("div", Object.assign({ className: styles.label }, { children: MOST_SIGNIFICANT_MODEL_LABEL }), void 0), _jsx("div", Object.assign({ className: styles.tooltipContainer }, { children: _jsx(InfoTooltip, Object.assign({ targetEvent: ETooltipAction.Hover, position: ETooltipPlacement.BottomCenter, id: `${ELEMENT_PREFIX}_tooltip`, overlayClassName: styles.tooltip, align: ETooltipAlign.Start }, { children: tooltipLabel }), void 0) }), void 0)] }), void 0));
};
const MemoizedFilterByMostSignificantModel = memo(FilterByMostSignificantModel);
export { MemoizedFilterByMostSignificantModel as FilterByMostSignificantModel };
