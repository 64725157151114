var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/**
 * Decorates formatter with table cell interface and adds row data
 *
 * @param formatter - Formatter to apply in table cell
 * @returns Formatting table cell with row data
 */
export const tableCellDecorator = (formatter) => (_a) => {
    var value = __rest(_a.cell, []), { row: { original, } } = _a;
    return formatter(value, original);
};
