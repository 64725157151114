import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import classnames from 'classnames';
import Legend from '../../../Legend/Legend';
import stylesCSS from './LollipopPlotLegend.css';
const LollipopPlotLegend = memo(function LollipopPlotLegend(props) {
    const { data, className, maxColumns, title, } = props;
    const styles = useMemo(() => ({
        oddItemLabel: {
            className: stylesCSS.oddDomainLabel,
        },
        evenItemLabel: {
            className: stylesCSS.evenDomainLabel,
        },
    }), []);
    return (_jsx(Legend, { data: data, className: classnames(stylesCSS.container, className), maxColumns: maxColumns, title: title, styles: styles, isCollapsedByDefault: false }, void 0));
});
export default LollipopPlotLegend;
