// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LegendColumn__wrapper__3s8i3{display:flex;flex:1 0 0;flex-direction:column;list-style:none;margin:0;padding:0}.LegendColumn__wrapper__3s8i3+.LegendColumn__wrapper__3s8i3{margin-left:.75rem}.LegendColumn__wrapper__3s8i3>li{align-items:flex-start;display:flex}.LegendColumn__wrapper__3s8i3>li+li{margin-top:.75rem}.LegendColumn__label__1ibRX{flex-grow:0;margin-right:.75rem;min-width:1rem;padding:1px 3px;white-space:nowrap}.LegendColumn__oddItemLabel__3Hrjv{background-color:var(--plot-group-odd-color)}.LegendColumn__evenItemLabel__18gWn{background-color:var(--plot-group-even-color)}.LegendColumn__name__3PMeX{flex-shrink:1;padding:1px 0}", "",{"version":3,"sources":["webpack://./shared/src/plots/components/Legend/components/LegendColumn/LegendColumn.css"],"names":[],"mappings":"AAAA,8BACE,YAAa,CAEb,UAAW,CADX,qBAAsB,CAEtB,eAAgB,CAEhB,QAAS,CADT,SAEF,CAEA,4DACE,kBACF,CAEA,iCAEE,sBAAuB,CADvB,YAEF,CAEA,oCACE,iBACF,CAEA,4BAIE,WAAY,CAFZ,mBAAqB,CADrB,cAAe,CAEf,eAAgB,CAEhB,kBACF,CAEA,mCACE,4CACF,CAEA,oCACE,6CACF,CAEA,2BACE,aAAc,CACd,aACF","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n  flex: 1 0 0;\n  list-style: none;\n  padding: 0;\n  margin: 0;\n}\n\n.wrapper + .wrapper {\n  margin-left: 0.75rem;\n}\n\n.wrapper > li {\n  display: flex;\n  align-items: flex-start;\n}\n\n.wrapper > li + li {\n  margin-top: 0.75rem;\n}\n\n.label {\n  min-width: 1rem;\n  margin-right: 0.75rem;\n  padding: 1px 3px;\n  flex-grow: 0;\n  white-space: nowrap;\n}\n\n.oddItemLabel {\n  background-color: var(--plot-group-odd-color);\n}\n\n.evenItemLabel {\n  background-color: var(--plot-group-even-color);\n}\n\n.name {\n  flex-shrink: 1;\n  padding: 1px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "LegendColumn__wrapper__3s8i3",
	"label": "LegendColumn__label__1ibRX",
	"oddItemLabel": "LegendColumn__oddItemLabel__3Hrjv",
	"evenItemLabel": "LegendColumn__evenItemLabel__18gWn",
	"name": "LegendColumn__name__3PMeX"
};
export default ___CSS_LOADER_EXPORT___;
