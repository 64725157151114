import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, useMemo, useCallback } from 'react';
import classnames from 'classnames';
import { filledArray } from '../../../../utils';
import { Button } from '../../../Button';
import { SelectField } from '../../../SelectField';
import { EBoxDirection } from '../../../../types';
import { MAX_PAGE_LIMIT } from './consts';
import { useLimitedPages } from './hooks';
import NavIcon from '../../../../assets/icons/nav.svg';
import NavDoubleIcon from '../../../../assets/icons/navDouble.svg';
import styles from './TablePagination.css';
export const TablePagination = memo(function TablePagination(props) {
    const { itemsCount, pageSizes, onPageSizeChange, maxPageLimit = MAX_PAGE_LIMIT, paginationOptionClassName, paginationButtonClassName, paginationContainerClassName, hasStickyHeader = false, config: { pageIndex, pageSize, pageCount, canPreviousPage, canNextPage, gotoPage, nextPage, previousPage, setPageSize, }, } = props;
    const normalizedPageCount = pageCount || 1;
    const pageItems = useMemo(() => filledArray(normalizedPageCount, (index) => ({
        label: `${index + 1}`,
        value: index,
    })), [normalizedPageCount]);
    const pageSizeItems = useMemo(() => pageSizes.map((size) => ({
        label: `${size}`,
        value: size,
    })), [pageSizes]);
    const pageNavigationClassName = classnames(styles.pageButton, styles.pageButtonNavigation);
    const optionClassName = classnames(styles.option, paginationOptionClassName);
    const containerClassName = classnames(styles.pageSelect, paginationContainerClassName);
    const { firstPageIndex, lastPageIndex, pageIndexes } = useLimitedPages(pageIndex, normalizedPageCount, maxPageLimit);
    const handlePageSizeChange = useCallback(({ value }) => {
        const updatedPageSize = value;
        setPageSize(updatedPageSize);
        if (onPageSizeChange) {
            onPageSizeChange(updatedPageSize);
        }
    }, [onPageSizeChange, setPageSize]);
    const handlePageChange = useCallback(({ value }) => {
        gotoPage(value);
    }, [gotoPage]);
    const handleGoToPage = useCallback(() => gotoPage(pageCount - 1), [gotoPage, pageCount]);
    const pageSizeOption = useMemo(() => { var _a; return (_a = pageSizeItems.find(({ value }) => value === pageSize)) !== null && _a !== void 0 ? _a : null; }, [pageSize, pageSizeItems]);
    const pageItemOption = useMemo(() => { var _a; return (_a = pageItems.find(({ value }) => value === pageIndex)) !== null && _a !== void 0 ? _a : null; }, [pageIndex, pageItems]);
    const goToPage0 = useCallback(() => {
        gotoPage(0);
    }, [gotoPage]);
    const getOptionValue = useCallback(({ value }) => value, []);
    const getOptionLabel = useCallback(({ label }) => label, []);
    const goToPreviousNormalizedPage = useCallback(() => {
        gotoPage(normalizedPageCount - 1);
    }, [gotoPage, normalizedPageCount]);
    return (_jsxs("section", Object.assign({ className: styles.pagination }, { children: [_jsxs("section", Object.assign({ className: styles.pageSizes }, { children: ["Show", ' ', _jsx(SelectField, { className: styles.pageSizeSelect, options: pageSizeItems, value: pageSizeOption, boxDirection: EBoxDirection.Up, isInStickyTable: hasStickyHeader, onChange: handlePageSizeChange, getOptionValue: getOptionValue, getOptionLabel: getOptionLabel, testId: "tableSizes" }, void 0), ' ', "items per page. Total:", ' ', itemsCount, ' ', "items"] }), void 0), pageCount > 1 && (_jsxs(_Fragment, { children: [_jsxs("section", Object.assign({ className: styles.pageButtons, "data-testid": "tablePagination" }, { children: [_jsx(Button, Object.assign({ className: pageNavigationClassName, onClick: goToPage0, disabled: !canPreviousPage, title: "goToStartPage" }, { children: _jsx(NavDoubleIcon, { className: styles.leftIcon, "data-testid": "TablePagination.firstPage" }, void 0) }), void 0), _jsx(Button, Object.assign({ className: pageNavigationClassName, onClick: previousPage, disabled: !canPreviousPage, title: "goToPreviousPage" }, { children: _jsx(NavIcon, { className: styles.leftIcon, "data-testid": "TablePagination.prevPage" }, void 0) }), void 0), firstPageIndex !== 0 && (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ className: styles.pageButton, onClick: goToPage0 }, { children: "1" }), void 0), firstPageIndex !== 1 && (_jsx("div", Object.assign({ className: classnames(styles.pageButton, styles.pageDelimiter) }, { children: "..." }), void 0))] }, void 0)), pageIndexes.map((currentPageIndex) => {
                                const buttonIndex = `page-${currentPageIndex}`;
                                const className = classnames(styles.pageButton, {
                                    [styles.pageButtonActive]: currentPageIndex === pageIndex,
                                });
                                return (_jsx(Button, Object.assign({ className: className, onClick: () => gotoPage(currentPageIndex) }, { children: currentPageIndex + 1 }), buttonIndex));
                            }), lastPageIndex !== normalizedPageCount - 1 && (_jsxs(_Fragment, { children: [lastPageIndex !== normalizedPageCount - 2 && (_jsx("div", Object.assign({ className: classnames(styles.pageButton, styles.pageDelimiter) }, { children: "..." }), void 0)), _jsx(Button, Object.assign({ className: styles.pageButton, onClick: goToPreviousNormalizedPage }, { children: normalizedPageCount }), void 0)] }, void 0)), _jsx(Button, Object.assign({ className: pageNavigationClassName, onClick: nextPage, disabled: !canNextPage, title: "goToNextPage" }, { children: _jsx(NavIcon, { "data-testid": "TablePagination.nextPage" }, void 0) }), void 0), _jsx(Button, Object.assign({ className: pageNavigationClassName, onClick: handleGoToPage, disabled: !canNextPage, title: "goToEndPage" }, { children: _jsx(NavDoubleIcon, { "data-testid": "TablePagination.lastPage" }, void 0) }), void 0)] }), void 0), _jsxs("section", Object.assign({ className: styles.pageSelector }, { children: ["Go to page:", ' ', _jsx(SelectField, { options: pageItems, value: pageItemOption, boxDirection: EBoxDirection.Up, isInStickyTable: hasStickyHeader, onChange: handlePageChange, getOptionValue: getOptionValue, getOptionLabel: getOptionLabel, optionClassName: optionClassName, buttonClassName: paginationButtonClassName, className: containerClassName, testId: "goToPage" }, void 0)] }), void 0)] }, void 0))] }), void 0));
});
