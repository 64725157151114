// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ManhattanPlotData__isInProgress__5Ocl9{opacity:.2}", "",{"version":3,"sources":["webpack://./shared/src/plots/components/ManhattanPlot/components/ManhattanPlotData/ManhattanPlotData.css"],"names":[],"mappings":"AAAA,wCACE,UACF","sourcesContent":[".isInProgress {\n  opacity: 0.2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"isInProgress": "ManhattanPlotData__isInProgress__5Ocl9"
};
export default ___CSS_LOADER_EXPORT___;
