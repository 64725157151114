import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useMemo, useCallback, } from 'react';
import classnames from 'classnames';
import { EImpactLevel } from '../../../../types';
import { LOLLIPOP_RADIUS, DOMAINS_BORDER_PADDING, Y_CANVAS_PADDING } from '../../consts';
import { LollipopVariantType } from '../../types';
import LollipopPlotDataPointArrow from '../LollipopPlotDataPointArrow/LollipopPlotDataPointArrow';
import { getInlineStyles } from './utils';
import styles from './LollipopPlotDataPoint.css';
const LollipopPlotDataPoint = memo(function LollipopPlotDataPoint(props) {
    const { type = LollipopVariantType.Active, data: { codon, count, id, impactLevel = EImpactLevel.Low, isSplice, }, positionX, positionY, getPositionOnAxisX, getPositionOnAxisY, handleTooltipShow, handleTooltipHide, useInlineStyles, } = props;
    const handleMouseEnter = useCallback((event) => {
        if (handleTooltipShow) {
            handleTooltipShow(event, id);
        }
    }, [handleTooltipShow, id]);
    const handleMouseLeave = useCallback(() => {
        if (handleTooltipHide) {
            handleTooltipHide();
        }
    }, [handleTooltipHide]);
    const xRelative = getPositionOnAxisX(codon);
    const x = positionX + xRelative;
    const yRelative = getPositionOnAxisY(count);
    const y = (positionY
        - yRelative
        - DOMAINS_BORDER_PADDING
        - Y_CANVAS_PADDING);
    const circleStyle = useMemo(() => getInlineStyles(type, useInlineStyles, impactLevel), [impactLevel, type, useInlineStyles]);
    const isActiveVariantType = type === LollipopVariantType.Active;
    const circleClassName = classnames(styles.circle, {
        [styles.circleHighImpact]: impactLevel === EImpactLevel.High && isActiveVariantType,
        [styles.circleModerateImpact]: impactLevel === EImpactLevel.Moderate && isActiveVariantType,
        [styles.circleLowImpact]: impactLevel === EImpactLevel.Low && isActiveVariantType,
        [styles.circleInactive]: type === LollipopVariantType.Inactive,
    });
    return (_jsxs(_Fragment, { children: [isSplice && isActiveVariantType && (_jsx(LollipopPlotDataPointArrow, { x: x, y: y }, void 0)), _jsx("circle", { className: circleClassName, style: circleStyle, onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, cx: x, cy: y, r: LOLLIPOP_RADIUS }, void 0)] }, void 0));
});
export default LollipopPlotDataPoint;
