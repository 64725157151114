// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Section__layout__zZodZ{background-color:var(--content-background-color);box-shadow:0 1px 4px 0 var(--section-box-shadow-color);display:flex}.Section__default__2HUNW{flex-direction:column;margin-top:2.625rem}.Section__minimized__1dweJ{border-bottom:1px solid var(--content-shadow-color)}", "",{"version":3,"sources":["webpack://./shared/src/components/Section/Section.css"],"names":[],"mappings":"AAAA,wBAEE,gDAAiD,CACjD,sDAAuD,CAFvD,YAGF,CAEA,yBACE,qBAAsB,CACtB,mBACF,CAEA,2BACE,mDACF","sourcesContent":[".layout {\n  display: flex;\n  background-color: var(--content-background-color);\n  box-shadow: 0 1px 4px 0 var(--section-box-shadow-color);\n}\n\n.default {\n  flex-direction: column;\n  margin-top: 2.625rem;\n}\n\n.minimized {\n  border-bottom: 1px solid var(--content-shadow-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": "Section__layout__zZodZ",
	"default": "Section__default__2HUNW",
	"minimized": "Section__minimized__1dweJ"
};
export default ___CSS_LOADER_EXPORT___;
