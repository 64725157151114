export * from './selectField';
export * from './cssProperties';
export * from './export';
export var ELevelResult;
(function (ELevelResult) {
    ELevelResult["Gene"] = "glr";
    ELevelResult["Variant"] = "vlr";
    ELevelResult["Phenotype"] = "plr";
})(ELevelResult || (ELevelResult = {}));
export var ETraitsTypes;
(function (ETraitsTypes) {
    ETraitsTypes["Binary"] = "binary";
    ETraitsTypes["Continuous"] = "continuous";
})(ETraitsTypes || (ETraitsTypes = {}));
