import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useCallback, } from 'react';
import { EDataPointShape } from '../../../../../types';
const FESPlotDataPoint = ({ dataPointZone: { value: dataPointValue, rect: dataPointRect, shape, styles, }, handleTooltipShow, handleTooltipHide, }) => {
    var _a, _b;
    const handleMouseEnter = useCallback((event) => {
        if (handleTooltipShow) {
            handleTooltipShow(event, dataPointValue);
        }
    }, [handleTooltipShow, dataPointValue]);
    const handleMouseLeave = useCallback(() => {
        if (handleTooltipHide) {
            handleTooltipHide();
        }
    }, [handleTooltipHide]);
    const circleRadius = dataPointRect.width / 2;
    const circleX = dataPointRect.x + circleRadius;
    const circleY = dataPointRect.y + circleRadius;
    return (_jsx("rect", { onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, x: circleX, y: circleY, height: circleRadius * 2, width: circleRadius * 2, rx: shape === EDataPointShape.Circle ? circleRadius : 0, style: {
            fill: (_a = styles === null || styles === void 0 ? void 0 : styles.fill) !== null && _a !== void 0 ? _a : 'rgb(131, 0, 81)',
            fillOpacity: 0.5,
            stroke: (_b = styles === null || styles === void 0 ? void 0 : styles.stroke) !== null && _b !== void 0 ? _b : '#830051',
            strokeWidth: '1px',
            cursor: 'pointer',
        } }, void 0));
};
export default memo(FESPlotDataPoint);
