import { useEffect, useMemo } from 'react';
import useDataRegion from '../../../NetworkPlot/hooks/useDataRegion';
import useScroll from '../../../NetworkPlot/hooks/useScroll';
import useSelectRegion from '../../../NetworkPlot/hooks/useSelectRegion';
const useZoomRectangle = (props) => {
    const { scaleXStepWidth, scale, scaleYStepWidth, containerRef, innerContainerRef, axisWidth, zoomMinValue, zoomMaxValue, handleZoomChange, zoomIndex, topPadding, } = props;
    const { isDataRegion, left, top } = useDataRegion({
        scaleXStepWidth,
        scale,
        scaleYStepWidth,
        containerRef,
        zoomIndex,
        topPadding,
    });
    const { handleMouseDown, handleMouseUp, handleMouseMove, selectRegionWidth, selectRegionStart, resetSelect, selectRegionHeightRef, selectRegionTopRef, } = useSelectRegion({
        zoomMinValue,
        zoomMaxValue,
        handleZoomChange,
        scaleXStepWidth,
        scale,
        isDataRegion,
        zoomIndex,
    });
    const { zoomXStart, zoomYStart } = useScroll({
        selectRegionStart,
        axisWidth,
        zoomIndex,
        containerRef,
        selectRegionTopRef,
        innerContainerRef,
        left,
        top,
    });
    useEffect(() => {
        var _a, _b;
        resetSelect();
        (_a = innerContainerRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo({
            left: zoomXStart,
        });
        (_b = containerRef.current) === null || _b === void 0 ? void 0 : _b.scrollTo({
            top: zoomYStart,
        });
    }, [
        containerRef,
        innerContainerRef,
        resetSelect,
        zoomXStart,
        zoomYStart,
        zoomIndex,
    ]);
    return useMemo(() => ({
        handleMouseDown,
        handleMouseUp,
        handleMouseMove,
        selectRegionStart,
        selectRegionWidth,
        selectRegionTop: selectRegionTopRef.current,
        selectRegionHeight: selectRegionHeightRef.current,
    }), [
        handleMouseDown,
        handleMouseUp,
        handleMouseMove,
        selectRegionStart,
        selectRegionWidth,
        selectRegionTopRef,
        selectRegionHeightRef,
    ]);
};
export default useZoomRectangle;
