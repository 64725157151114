// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LegendList__wrapper__XlpBU{display:flex;font-size:.875rem;justify-items:space-between;padding:1.5rem 5rem 1rem}", "",{"version":3,"sources":["webpack://./shared/src/plots/components/Legend/components/LegendList/LegendList.css"],"names":[],"mappings":"AAAA,4BACE,YAAa,CAGb,iBAAmB,CAFnB,2BAA4B,CAC5B,wBAEF","sourcesContent":[".wrapper {\n  display: flex;\n  justify-items: space-between;\n  padding: 1.5rem 5rem 1rem;\n  font-size: 0.875rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "LegendList__wrapper__XlpBU"
};
export default ___CSS_LOADER_EXPORT___;
