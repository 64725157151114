import { DEFAULT_DATA_POINT_RADIUS } from '../../../consts';
/**
 * Calculates data points zones
 *
 * @param dataZoneRect - Geometry rect of data zone
 * @param data - Plot's data
 * @param maxAxisScaleValueX - Max value of X axis scale
 * @param maxAxisScaleValueY - Max value of Y axis scale
 * @param getDataPointConfig - Fn for defining data point config
 * @returns Array of data point zones
 */
export const calculateDataPoints = (dataZoneRect, data, maxAxisScaleValueX, minAxisScaleValueX, maxAxisScaleValueY, minAxisScaleValueY, getDataPointConfig) => (data.map((dataPoint) => {
    const { fill, stroke, shape, } = getDataPointConfig(dataPoint.levelResult, dataPoint.pvalue);
    const relativeX = (dataPoint.coordinates.x - minAxisScaleValueX) / (maxAxisScaleValueX - minAxisScaleValueX);
    const relativeY = (maxAxisScaleValueY - dataPoint.coordinates.y) / (maxAxisScaleValueY - minAxisScaleValueY);
    const pointX = dataZoneRect.x + relativeX * dataZoneRect.width;
    const pointY = dataZoneRect.y + relativeY * dataZoneRect.height;
    const absoluteX = pointX - DEFAULT_DATA_POINT_RADIUS;
    const absoluteY = pointY - DEFAULT_DATA_POINT_RADIUS;
    const height = 2 * DEFAULT_DATA_POINT_RADIUS;
    const width = 2 * DEFAULT_DATA_POINT_RADIUS;
    const dataPointRect = {
        x: absoluteX,
        y: absoluteY,
        height,
        width,
    };
    return ({
        value: dataPoint,
        rect: dataPointRect,
        shape,
        styles: {
            fill,
            stroke,
        },
    });
}));
