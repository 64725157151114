import { escapeRegExp } from '../escapeRegExp/escapeRegExp';
/**
 * Returns an array of elements which matched passed pattern
 *
 * @param options - Array of elements
 * @param pattern  - pattern for using in RegExp
 * @param getOptionLabel - function for calculation label value
 * @returns Array of filtered elements
 */
export function getDefaultOption(options, pattern, getOptionLabel) {
    const trimmedPattern = pattern.trim();
    if (trimmedPattern.length > 0) {
        const regex = new RegExp(escapeRegExp(trimmedPattern), 'i');
        return options.filter((item) => regex.test(getOptionLabel(item)));
    }
    return [];
}
