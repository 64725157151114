import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { EPlotOrientation } from '../../../../types';
import { DEFAULT_AXIS_BORDER_WIDTH } from '../../consts';
import PlotAxisBase from '../PlotAxis/PlotAxisBase';
import styles from './PlotAxisScrollable.css';
const PlotAxisScrollableY = ({ axisZone, axisLabelZone, size, yAxisRef, inlineStylesGetter, }) => {
    const { value: axisZoneScale, rect: axisZoneRect, } = axisZone;
    /**
     * It appears that SVG rendering considers rounding down float numbers
     * However, 0 value should be rounded up as it won't be considered otherwise
     */
    const HALF_BORDER_WIDTH = Math.floor(DEFAULT_AXIS_BORDER_WIDTH / 2) || 1;
    let labelTextAnchor = 'end';
    const lineX1 = axisZoneRect.x + axisZoneRect.width;
    const lineY1 = axisZoneRect.y;
    const lineX2 = axisZoneRect.x + axisZoneRect.width;
    const lineY2 = axisZoneRect.y + axisZoneRect.height + HALF_BORDER_WIDTH;
    labelTextAnchor = 'start';
    const scaleOrientation = EPlotOrientation.Horizontal;
    const HideRect = (hideRectProps) => (_jsx("rect", Object.assign({}, hideRectProps, { className: styles.axisBackground }), void 0));
    return (_jsx("svg", Object.assign({ ref: yAxisRef, height: size.height, width: size.width, viewBox: `0 0 ${size.width} ${size.height}`, xmlns: "http://www.w3.org/2000/svg", className: styles.axisYContainer }, { children: _jsx(PlotAxisBase, { axisZoneScale: axisZoneScale, axisLabelZone: axisLabelZone, lineX1: lineX1, lineY1: lineY1, lineX2: lineX2, lineY2: lineY2, scaleOrientation: scaleOrientation, labelTextAnchor: labelTextAnchor, hideRect: _jsx(HideRect, Object.assign({}, axisZoneRect), void 0), inlineStylesGetter: inlineStylesGetter, woAxisLines: true }, void 0) }), void 0));
};
export default memo(PlotAxisScrollableY);
