import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { getOffset } from './utils';
import { inlineStyles } from './Legend.style';
import styles from './Legend.css';
/**
 * Props for Legend component
 *
 * @param useInlineStyles - Boolean, provides to use inline styles
 * @param labelsConfig - Render order is equal to order of keys in config
 */
export const CombinedLegend = memo(function CombinedLegend({ useInlineStyles = false, labelsConfig }) {
    if (!labelsConfig) {
        return null;
    }
    const { triangleUpIcon, triangleUpText, triangleDownIcon, triangleDownText, } = getOffset(labelsConfig, true);
    return (_jsxs("g", Object.assign({ className: styles.legend, style: useInlineStyles ? inlineStyles.legend : {} }, { children: [labelsConfig.triangleUp && (_jsxs(_Fragment, { children: [_jsx("path", { className: styles.figure, style: useInlineStyles ? inlineStyles.triangle : {}, d: `M${triangleUpIcon} -1 l5.015 -6.853 l5.015 6.853 z` }, void 0), _jsx("text", Object.assign({ x: triangleUpText, y: "0", className: styles.combinedLegendText, style: useInlineStyles ? inlineStyles.combinedLegendText : {} }, { children: labelsConfig.triangleUp }), void 0)] }, void 0)), labelsConfig.triangleDown && (_jsxs(_Fragment, { children: [_jsx("path", { className: styles.figure, style: useInlineStyles ? inlineStyles.triangle : {}, d: `M${triangleDownIcon} -8 l5.015 6.853 l5.015 -6.853 z` }, void 0), _jsx("text", Object.assign({ x: triangleDownText, y: "0", className: styles.combinedLegendText, style: useInlineStyles ? inlineStyles.combinedLegendText : {} }, { children: labelsConfig.triangleDown }), void 0)] }, void 0))] }), void 0));
});
