const inlineStyles = {
    circleHighImpact: {
        fill: '#8300514d',
        stroke: '#830051',
        strokeWidth: '1px',
        paintOrder: 'stroke',
    },
    circleModerateImpact: {
        fill: '#c4d6004d',
        stroke: '#c4d600',
        strokeWidth: '1px',
        paintOrder: 'stroke',
    },
    circleLowImpact: {
        fill: '#68d2df4d',
        stroke: '#68d2df',
        strokeWidth: '1px',
        paintOrder: 'stroke',
    },
    circleInactive: {
        fill: '#f5f7f9',
        stroke: '#e4eaf0',
        strokeWidth: '1px',
        paintOrder: 'stroke',
    },
};
export default inlineStyles;
