import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { DEFAULT_LEGEND_WIDTH, LEGEND_PADDING, LEGEND_TITLE_HEIGHT, } from './consts';
import { useLegendDimensions } from './hooks';
import SVGLegendLayer from './components/SVGLegendLayer/SVGLegendLayer';
import SVGLegendList from './components/SVGLegendList/SVGLegendList';
import { inlineStyles } from './SVGLegend.style';
import stylesCSS from './SVGLegend.css';
const SVGLegend = (props) => {
    const { data, title = 'Legend', maxWidth = DEFAULT_LEGEND_WIDTH, styles, useInlineStyles, } = props;
    const { height: legendHeight, data: legendGroups, } = useLegendDimensions(data, maxWidth);
    const SVGHeight = legendHeight + 2 * LEGEND_PADDING + LEGEND_TITLE_HEIGHT;
    const SVGWidth = maxWidth + 4 * LEGEND_PADDING;
    const titleStyle = useInlineStyles
        ? inlineStyles.title
        : {};
    const plotStyle = useInlineStyles
        ? inlineStyles.plot
        : {};
    return (_jsx("svg", Object.assign({ height: SVGHeight, width: SVGWidth, viewBox: `0 0 ${SVGWidth} ${SVGHeight}`, style: plotStyle, xmlns: "http://www.w3.org/2000/svg", "data-testid": "SVGLegend" }, { children: _jsxs(SVGLegendLayer, Object.assign({ leftPadding: LEGEND_PADDING, topPadding: LEGEND_PADDING }, { children: [_jsx("text", Object.assign({ className: stylesCSS.title, style: titleStyle, x: 0, y: 0 }, { children: title }), void 0), _jsx(SVGLegendList, { data: legendGroups, styles: styles, useInlineStyles: useInlineStyles }, void 0)] }), void 0) }), void 0));
};
export default memo(SVGLegend);
