import { DATA_POINT_RADIUS, ZERO_POINT_OFFSET, } from '../../consts';
/**
 * Calculates number of plot's elements which are scrolled out from the plot
 *
 * Plot's elements which are calculated:
 * - group delimiters/borders;
 * - group paddings (equal to data point's radius for both sides of a group);
 * - distance between two data points.
 *
 * I.e. this function converts current scroll position (relative to plot's coordinate start point)
 * into new coordinates tuple:
 * - number of group borders/delimiters;
 * - number of data point radiuses;
 * - number of data point distances.
 *
 * @param data - Plot's data
 * @param dataPointStep - Step between data points
 * @param [scrollPosition = 0] - Scroll position
 * @returns Array of plot's element counts:
 * [
 *  number of data point distances,
 *  number of data point radiuses,
 *  number of group borders,
 * ]
 */
export function scrolledElementsCounter(data, dataPointStep, scrollPosition = 0) {
    const scrollOffset = scrollPosition - ZERO_POINT_OFFSET;
    if (scrollOffset <= 0) {
        return [0, 0, 0];
    }
    let currentGroupOffset = 0;
    let currentGroupStepsLength = 0;
    let currentTotalOffset = 0;
    let totalStepsCount = 0;
    let totalRadiusCount = 0;
    let totalBordersCount = 0;
    const groups = data.getGroups();
    for (let groupIndex = 0; groupIndex < groups.length; groupIndex += 1) {
        const currentGroup = groups[groupIndex];
        currentGroupStepsLength = currentGroup.length;
        currentGroupOffset = (2 * DATA_POINT_RADIUS
            + currentGroupStepsLength * dataPointStep
            + 1 // Size of group delimiter
        );
        currentTotalOffset += currentGroupOffset;
        if (currentTotalOffset > scrollOffset) {
            break;
        }
        totalStepsCount += currentGroupStepsLength;
        totalRadiusCount += 2;
        totalBordersCount += 1;
        if (currentTotalOffset === scrollOffset) {
            return [
                totalStepsCount,
                totalRadiusCount,
                totalBordersCount,
            ];
        }
    }
    /**
     * Scroll offset relative to the current group's position
     * Indicates how far the current position is from the current group's beginning
     */
    let deltaOffset = scrollOffset - (currentTotalOffset - currentGroupOffset);
    /**
     * As border's width is fixed to 1px
     * and current offset is already greater than previous group's offset,
     * then it means that the border should be already added
     */
    totalBordersCount += 1;
    /**
     * Next, processing group padding by calculating the number of data point radiuses
     * which the rest of the offset can contain
     *
     * For this case it could be full radius or it's part
     */
    deltaOffset -= 1;
    if (deltaOffset > DATA_POINT_RADIUS) {
        totalRadiusCount += 1;
    }
    else {
        totalRadiusCount += deltaOffset / DATA_POINT_RADIUS;
        return [
            totalStepsCount,
            totalRadiusCount,
            totalBordersCount,
        ];
    }
    /**
     * Next, processing data points section by calculating the number of steps between points
     * which the reset of the offset can contain
     */
    deltaOffset -= DATA_POINT_RADIUS;
    const deltaSteps = deltaOffset / dataPointStep;
    totalStepsCount += deltaSteps;
    if (deltaSteps <= currentGroupStepsLength) {
        return [
            totalStepsCount,
            totalRadiusCount,
            totalBordersCount,
        ];
    }
    /**
     * If the number of data point steps is greater than total number of steps from the current group,
     * then it means that the offset contains additional padding at the end of this group
     *
     * Calculating number of data point radiuses from the rest offset
     */
    deltaOffset -= currentGroupStepsLength * dataPointStep;
    totalRadiusCount += deltaOffset / DATA_POINT_RADIUS;
    return [
        totalStepsCount,
        totalRadiusCount,
        totalBordersCount,
    ];
}
