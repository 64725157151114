import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useCallback, useEffect, useLayoutEffect, useRef, useState, } from 'react';
import { EPlotOrientation } from '../../../../types';
import { DEFAULT_AXIS_BORDER_WIDTH, SYNTHETIC_SCROLL_MARGIN } from '../../consts';
import { AXIS_LINE_WIDTH } from '../../../NetworkPlot/consts';
import PlotAxisBase from '../PlotAxis/PlotAxisBase';
import styles from './PlotAxisScrollable.css';
const PlotAxisScrollableX = (props) => {
    const { size, axisZone, axisLabelZone, yAxisRef, innerContainerRef, containerRef, containerHeight, labelsRef, } = props;
    const xAxisRef = useRef(null);
    const heightRef = useRef(size.height);
    const synchronizeXAxis = useCallback((scrollTop) => {
        if (xAxisRef.current) {
            const translate = containerHeight - heightRef.current + scrollTop - AXIS_LINE_WIDTH;
            xAxisRef.current.style.transform = `translate(0, ${translate < 0 ? translate : 0}px)`;
        }
        if (innerContainerRef.current && yAxisRef.current) {
            innerContainerRef.current.style.transform = '';
            yAxisRef.current.style.transform = '';
        }
    }, [containerHeight, innerContainerRef, yAxisRef]);
    const onScroll = useCallback((e) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        synchronizeXAxis(e.target.scrollTop);
    }, [synchronizeXAxis]);
    const [scrollLeft, setScrollLeft] = useState(0);
    const onHorizontalScroll = (e) => {
        setScrollLeft(e.target.scrollLeft);
    };
    const onPageScroll = useCallback(() => {
        /**
         * We need this implementation for the safari scrolling to work correctly.
         * For every page scroll, we set transform = translate (0) for container (innerContainerRef).
         * For the y-axis we set the value of the first argument as 'scrollLeft' for correct display
         * and the second argument is set to 0.
         * Also, the value for these two refs will be changed to an empty line when
         * horizontal or vertical scrolling of the plot.
         */
        if (innerContainerRef.current && yAxisRef.current) {
            innerContainerRef.current.style.transform = 'translate(0)';
            yAxisRef.current.style.transform = `translate(${innerContainerRef.current.scrollLeft}px, 0)`;
            innerContainerRef.current.style.transform = '';
            yAxisRef.current.style.transform = '';
        }
    }, [innerContainerRef, yAxisRef]);
    useLayoutEffect(() => {
        var _a;
        heightRef.current = size.height;
        synchronizeXAxis(((_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.scrollTop) || 0);
    }, [containerRef, synchronizeXAxis, size.height]);
    useEffect(() => {
        const ref = containerRef.current;
        const innerRef = innerContainerRef.current;
        if (ref) {
            ref.addEventListener('scroll', onScroll);
        }
        if (innerRef) {
            innerRef.addEventListener('scroll', onHorizontalScroll);
        }
        window.addEventListener('scroll', onPageScroll);
        return () => {
            ref === null || ref === void 0 ? void 0 : ref.removeEventListener('scroll', onScroll);
            innerRef === null || innerRef === void 0 ? void 0 : innerRef.removeEventListener('scroll', onHorizontalScroll);
            window.removeEventListener('scroll', onPageScroll);
        };
    }, [containerRef, onScroll, onPageScroll, innerContainerRef]);
    useEffect(() => {
        var _a;
        if (labelsRef === null || labelsRef === void 0 ? void 0 : labelsRef.current) {
            labelsRef.current.style.left = `-${(_a = innerContainerRef.current) === null || _a === void 0 ? void 0 : _a.scrollLeft}`;
        }
    }, [scrollLeft, innerContainerRef, labelsRef]);
    const { value: axisZoneScale, rect: axisZoneRect, } = axisZone;
    /**
     * It appears that SVG rendering considers rounding down float numbers
     * However, 0 value should be rounded up as it won't be considered otherwise
     */
    const HALF_BORDER_WIDTH = Math.floor(DEFAULT_AXIS_BORDER_WIDTH / 2) || 1;
    const lineX1 = axisZoneRect.x - HALF_BORDER_WIDTH;
    const lineY1 = axisZoneRect.y;
    const lineX2 = axisZoneRect.x + axisZoneRect.width;
    const lineY2 = axisZoneRect.y;
    const labelTextAnchor = 'end';
    const scaleOrientation = EPlotOrientation.Vertical;
    const HideRect = (hideRectProps) => {
        const { y, height } = hideRectProps;
        return (_jsx("rect", { x: 0, y: y, height: height + SYNTHETIC_SCROLL_MARGIN, width: "100%", className: styles.axisBackground }, void 0));
    };
    return (_jsx("svg", Object.assign({ height: size.height, width: size.width, viewBox: `0 0 ${size.width} ${size.height}`, xmlns: "http://www.w3.org/2000/svg", className: styles.axisXContainer }, { children: _jsx("g", Object.assign({ ref: xAxisRef }, { children: _jsx(PlotAxisBase, { axisZoneScale: axisZoneScale, axisLabelZone: axisLabelZone, lineX1: lineX1, lineY1: lineY1, lineX2: lineX2, lineY2: lineY2, scaleOrientation: scaleOrientation, labelTextAnchor: labelTextAnchor, hideRect: _jsx(HideRect, { y: axisZoneRect.y, height: axisZoneRect.height }, void 0), scroll: scrollLeft + lineX1 }, void 0) }), void 0) }), void 0));
};
export default memo(PlotAxisScrollableX);
