import { DEFAULT_QUANTITY_LABELS_AXIS_X, DEFAULT_QUANTITY_LABELS_AXIS_Y } from '../../../NetworkPlot/consts';
const getRoundCoordinate = (val, method) => method(val / 10) * 10;
const getMaxRoundCoordinate = (val) => getRoundCoordinate(val, Math.ceil);
const getMinRoundCoordinate = (val) => getRoundCoordinate(val, Math.floor);
const getStepException = ({ length, quantityLabels, step = 5 }) => {
    if (length <= 20) {
        return 1;
    }
    if (length <= 40) {
        return 2;
    }
    if (length / step > quantityLabels) {
        return getStepException({ length, quantityLabels, step: step * 2 });
    }
    return step;
};
const getStep = (minValue, maxValue) => {
    if (!maxValue) {
        return minValue;
    }
    return getStep(maxValue, minValue % maxValue);
};
export const getAxis = ({ x, y }) => {
    const axisMaxX = getMaxRoundCoordinate(x.max);
    const axisMaxY = getMaxRoundCoordinate(y.max);
    const axisMinX = getMinRoundCoordinate(x.min);
    const axisMinY = getMinRoundCoordinate(y.min);
    const axisXLength = Math.abs(axisMaxX) + Math.abs(axisMinX);
    const axisYLength = Math.abs(axisMaxY) + Math.abs(axisMinY);
    const axisX = {
        min: axisMinX,
        max: axisMaxX,
        step: 0,
    };
    const axisY = {
        min: axisMinY,
        max: axisMaxY,
        step: 0,
    };
    if (Math.abs(axisMaxX) === Math.abs(axisMinX) || axisMaxX === 0 || axisMinX === 0) {
        axisX.step = getStepException({
            length: axisXLength,
            quantityLabels: DEFAULT_QUANTITY_LABELS_AXIS_X,
        });
    }
    else {
        axisX.step = getStep(Math.abs(axisMinX), Math.abs(axisMaxX));
    }
    if (Math.abs(axisMaxY) === Math.abs(axisMinY) || axisMaxY === 0 || axisMinY === 0) {
        axisY.step = getStepException({
            length: axisYLength,
            quantityLabels: DEFAULT_QUANTITY_LABELS_AXIS_Y,
        });
    }
    else {
        axisY.step = getStep(Math.abs(axisMinY), Math.abs(axisMaxY));
    }
    return {
        x: axisX,
        y: axisY,
    };
};
