import { useMemo } from 'react';
import { filledArray } from '../../../../../../utils';
export default function useLimitedPages(pageIndex, pageCount, limit) {
    const correctLimit = limit < 5 ? 5 : limit;
    const maxOffset = Math.ceil(correctLimit / 2);
    let firstPageIndex = pageIndex - maxOffset + 2;
    let lastPageIndex = pageIndex + maxOffset - 2;
    let offsetFromFirstIndex = 0;
    if (firstPageIndex < 0) {
        offsetFromFirstIndex = Math.abs(firstPageIndex);
        firstPageIndex = 0;
    }
    let offsetFromLastIndex = 0;
    if (lastPageIndex > pageCount - 1) {
        offsetFromLastIndex = lastPageIndex - pageCount + 1;
        lastPageIndex = pageCount - 1;
    }
    if (offsetFromFirstIndex > 0) {
        lastPageIndex = Math.min(lastPageIndex + offsetFromFirstIndex, pageCount - 1);
    }
    if (offsetFromLastIndex > 0) {
        firstPageIndex = Math.max(firstPageIndex - offsetFromLastIndex, 0);
    }
    if (firstPageIndex > 0 && lastPageIndex < pageCount - 1) {
        firstPageIndex += 1;
        lastPageIndex -= 1;
    }
    const pageIndexes = useMemo(() => (filledArray(lastPageIndex - firstPageIndex + 1, (index) => (index + firstPageIndex))), [firstPageIndex, lastPageIndex]);
    return {
        firstPageIndex,
        lastPageIndex,
        pageIndexes,
    };
}
