import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, useEffect, useMemo, useRef, useState, } from 'react';
import classNames from 'classnames';
import { useResizeContainer, useSelectRegion, } from '../../hooks';
import { useDimensions, useZoom, useGroupScroll, ScrollContext, } from './hooks';
import { PLOT_LEFT_PADDING, DEFAULT_PLOT_HEIGHT, DEFAULT_SELECT_REGION_OFFSET, DATA_POINT_STEP, PLOT_RIGHT_PADDING, DEFAULT_LOWER_DATA_LIMIT, DEFAULT_UPPER_DATA_LIMIT, PLOT_TOP_PADDING, DATA_CONTAINER_DIFFERENCE, PLOT_BOTTOM_PADDING, AXIS_LABEL_FONT_SIZE, LEGEND_TEXT_ROW_HEIGHT, LEFT_AXIS_SCALE_WIDTH, DATA_TOP_OFFSET, DEFAULT_Y_SCROLLBAR_RIGHT_PADDING, SYNTHETIC_SCROLL_X_MARGIN, DATA_BOTTOM_OFFSET, RIGHT_SCROLLBAR_SHIFT, } from './consts';
import { ELevelResult, ETraitsTypes } from '../../../types';
import ManhattanPlotAxis from './components/ManhattanPlotAxis/ManhattanPlotAxis';
import ManhattanPlotData from './components/ManhattanPlotData/ManhattanPlotData';
import ManhattanPlotAxisScaleLabels from './components/ManhattanPlotAxisScaleLabels/ManhattanPlotAxisScaleLabels';
import ManhattanPlotAxisScaleLines from './components/ManhattanPlotAxisScaleLines/ManhattanPlotAxisScaleLines';
import ManhattanPlotThresholdsArea from './components/ManhattanPlotThresholdsArea/ManhattanPlotThresholdsArea';
import ManhattanPlotThresholds from './components/ManhattanPlotThresholds/ManhattanPlotThresholds';
import { ZoomControls } from '../common/components';
import { PlotSettings } from './components/PlotSettings/PlotSettings';
import { getDefaultDatasetsNameMapper, getDatasetsOpacityCollection } from './utils';
import { PlotSelectRegion, SyntheticScrollX, SyntheticScrollY } from '../common';
import ManhattanPlotGroupsLabels from './components/ManhattanPlotGroupsLabels/ManhattanPlotGroupsLabels';
import { EPlot } from '../common/types';
import styles from './ManhattanPlot.css';
const ManhattanScrollablePlot = (props) => {
    const { data, axis, lowerThreshold, upperThreshold, upperDataLimit = DEFAULT_UPPER_DATA_LIMIT, lowerDataLimit = DEFAULT_LOWER_DATA_LIMIT, height = DEFAULT_PLOT_HEIGHT, dataPointStep = DATA_POINT_STEP, withChunkedData = true, withZoomControls = true, withZoomReset = false, withScrollListener = true, zoomConfig, zoomControlsClassName, counters, zoomCounters, getTooltipContent, renderGroupBorder, getDataPointShape, legend, withSettings, datasets: initialDatasets = [], getDatasetsNameMapper, levelResultMapper, traitsTypeMapper, initialLevelResultsCollection, initialTraitsTypesCollection, defaultLevelResult = ELevelResult.Gene, } = props;
    const labelsContainerRef = useRef(null);
    const yAxisRef = useRef(null);
    const ratioRef = useRef(0);
    const [labelsOffset, setLabelsOffset] = useState(0);
    const { containerWidth: axisContainerWidth, containerRef, } = useResizeContainer();
    const contentWidth = axisContainerWidth - PLOT_LEFT_PADDING - PLOT_RIGHT_PADDING > 0
        ? axisContainerWidth - PLOT_LEFT_PADDING - PLOT_RIGHT_PADDING
        : 0;
    const thresholds = useMemo(() => ([
        lowerThreshold,
        upperThreshold,
    ]), [lowerThreshold, upperThreshold]);
    const dataLimits = useMemo(() => ([
        lowerDataLimit,
        upperDataLimit,
    ]), [lowerDataLimit, upperDataLimit]);
    const { dataPointStep: zoomedDataPointStep, zoomStep, minAreaZoomFactor, maxAreaZoomFactor, areaZoomFactor: zoomFactor, allowZooming, handleZoomChange, handleZoomAreaChange, dataContainerRef, dataHeight, resetDataHeight, } = useZoom({
        data,
        containerWidth: contentWidth,
        height,
        dataPointStep,
        config: zoomConfig,
        counters,
        zoomCounters,
    });
    useEffect(() => {
        resetDataHeight();
    }, [axisContainerWidth, resetDataHeight]);
    const { SVGWidth, scale, scaleRange, width, groupsDimensions, maxGroupLabelHeight, } = useDimensions({
        data,
        height: dataHeight,
        containerHeight: height,
        thresholds,
        limits: dataLimits,
        dataPointStep: zoomedDataPointStep,
        counters,
    });
    useEffect(() => {
        if (axisContainerWidth && dataHeight && !ratioRef.current) {
            ratioRef.current = (contentWidth - LEFT_AXIS_SCALE_WIDTH)
                / (dataHeight - PLOT_TOP_PADDING - DATA_TOP_OFFSET);
        }
    }, [contentWidth, dataHeight, axisContainerWidth]);
    const { containerHandlers: selectRegionContainerHandlers, position: selectRegionPosition, isVisible: isSelectRegionVisible, } = useSelectRegion({
        innerContainerRef: dataContainerRef,
        onSelect: handleZoomAreaChange,
        allowZooming: allowZooming[1],
        offset: DEFAULT_SELECT_REGION_OFFSET,
        ratio: ratioRef.current,
    });
    const dataContainerWidth = Math.max(SVGWidth, axisContainerWidth);
    const dataAxisWidth = Math.max(width, contentWidth);
    const groupsVisibility = useGroupScroll({
        containerRef: dataContainerRef,
        containerWidth: contentWidth,
        groupsDimensions,
        attachScrollListener: withScrollListener,
    });
    const datasetNameMapper = getDatasetsNameMapper
        ? getDatasetsNameMapper(initialDatasets)
        : getDefaultDatasetsNameMapper(initialDatasets);
    const [levelResultsCollection, setLevelResults] = useState(initialLevelResultsCollection || Object.values(ELevelResult).reduce((acc, levelResult) => (Object.assign(Object.assign({}, acc), { [levelResult]: levelResult === defaultLevelResult })), {}));
    const [traitsTypesCollection, setTraitsTypes] = useState(initialTraitsTypesCollection || Object.values(ETraitsTypes).reduce((acc, traitsType) => (Object.assign(Object.assign({}, acc), { [traitsType]: traitsType === ETraitsTypes.Binary })), {}));
    const [datasets, setDatasets] = useState(getDatasetsOpacityCollection(initialDatasets));
    const onOpacityChange = (id, value) => {
        setDatasets((currentDatasets) => (Object.assign(Object.assign({}, currentDatasets), { [id.slice(6)]: value })));
    };
    const onLevelResultChange = (id, value) => {
        const updatedLevelResults = Object.assign({}, levelResultsCollection);
        updatedLevelResults[id.slice(3)] = value;
        setLevelResults(updatedLevelResults);
    };
    const onTraitsTypesChange = (id, value) => {
        const updatedTraitsTypes = Object.assign({}, traitsTypesCollection);
        updatedTraitsTypes[id.slice(3)] = value;
        setTraitsTypes(updatedTraitsTypes);
    };
    const onScroll = () => {
        if (dataContainerRef.current) {
            setLabelsOffset(dataContainerRef.current.scrollTop);
        }
    };
    const dataContainerClassName = classNames(styles.dataContainer, {
        [styles.hideScrollBar]: height >= dataHeight - DATA_CONTAINER_DIFFERENCE,
    });
    const calculatedDataAreaWidth = axisContainerWidth - PLOT_RIGHT_PADDING - LEFT_AXIS_SCALE_WIDTH;
    const dataAreaWidth = calculatedDataAreaWidth > 0 ? calculatedDataAreaWidth : 0;
    const dataContainerSVGWidth = dataContainerWidth - DEFAULT_Y_SCROLLBAR_RIGHT_PADDING > 0
        ? dataContainerWidth - DEFAULT_Y_SCROLLBAR_RIGHT_PADDING
        : 0;
    const containersHeight = height + maxGroupLabelHeight + DATA_TOP_OFFSET - PLOT_TOP_PADDING;
    return (_jsxs(_Fragment, { children: [withSettings && (_jsx(PlotSettings, { datasets: datasets, levelResultsCollection: levelResultsCollection, traitsTypesCollection: traitsTypesCollection, datasetNameMapper: datasetNameMapper, levelResultMapper: levelResultMapper, traitsTypeMapper: traitsTypeMapper, onOpacityChange: onOpacityChange, onLevelResultChange: onLevelResultChange, onTraitsTypesChange: onTraitsTypesChange }, void 0)), _jsxs("div", Object.assign({ className: styles.outerContainer, ref: containerRef, style: {
                    height: containersHeight,
                } }, { children: [_jsx(SyntheticScrollY, { containerHeight: height, contentHeight: dataHeight - DATA_CONTAINER_DIFFERENCE, contentRef: dataContainerRef, yAxisRef: yAxisRef, marginTop: DATA_TOP_OFFSET, marginBottom: DATA_BOTTOM_OFFSET, rightShift: RIGHT_SCROLLBAR_SHIFT, customStyled: true }, void 0), _jsx("div", Object.assign({ className: styles.innerContainer }, selectRegionContainerHandlers, { style: {
                            height: containersHeight,
                        } }, { children: _jsxs(ScrollContext.Provider, Object.assign({ value: groupsVisibility }, { children: [_jsx("div", Object.assign({ ref: labelsContainerRef, className: styles.labelsXContainer, style: {
                                        height: height + maxGroupLabelHeight,
                                        top: DATA_TOP_OFFSET,
                                        left: renderGroupBorder
                                            ? PLOT_LEFT_PADDING + LEFT_AXIS_SCALE_WIDTH
                                            : LEFT_AXIS_SCALE_WIDTH,
                                        width: renderGroupBorder
                                            ? dataAreaWidth - PLOT_LEFT_PADDING
                                            : dataAreaWidth,
                                    }, "data-testid": "axisX" }, { children: _jsx("svg", Object.assign({ className: styles.axisXContainer, height: height + maxGroupLabelHeight, width: dataContainerWidth, viewBox: `0 0 ${dataContainerWidth} ${height + maxGroupLabelHeight}`, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx(ManhattanPlotGroupsLabels, { data: data, height: height, groupsDimensions: groupsDimensions, renderGroupBorder: renderGroupBorder, dataPointStep: zoomedDataPointStep }, void 0) }), void 0) }), void 0), _jsx("svg", Object.assign({ ref: yAxisRef, className: styles.axisContainer, height: height, width: axisContainerWidth > 0 ? axisContainerWidth : 0, viewBox: `0 0 ${axisContainerWidth} ${height}`, xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx(ManhattanPlotAxis, { axis: axis, height: height, width: contentWidth, withOverflow: true, legend: legend }, void 0) }), void 0), _jsx("svg", Object.assign({ className: styles.axisContainer, height: height - PLOT_TOP_PADDING - PLOT_BOTTOM_PADDING + AXIS_LABEL_FONT_SIZE, style: {
                                        top: PLOT_TOP_PADDING + LEGEND_TEXT_ROW_HEIGHT,
                                    }, xmlns: "http://www.w3.org/2000/svg", "data-testid": "axisY" }, { children: _jsx(ManhattanPlotAxisScaleLabels, { scale: scale, offset: labelsOffset + LEGEND_TEXT_ROW_HEIGHT }, void 0) }), void 0), _jsx("div", Object.assign({ "data-testid": "ManhattanScrollablePlot_dataContainerSVG", ref: dataContainerRef, className: dataContainerClassName, style: { height, width: axisContainerWidth - PLOT_RIGHT_PADDING }, onScroll: onScroll }, { children: _jsxs("svg", Object.assign({ className: styles.dataContainerSVG, height: dataHeight - DATA_CONTAINER_DIFFERENCE, width: dataContainerSVGWidth, xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx(ManhattanPlotThresholdsArea, { scale: scale, width: dataAxisWidth, height: dataHeight }, void 0), _jsx(ManhattanPlotThresholds, { scale: scale, width: dataAxisWidth }, void 0), _jsx(ManhattanPlotAxisScaleLines, { scale: scale, width: dataAxisWidth }, void 0), _jsx(ManhattanPlotData, { data: data, height: dataHeight, scaleRange: scaleRange, dataLimits: dataLimits, dataPointStep: zoomedDataPointStep, getTooltipContent: getTooltipContent, getDataPointShape: getDataPointShape, groupsDimensions: groupsDimensions, withChunkedData: withChunkedData, datasets: datasets, levelResultsCollection: levelResultsCollection, traitsTypesCollection: traitsTypesCollection, hasPlotSettings: withSettings }, void 0)] }), void 0) }), void 0), selectRegionPosition
                                    && (_jsx(PlotSelectRegion, { isVisible: isSelectRegionVisible, plot: EPlot.Manhattan, left: selectRegionPosition === null || selectRegionPosition === void 0 ? void 0 : selectRegionPosition.left, top: selectRegionPosition === null || selectRegionPosition === void 0 ? void 0 : selectRegionPosition.top, width: selectRegionPosition === null || selectRegionPosition === void 0 ? void 0 : selectRegionPosition.width, height: selectRegionPosition === null || selectRegionPosition === void 0 ? void 0 : selectRegionPosition.height }, void 0))] }), void 0) }), void 0)] }), void 0), withZoomControls && (_jsxs(_Fragment, { children: [_jsx(SyntheticScrollX, { containerWidth: axisContainerWidth, contentWidth: SVGWidth, contentRef: dataContainerRef, yAxisRef: yAxisRef, labelsContainerRef: labelsContainerRef, marginLeft: SYNTHETIC_SCROLL_X_MARGIN, marginRight: SYNTHETIC_SCROLL_X_MARGIN, customStyled: true }, void 0), _jsx(ZoomControls, { className: zoomControlsClassName, step: zoomStep, value: zoomFactor, minValue: minAreaZoomFactor, maxValue: maxAreaZoomFactor, isEnabled: allowZooming, withZoomReset: withZoomReset, zoomResetCallback: resetDataHeight, onZoomChange: handleZoomChange, allowReset: dataHeight > height }, void 0)] }, void 0))] }, void 0));
};
/** Since memo loses generic props */
export default memo(ManhattanScrollablePlot);
