import { useState, useEffect } from 'react';
export const emptyUtag = {
    link: () => undefined,
    view: () => undefined,
    data: {},
};
const INTERVAL_TIME = 200;
export const useUtag = (intervalTime = INTERVAL_TIME) => {
    const [utag, setUtag] = useState(emptyUtag);
    useEffect(() => {
        if (window.utag) {
            setUtag(window.utag);
            return;
        }
        let counter = 0;
        const interval = setInterval(() => {
            if (window.utag) {
                setUtag(window.utag);
                clearInterval(interval);
                return;
            }
            counter += 1;
            if (counter > 50) {
                clearInterval(interval);
            }
        }, intervalTime);
        // eslint-disable-next-line consistent-return
        return () => clearInterval(interval);
    }, [intervalTime]);
    return utag;
};
