import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useState, useCallback, useEffect, useMemo, useRef, useLayoutEffect, } from 'react';
import classnames from 'classnames';
import { EInputTextType, TextField, Button } from '../../../../../components';
import { EKeyCode } from '../../../../../consts';
import { limitByRange } from '../../../../../utils';
import { validateInput, convertToPercentage, convertFromPercentage } from './utils';
import { DECREASE_BUTTON_LABEL, INCREASE_BUTTON_LABEL, RESET_ZOOM_BUTTON_LABEL, } from './consts';
import styles from './ZoomControls.css';
export const ZoomControls = memo(function ZoomControlsBase(props) {
    const { value, step = 1, isEnabled = [], minValue, maxValue, className, withZoomReset, zoomResetCallback, onZoomChange, allowReset, } = props;
    const defaultValue = useRef(value);
    const [isZoomOutEnabled = true, isZoomInEnabled = true] = isEnabled;
    const isZoomEnabled = isZoomOutEnabled || isZoomInEnabled;
    /**
     * Calls zoom changing callback if it is set and if updated value differs from the current
     *
     * @param zoomValue - Current value
     * @param updatedZoomValue - Updated value
     */
    const changeZoomIfNeeded = useCallback((zoomValue, updatedZoomValue) => {
        if (onZoomChange && zoomValue !== updatedZoomValue) {
            onZoomChange(updatedZoomValue);
        }
    }, [onZoomChange]);
    /**
     * State value which indicates if input element should be shown instead of a label or not
     */
    const [editState, setEditState] = useState(false);
    /**
     * State value to store given zoom factor
     */
    const [zoomValue, setZoomValue] = useState(() => limitByRange(value, minValue, maxValue));
    /**
     * State value for showing current percentage from stored zoom factor
     * This value is shown to user and is controlled by input element
     */
    const [inputValue, setInputValue] = useState(convertToPercentage(zoomValue));
    /**
     * Sync zoom value state with a corresponding prop
     */
    useEffect(() => {
        setZoomValue(limitByRange(value, minValue, maxValue));
    }, [value, minValue, maxValue]);
    /**
     * Sync input value state with the current zoom value state
     */
    useEffect(() => {
        setInputValue(convertToPercentage(zoomValue));
    }, [zoomValue]);
    /**
     * Disables input element state and sets input value
     *
     * @param factorValue - Zoom value to calculate input value from
     */
    const restoreInputValue = useCallback((factorValue) => {
        setEditState(false);
        setInputValue(convertToPercentage(factorValue));
    }, []);
    /**
     * Input value change callback
     *
     * @param event - Change event
     */
    const handleInputChange = useCallback((event) => {
        const targetElement = event.target;
        const updatedInputValue = targetElement.value.replace(',', '');
        if (validateInput(updatedInputValue)) {
            setInputValue(updatedInputValue);
        }
    }, []);
    /**
     * Input value handler callback
     *
     * @param updatedInputValue - Input value
     */
    const handleInputValue = useCallback((updatedInputValue) => {
        let unlimitedZoomValue;
        try {
            unlimitedZoomValue = convertFromPercentage(updatedInputValue);
        }
        catch (_a) {
            unlimitedZoomValue = zoomValue;
        }
        const updatedZoomValue = limitByRange(unlimitedZoomValue, minValue, maxValue);
        setZoomValue(updatedZoomValue);
        restoreInputValue(updatedZoomValue);
        changeZoomIfNeeded(zoomValue, updatedZoomValue);
    }, [zoomValue, minValue, maxValue, changeZoomIfNeeded, restoreInputValue]);
    /**
     * Input key down callback
     * Mostly for controlling blur and text entered events
     *
     * @param event - Keyboard event
     */
    const handleInputKeyDown = useCallback((event) => {
        switch (event.code) {
            case EKeyCode.Esc: {
                restoreInputValue(zoomValue);
                break;
            }
            case EKeyCode.Enter:
            case EKeyCode.NumpadEnter: {
                const targetElement = event.target;
                handleInputValue(targetElement.value);
                break;
            }
            default:
        }
    }, [zoomValue, handleInputValue, restoreInputValue]);
    /**
     * Input blur callback
     */
    const handleInputBlur = useCallback((event) => {
        const targetElement = event.target;
        handleInputValue(targetElement.value);
    }, [handleInputValue]);
    /**
     * Label click callback which enables input element state
     */
    const handleLabelClick = useCallback(() => {
        if (!isZoomEnabled) {
            return;
        }
        setEditState(true);
    }, [isZoomEnabled]);
    /**
     * Label keypress callback which enables input element state
     */
    const handleLabelKeyPress = useCallback((event) => {
        if (event.code === EKeyCode.Enter) {
            if (!isZoomEnabled) {
                return;
            }
            setEditState(true);
        }
    }, [isZoomEnabled]);
    /**
     * Indicates if the current zoom value reached its allowed minimum value
     */
    const isMinValue = useMemo(() => !isZoomOutEnabled || (typeof minValue === 'number' && zoomValue <= minValue), [isZoomOutEnabled, zoomValue, minValue]);
    /**
     * Indicates if the current zoom value reached its allowed maximum value
     */
    const isMaxValue = useMemo(() => !isZoomInEnabled || (typeof maxValue === 'number' && zoomValue >= maxValue), [isZoomInEnabled, zoomValue, maxValue]);
    /**
     * Button click handler which decreases zoom value by a given step if possible
     */
    const handleDecreaseButtonClick = useCallback(() => {
        // No action if value reached its limit
        if (isMinValue) {
            return;
        }
        const updatedZoomValue = limitByRange(zoomValue - step, minValue, maxValue);
        setZoomValue(updatedZoomValue);
        changeZoomIfNeeded(zoomValue, updatedZoomValue);
    }, [minValue, maxValue, isMinValue, step, zoomValue, changeZoomIfNeeded]);
    /**
     * Button click handler which increases zoom value by a given step if possible
     */
    const handleIncreaseButtonClick = useCallback(() => {
        // No action if value reached its limit
        if (isMaxValue) {
            return;
        }
        const updatedZoomValue = limitByRange(zoomValue + step, minValue, maxValue);
        setZoomValue(updatedZoomValue);
        changeZoomIfNeeded(zoomValue, updatedZoomValue);
    }, [minValue, maxValue, isMaxValue, step, zoomValue, changeZoomIfNeeded]);
    const inputRef = useRef(null);
    useLayoutEffect(() => {
        if (editState && inputRef.current) {
            inputRef.current.select();
        }
    }, [editState]);
    const labelClassName = classnames(styles.value, {
        [styles.disabled]: !isZoomEnabled,
    });
    const resetButtonClassName = classnames(styles.resetButton, styles.disabledResetButton, { [styles.enabledResetButton]: zoomValue !== defaultValue.current || allowReset });
    const handleZoomReset = useCallback(() => {
        const updatedZoomValue = limitByRange(defaultValue.current, minValue, maxValue);
        setZoomValue(updatedZoomValue);
        changeZoomIfNeeded(value, updatedZoomValue);
        if (zoomResetCallback) {
            zoomResetCallback();
        }
    }, [changeZoomIfNeeded, maxValue, minValue, value, zoomResetCallback]);
    const classNamesMemo = useMemo(() => classnames(styles.layout, className), [className]);
    return (_jsx("div", Object.assign({ className: classNamesMemo }, { children: _jsxs("div", Object.assign({ className: styles.block }, { children: [_jsx("div", Object.assign({ className: styles.label }, { children: "Zoom:" }), void 0), _jsxs("div", Object.assign({ className: styles.content }, { children: [_jsx(Button, Object.assign({ className: styles.button, disabled: isMinValue, onClick: handleDecreaseButtonClick }, { children: DECREASE_BUTTON_LABEL }), void 0), editState ? (_jsx(TextField, { type: EInputTextType.Text, ref: inputRef, className: styles.input, value: inputValue, onChange: handleInputChange, onKeyDown: handleInputKeyDown, onBlur: handleInputBlur, 
                            /* eslint-disable-next-line jsx-a11y/no-autofocus */
                            autoFocus: true }, void 0)) : (_jsx("div", Object.assign({ className: labelClassName, role: "button", tabIndex: 0, onClick: handleLabelClick, onKeyPress: handleLabelKeyPress }, { children: `${inputValue}%` }), void 0)), _jsx(Button, Object.assign({ className: styles.button, disabled: isMaxValue, onClick: handleIncreaseButtonClick }, { children: INCREASE_BUTTON_LABEL }), void 0)] }), void 0), withZoomReset && (_jsx("span", Object.assign({ className: resetButtonClassName, onClick: handleZoomReset, role: "presentation" }, { children: RESET_ZOOM_BUTTON_LABEL }), void 0))] }), void 0) }), void 0));
});
