/* eslint-disable no-console */
export var ELogType;
(function (ELogType) {
    ELogType["Error"] = "error";
    ELogType["Warning"] = "warn";
    ELogType["Log"] = "log";
})(ELogType || (ELogType = {}));
export var ELogEnvironment;
(function (ELogEnvironment) {
    ELogEnvironment["Development"] = "development";
    ELogEnvironment["Production"] = "production";
    ELogEnvironment["All"] = "all";
})(ELogEnvironment || (ELogEnvironment = {}));
/**
 * Logger class which logs messages depending on node environment
 */
export class Logger {
    /**
     * Creates new instance of the Logger
     *
     * @param loggerConfig - config for the logger with default environment for specific logging
     * @returns New instance of the Logger
     */
    constructor(loggerConfig) {
        if (typeof loggerConfig === 'object') {
            this.defaultLogEnv = loggerConfig;
        }
        else {
            this.defaultLogEnv = {
                log: loggerConfig,
                warn: loggerConfig,
                error: loggerConfig,
            };
        }
    }
    /**
     * Check if logging should be performed
     *
     * @static
     * @private
     *
     * @param environment - Environment for which logging performed
     * @returns boolean
     */
    static shouldLog(environment) {
        return process.env.NODE_ENV === environment || environment === ELogEnvironment.All;
    }
    /**
     * Logs specific message by console.log depending on environment
     *
     * @param message - Text message to log
     * @param environment - Environment for which logging performed 'development' by default
     * @returns void
     */
    log(message, environment = this.defaultLogEnv.log || ELogEnvironment.Development) {
        if (Logger.shouldLog(environment)) {
            console.log(message);
        }
    }
    /**
     * Logs specific message by console.warn depending on environment
     *
     * @param message - Text message to log
     * @param environment - Environment for which logging performed 'development' by default
     * @returns void
     */
    warn(message, environment = this.defaultLogEnv.warn || ELogEnvironment.Development) {
        if (Logger.shouldLog(environment)) {
            console.warn(message);
        }
    }
    /**
     * Logs specific message by console.error depending on environment
     *
     * @param message - Text message to log
     * @param environment - Environment for which logging performed 'development' by default
     * @returns void
     */
    error(message, environment = this.defaultLogEnv.error || ELogEnvironment.Development) {
        if (Logger.shouldLog(environment)) {
            console.error(message);
        }
    }
}
/**
 * Logger instance used in shared repo
 */
export const logger = new Logger({
    log: ELogEnvironment.Development,
    warn: ELogEnvironment.Development,
    error: ELogEnvironment.Development,
});
