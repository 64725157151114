import { useState, useCallback, useMemo } from 'react';
export const EMPTY_INDEX = -1;
export var EIterationDirection;
(function (EIterationDirection) {
    EIterationDirection[EIterationDirection["Previous"] = -1] = "Previous";
    EIterationDirection[EIterationDirection["Next"] = 1] = "Next";
})(EIterationDirection || (EIterationDirection = {}));
export function useIterateOptions(props) {
    const { options, getOptionValue } = props;
    const [option, setOption] = useState(null);
    const indexById = useMemo(() => options
        .reduce((acc, item, index) => (Object.assign(Object.assign({}, acc), { [getOptionValue(item)]: index })), {}), [getOptionValue, options]);
    const highlightOption = useCallback((direction) => () => {
        const size = options.length;
        const index = option ? indexById[getOptionValue(option)] : EMPTY_INDEX;
        const nextUnboundedIndex = index + direction;
        const nextIndex = nextUnboundedIndex < 0
            ? (size + EMPTY_INDEX)
            : (nextUnboundedIndex % size);
        const nextOption = options[nextIndex] || null;
        setOption(nextOption);
        return nextOption;
    }, [getOptionValue, indexById, option, options]);
    const reset = useCallback(() => setOption(null), []);
    const next = highlightOption(EIterationDirection.Next);
    const previous = highlightOption(EIterationDirection.Previous);
    return useMemo(() => ({
        option,
        setOption,
        reset,
        next,
        previous,
    }), [next, option, previous, reset]);
}
