import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useContext, useCallback, } from 'react';
import classnames from 'classnames';
import { Context } from '../../Tabs';
import styles from '../../Tabs.css';
export const TabsTab = memo(function TabsTab(props) {
    const { children, id, className, disabled = false, } = props;
    const { handleClick: onClick, activeTabId } = useContext(Context);
    const classNames = classnames(styles.label, className, {
        [styles.active]: activeTabId === id,
        [styles.disabled]: disabled,
    });
    const handleClick = useCallback(() => {
        if (!disabled) {
            onClick(id);
        }
    }, [id, disabled, onClick]);
    return (_jsx("div", Object.assign({ tabIndex: -1, role: "tab", className: classNames, onKeyDown: handleClick, onClick: handleClick }, { children: children }), void 0));
});
