import { ELevelResult } from '../../../types';
export const ELEMENT_PREFIX = 'filter-by-most-significant-model';
export const MOST_SIGNIFICANT_MODEL_LABEL = 'Most significant model';
const MOST_SIGNIFICANT_MODEL_TOOLTIP_GLR = 'Select the most significant model for each gene-phenotype combination from available data based on selected filters';
const MOST_SIGNIFICANT_MODEL_TOOLTIP_VLR = 'Select the most significant model for each variant-phenotype combination from available data based on selected filters';
export const MOST_SIGNIFICANT_MODEL_TOOLTIP = {
    [ELevelResult.Gene]: MOST_SIGNIFICANT_MODEL_TOOLTIP_GLR,
    [ELevelResult.Variant]: MOST_SIGNIFICANT_MODEL_TOOLTIP_VLR,
    [ELevelResult.Phenotype]: '',
};
export const MOST_SIGNIFICANT_MODEL_TOGGLER_ID = `${ELEMENT_PREFIX}_toggler`;
