import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useRef } from 'react';
import { Tooltip, ETooltipAction, ETooltipPlacement } from '../../../../../Tooltip';
import styles from './TableViewCell.css';
const TableViewCell = (props) => {
    const { children, tooltipContent, showOnlyOnEllipsis, columnProps, cellProps, columnHeaderName = '', } = props;
    const cellRef = useRef(null);
    return (_jsx(Tooltip, Object.assign({ action: ETooltipAction.Hover, className: styles.tableTooltip, placement: ETooltipPlacement.TopCenter, mouseLeaveDelay: 0.02, offset: [0, -20], tooltipContent: tooltipContent, showOnlyOnEllipsis: showOnlyOnEllipsis, childRef: cellRef }, { children: _jsx("td", Object.assign({}, columnProps, cellProps, { "data-testid": `Table.Cell.${columnHeaderName}`, ref: cellRef }, { children: _jsx("div", Object.assign({ className: styles.cellContainer }, { children: children }), void 0) }), void 0) }), void 0));
};
export default memo(TableViewCell);
