export var ESortType;
(function (ESortType) {
    ESortType[ESortType["Asc"] = 0] = "Asc";
    ESortType[ESortType["Desc"] = 1] = "Desc";
    ESortType[ESortType["None"] = 2] = "None";
})(ESortType || (ESortType = {}));
export var SortResult;
(function (SortResult) {
    SortResult[SortResult["EQUAL"] = 0] = "EQUAL";
    SortResult[SortResult["BEFORE"] = -1] = "BEFORE";
    SortResult[SortResult["AFTER"] = 1] = "AFTER";
})(SortResult || (SortResult = {}));
export var TableWidths;
(function (TableWidths) {
    TableWidths[TableWidths["WIDTH_96"] = 96] = "WIDTH_96";
    TableWidths[TableWidths["WIDTH_120"] = 120] = "WIDTH_120";
    TableWidths[TableWidths["WIDTH_160"] = 160] = "WIDTH_160";
    TableWidths[TableWidths["WIDTH_240"] = 240] = "WIDTH_240";
    TableWidths[TableWidths["WIDTH_320"] = 320] = "WIDTH_320";
})(TableWidths || (TableWidths = {}));
export var RomanNumeral;
(function (RomanNumeral) {
    RomanNumeral[RomanNumeral["I"] = 1] = "I";
    RomanNumeral[RomanNumeral["IV"] = 4] = "IV";
    RomanNumeral[RomanNumeral["V"] = 5] = "V";
    RomanNumeral[RomanNumeral["IX"] = 9] = "IX";
    RomanNumeral[RomanNumeral["X"] = 10] = "X";
    RomanNumeral[RomanNumeral["XL"] = 40] = "XL";
    RomanNumeral[RomanNumeral["L"] = 50] = "L";
    RomanNumeral[RomanNumeral["XC"] = 90] = "XC";
    RomanNumeral[RomanNumeral["C"] = 100] = "C";
    RomanNumeral[RomanNumeral["CD"] = 400] = "CD";
    RomanNumeral[RomanNumeral["D"] = 500] = "D";
    RomanNumeral[RomanNumeral["CM"] = 900] = "CM";
    RomanNumeral[RomanNumeral["M"] = 1000] = "M";
})(RomanNumeral || (RomanNumeral = {}));
