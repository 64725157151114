import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useMemo, memo, useCallback, cloneElement, useRef, useLayoutEffect, } from 'react';
import { createPortal } from 'react-dom';
import classnames from 'classnames';
import { v4 as uuid4 } from 'uuid';
import { useTooltip } from './hooks';
import { checkIfCellElHasEllipsis, getReplacement, getTooltipPosition } from './utils';
import { getElementPosition, getPortalContainer, logger, } from '../../utils';
import { ETooltipPlacement, ETooltipAlign, ETooltipAction, ETooltipPlacementDirection, } from './consts';
import { TooltipEvents, ETooltipEventAction } from './models';
import styles from './Tooltip.css';
function TooltipBase(props) {
    const { children, tooltipContent, tooltipTarget: tooltipTargetProp, mouseEnterDelay, mouseLeaveDelay, id = uuid4(), showArrow = true, offset = [0, 0], placement = ETooltipPlacement.TopCenter, align = ETooltipAlign.Center, action = ETooltipAction.Hover, container = getPortalContainer(), customTooltipAPI, className, containerRef, childRef, showOnlyOnEllipsis = false, } = props;
    const tooltipElementRef = useRef(null);
    const recalculationPlacements = useRef(new Set());
    const [tooltipElement, setTooltipElement] = useState(null);
    const [isVisible, setIsVisible] = useState(false);
    const [contentArgs, setContentArgs] = useState();
    const [tooltipTarget, setTooltipTarget] = useState(() => tooltipTargetProp !== null && tooltipTargetProp !== void 0 ? tooltipTargetProp : null);
    const [tooltipPlacement, setTooltipPlacement] = useState(() => placement);
    useEffect(() => {
        setTooltipPlacement(placement);
    }, [placement]);
    useEffect(() => {
        if (tooltipTargetProp) {
            setTooltipTarget(tooltipTargetProp);
        }
    }, [tooltipTargetProp]);
    const tooltipRef = useCallback((node) => {
        if (node) {
            tooltipElementRef.current = node;
            setTooltipElement(node);
        }
    }, []);
    const restorePlacementIfNeeded = useCallback(() => {
        if (placement !== tooltipPlacement) {
            setTooltipPlacement(placement);
        }
    }, [placement, tooltipPlacement]);
    const recalculatePlacementIfNeeded = useCallback(() => {
        if (!tooltipElement) {
            return;
        }
        const { top, left, right, bottom, } = getElementPosition(tooltipElement);
        /**
         * Calculates direction to change the current placement on
         *
         * Changed placement will trigger component's re-render and hence additional re-check
         * If updated placement fits visible area of the document,
         * then no more recalculations will happen
         * Otherwise, new direction will be defined and new placement will be recalculated
         *
         * The process ends when corresponding placement found or in case circular dependency occured
         */
        let updatedPlacement = tooltipPlacement;
        let replacementDirection;
        if (left < 0) {
            replacementDirection = ETooltipPlacementDirection.Right;
        }
        else if (right < 0) {
            replacementDirection = ETooltipPlacementDirection.Left;
        }
        if (top < 0 && !replacementDirection) {
            replacementDirection = ETooltipPlacementDirection.Bottom;
        }
        else if (top < 0
            && replacementDirection === ETooltipPlacementDirection.Right) {
            replacementDirection = ETooltipPlacementDirection.BottomRight;
        }
        else if (top < 0
            && replacementDirection === ETooltipPlacementDirection.Left) {
            replacementDirection = ETooltipPlacementDirection.BottomLeft;
        }
        else if (bottom < 0 && !replacementDirection) {
            replacementDirection = ETooltipPlacementDirection.Top;
        }
        else if (bottom < 0
            && replacementDirection === ETooltipPlacementDirection.Right) {
            replacementDirection = ETooltipPlacementDirection.TopRight;
        }
        else if (bottom < 0
            && replacementDirection === ETooltipPlacementDirection.Left) {
            replacementDirection = ETooltipPlacementDirection.TopLeft;
        }
        if (replacementDirection) {
            try {
                updatedPlacement = getReplacement(updatedPlacement, replacementDirection, recalculationPlacements.current);
            }
            catch (err) {
                if (err instanceof Error) {
                    logger.warn(err.message);
                }
            }
        }
        if (updatedPlacement === tooltipPlacement) {
            recalculationPlacements.current.clear();
            return;
        }
        if (recalculationPlacements.current.has(updatedPlacement)) {
            logger.warn(`Found circular recalculation for tooltip ${id}: aborted`);
            recalculationPlacements.current.clear();
            return;
        }
        recalculationPlacements.current.add(updatedPlacement);
        setTooltipPlacement(updatedPlacement);
    }, [id, tooltipPlacement, tooltipElement]);
    useLayoutEffect(() => {
        if (isVisible) {
            recalculatePlacementIfNeeded();
        }
    }, [isVisible, recalculatePlacementIfNeeded, tooltipTarget]);
    const handleTooltipAction = useCallback((event) => {
        const { detail: { action: tooltipAction, targetElement: tooltipTargetElement, contentArgs: tooltipContentArgs, }, } = event;
        switch (tooltipAction) {
            case ETooltipEventAction.Show: {
                if (tooltipContentArgs) {
                    setContentArgs(tooltipContentArgs);
                }
                let tooltipTargetHTMLElement;
                if (tooltipTargetElement) {
                    tooltipTargetHTMLElement = tooltipTargetElement;
                    setTooltipTarget(tooltipTargetHTMLElement);
                }
                restorePlacementIfNeeded();
                if (showOnlyOnEllipsis) {
                    setIsVisible(checkIfCellElHasEllipsis(childRef));
                }
                else {
                    setIsVisible(true);
                }
                break;
            }
            case ETooltipEventAction.Hide:
                restorePlacementIfNeeded();
                setIsVisible(false);
                break;
            default:
                throw new Error(`Tooltip action ('${tooltipAction}') is not supported`);
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [restorePlacementIfNeeded, childRef]);
    useEffect(() => {
        TooltipEvents.addEventListener(handleTooltipAction, id);
        return () => {
            TooltipEvents.removeEventListener(handleTooltipAction, id);
        };
    }, [container, handleTooltipAction, id]);
    const defaultTooltipAPI = useTooltip(id, mouseEnterDelay, mouseLeaveDelay);
    const { handleTooltipShow, handleTooltipHide, handleTooltipShowWithDelay, handleTooltipHideWithDelay, abortDelayedHandlers, } = customTooltipAPI !== null && customTooltipAPI !== void 0 ? customTooltipAPI : defaultTooltipAPI;
    const handleTooltipToggle = useCallback((event) => {
        if (isVisible) {
            handleTooltipHide();
        }
        else {
            handleTooltipShow(event);
        }
    }, [isVisible, handleTooltipShow, handleTooltipHide]);
    const contentToRender = useMemo(() => {
        if (tooltipContent && isVisible) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return typeof tooltipContent === 'function'
                ? tooltipContent(contentArgs)
                : tooltipContent;
        }
        return null;
    }, [isVisible, tooltipContent, contentArgs]);
    const hasTopPlacement = useMemo(() => tooltipPlacement === ETooltipPlacement.TopLeft
        || tooltipPlacement === ETooltipPlacement.TopCenter
        || tooltipPlacement === ETooltipPlacement.TopRight, [tooltipPlacement]);
    const hasBottomPlacement = useMemo(() => tooltipPlacement === ETooltipPlacement.BottomLeft
        || tooltipPlacement === ETooltipPlacement.BottomCenter
        || tooltipPlacement === ETooltipPlacement.BottomRight, [tooltipPlacement]);
    const hasLeftPlacement = useMemo(() => tooltipPlacement === ETooltipPlacement.LeftTop
        || tooltipPlacement === ETooltipPlacement.LeftCenter
        || tooltipPlacement === ETooltipPlacement.LeftBottom, [tooltipPlacement]);
    const hasRightPlacement = useMemo(() => tooltipPlacement === ETooltipPlacement.RightTop
        || tooltipPlacement === ETooltipPlacement.RightCenter
        || tooltipPlacement === ETooltipPlacement.RightBottom, [tooltipPlacement]);
    const tooltipClassName = classnames(styles.tooltip, className, {
        [styles.withArrow]: showArrow,
        [styles.bottomLeftArrow]: align === ETooltipAlign.Start && hasTopPlacement,
        [styles.bottomCenterArrow]: align === ETooltipAlign.Center && hasTopPlacement,
        [styles.bottomRightArrow]: align === ETooltipAlign.End && hasTopPlacement,
        [styles.topLeftArrow]: align === ETooltipAlign.Start && hasBottomPlacement,
        [styles.topCenterArrow]: align === ETooltipAlign.Center && hasBottomPlacement,
        [styles.topRightArrow]: align === ETooltipAlign.End && hasBottomPlacement,
        [styles.rightTopArrow]: align === ETooltipAlign.Start && hasLeftPlacement,
        [styles.rightCenterArrow]: align === ETooltipAlign.Center && hasLeftPlacement,
        [styles.rightBottomArrow]: align === ETooltipAlign.End && hasLeftPlacement,
        [styles.leftTopArrow]: align === ETooltipAlign.Start && hasRightPlacement,
        [styles.leftCenterArrow]: align === ETooltipAlign.Center && hasRightPlacement,
        [styles.leftBottomArrow]: align === ETooltipAlign.End && hasRightPlacement,
    });
    const wrappedChildren = useMemo(() => {
        if (!children) {
            return null;
        }
        const handlers = action === ETooltipAction.Hover
            ? {
                onMouseEnter: handleTooltipShowWithDelay,
                onMouseLeave: handleTooltipHideWithDelay,
            }
            : {
                onClick: handleTooltipToggle,
            };
        return cloneElement(children, handlers);
    }, [
        action,
        children,
        handleTooltipShowWithDelay,
        handleTooltipHideWithDelay,
        handleTooltipToggle,
    ]);
    useEffect(() => {
        const onDocumentClick = (event) => {
            var _a;
            const clickTarget = event.target;
            if (!((_a = tooltipElementRef.current) === null || _a === void 0 ? void 0 : _a.contains(clickTarget))) {
                setIsVisible(false);
            }
        };
        if (action === ETooltipAction.Click) {
            document.addEventListener('click', onDocumentClick, true);
        }
        // ToDo check implementation after clarifying with the project designer
        document.addEventListener('scroll', handleTooltipHide);
        return () => {
            document.removeEventListener('click', onDocumentClick, true);
            document.removeEventListener('scroll', handleTooltipHide);
        };
    }, [tooltipElementRef, action, handleTooltipHide]);
    const handleTooltipMouseEnter = useCallback(() => {
        if (action === ETooltipAction.Hover) {
            abortDelayedHandlers();
        }
    }, [action, abortDelayedHandlers]);
    const handleTooltipMouseLeave = useCallback(() => {
        if (action === ETooltipAction.Hover) {
            handleTooltipHideWithDelay();
        }
    }, [action, handleTooltipHideWithDelay]);
    useEffect(() => {
        const ref = containerRef === null || containerRef === void 0 ? void 0 : containerRef.current;
        if (ref) {
            ref.addEventListener('scroll', handleTooltipHide);
        }
        return () => ref === null || ref === void 0 ? void 0 : ref.removeEventListener('scroll', handleTooltipHide);
    }, [containerRef, handleTooltipHide]);
    const { left, top } = getTooltipPosition(tooltipTarget, tooltipElement, tooltipPlacement, align, offset);
    return (_jsxs(_Fragment, { children: [isVisible
                && contentToRender
                && createPortal(_jsx("div", Object.assign({ ref: tooltipRef, className: tooltipClassName, onMouseEnter: handleTooltipMouseEnter, onMouseLeave: handleTooltipMouseLeave, "data-testid": "infoTooltipContent", style: {
                        top: `${top}px`,
                        left: `${left}px`,
                    } }, { children: contentToRender }), void 0), container), wrappedChildren] }, void 0));
}
export const Tooltip = memo(TooltipBase);
