import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { memo, useCallback, useEffect, useMemo, useRef, } from 'react';
import classNames from 'classnames';
import styles from './SyntheticScrollX.css';
export const SyntheticScrollX = memo(function SyntheticScrollX(props) {
    const { containerWidth, contentWidth, contentRef, yAxisRef, additionalRef, labelsContainerRef, customStyled, marginRight = 0, marginLeft = 0, } = props;
    const syntheticScrollRef = useRef(null);
    const calculatedContainerWidth = (containerWidth - marginRight - marginLeft) > 0
        ? containerWidth - marginRight - marginLeft
        : 0;
    const ratio = containerWidth > 0 ? calculatedContainerWidth / containerWidth : 1;
    const calculatedContentWidth = contentWidth * ratio;
    const containerStyle = useMemo(() => ({
        width: calculatedContainerWidth,
        marginLeft,
    }), [calculatedContainerWidth, marginLeft]);
    const contentStyle = useMemo(() => ({
        width: calculatedContentWidth,
    }), [calculatedContentWidth]);
    const handleScroll = useCallback((e) => {
        var _a;
        const { scrollLeft } = e.target;
        const scrollValue = Math.round(scrollLeft / ratio);
        const isChanged = ((_a = contentRef.current) === null || _a === void 0 ? void 0 : _a.scrollLeft) !== scrollValue;
        if (contentRef.current && isChanged) {
            contentRef.current.scrollLeft = scrollValue;
        }
    }, [contentRef, ratio]);
    const onContentScroll = useCallback(() => {
        var _a;
        const syntheticBar = syntheticScrollRef.current;
        const isChanged = contentRef.current && (Math.round(((_a = contentRef.current) === null || _a === void 0 ? void 0 : _a.scrollLeft) * ratio) !== (syntheticBar === null || syntheticBar === void 0 ? void 0 : syntheticBar.scrollLeft));
        if (contentRef.current) {
            const { scrollLeft } = contentRef.current;
            contentRef.current.style.transform = '';
            if (yAxisRef.current) {
                yAxisRef.current.style.transform = '';
            }
            if (additionalRef && additionalRef.current) {
                additionalRef.current.style.left = `-${scrollLeft}`;
            }
            if (labelsContainerRef && labelsContainerRef.current) {
                labelsContainerRef.current.scrollLeft = scrollLeft;
            }
            if (isChanged && syntheticBar) {
                syntheticBar.scrollLeft = Math.round(scrollLeft * ratio);
            }
        }
    }, [contentRef, ratio, yAxisRef, additionalRef, labelsContainerRef]);
    useEffect(() => {
        const ref = contentRef.current;
        if (ref) {
            ref.addEventListener('scroll', onContentScroll);
        }
        return () => {
            ref === null || ref === void 0 ? void 0 : ref.removeEventListener('scroll', onContentScroll);
        };
    }, [contentRef, onContentScroll]);
    const className = classNames(styles.container, {
        [styles.custom]: customStyled,
    });
    return (_jsx("div", Object.assign({ className: className, style: containerStyle, onScroll: handleScroll, ref: syntheticScrollRef }, { children: _jsx("div", { className: styles.content, style: contentStyle }, void 0) }), void 0));
});
