// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Group__radioGroup__1__qQ{display:flex;flex-direction:column;font-size:.875rem}.Group__groupLabel__phYS1{font-weight:500;margin-bottom:.875rem}", "",{"version":3,"sources":["webpack://./shared/src/components/RadioButton/Group.css"],"names":[],"mappings":"AAAA,0BACE,YAAa,CACb,qBAAsB,CACtB,iBACF,CAEA,0BACE,eAAgB,CAChB,qBACF","sourcesContent":[".radioGroup {\n  display: flex;\n  flex-direction: column;\n  font-size: 0.875rem;\n}\n\n.groupLabel {\n  font-weight: 500;\n  margin-bottom: 0.875rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radioGroup": "Group__radioGroup__1__qQ",
	"groupLabel": "Group__groupLabel__phYS1"
};
export default ___CSS_LOADER_EXPORT___;
