import { logger } from '../..';
export const joinWithCommaFormatter = ({ value }) => {
    if (value === null) {
        return null;
    }
    if (typeof value === 'string') {
        return value;
    }
    if (Array.isArray(value) && value.every((item) => typeof item === 'string')) {
        return value.join(', ');
    }
    logger.warn(`Value ${Array.isArray(value) ? '' : `${value} `}for formatting is invalid.`);
    return null;
};
