// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LinePlotThresholdIcon__iconArea__2Vg4n{cursor:pointer}.LinePlotThresholdIcon__icon__22yS3{fill:var(--icon-color)}.LinePlotThresholdIcon__iconPlaceholder__1pSUZ{fill:#0000}.LinePlotThresholdIcon__iconArea__2Vg4n:hover>.LinePlotThresholdIcon__icon__22yS3{opacity:.8}", "",{"version":3,"sources":["webpack://./shared/src/plots/components/LinePlot/components/LinePlotThresholdIcon/LinePlotThresholdIcon.css"],"names":[],"mappings":"AAAA,wCACE,cACF,CAEA,oCACE,sBACF,CAEA,+CACE,UACF,CAEA,kFACE,UACF","sourcesContent":[".iconArea {\n  cursor: pointer;\n}\n\n.icon {\n  fill: var(--icon-color);\n}\n\n.iconPlaceholder {\n  fill: transparent;\n}\n\n.iconArea:hover > .icon {\n  opacity: 0.8;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconArea": "LinePlotThresholdIcon__iconArea__2Vg4n",
	"icon": "LinePlotThresholdIcon__icon__22yS3",
	"iconPlaceholder": "LinePlotThresholdIcon__iconPlaceholder__1pSUZ"
};
export default ___CSS_LOADER_EXPORT___;
