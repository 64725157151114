/**
 * Empty paddings around the plot (inside SVG element)
 */
export const PLOT_TOP_PADDING = 10;
export const PLOT_BOTTOM_PADDING = 10;
export const PLOT_RIGHT_PADDING = 25;
export const PLOT_LEFT_PADDING = 20;
/**
 * Height of the plot's header
 * This is a place where Y axis label is rendered
 */
export const PLOT_HEADER_HEIGHT = 40;
/**
 * Heights of each axis labels
 * Basically, this is a height of label containing blocks
 *
 * Position for X axis label is calculated from X axis border (containing its width)
 * Position for Y axis label is calculated from the end of plot's top padding
 */
export const X_AXIS_LABEL_HEIGHT = 16;
export const Y_AXIS_LABEL_HEIGHT = 16;
/**
 * Paddings for axis labels
 * Position is calculated from each of plot's sides / paddings
 */
export const X_AXIS_LABEL_PADDING = 10;
export const Y_AXIS_LABEL_PADDING = 10;
/**
 * Default axis border width
 */
export const DEFAULT_AXIS_BORDER_WIDTH = 4;
/**
 * Each axis border width
 */
export const X_AXIS_BORDER_WIDTH = DEFAULT_AXIS_BORDER_WIDTH;
export const Y_AXIS_BORDER_WIDTH = DEFAULT_AXIS_BORDER_WIDTH;
/**
 * Height and width of X and Y axis scale labels
 */
export const X_SCALE_LABEL_HEIGHT = 18;
export const Y_SCALE_LABEL_WIDTH = 22;
/**
 * Height and width of X and Y axis ticks (lines)
 */
export const X_SCALE_TICK_HEIGHT = 30;
export const Y_SCALE_TICK_WIDTH = 20;
/**
 * Full height and width of X and Y axis including their borders
 */
export const X_AXIS_HEIGHT = X_SCALE_LABEL_HEIGHT + X_SCALE_TICK_HEIGHT + X_AXIS_BORDER_WIDTH;
export const Y_AXIS_WIDTH = Y_SCALE_LABEL_WIDTH + Y_SCALE_TICK_WIDTH + Y_AXIS_BORDER_WIDTH;
/**
 * Height of domains border (i.e. rectangle domains are placed on)
 */
export const DOMAINS_BORDER_HEIGHT = 24;
/**
 * Paddings above and below domains border
 * Those paddings give a distance between axis and the border
 */
export const DOMAINS_BORDER_PADDING = 24;
/**
 * Full height of domains section
 */
export const DOMAINS_HEIGHT = DOMAINS_BORDER_HEIGHT + 2 * DOMAINS_BORDER_PADDING;
/**
 * Height of a single domain rectangle / block
 */
export const DOMAIN_BLOCK_HEIGHT = 40;
/**
 * Border radius of a single domain rectangle / block
 */
export const DOMAIN_BLOCK_BORDER_RADIUS = 4;
/**
 * Paddings between axis and data canvas
 * Gives additional distance between axis and scale start points
 */
export const Y_CANVAS_PADDING = 0;
export const X_CANVAS_PADDING = 40;
/**
 * Radius of a single lollipop item's point / circle
 */
export const LOLLIPOP_RADIUS = 6;
/**
 * Default height of the plot
 */
export const DEFAULT_PLOT_HEIGHT = 480;
/**
 * Default width of the plot
 */
export const DEFAULT_PLOT_WIDTH = 1280;
/**
 * Default timeout before plot's width recalculation on resize
 */
export const DEFAULT_RESIZE_TIMEOUT = 100;
/**
 * Maximum allowed number of ticks on axis
 */
export const DEFAULT_X_AXIS_MAX_TICKS = 16;
export const DEFAULT_Y_AXIS_MAX_TICKS = 10;
/**
 * Allowed tick intervals for X axis
 */
export const DEFAULT_X_AXIS_TICK_INTERVALS = [
    50,
    100,
    200,
    250,
    500,
    1000,
    2500,
    5000,
    10000,
    25000,
];
/**
 * Allowed tick intervals for Y axis
 */
export const DEFAULT_Y_AXIS_TICK_INTERVALS = [1, 2, 5, 10, 20, 50, 100, 200, 500];
/**
 * Plot's tooltip id which is used as a base
 */
export const TOOLTIP_PLOT_ID = 'LollipopPlotTooltip';
/**
 * Plot's domains tooltip id which is used as a base
 */
export const TOOLTIP_DOMAINS_ID = 'LollipopPlotDomainsTooltip';
/**
 * Font size and family for axis scale labels
 */
export const SCALE_LABEL_FONT_SIZE = 14;
export const SCALE_LABEL_FONT_FAMILY = `${SCALE_LABEL_FONT_SIZE}px Roboto`;
/**
 * Min distance between two ticks
 * Used to decide to hide or not the nearest tick to the last one on specific axis
 */
export const X_SCALE_MIN_TICK_DISTANCE = 10;
export const Y_SCALE_MIN_TICK_DISTANCE = 0;
