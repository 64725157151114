import { MIN_X_AXIS_SCALE_LINE_HEIGHT, DEFAULT_FONT_FAMILY, DEFAULT_DATA_ZONE_LEFT_PADDING, DEFAULT_DATA_ZONE_RIGHT_PADDING, } from '../../consts';
import { getTextWidth } from '../../../../utils';
import { createZone } from '../createZone/createZone';
/**
 * Calculates scale for X axis
 *
 * Scale is represented by an array of zones for each scale value
 * aggregated by object which contains both text and line zones
 *
 * @param scaleValues - Array of scale values
 * @param axisXZoneRect - Geometry rect of X axis zone
 * @param axisYZoneRect - Geometry rect of Y axis zone
 * @param plotHeight - Plot's height
 * @returns X axis scale
 */
export const calculateXAxisScale = (scaleValues, axisXZoneRect, axisYZoneRect, plotHeight, labelFormatter = (scaleValue) => `${scaleValue}`) => {
    const scaleWidth = (axisXZoneRect.width
        - DEFAULT_DATA_ZONE_LEFT_PADDING
        - DEFAULT_DATA_ZONE_RIGHT_PADDING);
    /**
     * If there are less than 2 scale values,
     * take the full allowed width as a step
     */
    const scaleValuesWidthStep = scaleValues.length > 1
        ? scaleWidth / (scaleValues.length - 1)
        : scaleWidth;
    return scaleValues.map((scaleValue, index) => {
        const positionX = (axisXZoneRect.x
            + DEFAULT_DATA_ZONE_LEFT_PADDING
            + scaleValuesWidthStep * index);
        const labelValue = labelFormatter(scaleValue);
        const labelX = positionX;
        const labelY = axisXZoneRect.y + axisXZoneRect.height;
        // Height is unused since would be calculated based on styles
        const labelHeight = axisXZoneRect.height - MIN_X_AXIS_SCALE_LINE_HEIGHT;
        const labelWidth = getTextWidth(labelValue, DEFAULT_FONT_FAMILY);
        const lineX = positionX;
        const lineY = axisYZoneRect.y;
        const lineHeight = plotHeight - lineY - labelHeight;
        const lineWidth = 1;
        const labelRect = {
            x: labelX,
            y: labelY,
            height: labelHeight,
            width: labelWidth,
        };
        const lineRect = {
            x: lineX,
            y: lineY,
            height: lineHeight,
            width: lineWidth,
        };
        return {
            line: createZone(lineRect, null),
            label: createZone(labelRect, labelValue),
        };
    });
};
