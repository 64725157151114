// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ExportButtonIcon__disabled__2pigW{align-items:center;display:flex;margin-left:.5rem}.ExportButtonIcon__disabled__2pigW svg{fill:var(--button-background-color)}.ExportButtonIcon__info__P5bet{align-items:center;display:flex;margin-left:.5rem}.ExportButtonIcon__info__P5bet svg{fill:var(--info-icon-color)}.ExportButtonIcon__info__P5bet svg:hover{fill:var(--button-background-color)}.ExportButtonIcon__infoIcon__1K9Lp{line-height:1.125rem;padding:.56rem 1rem;width:9.5rem}", "",{"version":3,"sources":["webpack://./shared/src/components/ExportButton/components/ExportButtonIcon/ExportButtonIcon.css"],"names":[],"mappings":"AAAA,mCAGE,kBAAmB,CADnB,YAAa,CADb,iBAOF,CAHE,uCACE,mCACF,CAGF,+BAGE,kBAAmB,CADnB,YAAa,CADb,iBAWF,CAPE,mCACE,2BAKF,CAHE,yCACE,mCACF,CAIJ,mCACE,oBAAqB,CACrB,mBAAqB,CACrB,YACF","sourcesContent":[".disabled {\n  margin-left: 0.5rem;\n  display: flex;\n  align-items: center;\n\n  & svg {\n    fill: var(--button-background-color);\n  }\n}\n\n.info {\n  margin-left: 0.5rem;\n  display: flex;\n  align-items: center;\n\n  & svg {\n    fill: var(--info-icon-color);\n\n    &:hover {\n      fill: var(--button-background-color);\n    }\n  }\n}\n\n.infoIcon {\n  line-height: 1.125rem;\n  padding: 0.56rem 1rem;\n  width: 9.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"disabled": "ExportButtonIcon__disabled__2pigW",
	"info": "ExportButtonIcon__info__P5bet",
	"infoIcon": "ExportButtonIcon__infoIcon__1K9Lp"
};
export default ___CSS_LOADER_EXPORT___;
