import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import { Y_AXIS_BORDER_WIDTH, PLOT_HEADER_HEIGHT, Y_AXIS_LABEL_PADDING, Y_SCALE_TICK_WIDTH, Y_AXIS_WIDTH, Y_CANVAS_PADDING, Y_AXIS_LABEL_HEIGHT, } from '../../consts';
import LollipopPlotAxis from '../LollipopPlotAxis';
import { CORRECTION_Y, CRITICAL_LABEL_LENGTH, } from './consts';
import { getXCorrection } from './utils';
import styles from './LollipopPlotAxisY.css';
import inlineStyles from './LollipopPlotAxisY.style';
const LollipopPlotAxisY = memo(function LollipopPlotAxisY(props) {
    const { data, scale, scaleLength, positionX: axisPositionX, positionY: axisPositionY, useInlineStyles, } = props;
    const linePosition = useMemo(() => {
        const x1 = axisPositionX;
        const x2 = axisPositionX;
        const y1 = axisPositionY;
        const y2 = (y1
            + scaleLength
            + Y_CANVAS_PADDING);
        return {
            x1,
            x2,
            y1,
            y2,
        };
    }, [
        axisPositionX,
        axisPositionY,
        scaleLength,
    ]);
    const lineWidth = Y_AXIS_BORDER_WIDTH;
    const scalePosition = useMemo(() => (scale && scale.map(({ position: scaleItemPosition, label: scaleItemLabel }) => {
        var _a;
        const scaleItemLabelLength = (_a = scaleItemLabel === null || scaleItemLabel === void 0 ? void 0 : scaleItemLabel.length) !== null && _a !== void 0 ? _a : 0;
        const isTextLengthCritical = scaleItemLabelLength > CRITICAL_LABEL_LENGTH;
        const baseX = (axisPositionX
            - Y_AXIS_BORDER_WIDTH / 2
            - Y_SCALE_TICK_WIDTH);
        const correctedX = baseX + (getXCorrection(scaleItemLabelLength));
        const baseY = (axisPositionY + scaleLength);
        const correctedY = baseY - (isTextLengthCritical ? CORRECTION_Y : 0);
        const scaleTextPosition = {
            x: correctedX,
            y: correctedY - scaleItemPosition,
        };
        const scaleLinePosition = {
            x1: isTextLengthCritical ? baseX : correctedX,
            x2: axisPositionX + Y_AXIS_BORDER_WIDTH / 2,
            y1: baseY - scaleItemPosition,
            y2: baseY - scaleItemPosition,
        };
        return {
            textPosition: scaleTextPosition,
            linePosition: scaleLinePosition,
            textClassName: styles.scaleText,
            textStyle: inlineStyles.scaleText,
            label: scaleItemLabel,
        };
    })), [
        scale,
        scaleLength,
        axisPositionX,
        axisPositionY,
    ]);
    const labelPosition = useMemo(() => ({
        x: (axisPositionX
            + Y_AXIS_BORDER_WIDTH / 2
            - Y_AXIS_WIDTH
            - Y_AXIS_LABEL_PADDING),
        y: (axisPositionY
            - PLOT_HEADER_HEIGHT
            + Y_AXIS_LABEL_HEIGHT),
    }), [
        axisPositionX,
        axisPositionY,
    ]);
    return (_jsx(LollipopPlotAxis, { label: data === null || data === void 0 ? void 0 : data.label, labelPosition: labelPosition, linePosition: linePosition, lineWidth: lineWidth, scalePosition: scalePosition, scaleTextAnchor: "end", useInlineStyles: useInlineStyles }, void 0));
});
export default LollipopPlotAxisY;
