import { useCallback, useMemo, useEffect, useState, useRef, } from 'react';
import { getElementPosition } from '../../../../../utils';
const useDataRegion = (props) => {
    const { scaleXStepWidth, scale, scaleYStepWidth, containerRef, zoomIndex, topPadding = 0, } = props;
    const [{ top, left }, setPosition] = useState({ top: 0, left: 0 });
    const rightBorderRef = useRef(scale.x[scale.x.length - 1].x + scaleXStepWidth / 2);
    const leftBorderRef = useRef(scale.x[0].x - scaleXStepWidth / 2);
    const bottomBorderRef = useRef(scale.y[scale.y.length - 1].y + scaleYStepWidth / 2);
    const topBorderRef = useRef(scale.y[0].y - scaleYStepWidth / 2);
    const rightBorder = zoomIndex <= 1
        ? left + scale.x[scale.x.length - 1].x + scaleXStepWidth / 2
        : rightBorderRef.current + left;
    const leftBorder = zoomIndex <= 1
        ? left + scale.x[0].x - scaleXStepWidth / 2
        : leftBorderRef.current + left;
    const bottomBorder = zoomIndex <= 1
        ? top + scale.y[scale.y.length - 1].y + scaleYStepWidth / 2
        : bottomBorderRef.current + top;
    const topBorder = zoomIndex <= 1
        ? top + scale.y[0].y - scaleYStepWidth / 2 + topPadding
        : topBorderRef.current + top + topPadding;
    useEffect(() => {
        if (containerRef.current) {
            setPosition(getElementPosition(containerRef.current));
        }
    }, [containerRef]);
    const isDataRegion = useCallback((x, y) => (x > leftBorder && x < rightBorder && y > topBorder && y < bottomBorder), [topBorder, leftBorder, rightBorder, bottomBorder]);
    return useMemo(() => ({
        isDataRegion,
        left,
        top,
    }), [isDataRegion, left, top]);
};
export default useDataRegion;
