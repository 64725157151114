// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Legend__legend__1txwL{font-size:.75rem;font-weight:500}.Legend__combinedLegendText__3jtO3{font-size:.875rem}.Legend__figure__25KwL{fill-rule:evenodd;fill:#0000004d;stroke:#000;stroke-width:1px}", "",{"version":3,"sources":["webpack://./shared/src/plots/components/ManhattanPlot/components/Legend/Legend.css"],"names":[],"mappings":"AAAA,uBACE,gBAAkB,CAClB,eACF,CAEA,mCACE,iBACF,CAEA,uBACE,iBAAkB,CAClB,cAAwB,CACxB,WAAwB,CACxB,gBACF","sourcesContent":[".legend {\n  font-size: 0.75rem;\n  font-weight: 500;\n}\n\n.combinedLegendText {\n  font-size: 0.875rem;\n}\n\n.figure {\n  fill-rule: evenodd;\n  fill: rgba(0, 0, 0, 0.3);\n  stroke: rgba(0, 0, 0, 1);\n  stroke-width: 1px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"legend": "Legend__legend__1txwL",
	"combinedLegendText": "Legend__combinedLegendText__3jtO3",
	"figure": "Legend__figure__25KwL"
};
export default ___CSS_LOADER_EXPORT___;
