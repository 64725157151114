import { useMemo, useState } from 'react';
import { getScales as defaultGetScales, } from '../../../NetworkPlot/components/NetworkPlotAxis/utils/getScales';
import { ZOOM_INITIAL_VALUE, ZOOM_STEP } from '../../../NetworkPlot/hooks/consts';
import { getZoomMinValue } from './utils';
const getZoomedStep = (props) => {
    const { step, zoomIndex } = props;
    return step < 1 ? step / zoomIndex : Math.round(step / zoomIndex);
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useZoom = ({ axis, height, width, zoomConfig, plotBottomPadding, customGetScales, }) => {
    const zoomInitialValue = (zoomConfig === null || zoomConfig === void 0 ? void 0 : zoomConfig.initialValue) || ZOOM_INITIAL_VALUE;
    const zoomStep = (zoomConfig === null || zoomConfig === void 0 ? void 0 : zoomConfig.step) || ZOOM_STEP;
    const zoomMaxValue = axis.x.step < 1 ? axis.x.step * 100 : axis.x.step;
    const zoomMinValue = useMemo(() => getZoomMinValue(axis), [axis]);
    const [zoomIndex, setZoomIndex] = useState(zoomInitialValue);
    const zoomedXStep = getZoomedStep({ step: axis.x.step, zoomIndex });
    const zoomedYStep = getZoomedStep({ step: axis.y.step, zoomIndex });
    const zoomedWidth = width * zoomIndex;
    const zoomedHeight = height * zoomIndex;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const zoomedAxis = {
        y: Object.assign(Object.assign({}, axis.y), { step: zoomedYStep }),
        x: Object.assign(Object.assign({}, axis.x), { step: zoomedXStep }),
    };
    const getScales = customGetScales !== null && customGetScales !== void 0 ? customGetScales : defaultGetScales;
    const { scale, startingPoint, sectionWidth, scaleXStepWidth, scaleYStepWidth, } = useMemo(() => getScales({
        axis: zoomedAxis,
        height: zoomedHeight,
        width: zoomedWidth,
        initialHeight: height,
        plotBottomPadding,
    }), [zoomedAxis, zoomedHeight, zoomedWidth, height, plotBottomPadding, getScales]);
    const axisWidth = useMemo(() => {
        if (zoomIndex < zoomInitialValue) {
            return width;
        }
        return zoomedWidth;
    }, [width, zoomIndex, zoomInitialValue, zoomedWidth]);
    const axisHeight = useMemo(() => {
        if (zoomIndex < zoomInitialValue) {
            return height;
        }
        return zoomedHeight;
    }, [height, zoomIndex, zoomInitialValue, zoomedHeight]);
    return useMemo(() => ({
        scale,
        startingPoint,
        sectionWidth,
        handleZoomChange: setZoomIndex,
        axisWidth,
        axisHeight,
        scaleXStepWidth,
        zoomMinValue,
        zoomMaxValue,
        zoomStep,
        zoomIndex,
        scaleYStepWidth,
    }), [
        scale,
        startingPoint,
        sectionWidth,
        setZoomIndex,
        axisWidth,
        axisHeight,
        scaleXStepWidth,
        zoomMinValue,
        zoomMaxValue,
        zoomStep,
        zoomIndex,
        scaleYStepWidth,
    ]);
};
export default useZoom;
