// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MultiSelectFieldOptions__layout__3vBfr{display:flex;list-style:none;max-height:20rem;min-width:100%;overflow-y:auto}.MultiSelectFieldOptions__description__k0UUQ{border-left:1px solid var(--placeholder-color);font-size:.875rem;margin-bottom:.5rem;min-height:100%;min-width:600px;position:sticky;top:0}.MultiSelectFieldOptions__options__2UFjK{flex-grow:2;list-style:none;margin:0;min-width:150px;padding:0}.MultiSelectFieldOptions__nestedBlock__ogji5{padding-left:2rem}", "",{"version":3,"sources":["webpack://./shared/src/components/MultiSelectField/components/MultiSelectFieldOptions/MultiSelectFieldOptions.css"],"names":[],"mappings":"AAAA,wCAGE,YAAa,CAEb,eAAgB,CAHhB,gBAAiB,CADjB,cAAe,CAGf,eAEF,CAEA,6CAKE,8CAA+C,CAE/C,iBAAmB,CADnB,mBAAqB,CAFrB,eAAgB,CADhB,eAAgB,CAFhB,eAAgB,CAChB,KAMF,CAEA,yCACE,WAAY,CAIZ,eAAgB,CADhB,QAAS,CAFT,eAAgB,CAChB,SAGF,CAEA,6CACE,iBACF","sourcesContent":[".layout {\n  min-width: 100%;\n  max-height: 20rem;\n  display: flex;\n  overflow-y: auto;\n  list-style: none;\n}\n\n.description {\n  position: sticky;\n  top: 0;\n  min-width: 600px;\n  min-height: 100%;\n  border-left: 1px solid var(--placeholder-color);\n  margin-bottom: 0.5rem;\n  font-size: 0.875rem;\n}\n\n.options {\n  flex-grow: 2;\n  min-width: 150px;\n  padding: 0;\n  margin: 0;\n  list-style: none;\n}\n\n.nestedBlock {\n  padding-left: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": "MultiSelectFieldOptions__layout__3vBfr",
	"description": "MultiSelectFieldOptions__description__k0UUQ",
	"options": "MultiSelectFieldOptions__options__2UFjK",
	"nestedBlock": "MultiSelectFieldOptions__nestedBlock__ogji5"
};
export default ___CSS_LOADER_EXPORT___;
