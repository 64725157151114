// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PlotSelectRegion__layout__2F1kR{cursor:move;height:100%;left:0;top:0;width:100%;z-index:var(--plot-layout-z-index)}.PlotSelectRegion__region__BPKc4{background-color:var(--plot-select-region-background);border:3px dashed var(--plot-select-region-border);border-radius:4px;position:absolute;will-change:left,top,width,height}", "",{"version":3,"sources":["webpack://./shared/src/plots/components/common/components/PlotSelectRegion/PlotSelectRegion.css"],"names":[],"mappings":"AAAA,iCAME,WAAY,CAFZ,WAAY,CAFZ,MAAO,CADP,KAAM,CAEN,UAAW,CAEX,kCAEF,CAEA,iCAIE,qDAAsD,CAFtD,kDAAmD,CACnD,iBAAkB,CAFlB,iBAAkB,CAIlB,iCACF","sourcesContent":[".layout {\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  z-index: var(--plot-layout-z-index);\n  cursor: move;\n}\n\n.region {\n  position: absolute;\n  border: 3px dashed var(--plot-select-region-border);\n  border-radius: 4px;\n  background-color: var(--plot-select-region-background);\n  will-change: left, top, width, height;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": "PlotSelectRegion__layout__2F1kR",
	"region": "PlotSelectRegion__region__BPKc4"
};
export default ___CSS_LOADER_EXPORT___;
