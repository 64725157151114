import { filledArray } from '../../../../../utils';
import { getTextWidth } from '../../../../utils';
import { SCALE_LABEL_FONT_FAMILY, SCALE_LABEL_FONT_SIZE, X_SCALE_MIN_TICK_DISTANCE, Y_SCALE_MIN_TICK_DISTANCE, } from '../../consts';
/**
 * Calculates position of scale ticks (within the scale's length)
 *
 * @param tickInterval - Tick interval used between each pair of ticks
 * @param scaleLength - Length of the scale
 * @param maxTickValue - Max tick value
 * @param [isVertical] - If the scale refers to vertial axis or not
 * @returns Scale tick values and their position along the scale
 */
export default function getScale(tickInterval, scaleLength, maxTickValue, isVertical) {
    if (maxTickValue === 0) {
        return [];
    }
    const ticksCount = Math.ceil(maxTickValue / tickInterval);
    const delta = (ticksCount * tickInterval) / maxTickValue;
    const scaleInterval = (scaleLength * delta) / ticksCount;
    const ticks = filledArray(ticksCount, (tickIndex) => {
        const position = tickIndex * scaleInterval;
        const value = tickIndex * tickInterval;
        const label = `${value}`;
        return {
            label,
            position,
        };
    });
    const minDistance = isVertical ? Y_SCALE_MIN_TICK_DISTANCE : X_SCALE_MIN_TICK_DISTANCE;
    const borderTickLabel = isVertical ? `${maxTickValue}` : `${maxTickValue}`;
    if (delta > 1) {
        const lastTickWidth = isVertical
            ? SCALE_LABEL_FONT_SIZE
            : getTextWidth(ticks[ticks.length - 1].label, SCALE_LABEL_FONT_FAMILY);
        const borderTickWidth = isVertical
            ? SCALE_LABEL_FONT_SIZE
            : getTextWidth(borderTickLabel, SCALE_LABEL_FONT_FAMILY) * 2;
        const currentWidth = (lastTickWidth + borderTickWidth) / 2 + minDistance;
        const allowedWidth = scaleInterval - scaleLength * (delta - 1);
        if (currentWidth > allowedWidth) {
            ticks.pop();
        }
    }
    ticks.push({
        label: borderTickLabel,
        position: scaleLength,
    });
    return ticks;
}
