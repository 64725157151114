import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import LollipopPlotDomain from '../LollipopPlotDomain';
const LollipopPlotDomainGroup = memo(function LollipopPlotDomainGroup(props) {
    const { data, handleTooltipShow, handleTooltipHide, positionX, positionY, getPositionOnAxisX, index, useInlineStyles, } = props;
    const domains = useMemo(() => data.intervals.map(({ codonStart, codonEnd }) => (_jsx(LollipopPlotDomain, { index: index, name: data.groupName, label: data.groupLabel, codonStart: codonStart, codonEnd: codonEnd, handleTooltipShow: handleTooltipShow, handleTooltipHide: handleTooltipHide, positionX: positionX, positionY: positionY, getPositionOnAxisX: getPositionOnAxisX, useInlineStyles: useInlineStyles }, `${data.groupId}_${codonStart}:${codonEnd}`))), [
        data.intervals,
        data.groupId,
        data.groupName,
        data.groupLabel,
        index,
        handleTooltipShow,
        handleTooltipHide,
        positionX,
        positionY,
        getPositionOnAxisX,
        useInlineStyles,
    ]);
    return _jsx(_Fragment, { children: domains }, void 0);
});
export default LollipopPlotDomainGroup;
