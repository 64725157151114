// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LollipopPlotAxisLine__border__2CnEQ{stroke:var(--border-color);stroke-width:4px}", "",{"version":3,"sources":["webpack://./shared/src/plots/components/LollipopPlot/components/LollipopPlotAxisLine/LollipopPlotAxisLine.css"],"names":[],"mappings":"AAAA,qCACE,0BAA2B,CAC3B,gBACF","sourcesContent":[".border {\n  stroke: var(--border-color);\n  stroke-width: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"border": "LollipopPlotAxisLine__border__2CnEQ"
};
export default ___CSS_LOADER_EXPORT___;
