import { romanToArabic } from '../romanToArabic';
import { isValidRomanNumeral } from '../isValidRomanNumeral';
const CHAPTER = 'Chapter';
export const categorySort = (rowA, rowB, columnId) => {
    const labelA = rowA.values[columnId] || '';
    const labelB = rowB.values[columnId] || '';
    const labelASplit = labelA.split(' ');
    const labelBSplit = labelB.split(' ');
    if (labelASplit[0] === CHAPTER) {
        const romanCharA = romanToArabic(labelASplit[1] || '');
        const valueToCheck = labelBSplit[0];
        if (valueToCheck === CHAPTER) {
            const romanCharB = romanToArabic(labelBSplit[1] || '');
            if (romanCharA !== romanCharB)
                return romanCharA - romanCharB;
        }
        if (isValidRomanNumeral(valueToCheck)) {
            const romanCharB = romanToArabic(valueToCheck || '');
            if (romanCharA !== romanCharB)
                return romanCharA - romanCharB;
        }
        return -1;
    }
    if (labelBSplit[0] === CHAPTER) {
        const romanCharB = romanToArabic(labelBSplit[1] || '');
        const valueToCheck = labelASplit[0];
        if (valueToCheck === CHAPTER) {
            const romanCharA = romanToArabic(labelASplit[1] || '');
            if (romanCharA !== romanCharB)
                return romanCharA - romanCharB;
        }
        if (isValidRomanNumeral(valueToCheck)) {
            const romanCharA = romanToArabic(valueToCheck || '');
            if (romanCharA !== romanCharB)
                return romanCharA - romanCharB;
        }
        return 1;
    }
    if (isValidRomanNumeral(labelASplit[0])) {
        const romanCharA = romanToArabic(labelASplit[0] || '');
        const valueToCheck = labelBSplit[0];
        if (valueToCheck === CHAPTER) {
            const romanCharB = romanToArabic(labelBSplit[1] || '');
            if (romanCharA !== romanCharB)
                return romanCharA - romanCharB;
        }
        if (isValidRomanNumeral(valueToCheck)) {
            const romanCharB = romanToArabic(valueToCheck || '');
            if (romanCharA !== romanCharB)
                return romanCharA - romanCharB;
        }
        return -1;
    }
    if (isValidRomanNumeral(labelBSplit[0])) {
        const romanCharB = romanToArabic(labelBSplit[0] || '');
        const valueToCheck = labelASplit[0];
        if (valueToCheck === CHAPTER) {
            const romanCharA = romanToArabic(labelASplit[1] || '');
            if (romanCharA !== romanCharB)
                return romanCharA - romanCharB;
        }
        if (isValidRomanNumeral(valueToCheck)) {
            const romanCharA = romanToArabic(valueToCheck || '');
            if (romanCharA !== romanCharB)
                return romanCharA - romanCharB;
        }
        return 1;
    }
    return labelA.localeCompare(labelB);
};
