// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ExportSettingsControl__activeArrow__3cR53{transform:rotate(180deg)}.ExportSettingsControl__button__2hXbe{border:none;height:100%;padding-left:.625rem;width:7.125rem}.ExportSettingsControl__icon__2gqRD{fill:var(--primary-contrast-color);outline:none}.ExportSettingsControl__icon__2gqRD:last-child{margin-left:2.25rem}.ExportSettingsControl__icon__2gqRD svg{fill:var(--primary-contrast-color)}.ExportSettingsControl__infoTooltip__2Fdxo{height:100%;width:7.125rem}.ExportSettingsControl__spinner__1Qumj>*{fill:var(--primary-contrast-color)}", "",{"version":3,"sources":["webpack://./shared/src/components/ExportSettingsControl/ExportSettingsControl.css"],"names":[],"mappings":"AAAA,2CACE,wBACF,CAEA,sCAGE,WAAY,CAFZ,WAAY,CAGZ,oBAAsB,CAFtB,cAGF,CAEA,oCAEE,kCAAmC,CADnC,YAUF,CAPE,+CACE,mBACF,CAEA,wCACE,kCACF,CAGF,2CACE,WAAY,CACZ,cACF,CAEA,yCACE,kCACF","sourcesContent":[".activeArrow {\n  transform: rotate(180deg);\n}\n\n.button {\n  height: 100%;\n  width: 7.125rem;\n  border: none;\n  padding-left: 0.625rem;\n}\n\n.icon {\n  outline: none;\n  fill: var(--primary-contrast-color);\n\n  &:last-child {\n    margin-left: 2.25rem;\n  }\n\n  & svg {\n    fill: var(--primary-contrast-color);\n  }\n}\n\n.infoTooltip {\n  height: 100%;\n  width: 7.125rem;\n}\n\n.spinner > * {\n  fill: var(--primary-contrast-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"activeArrow": "ExportSettingsControl__activeArrow__3cR53",
	"button": "ExportSettingsControl__button__2hXbe",
	"icon": "ExportSettingsControl__icon__2gqRD",
	"infoTooltip": "ExportSettingsControl__infoTooltip__2Fdxo",
	"spinner": "ExportSettingsControl__spinner__1Qumj"
};
export default ___CSS_LOADER_EXPORT___;
