// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LollipopPlotAxisScale__text__2LHiU{font-size:.875rem}.LollipopPlotAxisScale__line__U8ojJ{stroke:var(--border-color);stroke-width:1px}", "",{"version":3,"sources":["webpack://./shared/src/plots/components/LollipopPlot/components/LollipopPlotAxisScale/LollipopPlotAxisScale.css"],"names":[],"mappings":"AAAA,oCACE,iBACF,CAEA,oCACE,0BAA2B,CAC3B,gBACF","sourcesContent":[".text {\n  font-size: 0.875rem;\n}\n\n.line {\n  stroke: var(--border-color);\n  stroke-width: 1px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "LollipopPlotAxisScale__text__2LHiU",
	"line": "LollipopPlotAxisScale__line__U8ojJ"
};
export default ___CSS_LOADER_EXPORT___;
