import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import classnames from 'classnames';
import { EImpactLevel } from '../../types';
import InfoIcon from '../../../assets/icons/infoFilled.svg';
// eslint-disable-next-line import/no-named-as-default
import inlineStyles from './ImpactItem.style';
const ImpactItem = (props) => {
    const { id, impactLevel, 
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    icon: Icon = InfoIcon, children, leftPadding = 0, } = props;
    const iconStyle = classnames({
        spliceIcon: impactLevel === undefined,
        highImpactIcon: impactLevel === EImpactLevel.High,
        moderateImpactIcon: impactLevel === EImpactLevel.Moderate,
        lowImpactIcon: impactLevel === EImpactLevel.Low,
    });
    return (_jsxs("g", Object.assign({ transform: `translate(${leftPadding}, 0) scale(0.9)`, style: { transform: `translate(${leftPadding}px, 0) scale(0.9)` } }, { children: [_jsx(Icon, { width: 18, height: 18, style: inlineStyles[iconStyle] }, void 0), _jsx("text", Object.assign({ x: 0, y: 0, transform: "translate(0 -3)" }, { children: _jsx("tspan", Object.assign({ x: 22, dy: 16 }, { children: children }), id) }), void 0)] }), void 0));
};
export default memo(ImpactItem);
