// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ImpactDescriptions__wrapper__3JQxa{font-size:.6rem;left:inherit;position:absolute;top:0;transform:translate(12rem,2rem)}", "",{"version":3,"sources":["webpack://./shared/src/components/ImpactDescriptions/ImpactDescriptions.css"],"names":[],"mappings":"AAAA,oCAKE,eAAiB,CAFjB,YAAa,CAFb,iBAAkB,CAClB,KAAM,CAEN,+BAEF","sourcesContent":[".wrapper {\n  position: absolute;\n  top: 0;\n  left: inherit;\n  transform: translate(12rem, 2rem);\n  font-size: 0.6rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "ImpactDescriptions__wrapper__3JQxa"
};
export default ___CSS_LOADER_EXPORT___;
