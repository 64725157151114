import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback, useMemo, } from 'react';
import { Tooltip } from '../../../../../components/Tooltip';
import { TOP_LABEL_HEIGHT, DEFAULT_GROUP_LABEL_FONT_SIZE, DEFAULT_GROUP_LABEL_ANGLE_DEGREES, } from '../../consts';
import { useScrollContext } from '../../hooks';
import styles from './ManhattanPlotGroupBorder.css';
import inlineStyles from './ManhattanPlotGroupBorder.style';
const ManhattanPlotGroupBorder = (props) => {
    const { index = 0, height, groupLabel, groupTooltipLabel = groupLabel, widthOffset, useInlineStyles, heightOffset = 0, groupLabelFontSize = DEFAULT_GROUP_LABEL_FONT_SIZE, } = props;
    const isIntersected = useScrollContext(index);
    const fullHeight = height + heightOffset;
    const tooltipContent = useCallback(() => (_jsx("div", Object.assign({ className: styles.tooltipContent }, { children: groupTooltipLabel }), void 0)), [groupTooltipLabel]);
    const tooltipOffset = useMemo(() => [10, 0], []);
    const textElement = (_jsx("text", Object.assign({ className: styles.groupLabelText, transform: "translate(-20.8 -17.6)", textAnchor: "end", fontSize: groupLabelFontSize }, useInlineStyles && {
        style: inlineStyles.groupLabelText,
    }, { x: widthOffset, y: fullHeight }, { children: groupLabel }), void 0));
    return (_jsxs("g", Object.assign({ style: { visibility: isIntersected ? 'hidden' : 'visible' }, "data-testid": "plotGroup" }, { children: [_jsx("line", Object.assign({ className: styles.groupLine }, useInlineStyles && {
                style: inlineStyles.groupLine,
            }, { x1: widthOffset, y1: TOP_LABEL_HEIGHT, x2: widthOffset, y2: fullHeight }), void 0), _jsx("g", Object.assign({ style: { pointerEvents: 'visiblePainted' }, transform: `translate(${widthOffset} ${fullHeight}) rotate(-${DEFAULT_GROUP_LABEL_ANGLE_DEGREES}) translate(${-widthOffset} ${-fullHeight})` }, { children: 
                /* Since tooltips are useless for exported versions */
                useInlineStyles
                    ? textElement
                    : (_jsx(Tooltip, Object.assign({ tooltipContent: tooltipContent, offset: tooltipOffset }, { children: textElement }), void 0)) }), void 0)] }), void 0));
};
export default memo(ManhattanPlotGroupBorder);
