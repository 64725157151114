import { DEFAULT_PLOT_TOP_PADDING, PLOT_LEFT_PADDING, } from '../../../../consts';
import getScaleLabels from '../getScaleLabels';
import getScaleSectionWidth from '../getScaleSectionWidth';
export const getScales = ({ axis: { x: axisX, y: axisY }, height, width, initialHeight, plotBottomPadding, }) => {
    const scaleYLabels = getScaleLabels(axisY);
    const scaleXLabels = getScaleLabels(axisX);
    const scaleYWidth = height - DEFAULT_PLOT_TOP_PADDING - plotBottomPadding;
    const scaleXWidth = width - PLOT_LEFT_PADDING;
    const scaleYStepWidth = scaleYWidth / scaleYLabels.length;
    const scaleXStepWidth = scaleXWidth / scaleXLabels.length;
    let plotHeight = height;
    let scaleTopPadding = 0;
    if (initialHeight && initialHeight > height) {
        plotHeight = initialHeight;
        scaleTopPadding = initialHeight - height;
    }
    const scaleY = scaleYLabels.reverse().map((label, index) => ({
        label,
        x: 0,
        y: scaleYStepWidth * index
            + DEFAULT_PLOT_TOP_PADDING
            + scaleYStepWidth / 2
            + scaleTopPadding,
    }));
    const scaleX = scaleXLabels.map((label, index) => ({
        label,
        x: scaleXStepWidth * index + PLOT_LEFT_PADDING + scaleXStepWidth / 2,
        y: plotHeight,
    }));
    const sectionWidth = {
        x: getScaleSectionWidth({
            scaleWidth: scaleXWidth,
            max: axisX.max,
            min: axisX.min,
            step: axisX.step,
        }),
        y: getScaleSectionWidth({
            scaleWidth: scaleYWidth,
            max: axisY.max,
            min: axisY.min,
            step: axisY.step,
        }),
    };
    return {
        scale: {
            x: scaleX,
            y: scaleY,
        },
        startingPoint: {
            x: PLOT_LEFT_PADDING + scaleXStepWidth / 2,
            y: DEFAULT_PLOT_TOP_PADDING + scaleYStepWidth / 2 + scaleTopPadding,
        },
        sectionWidth,
        scaleXStepWidth,
        scaleYStepWidth,
    };
};
