import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import styles from './LollipopPlotAxisLabel.css';
import inlineStyles from './LollipopPlotAxisLabel.style';
const LollipopPlotAxisLabel = memo(function LollipopPlotAxisLabel(props) {
    const { x, y, label, textAnchor = 'start', useInlineStyles, } = props;
    const labelStyle = useInlineStyles
        ? inlineStyles.label
        : undefined;
    return (_jsx("text", Object.assign({ className: styles.label, style: labelStyle, textAnchor: textAnchor, x: x, y: y }, { children: label }), void 0));
});
export default LollipopPlotAxisLabel;
