import { jsx as _jsx } from "react/jsx-runtime";
import { memo, Fragment } from 'react';
import { LEFT_AXIS_SCALE_WIDTH } from '../../consts';
import { ManhattanScaleType } from '../../types';
import ManhattanPlotLayer from '../ManhattanPlotLayer/ManhattanPlotLayer';
import styles from './ManhattanPlotAxisScaleLines.css';
import { inlineStyles } from './ManhattanPlotAxisScaleLines.style';
const ManhattanPlotAxisScaleLines = (props) => {
    const { scale, width, useInlineStyles = false, } = props;
    return (_jsx(ManhattanPlotLayer, { children: scale
            .filter(({ type }) => type === ManhattanScaleType.Regular)
            .map(({ value, y, }) => (_jsx(Fragment, { children: _jsx("line", Object.assign({ className: styles.axisScaleLine }, useInlineStyles && {
                style: inlineStyles.axisScaleLine,
            }, { x1: LEFT_AXIS_SCALE_WIDTH, y1: y, x2: width, y2: y }), void 0) }, `scaleLine_${value}`))) }, void 0));
};
export default memo(ManhattanPlotAxisScaleLines);
