import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { memo } from 'react';
import { OpacityOrganizer } from './components/OpacityOrganizer/OpacityOrganizer';
import { Container } from './components/Container/Container';
import { LevelResultsOrganizer } from './components/LevelResultsOrganizer/LevelResultsOrganizer';
import { TraitsOrganizer } from './components/TraitsOrganizer/TraitsOrganizer';
import styles from './PlotSettings.css';
export const PlotSettings = memo(function TableSettings({ datasets, levelResultsCollection, traitsTypesCollection, datasetNameMapper, levelResultMapper, traitsTypeMapper, onOpacityChange, onLevelResultChange, onTraitsTypesChange, }) {
    return (_jsxs("div", Object.assign({ className: styles.wrapper }, { children: [_jsx(Container, Object.assign({ label: "Dataset opacity" }, { children: _jsx(OpacityOrganizer, { datasets: datasets, datasetNameMapper: datasetNameMapper, onOpacityChange: onOpacityChange }, void 0) }), void 0), Object.keys(levelResultsCollection).length > 1 && (_jsx(Container, Object.assign({ label: "Displaying level results", withLeftPadding: true }, { children: _jsx(LevelResultsOrganizer, { levelResultMapper: levelResultMapper, levelResultsCollection: levelResultsCollection, onLevelResultChange: onLevelResultChange }, void 0) }), void 0)), _jsx(Container, Object.assign({ label: "Displaying traits", withLeftPadding: true }, { children: _jsx(TraitsOrganizer, { traitsTypesCollection: traitsTypesCollection, traitsTypeMapper: traitsTypeMapper, onTraitsTypesChange: onTraitsTypesChange }, void 0) }), void 0)] }), void 0));
});
