import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import classnames from 'classnames';
import { MODELS_WITH_BOLD_DESCRIPTION } from './consts';
import { DefinitionModelList } from '../DefinitionModelList';
import styles from './DefinitionModel.css';
export const DefinitionModel = memo(function DefinitionModel({ description, list, className, modelName, }) {
    const boldDescriptionClassName = classnames(className, {
        [styles.bold]: modelName && MODELS_WITH_BOLD_DESCRIPTION.includes(modelName),
    });
    return (_jsxs("div", Object.assign({ className: boldDescriptionClassName }, { children: [description && _jsx("p", Object.assign({ className: styles.description }, { children: description }), void 0), list && _jsx(DefinitionModelList, { list: list }, void 0)] }), void 0));
});
