import { useCallback, useEffect, useState } from 'react';
export const useIntersection = (props) => {
    const { target, root, rootMargin, threshold, } = props;
    const [observer, setObserver] = useState(null);
    const [intersection, setIntersection] = useState(null);
    const handleIntersection = useCallback(([entry]) => {
        setIntersection(entry);
    }, []);
    useEffect(() => {
        setObserver(new IntersectionObserver(handleIntersection, {
            root,
            rootMargin,
            threshold,
        }));
    }, [handleIntersection, root, rootMargin, threshold]);
    useEffect(() => {
        if (observer && target) {
            observer.observe(target);
        }
        return () => {
            if (observer && target) {
                observer.unobserve(target);
            }
        };
    }, [observer, target]);
    return intersection;
};
