import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import classNames from 'classnames';
import styles from './Button.css';
function RadioButton(props) {
    const { onChange, id, label, value, isActive, isDisabled, name, } = props;
    const handleClick = () => {
        if (!isActive) {
            onChange(value);
        }
    };
    const labelClassName = classNames(styles.label, {
        [styles.disabled]: isDisabled,
    });
    return (_jsxs("div", Object.assign({ className: styles.radioButtonContainer }, { children: [_jsx("input", { id: id, type: "radio", className: styles.radioButton, onChange: handleClick, disabled: isDisabled, checked: isActive, name: name }, void 0), _jsx("label", Object.assign({ className: labelClassName, htmlFor: id }, { children: label }), void 0)] }), void 0));
}
export default memo(RadioButton);
