import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import classnames from 'classnames';
import CollapsibleSection from '../../../components/CollapsibleSection';
import LegendList from './components/LegendList/LegendList';
import { ESectionType } from '../../../components/Section';
import stylesCSS from './Legend.css';
const Legend = (props) => {
    const { data, className, maxColumns, title = 'Legend', styles, isCollapsedByDefault = true, } = props;
    const legendClassName = classnames(stylesCSS.sectionWrapper, className);
    return (_jsx(CollapsibleSection, Object.assign({ isCollapsedByDefault: isCollapsedByDefault, label: title, className: legendClassName, childrenContainerClassName: stylesCSS.itemList, headerContainerClassName: stylesCSS.sectionHeaderContainer, headerClassName: stylesCSS.headerContent, titleClassName: stylesCSS.legendTitle, typeSection: ESectionType.Minimized, iconClassName: stylesCSS.icon }, { children: _jsx(LegendList, { data: data, maxColumns: maxColumns, styles: styles }, void 0) }), void 0));
};
export default memo(Legend);
