import { useCallback, useMemo, useRef, } from 'react';
import { DEFAULT_HIDE_DELAY, DEFAULT_SHOW_DELAY } from '../../consts';
import { TooltipEvents, ETooltipEventAction } from '../../models';
/**
 * Hooks provides manual API for controlling isolated tooltips
 *
 * @param [tooltipId] - Tooltip id to provide API for
 * @param [showDelay = 100] - Delay between action and tooltip showing
 * @param [hideDelay = 500] - Delay between action and tooltip hiding
 * @returns Tooltip control API
 */
export function useTooltip(tooltipId, showDelay = DEFAULT_SHOW_DELAY, hideDelay = DEFAULT_HIDE_DELAY) {
    const timerRef = useRef(null);
    const handleTooltipShow = useCallback((event, contentArgs) => {
        const { target } = event;
        const targetElement = target;
        TooltipEvents.dispatchEvent(ETooltipEventAction.Show, tooltipId, targetElement, contentArgs);
    }, [tooltipId]);
    const handleTooltipHide = useCallback(() => {
        TooltipEvents.dispatchEvent(ETooltipEventAction.Hide, tooltipId);
    }, [tooltipId]);
    const handleTooltipShowWithDelay = useCallback((event, contentArgs) => {
        if (timerRef.current) {
            window.clearTimeout(timerRef.current);
        }
        timerRef.current = window.setTimeout(() => {
            timerRef.current = null;
            handleTooltipShow(event, contentArgs);
        }, showDelay);
    }, [
        handleTooltipShow,
        showDelay,
    ]);
    const handleTooltipHideWithDelay = useCallback(() => {
        if (timerRef.current) {
            window.clearTimeout(timerRef.current);
        }
        timerRef.current = window.setTimeout(() => {
            timerRef.current = null;
            handleTooltipHide();
        }, hideDelay);
    }, [
        handleTooltipHide,
        hideDelay,
    ]);
    const abortDelayedHandlers = useCallback(() => {
        if (timerRef.current) {
            window.clearTimeout(timerRef.current);
            timerRef.current = null;
        }
    }, []);
    return useMemo(() => ({
        handleTooltipHide,
        handleTooltipShow,
        handleTooltipShowWithDelay,
        handleTooltipHideWithDelay,
        abortDelayedHandlers,
    }), [
        handleTooltipHide,
        handleTooltipShow,
        handleTooltipShowWithDelay,
        handleTooltipHideWithDelay,
        abortDelayedHandlers,
    ]);
}
