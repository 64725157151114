export const getRadioOptions = (props) => {
    const { radioOptions, isFiltersNotChanged, isSettingsDisabled } = props;
    if (isSettingsDisabled) {
        return [
            Object.assign(Object.assign({}, radioOptions[0]), { isDisabled: true }),
            Object.assign(Object.assign({}, radioOptions[1]), { isDisabled: true }),
        ];
    }
    if (isFiltersNotChanged) {
        return [radioOptions[0], Object.assign(Object.assign({}, radioOptions[1]), { isDisabled: true })];
    }
    return radioOptions;
};
