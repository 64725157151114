export const inlineStyles = {
    thresholdScaleLine: {
        stroke: '#335f83',
        strokeWidth: '2px',
        strokeDasharray: '6px 8px',
        strokeDashoffset: '-4px',
        userSelect: 'none',
        pointerEvents: 'none',
    },
    thresholdAxisScaleLabel: {
        fontSize: '10px',
        transform: 'translate(8px, -10px)',
        fill: 'black',
        userSelect: 'none',
        pointerEvents: 'none',
        textTransform: 'uppercase',
    },
};
