// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ButtonLink__outlined__3uPZp{border:.125rem solid var(--link-color);border-radius:.25rem;padding:.375rem}.ButtonLink__outlined__3uPZp svg{margin-left:.375rem}.ButtonLink__layout__FWyAj{align-items:center;color:var(--link-color);display:flex;font-size:.875rem;font-weight:700;line-height:1.25rem;max-width:fit-content;text-decoration:none}.ButtonLink__layout__FWyAj svg{fill:var(--link-color)}.ButtonLink__layout__FWyAj:hover{border-color:var(--link-hover-color);color:var(--link-hover-color)}.ButtonLink__layout__FWyAj:hover svg{fill:var(--link-hover-color)}", "",{"version":3,"sources":["webpack://./shared/src/components/ButtonLink/ButtonLink.css"],"names":[],"mappings":"AAAA,6BAEE,sCAAwC,CADxC,oBAAsB,CAEtB,eAKF,CAHE,iCACE,mBACF,CAGF,2BAOE,kBAAmB,CANnB,uBAAwB,CAKxB,YAAa,CAHb,iBAAmB,CAEnB,eAAgB,CADhB,mBAAoB,CAIpB,qBAAsB,CANtB,oBAoBF,CAZE,+BACE,sBACF,CAEA,iCAEE,oCAAqC,CADrC,6BAMF,CAHE,qCACE,4BACF","sourcesContent":[".outlined {\n  border-radius: 0.25rem;\n  border: 0.125rem solid var(--link-color);\n  padding: 0.375rem;\n\n  & svg {\n    margin-left: 0.375rem;\n  }\n}\n\n.layout {\n  color: var(--link-color);\n  text-decoration: none;\n  font-size: 0.875rem;\n  line-height: 1.25rem;\n  font-weight: 700;\n  display: flex;\n  align-items: center;\n  max-width: fit-content;\n\n  & svg {\n    fill: var(--link-color);\n  }\n\n  &:hover {\n    color: var(--link-hover-color);\n    border-color: var(--link-hover-color);\n\n    & svg {\n      fill: var(--link-hover-color);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"outlined": "ButtonLink__outlined__3uPZp",
	"layout": "ButtonLink__layout__FWyAj"
};
export default ___CSS_LOADER_EXPORT___;
