import { range } from '../range/range';
/**
 * Returns array filled with specific values
 *
 * @template [T = number]
 *
 * @param size - Size of array
 * @param fillValue - Either value or value factory
 * @returns Array filled with specific values
 */
export function filledArray(size, fillValue) {
    if (size < 0) {
        throw new Error(`Size of array cannot be a negative number (${size})`);
    }
    if (size === 0) {
        return [];
    }
    return range(size)
        .map((_, index) => (fillValue instanceof Function ? fillValue(index) : fillValue));
}
