import { DEFAULT_Y_AXIS_WIDTH, DEFAULT_DATA_POINT_RADIUS, DEFAULT_DATA_ZONE_TOP_PADDING, DEFAULT_AXIS_LABEL_FONT_SIZE, } from '../../consts';
/**
 * Calculates geometry rect for Y axis zone
 *
 * @param plotHeight - Plot's height
 * @param XAxisZoneRect - Geometry rect of X axis zone
 * @param YAxisLabelRect - Geometry rect of Y axis label
 * @param [axisWidth]  - Width of the Y axis zone (if pre-calculated)
 * @returns Geometry rect
 */
export const calculateYAxisZoneRect = (plotHeight, XAxisZoneRect, YAxisLabelRect, axisWidth = DEFAULT_Y_AXIS_WIDTH) => {
    var _a;
    /**
     * There could be a case when default data zone right padding is not enough for scale
     * to contain either max scale value text or data point on the plot's right border
     *
     * To allocate enough space, additional width which might become necessary is calculated
     * based on maximum between a data point radius or width of the max scale value
     *
     * Note: due to inaccurate SVG positioning the full width of scale value is considered
     * instead of its half (as it should be considering middle `textAnchor`)
     */
    const maxPossibleHightOffset = Math.max(DEFAULT_AXIS_LABEL_FONT_SIZE, DEFAULT_DATA_POINT_RADIUS);
    let additionalHeightOffset = 0;
    if (maxPossibleHightOffset > DEFAULT_DATA_ZONE_TOP_PADDING) {
        additionalHeightOffset = maxPossibleHightOffset - DEFAULT_DATA_ZONE_TOP_PADDING;
    }
    /**
     * In case if label exists, consider recalculating possible offset
     */
    if (additionalHeightOffset > 0 && (YAxisLabelRect === null || YAxisLabelRect === void 0 ? void 0 : YAxisLabelRect.height)) {
        additionalHeightOffset = Math.max(0, additionalHeightOffset - YAxisLabelRect.height);
    }
    const x = 0;
    const y = ((_a = YAxisLabelRect === null || YAxisLabelRect === void 0 ? void 0 : YAxisLabelRect.height) !== null && _a !== void 0 ? _a : 0) + additionalHeightOffset;
    const height = plotHeight - y - XAxisZoneRect.height;
    const width = axisWidth;
    return {
        x,
        y,
        height,
        width,
    };
};
