import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo, useCallback, memo, useEffect, useRef, } from 'react';
import { useTable, useSortBy, usePagination, } from 'react-table';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DEFAULT_PAGE_SIZES } from './consts';
import { usePaginationConfig, useRowHover, useVerticalScrollIndicator, useHeaderWidthArray, } from './hooks';
import TableView from './components/TableView';
import { TableLayout } from './components/TableLayout';
import { TablePagination } from './components/TablePagination';
export const Table = memo(function Table(props) {
    const { columns, data, sortBy, pageSize, hasPagination = true, hasSorting = true, hasConfig = false, pageSizes = DEFAULT_PAGE_SIZES, onPageSizeChange, maxPageLimit, paginationOptionClassName, paginationButtonClassName, paginationContainerClassName, hasStickyHeader, onStickyChange, scrollThreshold = 0, withColumnNumbers, visibleColumns = columns, onConfigChange, onConfigUnHide, onConfigReset, } = props;
    const initialState = useMemo(() => {
        const initialStateHolder = {
            pageIndex: 0,
        };
        initialStateHolder.pageSize = data.length;
        if (hasPagination && pageSize) {
            initialStateHolder.pageSize = pageSize;
        }
        else if (hasPagination && pageSizes.length > 0) {
            [initialStateHolder.pageSize] = pageSizes;
        }
        if (sortBy) {
            initialStateHolder.sortBy = sortBy;
        }
        return initialStateHolder;
    }, [pageSize, pageSizes, hasPagination, data, sortBy]);
    const getRowId = useCallback((row, index) => {
        const rowData = row;
        const { id } = rowData;
        return id || `${index}`;
    }, []);
    const tableInstance = useTable({
        columns: visibleColumns,
        data,
        initialState: Object.assign({}, initialState),
        disableSortBy: !hasSorting,
        disableMultiSort: true,
        getRowId,
    }, useSortBy, usePagination);
    const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page, } = tableInstance;
    const rowHoverProps = useRowHover();
    const paginationConfig = usePaginationConfig(tableInstance);
    const tableHeaderRef = useRef(null);
    const { scrollTargetRef, stickyIndicator } = useVerticalScrollIndicator(scrollThreshold);
    const headerWidthArray = useHeaderWidthArray(tableHeaderRef, page);
    useEffect(() => {
        if (onStickyChange) {
            onStickyChange(stickyIndicator);
        }
    }, [onStickyChange, stickyIndicator]);
    return (_jsx(DndProvider, Object.assign({ backend: HTML5Backend }, { children: _jsx(TableLayout, Object.assign({}, rowHoverProps, { columns: columns, hasStickyHeader: hasStickyHeader, stickyIndicator: stickyIndicator, scrollThreshold: scrollThreshold, scrollTargetRef: scrollTargetRef, tableHeaderRef: tableHeaderRef, withColumnNumbers: withColumnNumbers, hasConfig: hasConfig, onConfigChange: onConfigChange, onConfigUnHide: onConfigUnHide, onConfigReset: onConfigReset, pagination: hasPagination && (_jsx(TablePagination, { hasStickyHeader: hasStickyHeader, itemsCount: data.length, pageSizes: pageSizes, config: paginationConfig, onPageSizeChange: onPageSizeChange, maxPageLimit: maxPageLimit, paginationOptionClassName: paginationOptionClassName, paginationButtonClassName: paginationButtonClassName, paginationContainerClassName: paginationContainerClassName }, void 0)) }, { children: _jsx(TableView, Object.assign({}, props, { columns: visibleColumns, tableHeaderRef: tableHeaderRef, headerWidthArray: headerWidthArray, getTableProps: getTableProps, getTableBodyProps: getTableBodyProps, headerGroups: headerGroups, prepareRow: prepareRow, page: page, withColumnNumbers: withColumnNumbers }), void 0) }), void 0) }), void 0));
});
