import { convertRadiansToDegrees } from '../../../../utils';
/**
 * Empty paddings around the plot (inside SVG element)
 */
export const PLOT_TOP_PADDING = 20;
export const PLOT_BOTTOM_PADDING = 40;
export const PLOT_RIGHT_PADDING = 80;
export const PLOT_LEFT_PADDING = 100;
/**
 * Top padding of the bottom (X axis) label
 *
 * Relative to plot's bottom axis
 */
export const BOTTOM_LABEL_PADDING = 16;
/**
 * Height of the block containing top (Y axis) label
 */
export const TOP_LABEL_HEIGHT = 20;
/**
 * Width and height of scale blocks for both axis
 *
 * Scale block for Y axis is located on the left to the axis
 * Scale block for X axis is located on the bottom after the axis
 */
export const LEFT_AXIS_SCALE_WIDTH = 24;
export const BOTTOM_AXIS_SCALE_HEIGHT = 40;
/**
 * Additional padding for Y axis label
 *
 * Relative to Y axis label
 */
export const LABEL_Y_PADDING_TOP = 5;
/**
 * Widths of axis lines
 */
export const AXIS_BORDER_WIDTH = 4;
/**
 * Paddings of plot's canvas (the area where plot's data point are drawn)
 *
 * Relative to axis cross point
 */
export const CANVAS_PADDING_WIDTH = 40;
export const CANVAS_PADDING_HEIGHT = 0;
/**
 * Radius of a data point
 */
export const DATA_POINT_RADIUS = 6;
/**
 * Radius of a circle when data triangle point hover
 */
export const TRIANGLE_OUTLINE_RADIUS = 18;
/**
 * Distance between two adjacent data points
 */
export const DATA_POINT_STEP = DATA_POINT_RADIUS * 2;
/**
 * Point to calculate scroll position from
 * This is a point when a data point is considered to be "scrolled out"
 */
export const ZERO_POINT_OFFSET = CANVAS_PADDING_WIDTH - AXIS_BORDER_WIDTH;
/**
 * Padding between SVG legend item label and detailed name
 */
export const LEGEND_LABEL_PADDING = 10;
/**
 * Min width of the label
 */
export const LEGEND_MIN_LABEL_WIDTH = 30;
/**
 * Height and padding of a single item row in SVG legend
 */
export const LEGEND_TEXT_ROW_HEIGHT = 16;
export const LEGEND_TEXT_ROW_PADDING = 16;
/**
 * Default value getter
 *
 * Value getters are used as keys for accessing plot's value
 *  from a given data items structure
 */
export const DEFAULT_VALUE_GETTER = 'pvalue';
/**
 * Default height of the plot
 *
 * Includes all paddings
 */
export const DEFAULT_PLOT_HEIGHT = 520;
/**
 * Default timeout for re-calculating SVG width after window resize
 */
export const DEFAULT_RESIZE_TIMEOUT = 100;
/**
 * Default size of groups data chunk which will be rendered one by one
 */
export const DEFAULT_DATA_GROUP_CHUNK_SIZE = 1;
/**
 * Allowed region of the plot where user is able to zoom via select
 */
const RIGHT_OFFSET = 0;
const BOTTOM_OFFSET = 24;
export const DEFAULT_SELECT_REGION_OFFSET = [
    PLOT_TOP_PADDING + TOP_LABEL_HEIGHT,
    RIGHT_OFFSET,
    BOTTOM_OFFSET,
    PLOT_LEFT_PADDING + LEFT_AXIS_SCALE_WIDTH + AXIS_BORDER_WIDTH,
];
/**
 * Plot's tooltip id which will be used as a base
 */
export const TOOLTIP_ID = 'ManhattanPlotTooltip';
/**
 * Min allowed width of the plot
 */
export const PLOT_MIN_WIDTH = 620;
/**
 * Default font size for group labels
 */
export const DEFAULT_GROUP_LABEL_FONT_SIZE = 12;
/**
 * Default angle for group labels in radians
 */
export const DEFAULT_GROUP_LABEL_ANGLE = Math.PI / 4;
/**
 * Default angle for group labels in degrees
 */
export const DEFAULT_GROUP_LABEL_ANGLE_DEGREES = convertRadiansToDegrees(DEFAULT_GROUP_LABEL_ANGLE);
/**
 * Default letter width of scale labels
 */
export const DEFAULT_SCALE_LETTER_WIDTH = 10;
/**
 * Default width of scale label
 */
export const DEFAULT_SCALE_LABEL_WIDTH = 20;
/**
 * Default font family for group labels
 */
export const DEFAULT_GROUP_LABEL_FONT_FAMILY = 'Roboto';
/**
 * Font which is used for drawing SVG legend
 */
export const DEFAULT_GROUP_LABEL_FONT = `${DEFAULT_GROUP_LABEL_FONT_SIZE}px ${DEFAULT_GROUP_LABEL_FONT_FAMILY}`;
/**
 * Default upper limit for points to be displayed on the plot
 * The points will not be displayed on Y axis higher then this value
 */
export const DEFAULT_UPPER_DATA_LIMIT = 20;
/**
 * Default lower limit for points to be displayed on the plot
 * The points will not be displayed on Y axis lower then this value
 */
export const DEFAULT_LOWER_DATA_LIMIT = 0;
/**
 * Min allowed zoom factor
 * Equals 1%
 */
export const MIN_ZOOM_FACTOR = 0.01;
/**
 * Max allowed zoom factor
 * Equals 100,000%
 */
export const MAX_ZOOM_FACTOR = 1000;
/**
 * Height of the threshold rect on axis
 */
export const THRESHOLD_AXIS_RECT_HEIGHT = 20;
/**
 * Font size of the axis labels
 */
export const AXIS_LABEL_FONT_SIZE = 14;
/**
 * Extra space (padding) above and below threshold rects on axis
 * which will be additionally limiting space on overlap check
 */
export const THRESHOLD_AXIS_SCALE_EXTRA_SPACE = 2;
export const THRESHOLD_LABEL_ICON_SPACE = 22;
export const THRESHOLD_LABEL_BORDER_RADIUS = 4;
export const DATA_CONTAINER_DIFFERENCE = 10;
export const DEFAULT_Y_SCROLLBAR_RIGHT_PADDING = 81;
export const DATA_TOP_OFFSET = 40;
export const DATA_BOTTOM_OFFSET = 20;
export const RIGHT_SCROLLBAR_SHIFT = 60;
export const DATA_TOP_G_OFFSET = 80;
export const AXIS_BACKGROUND_HEIGHT = 44;
export const SYNTHETIC_SCROLL_X_MARGIN = 80;
/**
 * The factor for Y axis zooming correction
 */
export const Y_ZOOMING_FACTOR = 1.04;
