export const inlineStyles = {
    label: {
        fontSize: '14px',
        transform: 'translate(2px, 13px)',
    },
    name: {
        transform: 'translateY(-2px)',
    },
    groupOdd: {
        fill: '#f2e5ed',
    },
    groupEven: {
        fill: '#f3f6cc',
    },
};
