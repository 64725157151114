var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState, useRef, useCallback } from 'react';
import JSZip from 'jszip';
import { EExportTypes } from '../../types';
import { downloadData, logger } from '../../utils';
export const useZipArchive = () => {
    const zipRef = useRef(new JSZip());
    const [isLoading, setIsLoading] = useState(false);
    const addFileToArchive = useCallback((fileName, fileData) => {
        var _a;
        (_a = zipRef.current) === null || _a === void 0 ? void 0 : _a.file(fileName, fileData);
    }, [zipRef]);
    const removeFileFromArchive = useCallback((file) => {
        zipRef.current.remove(file);
    }, [zipRef]);
    const downloadArchive = useCallback((files, archiveName = 'archiveFiles.zip') => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        setIsLoading(true);
        try {
            yield Promise.all(Object.entries(files).map(([fileName, fileDataGenerator]) => __awaiter(void 0, void 0, void 0, function* () {
                try {
                    const file = yield fileDataGenerator();
                    addFileToArchive(fileName, file);
                }
                catch (err) {
                    throw new Error();
                }
            })));
            const blob = yield zipRef.current.generateAsync({ type: 'blob' });
            if (blob) {
                downloadData(blob, archiveName, EExportTypes.ZIP);
            }
        }
        catch (err) {
            if (err instanceof Error) {
                logger.warn(`${err.message}Cannot archive data due to error`);
            }
        }
        finally {
            setIsLoading(false);
            Object.keys((_a = zipRef.current) === null || _a === void 0 ? void 0 : _a.files).map((file) => removeFileFromArchive(file));
        }
    }), [addFileToArchive, removeFileFromArchive]);
    return {
        isLoading,
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        downloadArchive,
    };
};
