const inlineStyles = {
    groupLine: {
        stroke: '#cad7e1',
    },
    groupLabelText: {
        fontSize: '14px',
        transform: 'translateY(5px)',
    },
    groupOdd: {
        fill: '#f2e5ed',
    },
    groupEven: {
        fill: '#f3f6cc',
    },
};
export default inlineStyles;
