import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import classnames from 'classnames';
import styles from './Button.css';
import { Tooltip } from '../Tooltip';
export const Button = memo(function Button({ className, children, onClick, tabIndex = 0, disabled = false, testId, title, type = 'button', outlined = false, tooltipProps, tooltipContainerClassName, withHover = false, }) {
    const renderButton = () => (_jsx("button", Object.assign({ className: classnames(styles.button, {
            [styles.outlined]: outlined,
            [styles.disabled]: disabled,
            [styles.withHover]: withHover && !disabled,
        }, className), 
        // eslint-disable-next-line react/button-has-type
        type: type, onClick: onClick, tabIndex: tabIndex, disabled: disabled, "data-testid": testId, title: title }, { children: children }), void 0));
    if (tooltipProps) {
        return (_jsx(Tooltip, Object.assign({}, tooltipProps, { children: _jsx("div", Object.assign({ className: tooltipContainerClassName }, { children: renderButton() }), void 0) }), void 0));
    }
    return renderButton();
});
