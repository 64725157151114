// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Toggler__togglerPoint__eiVTs{-webkit-appearance:none;appearance:none;background-color:var(--uncheck-checkbox-color);border-radius:.5rem;box-shadow:0 11px 25px 0 var(--box-shadow-color);cursor:pointer;height:.75rem;outline:none;position:relative;width:1.875rem}.Toggler__togglerPoint__eiVTs:before{background-color:var(--table-header-background-color);border-radius:50%;box-shadow:0 0 25px 0 var(--box-shadow-color);content:\"\";height:1.125rem;left:0;position:absolute;top:-3px;transition:.2s;width:1.125rem}.Toggler__togglerPoint__eiVTs:checked{background-color:var(--active-background-color)}.Toggler__togglerPoint__eiVTs:checked:before{background-color:var(--primary-color);left:.75rem}", "",{"version":3,"sources":["webpack://./shared/src/components/Toggler/Toggler.css"],"names":[],"mappings":"AAAA,8BAGE,uBAAgB,CAAhB,eAAgB,CAMhB,8CAA+C,CAJ/C,mBAAqB,CAGrB,gDAAiD,CADjD,cAAe,CALf,aAAe,CAEf,YAAa,CAEb,iBAAkB,CALlB,cAsBF,CAZE,qCAQE,qDAAsD,CAHtD,iBAAkB,CAIlB,6CAA8C,CAR9C,UAAW,CAGX,eAAgB,CAGhB,MAAO,CALP,iBAAkB,CAIlB,QAAS,CAIT,cAAgB,CAPhB,cAQF,CAGF,sCACE,+CAMF,CAJE,6CAEE,qCAAsC,CADtC,WAEF","sourcesContent":[".togglerPoint {\n  width: 1.875rem;\n  height: 0.75rem;\n  appearance: none;\n  outline: none;\n  border-radius: 0.5rem;\n  position: relative;\n  cursor: pointer;\n  box-shadow: 0 11px 25px 0 var(--box-shadow-color);\n  background-color: var(--uncheck-checkbox-color);\n\n  &::before {\n    content: '';\n    position: absolute;\n    width: 1.125rem;\n    height: 1.125rem;\n    border-radius: 50%;\n    top: -3px;\n    left: 0;\n    background-color: var(--table-header-background-color);\n    box-shadow: 0 0 25px 0 var(--box-shadow-color);\n    transition: 0.2s;\n  }\n}\n\n.togglerPoint:checked {\n  background-color: var(--active-background-color);\n\n  &::before {\n    left: 0.75rem;\n    background-color: var(--primary-color);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"togglerPoint": "Toggler__togglerPoint__eiVTs"
};
export default ___CSS_LOADER_EXPORT___;
