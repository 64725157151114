import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback } from 'react';
import classnames from 'classnames';
import { TRIANGLE_OUTLINE_RADIUS } from '../../../../consts';
import { TRIANGLE_X_CORRECTION_INDEX, TRIANGLE_Y_CORRECTION_INDEX } from '../../consts';
import { EDataPointOrder } from '../../../../types';
import { inlineStyles } from '../../ManhattanPlotDataPoint.style';
import style from '../../ManhattanPlotDataPoint.css';
const ManhattanPlotTriangleUp = ({ x, y, radius = TRIANGLE_OUTLINE_RADIUS, dataItem, useInlineStyles, handleTooltipShow, handleTooltipHide, dataPointOrder, opacity = 100, }) => {
    const onMouseEnter = useCallback((event) => {
        if (handleTooltipShow && opacity) {
            handleTooltipShow(event, dataItem.data);
        }
    }, [dataItem, handleTooltipShow, opacity]);
    const orderClass = classnames({
        [style.odd]: dataPointOrder === EDataPointOrder.Odd,
        [style.even]: dataPointOrder === EDataPointOrder.Even,
    });
    const styles = dataPointOrder === EDataPointOrder.Odd
        ? inlineStyles.pointOdd
        : inlineStyles.pointEven;
    const pointerEvents = !opacity ? 'none' : 'initial';
    return (_jsxs("g", Object.assign({ className: style.wrapper, onMouseEnter: onMouseEnter, onMouseLeave: handleTooltipHide, style: {
            opacity: !opacity ? 0 : opacity / 100,
            pointerEvents,
        }, "data-testid": "dataPointPlot triangleUp" }, { children: [_jsx("circle", { className: classnames(style.triangleCircle, orderClass), style: useInlineStyles ? inlineStyles.triangleCircle : {}, cx: x, cy: y, r: radius }, void 0), _jsx("path", { className: classnames(style.triangle, style.dataPoint, orderClass), d: `M${x - TRIANGLE_X_CORRECTION_INDEX} ${y + TRIANGLE_Y_CORRECTION_INDEX} l5.015 -6.853 l5.015 6.853 z`, style: useInlineStyles ? styles : {} }, void 0)] }), void 0));
};
/** Since memo loses generic props */
export default memo(ManhattanPlotTriangleUp);
