import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, memo, useCallback, } from 'react';
import classnames from 'classnames';
import { ETooltipAction, ETooltipAlign, ETooltipPlacement, InfoTooltip, Section, } from '..';
import ToggleIcon from '../../assets/icons/dropdown.svg';
import { EKeyCode } from '../../consts';
import styles from './CollapsibleSection.css';
const CollapsibleSection = ({ children, label, className, headerContainerClassName, headerClassName, titleClassName, childrenContainerClassName, description, containerRef, isCollapsedByDefault = false, infoTooltipID = 'CollapsiblePlotTooltip', testId, typeSection, iconClassName, }) => {
    const [isCollapsed, setCollapsed] = useState(isCollapsedByDefault);
    const toggleCollapsed = useCallback(() => setCollapsed((isCurrentlyCollapsed) => !isCurrentlyCollapsed), []);
    const handleKeyUpToggle = (event) => {
        if (event.code === EKeyCode.Enter) {
            toggleCollapsed();
        }
    };
    const toggleIconClassName = classnames(styles.toggleIcon, iconClassName, {
        [styles.toggleIconExpanded]: !isCollapsed,
    });
    const collapsibleSectionClassName = classnames(styles.container, className);
    const collapsibleSectionHeaderClassName = classnames(styles.header, headerClassName);
    const collapsibleSectionTitleClassName = classnames(styles.title, titleClassName);
    const collapsibleSectionHeaderContainerClassName = classnames(styles.headerContainer, headerContainerClassName);
    const collapsibleSectionChildrenContainerClassName = classnames(styles.childrenContainer, childrenContainerClassName);
    return (_jsxs(Section, Object.assign({ className: collapsibleSectionClassName, testId: testId, type: typeSection }, { children: [_jsx("div", Object.assign({ className: collapsibleSectionHeaderContainerClassName, role: "button", onClick: toggleCollapsed, onKeyUp: handleKeyUpToggle, tabIndex: -1 }, { children: _jsxs("div", Object.assign({ className: collapsibleSectionHeaderClassName, ref: containerRef }, { children: [_jsxs("div", Object.assign({ className: collapsibleSectionTitleClassName }, { children: [label, description && (_jsx(InfoTooltip, Object.assign({ id: infoTooltipID, targetEvent: ETooltipAction.Hover, position: ETooltipPlacement.BottomCenter, align: ETooltipAlign.Start, className: styles.infoIcon, overlayClassName: styles.tooltip }, { children: description }), void 0))] }), void 0), _jsx(ToggleIcon, { className: toggleIconClassName }, void 0)] }), void 0) }), void 0), !isCollapsed && (_jsx("div", Object.assign({ className: collapsibleSectionChildrenContainerClassName, "data-testid": "childrenContainer" }, { children: children }), void 0))] }), void 0));
};
export default memo(CollapsibleSection);
