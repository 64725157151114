/* eslint-disable @typescript-eslint/no-unsafe-return */
/**
 * Get value by specific string path from the object
 *
 * @param obj - Object to get value from
 * @param depsPath - String path ("someString.someString")
 *
 * @returns any value from the object
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getValueByPath = (obj, depsPath) => {
    if (!(Object.keys(obj).length && depsPath.length)) {
        return null;
    }
    const deps = depsPath.split('.');
    if (deps.length === 1) {
        return obj[depsPath];
    }
    return deps.reduce((acc, curr) => acc[curr], obj);
};
