import { range } from '../../../../../../utils';
/**
 * Calculates step value depends of difference between edge coordinates
 *
 * @param difference - difference between edge coordinates
 * @returns step as a number
 */
const getStep = (difference) => {
    if (difference <= 1) {
        return 0.1;
    }
    if (difference <= 2) {
        return 0.2;
    }
    if (difference <= 10) {
        return 1;
    }
    return 2;
};
/**
 * Calculates maximum axis edge
 *
 * @param value - maximum value
 * @returns axis edge as a number
 */
const getNormalizedMaxValue = (value) => {
    if (value === 0) {
        return 0.1;
    }
    if (value <= 1) {
        return Math.ceil(value * 10) / 10;
    }
    if (value <= 2) {
        const temp = Math.ceil(value * 10);
        return temp % 2 === 1 ? (temp + 1) / 10 : temp / 10;
    }
    if (value <= 10) {
        return Math.ceil(value);
    }
    const temp = Math.ceil(value);
    return temp % 2 === 1 ? temp + 1 : temp;
};
/**
 * Calculates scale values from 0 to `maxValue`
 *
 * Current strategy is simply generate even numbers between the give range
 * If `maxValue` is an odd number, it will be increased by 1
 *
 * @param maxValue - Max value for the scale range
 * @returns Array of scale numbers/values
 */
export const calculateScaleValues = (maxValue) => {
    const normalizedMaxValue = getNormalizedMaxValue(maxValue);
    return range(0, normalizedMaxValue + normalizedMaxValue / 10, getStep(normalizedMaxValue)).map((x) => Math.floor(x * 10) / 10);
};
