import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo } from 'react';
import { LinePlotPlotThresholdTooltip } from '../LinePlotThresholdTooltip/LinePlotThresholdTooltip';
import styles from './LinePlotThresholdIcon.css';
const INFO_ICON_WIDTH = 18;
const INFO_ICON_SCALE_RATIO = 2 / 3;
export const LinePlotThresholdIcon = memo(function LinePlotThresholdIcon({ x, y, width, description, color, }) {
    return (_jsxs(_Fragment, { children: [_jsx("rect", { x: x, y: y - width / 2, width: width, height: width, rx: 3, ry: 3, style: color ? {
                    fill: color,
                } : undefined }, void 0), _jsx(LinePlotPlotThresholdTooltip, Object.assign({ description: description }, { children: _jsxs("g", Object.assign({ className: styles.iconArea, transform: `translate(${x + width * ((1 - INFO_ICON_SCALE_RATIO) / 2)}, ${y - (width * INFO_ICON_SCALE_RATIO) / 2}) scale(${(width * INFO_ICON_SCALE_RATIO) / INFO_ICON_WIDTH})` }, { children: [_jsx("path", { d: "M9,0 C13.9705627,0 18,4.02943725 18,9 C18,13.9705627 13.9705627,18 9,18 C4.02943725,18 0,13.9705627 0,9 C0,4.02943725 4.02943725,0 9,0 Z M9,1.5 C4.85786438,1.5 1.5,4.85786438 1.5,9 C1.5,13.1421356 4.85786438,16.5 9,16.5 C13.1421356,16.5 16.5,13.1421356 16.5,9 C16.5,4.85786438 13.1421356,1.5 9,1.5 Z M9,7 C9.55228475,7 10,7.44771525 10,8 L10,13 C10,13.5522847 9.55228475,14 9,14 C8.44771525,14 8,13.5522847 8,13 L8,8 C8,7.44771525 8.44771525,7 9,7 Z M9,4 C9.55228475,4 10,4.44771525 10,5 C10,5.55228475 9.55228475,6 9,6 C8.44771525,6 8,5.55228475 8,5 C8,4.44771525 8.44771525,4 9,4 Z", className: styles.icon }, void 0), _jsx("circle", { cx: INFO_ICON_WIDTH / 2, cy: INFO_ICON_WIDTH / 2, r: INFO_ICON_WIDTH / 2, className: styles.iconPlaceholder }, void 0)] }), void 0) }), void 0)] }, void 0));
});
