import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { DEFAULT_AXIS_BORDER_WIDTH, DEFAULT_Y_AXIS_WIDTH, LEGEND_TOP_MARGIN, OUTER_CONTAINER_RIGHT_PADDING, X_SCROLLBAR_RIGHT_PADDING, } from '../../consts';
import { SCROLLABLE_PLOT_BOTTOM_PADDING, SCROLLBAR_MARGIN } from '../../FES/consts';
import styles from './PlotAxisScrollable.css';
const PlotAxisScrollableBorder = (props) => {
    const { size, square, zoomIndex = 1, } = props;
    const HALF_BORDER_WIDTH = Math.floor(DEFAULT_AXIS_BORDER_WIDTH / 2) || 1;
    return (_jsxs("svg", Object.assign({ height: size.height, width: size.width + OUTER_CONTAINER_RIGHT_PADDING + SCROLLBAR_MARGIN, viewBox: `0 0 ${size.width + OUTER_CONTAINER_RIGHT_PADDING + SCROLLBAR_MARGIN} ${size.height}`, xmlns: "http://www.w3.org/2000/svg", className: styles.axisBorder }, { children: [zoomIndex > 1 && (_jsxs(_Fragment, { children: [_jsx("rect", { x: size.width, y: size.height - SCROLLABLE_PLOT_BOTTOM_PADDING + DEFAULT_AXIS_BORDER_WIDTH, width: X_SCROLLBAR_RIGHT_PADDING, height: SCROLLABLE_PLOT_BOTTOM_PADDING + DEFAULT_AXIS_BORDER_WIDTH, className: styles.axisYHiding }, void 0), _jsx("rect", { x: 0, y: size.height - SCROLLABLE_PLOT_BOTTOM_PADDING + DEFAULT_AXIS_BORDER_WIDTH, width: DEFAULT_Y_AXIS_WIDTH, height: SCROLLABLE_PLOT_BOTTOM_PADDING + DEFAULT_AXIS_BORDER_WIDTH, className: styles.axisYHiding }, void 0)] }, void 0)), _jsx("rect", { x: 0, y: 0, width: size.width + OUTER_CONTAINER_RIGHT_PADDING, height: LEGEND_TOP_MARGIN, className: styles.axisYHiding }, void 0), _jsxs("g", { children: [_jsx("line", { x1: square.left + HALF_BORDER_WIDTH, y1: square.top, x2: square.left + HALF_BORDER_WIDTH, y2: size.height - SCROLLABLE_PLOT_BOTTOM_PADDING + 4, style: {
                            stroke: '#cad7e1',
                            strokeWidth: DEFAULT_AXIS_BORDER_WIDTH,
                        } }, void 0), _jsx("line", { x1: square.left + HALF_BORDER_WIDTH, y1: size.height - SCROLLABLE_PLOT_BOTTOM_PADDING + 4, x2: size.width, y2: size.height - SCROLLABLE_PLOT_BOTTOM_PADDING + 4 - HALF_BORDER_WIDTH, style: {
                            stroke: '#cad7e1',
                            strokeWidth: DEFAULT_AXIS_BORDER_WIDTH,
                        } }, void 0), _jsx("line", { x1: size.width - 1, y1: square.top, x2: size.width - 1, y2: size.height - SCROLLABLE_PLOT_BOTTOM_PADDING + 2, style: {
                            stroke: '#cad7e1',
                            strokeWidth: 1,
                        } }, void 0), _jsx("line", { x1: square.left + HALF_BORDER_WIDTH, y1: square.top + 1, x2: size.width, y2: square.top + 1, style: {
                            stroke: '#cad7e1',
                            strokeWidth: 1,
                        } }, void 0)] }, void 0)] }), void 0));
};
export default memo(PlotAxisScrollableBorder);
