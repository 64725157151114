import classnames from 'classnames';
import { ESearchBoxType } from '../../consts';
import styles from '../../styles/common.css';
import searchStyles from '../../styles/search.css';
import fieldStyles from '../../styles/field.css';
import navigationStyles from '../../styles/navigation.css';
export const stylesByType = {
    [ESearchBoxType.Field]: fieldStyles,
    [ESearchBoxType.Search]: searchStyles,
    [ESearchBoxType.Navigation]: navigationStyles,
};
export function getStyles(props) {
    const { type, isFocused, className, boxClassName, fieldClassName, inputClassName, optionListClassName, noOptionsClassName, optionClassName, selectedOptionClassName, highlightedOptionClassName, disabledOptionClassName, } = props;
    const typeStyles = stylesByType[type] || {};
    return {
        layout: classnames(styles.layout, typeStyles.layout, className),
        box: classnames(styles.box, typeStyles.box, {
            [typeStyles.boxFocused]: isFocused,
        }, boxClassName),
        field: classnames(styles.field, typeStyles.field, {
            [typeStyles.fieldFocused]: isFocused,
        }, fieldClassName),
        input: classnames(styles.input, typeStyles.input, inputClassName),
        spinnerContainer: classnames(styles.spinnerContainer, typeStyles.spinnerContainer),
        spinner: classnames(styles.spinner, typeStyles.spinner),
        iconClear: classnames(styles.icon, styles.iconClear, typeStyles.iconClear),
        iconSearch: classnames(styles.icon, typeStyles.iconSearch, {
            [typeStyles.iconSearchFocused]: isFocused,
        }),
        options: classnames(styles.options, typeStyles.options, optionListClassName),
        option: classnames(styles.option, typeStyles.option, optionClassName),
        noOptions: classnames(styles.noOptions, typeStyles.noOptions, noOptionsClassName),
        selected: classnames(styles.selected, typeStyles.selected, selectedOptionClassName),
        highlighted: classnames(styles.highlighted, typeStyles.highlighted, highlightedOptionClassName),
        disabled: classnames(styles.disabled, typeStyles.disabled, disabledOptionClassName),
    };
}
