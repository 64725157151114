import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useState, useCallback, Fragment, useEffect, } from 'react';
import classnames from 'classnames';
import { DEFAULT_GROUP } from '../../consts';
import { MultiSelectFieldItem } from '../MultiSelectFieldItem';
import styles from './MultiSelectFieldOptions.css';
function MultiSelectFieldOptionsBase(props) {
    const { valueMap, options, onChange, getOptionLabel, getOptionValue, renderDescription, disabled = false, optionsClassName, descriptionClassName, isOpen, } = props;
    const [activeOption, setActiveOption] = useState(null);
    const handleMouseEnter = useCallback((option) => {
        setActiveOption(option);
    }, []);
    const handleMouseLeave = useCallback(() => {
        if (!renderDescription) {
            setActiveOption(null);
        }
    }, [renderDescription]);
    useEffect(() => {
        setActiveOption(options[0].children[0]);
    }, [options, isOpen]);
    const renderMultiSelectField = useCallback((option, isSelected) => {
        const optionLabel = getOptionLabel(option);
        const optionValue = getOptionValue(option);
        return (_jsx(MultiSelectFieldItem, { value: optionValue, label: optionLabel, checked: isSelected !== null && isSelected !== void 0 ? isSelected : valueMap.has(optionValue), disabled: disabled, option: option, isActive: activeOption === option, onChange: onChange, onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave }, optionValue));
    }, [
        getOptionLabel,
        getOptionValue,
        disabled,
        handleMouseEnter,
        onChange,
        valueMap,
        handleMouseLeave,
        activeOption,
    ]);
    return (_jsxs("div", Object.assign({ className: styles.layout }, { children: [_jsx("ul", Object.assign({ className: classnames(styles.options, optionsClassName) }, { children: options.map((groupOption) => {
                    const { id: groupId, children } = groupOption;
                    if (!children.length)
                        return null;
                    if (groupId === DEFAULT_GROUP.id) {
                        return children.map((option) => renderMultiSelectField(option));
                    }
                    const isSelectedGroup = children.every((selectedItem) => (valueMap.has(getOptionValue(selectedItem))));
                    return (_jsxs(Fragment, { children: [renderMultiSelectField(groupOption, isSelectedGroup), _jsx("div", Object.assign({ className: styles.nestedBlock }, { children: _jsx("ul", Object.assign({ className: styles.options }, { children: children.map((childrenItem) => renderMultiSelectField(childrenItem)) }), void 0) }), void 0)] }, groupId.toString()));
                }) }), void 0), renderDescription && (_jsx("div", Object.assign({ className: classnames(styles.description, descriptionClassName) }, { children: activeOption && renderDescription(activeOption) }), void 0))] }), void 0));
}
export const MultiSelectFieldOptions = memo(MultiSelectFieldOptionsBase);
