import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useEffect, useMemo, useState, } from 'react';
import classnames from 'classnames';
import styles from './Toggler.css';
const Toggler = (props) => {
    const { className, isActive: isActiveProp = false, onToggle, id, name, } = props;
    const [isActive, setActive] = useState(isActiveProp);
    const mergedClassName = useMemo(() => classnames(styles.togglerPoint, className), [className]);
    useEffect(() => setActive(isActiveProp), [isActiveProp]);
    const handleChange = () => {
        setActive((prevIsActive) => !prevIsActive);
        if (onToggle) {
            onToggle(!isActive);
        }
    };
    return (_jsx("input", { "aria-label": name, id: id, "data-testid": id, name: name, type: "checkbox", className: mergedClassName, checked: isActive, onChange: handleChange }, void 0));
};
const MemoizedToggler = memo(Toggler);
export { MemoizedToggler as Toggler };
