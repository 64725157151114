export var ManhattanScaleType;
(function (ManhattanScaleType) {
    ManhattanScaleType[ManhattanScaleType["Regular"] = 0] = "Regular";
    ManhattanScaleType[ManhattanScaleType["LowerThreshold"] = 1] = "LowerThreshold";
    ManhattanScaleType[ManhattanScaleType["UpperThreshold"] = 2] = "UpperThreshold";
})(ManhattanScaleType || (ManhattanScaleType = {}));
export var EDataPointOrder;
(function (EDataPointOrder) {
    EDataPointOrder[EDataPointOrder["Even"] = 0] = "Even";
    EDataPointOrder[EDataPointOrder["Odd"] = 1] = "Odd";
})(EDataPointOrder || (EDataPointOrder = {}));
export var ELabelsConfig;
(function (ELabelsConfig) {
    ELabelsConfig["triangleUp"] = "triangleUp";
    ELabelsConfig["triangleDown"] = "triangleDown";
    ELabelsConfig["circle"] = "circle";
})(ELabelsConfig || (ELabelsConfig = {}));
