/**
 * Gets appropriate tick interval for scale to contain only allowed max number of ticks
 *
 * If no interval from the given array satisfies the maximum number of ticks,
 *  interval will be calculated based on the power of 10 needed to limit the number of ticks
 *
 * @param tickIntervals - Possible tick intervals to use
 * @param maxTickValue - Max tick value
 * @param maxTicks - Max allowed number of ticks
 * @returns Tick interval
 */
export default function getTickInterval(tickIntervals, maxTickValue, maxTicks) {
    const tickIntervalCandidate = tickIntervals.find((interval) => ((maxTickValue / interval) < (maxTicks - 1)));
    return tickIntervalCandidate || Math.pow(10, Math.ceil(Math.log10(maxTickValue)));
}
