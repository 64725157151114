export const DEFAULT_PLOT_WIDTH = 1280;
export const DEFAULT_PLOT_HEIGHT = 520;
export const DEFAULT_PLOT_TOP_PADDING = 0;
export const DEFAULT_PLOT_BOTTOM_PADDING = 25;
export const PLOT_WITH_LABEL_BOTTOM_PADDING = 50;
export const PLOT_LEFT_PADDING = 40;
export const DATA_POINT_RADIUS = 6;
export const AXIS_LABELS_HEIGHT = 30;
export const AXIS_LABELS_POSITION_CORRECTION_FACTOR = 55;
/*
need for correct intersection of axes
*/
export const AXIS_LINE_WIDTH = 4;
export const HALF_AXIS_LINE_WIDTH = AXIS_LINE_WIDTH / 2;
export const PLOT_TOOLTIP_ID = 'NetworkPlotTooltip';
export const DEFAULT_QUANTITY_LABELS_AXIS_X = 20;
export const DEFAULT_QUANTITY_LABELS_AXIS_Y = 10;
