import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import classnames from 'classnames';
import { Button } from '../../../Button';
import { Tag } from '../../../Tag';
import SelectFieldIcon from '../../../../assets/icons/dropdown.svg';
import styles from './SelectButton.css';
export const SelectButton = memo(function SelectButton(props) {
    const { value, label, onClick, isOpen, buttonClassName, isWithTag, childNode, } = props;
    const className = classnames(styles.selectButton, buttonClassName, {
        [styles.dropdownOpen]: isOpen,
        [styles.dropdownClose]: !isOpen,
        [styles.selectButtonWithTag]: isWithTag,
    });
    const buttonLabel = isWithTag
        ? (_jsxs("div", Object.assign({ className: styles.tagWrapper }, { children: [_jsx(Tag, { value: value, label: value }, void 0), _jsx("div", Object.assign({ className: styles.labelWrapper }, { children: label }), void 0)] }), void 0))
        : value;
    return (_jsxs(Button, Object.assign({ testId: "selectButton", className: className, onClick: onClick }, { children: [buttonLabel, childNode, _jsx(SelectFieldIcon, {}, void 0)] }), void 0));
});
