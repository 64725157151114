// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!../ManhattanPlotAxisScaleLines/ManhattanPlotAxisScaleLines.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ManhattanPlotAxis__axisLine__1w-B8{stroke:var(--border-color);stroke-width:4px}.ManhattanPlotAxis__axisScaleLine__gnO8W{}.ManhattanPlotAxis__axisLabel__qKHj5{font-size:.75rem;font-weight:500}.ManhattanPlotAxis__axisBackground__wahhL{fill:var(--plot-background-color)}", "",{"version":3,"sources":["webpack://./shared/src/plots/components/ManhattanPlot/components/ManhattanPlotAxis/ManhattanPlotAxis.css"],"names":[],"mappings":"AAAA,oCACE,0BAA2B,CAC3B,gBACF,CAEA,yCAEA,CAEA,qCACE,gBAAkB,CAClB,eACF,CAEA,0CACE,iCACF","sourcesContent":[".axisLine {\n  stroke: var(--border-color);\n  stroke-width: 4px;\n}\n\n.axisScaleLine {\n  composes: axisScaleLine from '../ManhattanPlotAxisScaleLines/ManhattanPlotAxisScaleLines.css';\n}\n\n.axisLabel {\n  font-size: 0.75rem;\n  font-weight: 500;\n}\n\n.axisBackground {\n  fill: var(--plot-background-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"axisLine": "ManhattanPlotAxis__axisLine__1w-B8",
	"axisScaleLine": "ManhattanPlotAxis__axisScaleLine__gnO8W " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["axisScaleLine"] + "",
	"axisLabel": "ManhattanPlotAxis__axisLabel__qKHj5",
	"axisBackground": "ManhattanPlotAxis__axisBackground__wahhL"
};
export default ___CSS_LOADER_EXPORT___;
