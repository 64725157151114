import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import { X_AXIS_BORDER_WIDTH, Y_AXIS_BORDER_WIDTH, X_CANVAS_PADDING, X_SCALE_TICK_HEIGHT, X_AXIS_LABEL_HEIGHT, X_SCALE_LABEL_HEIGHT, } from '../../consts';
import LollipopPlotAxis from '../LollipopPlotAxis';
const LollipopPlotAxisX = ({ data, scale, scaleLength, positionX: axisPositionX, positionY: axisPositionY, useInlineStyles, }) => {
    const linePosition = useMemo(() => {
        const x1 = axisPositionX;
        const x2 = (axisPositionX
            + scaleLength
            + X_CANVAS_PADDING
            + Y_AXIS_BORDER_WIDTH);
        const y1 = axisPositionY;
        const y2 = axisPositionY;
        return {
            x1,
            x2,
            y1,
            y2,
        };
    }, [
        axisPositionX,
        axisPositionY,
        scaleLength,
    ]);
    const lineWidth = X_AXIS_BORDER_WIDTH;
    const scalePosition = useMemo(() => (scale && scale.map(({ position: scaleItemPosition, label }, scaleIndex) => {
        const baseX = (axisPositionX
            + Y_AXIS_BORDER_WIDTH
            + X_CANVAS_PADDING);
        const baseY = axisPositionY - X_AXIS_BORDER_WIDTH / 2;
        const scaleTextPosition = {
            x: baseX + scaleItemPosition,
            y: (baseY
                + X_AXIS_BORDER_WIDTH
                + X_SCALE_TICK_HEIGHT
                + X_SCALE_LABEL_HEIGHT),
        };
        const scaleLinePosition = {
            x1: baseX + scaleItemPosition,
            x2: baseX + scaleItemPosition,
            y1: baseY,
            y2: (baseY
                + X_AXIS_BORDER_WIDTH
                + X_SCALE_TICK_HEIGHT),
        };
        const isLastTick = (scaleIndex === scale.length - 1);
        const scaleLabel = isLastTick
            ? `${label}aa`
            : label;
        return {
            textPosition: scaleTextPosition,
            linePosition: scaleLinePosition,
            label: scaleLabel,
        };
    })), [
        scale,
        axisPositionX,
        axisPositionY,
    ]);
    const labelPosition = useMemo(() => ({
        x: (axisPositionX
            + Y_AXIS_BORDER_WIDTH
            + X_CANVAS_PADDING
            + scaleLength),
        y: (axisPositionY
            + X_AXIS_BORDER_WIDTH / 2
            + X_AXIS_LABEL_HEIGHT),
    }), [
        axisPositionX,
        axisPositionY,
        scaleLength,
    ]);
    return (_jsx(LollipopPlotAxis, { label: data === null || data === void 0 ? void 0 : data.label, labelPosition: labelPosition, labelAnchor: "end", linePosition: linePosition, lineWidth: lineWidth, scalePosition: scalePosition, useInlineStyles: useInlineStyles }, void 0));
};
export default memo(LollipopPlotAxisX);
