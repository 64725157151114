// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ManhattanPlotGroupBorder__groupLine__2XulS{stroke:var(--border-color);stroke-width:1px}.ManhattanPlotGroupBorder__groupLabelText__1lwA_{cursor:pointer;transform:translate(-.6rem,.4rem);-webkit-user-select:none;user-select:none}.ManhattanPlotGroupBorder__tooltipContent__1TWIy{max-width:300px}", "",{"version":3,"sources":["webpack://./shared/src/plots/components/ManhattanPlot/components/ManhattanPlotGroupBorder/ManhattanPlotGroupBorder.css"],"names":[],"mappings":"AAAA,4CACE,0BAA2B,CAC3B,gBACF,CAEA,iDAEE,cAAe,CADf,iCAAqC,CAErC,wBAAiB,CAAjB,gBACF,CAEA,iDACE,eACF","sourcesContent":[".groupLine {\n  stroke: var(--border-color);\n  stroke-width: 1px;\n}\n\n.groupLabelText {\n  transform: translate(-0.6rem, 0.4rem);\n  cursor: pointer;\n  user-select: none;\n}\n\n.tooltipContent {\n  max-width: 300px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"groupLine": "ManhattanPlotGroupBorder__groupLine__2XulS",
	"groupLabelText": "ManhattanPlotGroupBorder__groupLabelText__1lwA_",
	"tooltipContent": "ManhattanPlotGroupBorder__tooltipContent__1TWIy"
};
export default ___CSS_LOADER_EXPORT___;
