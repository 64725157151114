/**
 * Returns position of the element relative to document body
 *
 * @param positionSource - Source of element's position: either element or its position object
 * @returns Coordinates of the element relative to body
 */
export function getElementPosition(positionSource) {
    const documentHeight = document.documentElement.clientHeight;
    const documentWidth = document.documentElement.clientWidth;
    const { top: elementTop, left: elementLeft, right: elementRight, bottom: elementBottom, } = positionSource instanceof HTMLElement
        ? positionSource.getBoundingClientRect()
        : positionSource;
    return {
        top: elementTop,
        left: elementLeft,
        right: documentWidth - elementRight,
        bottom: documentHeight - elementBottom,
    };
}
