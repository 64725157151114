import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback, useRef, useState, } from 'react';
import classNames from 'classnames';
import SettingsIcon from '../../../../assets/icons/settings.svg';
import { SettingsPanel } from './components/SettingsPanel/SettingsPanel';
import { TABLE_SETTINGS_BUTTON_LABEL } from './consts';
import styles from './TableSettings.css';
const getHiddenColumnsQuantity = (columns) => columns.reduce((acc, column) => {
    if (column.hidden) {
        return acc + 1;
    }
    return acc;
}, 0);
export const TableSettings = memo(function TableSettings({ className, columns, onConfigChange, onConfigUnHide, onConfigReset, }) {
    const wrapperRef = useRef(null);
    const initColumnsListRef = useRef(JSON.stringify(columns));
    const columnsInitialHideRef = useRef(getHiddenColumnsQuantity(columns));
    const isHiddenColumns = columnsInitialHideRef.current === getHiddenColumnsQuantity(columns);
    const [isVisible, setIsVisible] = useState(false);
    const handleClick = useCallback(() => {
        setIsVisible((value) => !value);
    }, []);
    const handleClose = useCallback(() => {
        setIsVisible(false);
    }, []);
    const settingsClassName = classNames(styles.settingsButton, {
        [styles.active]: isVisible,
    });
    return (_jsxs("div", Object.assign({ ref: wrapperRef, className: styles.wrapper }, { children: [isVisible && (_jsx(SettingsPanel, { wrapperRef: wrapperRef, columns: columns, onConfigChange: onConfigChange, onConfigUnHide: onConfigUnHide, onConfigReset: onConfigReset, handleClose: handleClose, initColumnsList: initColumnsListRef.current, isHiddenColumns: isHiddenColumns }, void 0)), _jsxs("button", Object.assign({ type: "button", onClick: handleClick, className: settingsClassName }, { children: [_jsx(SettingsIcon, { className: className, id: "TableSettingsIcon", "data-testid": "TableSettingsIcon" }, void 0), TABLE_SETTINGS_BUTTON_LABEL] }), void 0)] }), void 0));
});
