import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useCallback } from 'react';
import ManhattanPlot from '../ManhattanPlot/ManhattanPlot';
import GPAManhattanPlotGroupBorder from './components/GPAManhattanPlotGroupBorder/GPAManhattanPlotGroupBorder';
import { DATA_POINT_STEP } from './consts';
const GPAManhattanPlot = (props) => {
    const renderGroupBorder = useCallback((groupBorderProps) => (_jsx(GPAManhattanPlotGroupBorder, Object.assign({}, groupBorderProps), void 0)), []);
    return (_jsx(ManhattanPlot, Object.assign({ dataPointStep: DATA_POINT_STEP }, props, { renderGroupBorder: renderGroupBorder }), void 0));
};
/** Since memo loses generic props */
export default memo(GPAManhattanPlot);
