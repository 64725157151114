import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import { useColumnDistribution } from '../../hooks';
import LegendColumn from '../LegendColumn/LegendColumn';
import stylesCSS from './LegendList.css';
const LegendList = (props) => {
    const { data, styles, maxColumns = 4, } = props;
    const columnDistribution = useColumnDistribution(data.length, maxColumns);
    const columnsData = useMemo(() => (columnDistribution.map(({ startIndex, endIndex }) => data.slice(startIndex, endIndex))), [data, columnDistribution]);
    const columns = useMemo(() => (columnsData.map((columnData, columnIndex) => (_jsx(LegendColumn
    /* eslint-disable-next-line react/no-array-index-key */
    , { startIndex: columnDistribution[columnIndex].startIndex, data: columnData, styles: styles }, columnIndex)))), [columnDistribution, columnsData, styles]);
    return (_jsx("div", Object.assign({ className: stylesCSS.wrapper, "data-testid": "LegendList" }, { children: columns }), void 0));
};
export default memo(LegendList);
