import api from "!../../../../../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!./LollipopPlotDataLine.css";

var options = {"injectType":"singletonStyleTag"};

options.insert = "head";
options.singleton = true;

var update = api(content, options);



export default content.locals || {};