// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SVGLegendItem__label__RinP4{font-size:.865rem;transform:translate(.125rem,.8rem)}.SVGLegendItem__name__2qmKd{transform:translateY(-.125rem)}.SVGLegendItem__groupOdd__Qot7i{fill:var(--plot-group-odd-color)}.SVGLegendItem__groupEven__1YTJw{fill:var(--plot-group-even-color)}", "",{"version":3,"sources":["webpack://./shared/src/plots/components/SVGLegend/components/SVGLegendItem/SVGLegendItem.css"],"names":[],"mappings":"AAAA,6BACE,iBAAmB,CACnB,kCACF,CAEA,4BACE,8BACF,CAEA,gCACE,gCACF,CAEA,iCACE,iCACF","sourcesContent":[".label {\n  font-size: 0.865rem;\n  transform: translate(0.125rem, 0.8rem);\n}\n\n.name {\n  transform: translateY(-0.125rem);\n}\n\n.groupOdd {\n  fill: var(--plot-group-odd-color);\n}\n\n.groupEven {\n  fill: var(--plot-group-even-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "SVGLegendItem__label__RinP4",
	"name": "SVGLegendItem__name__2qmKd",
	"groupOdd": "SVGLegendItem__groupOdd__Qot7i",
	"groupEven": "SVGLegendItem__groupEven__1YTJw"
};
export default ___CSS_LOADER_EXPORT___;
