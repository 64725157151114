// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchField__controls__C1e_B{flex:0 0 auto;position:relative}.SearchField__controls__C1e_B,.SearchField__input__28GEP{height:100%;width:100%}.SearchField__input__28GEP{border:1px solid var(--placeholder-color);border-radius:4px;padding-left:1.875rem}.SearchField__input__28GEP:focus{border:1px solid var(--border-active-color)}.SearchField__input__28GEP::placeholder{color:var(--placeholder-color)}.SearchField__input__28GEP:focus::placeholder{color:#0000}.SearchField__icon__1J1Wn{cursor:pointer;position:absolute;top:50%;transform:translate(20%,-50%)}.SearchField__iconReset__1b29s{fill:var(--close-icon-color);height:.75rem;right:1.25rem;width:.75rem}.SearchField__iconReset__1b29s:hover{fill:var(--active-color)}.SearchField__hideIconReset__2qqsV{display:none}", "",{"version":3,"sources":["webpack://./shared/src/components/SearchField/SearchField.css"],"names":[],"mappings":"AAAA,8BACE,aAAc,CACd,iBAGF,CAEA,yDAHE,WAAY,CADZ,UAUF,CANA,2BAKE,yCAA0C,CAD1C,iBAAkB,CADlB,qBAGF,CAEA,iCACE,2CACF,CAEA,wCACE,8BACF,CAEA,8CACE,WACF,CAEA,0BAGE,cAAe,CAFf,iBAAkB,CAClB,OAAQ,CAER,6BACF,CAEA,+BAIE,4BAA6B,CAD7B,aAAe,CAFf,aAAc,CACd,YAOF,CAHE,qCACE,wBACF,CAGF,mCACE,YACF","sourcesContent":[".controls {\n  flex: 0 0 auto;\n  position: relative;\n  width: 100%;\n  height: 100%;\n}\n\n.input {\n  width: 100%;\n  height: 100%;\n  padding-left: 1.875rem;\n  border-radius: 4px;\n  border: 1px solid var(--placeholder-color);\n}\n\n.input:focus {\n  border: 1px solid var(--border-active-color);\n}\n\n.input::placeholder {\n  color: var(--placeholder-color);\n}\n\n.input:focus::placeholder {\n  color: transparent;\n}\n\n.icon {\n  position: absolute;\n  top: 50%;\n  cursor: pointer;\n  transform: translate(20%, -50%);\n}\n\n.iconReset {\n  right: 1.25rem;\n  width: 0.75rem;\n  height: 0.75rem;\n  fill: var(--close-icon-color);\n\n  &:hover {\n    fill: var(--active-color);\n  }\n}\n\n.hideIconReset {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"controls": "SearchField__controls__C1e_B",
	"input": "SearchField__input__28GEP",
	"icon": "SearchField__icon__1J1Wn",
	"iconReset": "SearchField__iconReset__1b29s",
	"hideIconReset": "SearchField__hideIconReset__2qqsV"
};
export default ___CSS_LOADER_EXPORT___;
