import { ETooltipPlacementDirection, ETooltipPlacement } from '../../consts';
/**
 * Graph object representing possible transitions between different
 * placement types depending on applied direction
 *
 * Basically, describes typed weighted oriented graph
 *
 * @todo Optimize with math-based approach using dedicated classes
 */
const REPLACEMENT_GRAPH = Object.freeze({
    [ETooltipPlacement.TopLeft]: {
        [ETooltipPlacementDirection.Top]: [],
        [ETooltipPlacementDirection.TopRight]: [],
        [ETooltipPlacementDirection.Right]: [
            ETooltipPlacement.TopCenter,
        ],
        [ETooltipPlacementDirection.BottomRight]: [
            ETooltipPlacement.RightCenter,
        ],
        [ETooltipPlacementDirection.Bottom]: [
            ETooltipPlacement.LeftCenter,
        ],
        [ETooltipPlacementDirection.BottomLeft]: [
            ETooltipPlacement.LeftCenter,
        ],
        [ETooltipPlacementDirection.Left]: [
            ETooltipPlacement.LeftCenter,
        ],
        [ETooltipPlacementDirection.TopLeft]: [],
    },
    [ETooltipPlacement.TopCenter]: {
        [ETooltipPlacementDirection.Top]: [],
        [ETooltipPlacementDirection.TopRight]: [],
        [ETooltipPlacementDirection.Right]: [
            ETooltipPlacement.RightCenter,
            ETooltipPlacement.TopRight,
            ETooltipPlacement.RightTop,
            ETooltipPlacement.RightBottom,
            ETooltipPlacement.BottomRight,
        ],
        [ETooltipPlacementDirection.BottomRight]: [
            ETooltipPlacement.RightCenter,
            ETooltipPlacement.RightTop,
            ETooltipPlacement.RightBottom,
            ETooltipPlacement.BottomRight,
        ],
        [ETooltipPlacementDirection.Bottom]: [
            ETooltipPlacement.BottomCenter,
            ETooltipPlacement.LeftCenter,
            ETooltipPlacement.RightCenter,
            ETooltipPlacement.LeftTop,
            ETooltipPlacement.RightTop,
            ETooltipPlacement.LeftBottom,
            ETooltipPlacement.RightBottom,
            ETooltipPlacement.BottomLeft,
            ETooltipPlacement.BottomRight,
        ],
        [ETooltipPlacementDirection.BottomLeft]: [
            ETooltipPlacement.LeftCenter,
            ETooltipPlacement.LeftTop,
            ETooltipPlacement.LeftBottom,
            ETooltipPlacement.BottomLeft,
        ],
        [ETooltipPlacementDirection.Left]: [
            ETooltipPlacement.LeftCenter,
            ETooltipPlacement.TopLeft,
            ETooltipPlacement.LeftTop,
            ETooltipPlacement.LeftBottom,
            ETooltipPlacement.BottomLeft,
        ],
        [ETooltipPlacementDirection.TopLeft]: [],
    },
    [ETooltipPlacement.TopRight]: {
        [ETooltipPlacementDirection.Top]: [],
        [ETooltipPlacementDirection.TopRight]: [],
        [ETooltipPlacementDirection.Right]: [
            ETooltipPlacement.RightCenter,
        ],
        [ETooltipPlacementDirection.BottomRight]: [
            ETooltipPlacement.RightCenter,
        ],
        [ETooltipPlacementDirection.Bottom]: [
            ETooltipPlacement.RightCenter,
        ],
        [ETooltipPlacementDirection.BottomLeft]: [
            ETooltipPlacement.BottomCenter,
        ],
        [ETooltipPlacementDirection.Left]: [
            ETooltipPlacement.TopCenter,
        ],
        [ETooltipPlacementDirection.TopLeft]: [],
    },
    [ETooltipPlacement.RightTop]: {
        [ETooltipPlacementDirection.Top]: [
            ETooltipPlacement.TopCenter,
        ],
        [ETooltipPlacementDirection.TopRight]: [],
        [ETooltipPlacementDirection.Right]: [],
        [ETooltipPlacementDirection.BottomRight]: [],
        [ETooltipPlacementDirection.Bottom]: [
            ETooltipPlacement.RightBottom,
        ],
        [ETooltipPlacementDirection.BottomLeft]: [
            ETooltipPlacement.BottomCenter,
        ],
        [ETooltipPlacementDirection.Left]: [
            ETooltipPlacement.TopCenter,
        ],
        [ETooltipPlacementDirection.TopLeft]: [
            ETooltipPlacement.TopCenter,
        ],
    },
    [ETooltipPlacement.RightCenter]: {
        [ETooltipPlacementDirection.Top]: [
            ETooltipPlacement.TopCenter,
            ETooltipPlacement.RightTop,
            ETooltipPlacement.TopRight,
            ETooltipPlacement.TopLeft,
            ETooltipPlacement.LeftTop,
        ],
        [ETooltipPlacementDirection.TopRight]: [],
        [ETooltipPlacementDirection.Right]: [],
        [ETooltipPlacementDirection.BottomRight]: [],
        [ETooltipPlacementDirection.Bottom]: [
            ETooltipPlacement.BottomCenter,
            ETooltipPlacement.RightBottom,
            ETooltipPlacement.BottomRight,
            ETooltipPlacement.BottomLeft,
            ETooltipPlacement.LeftBottom,
        ],
        [ETooltipPlacementDirection.BottomLeft]: [
            ETooltipPlacement.BottomCenter,
            ETooltipPlacement.BottomRight,
            ETooltipPlacement.BottomLeft,
            ETooltipPlacement.LeftBottom,
        ],
        [ETooltipPlacementDirection.Left]: [
            ETooltipPlacement.TopCenter,
            ETooltipPlacement.BottomCenter,
            ETooltipPlacement.LeftCenter,
            ETooltipPlacement.TopRight,
            ETooltipPlacement.BottomRight,
            ETooltipPlacement.TopLeft,
            ETooltipPlacement.BottomLeft,
            ETooltipPlacement.LeftTop,
            ETooltipPlacement.LeftBottom,
        ],
        [ETooltipPlacementDirection.TopLeft]: [
            ETooltipPlacement.TopCenter,
            ETooltipPlacement.TopRight,
            ETooltipPlacement.TopLeft,
            ETooltipPlacement.LeftTop,
        ],
    },
    [ETooltipPlacement.RightBottom]: {
        [ETooltipPlacementDirection.Top]: [
            ETooltipPlacement.RightCenter,
        ],
        [ETooltipPlacementDirection.TopRight]: [],
        [ETooltipPlacementDirection.Right]: [],
        [ETooltipPlacementDirection.BottomRight]: [],
        [ETooltipPlacementDirection.Bottom]: [
            ETooltipPlacement.BottomCenter,
        ],
        [ETooltipPlacementDirection.BottomLeft]: [
            ETooltipPlacement.BottomCenter,
        ],
        [ETooltipPlacementDirection.Left]: [
            ETooltipPlacement.BottomCenter,
        ],
        [ETooltipPlacementDirection.TopLeft]: [
            ETooltipPlacement.TopCenter,
        ],
    },
    [ETooltipPlacement.BottomRight]: {
        [ETooltipPlacementDirection.Top]: [
            ETooltipPlacement.RightCenter,
        ],
        [ETooltipPlacementDirection.TopRight]: [
            ETooltipPlacement.RightCenter,
        ],
        [ETooltipPlacementDirection.Right]: [
            ETooltipPlacement.RightCenter,
        ],
        [ETooltipPlacementDirection.BottomRight]: [],
        [ETooltipPlacementDirection.Bottom]: [],
        [ETooltipPlacementDirection.BottomLeft]: [],
        [ETooltipPlacementDirection.Left]: [
            ETooltipPlacement.BottomCenter,
        ],
        [ETooltipPlacementDirection.TopLeft]: [
            ETooltipPlacement.LeftCenter,
        ],
    },
    [ETooltipPlacement.BottomCenter]: {
        [ETooltipPlacementDirection.Top]: [
            ETooltipPlacement.LeftCenter,
            ETooltipPlacement.RightCenter,
            ETooltipPlacement.TopCenter,
            ETooltipPlacement.LeftBottom,
            ETooltipPlacement.RightBottom,
            ETooltipPlacement.LeftTop,
            ETooltipPlacement.RightTop,
            ETooltipPlacement.TopLeft,
            ETooltipPlacement.TopRight,
        ],
        [ETooltipPlacementDirection.TopRight]: [
            ETooltipPlacement.RightCenter,
            ETooltipPlacement.RightBottom,
            ETooltipPlacement.RightTop,
            ETooltipPlacement.TopRight,
        ],
        [ETooltipPlacementDirection.Right]: [
            ETooltipPlacement.RightCenter,
            ETooltipPlacement.BottomRight,
            ETooltipPlacement.RightBottom,
            ETooltipPlacement.RightTop,
            ETooltipPlacement.TopRight,
        ],
        [ETooltipPlacementDirection.BottomRight]: [],
        [ETooltipPlacementDirection.Bottom]: [],
        [ETooltipPlacementDirection.BottomLeft]: [],
        [ETooltipPlacementDirection.Left]: [
            ETooltipPlacement.LeftCenter,
            ETooltipPlacement.BottomLeft,
            ETooltipPlacement.LeftBottom,
            ETooltipPlacement.LeftTop,
            ETooltipPlacement.TopLeft,
        ],
        [ETooltipPlacementDirection.TopLeft]: [
            ETooltipPlacement.LeftCenter,
            ETooltipPlacement.LeftBottom,
            ETooltipPlacement.LeftTop,
            ETooltipPlacement.TopLeft,
        ],
    },
    [ETooltipPlacement.BottomLeft]: {
        [ETooltipPlacementDirection.Top]: [
            ETooltipPlacement.LeftCenter,
        ],
        [ETooltipPlacementDirection.TopRight]: [
            ETooltipPlacement.RightCenter,
        ],
        [ETooltipPlacementDirection.Right]: [
            ETooltipPlacement.BottomCenter,
        ],
        [ETooltipPlacementDirection.BottomRight]: [],
        [ETooltipPlacementDirection.Bottom]: [],
        [ETooltipPlacementDirection.BottomLeft]: [],
        [ETooltipPlacementDirection.Left]: [
            ETooltipPlacement.LeftCenter,
        ],
        [ETooltipPlacementDirection.TopLeft]: [
            ETooltipPlacement.LeftCenter,
        ],
    },
    [ETooltipPlacement.LeftBottom]: {
        [ETooltipPlacementDirection.Top]: [
            ETooltipPlacement.LeftCenter,
        ],
        [ETooltipPlacementDirection.TopRight]: [
            ETooltipPlacement.TopCenter,
        ],
        [ETooltipPlacementDirection.Right]: [
            ETooltipPlacement.BottomCenter,
        ],
        [ETooltipPlacementDirection.BottomRight]: [
            ETooltipPlacement.BottomCenter,
        ],
        [ETooltipPlacementDirection.Bottom]: [
            ETooltipPlacement.BottomCenter,
        ],
        [ETooltipPlacementDirection.BottomLeft]: [],
        [ETooltipPlacementDirection.Left]: [],
        [ETooltipPlacementDirection.TopLeft]: [],
    },
    [ETooltipPlacement.LeftCenter]: {
        [ETooltipPlacementDirection.Top]: [
            ETooltipPlacement.TopCenter,
            ETooltipPlacement.LeftTop,
            ETooltipPlacement.TopLeft,
            ETooltipPlacement.TopRight,
            ETooltipPlacement.RightTop,
        ],
        [ETooltipPlacementDirection.TopRight]: [
            ETooltipPlacement.TopCenter,
            ETooltipPlacement.TopLeft,
            ETooltipPlacement.TopRight,
            ETooltipPlacement.RightTop,
        ],
        [ETooltipPlacementDirection.Right]: [
            ETooltipPlacement.TopCenter,
            ETooltipPlacement.BottomCenter,
            ETooltipPlacement.RightCenter,
            ETooltipPlacement.TopLeft,
            ETooltipPlacement.TopRight,
            ETooltipPlacement.RightTop,
            ETooltipPlacement.RightBottom,
            ETooltipPlacement.BottomRight,
            ETooltipPlacement.BottomLeft,
        ],
        [ETooltipPlacementDirection.BottomRight]: [
            ETooltipPlacement.BottomCenter,
            ETooltipPlacement.BottomLeft,
            ETooltipPlacement.BottomRight,
            ETooltipPlacement.RightBottom,
        ],
        [ETooltipPlacementDirection.Bottom]: [
            ETooltipPlacement.BottomCenter,
            ETooltipPlacement.LeftBottom,
            ETooltipPlacement.BottomLeft,
            ETooltipPlacement.BottomRight,
            ETooltipPlacement.RightBottom,
        ],
        [ETooltipPlacementDirection.BottomLeft]: [],
        [ETooltipPlacementDirection.Left]: [],
        [ETooltipPlacementDirection.TopLeft]: [],
    },
    [ETooltipPlacement.LeftTop]: {
        [ETooltipPlacementDirection.Top]: [
            ETooltipPlacement.TopCenter,
        ],
        [ETooltipPlacementDirection.TopRight]: [
            ETooltipPlacement.TopCenter,
        ],
        [ETooltipPlacementDirection.Right]: [
            ETooltipPlacement.TopCenter,
        ],
        [ETooltipPlacementDirection.BottomRight]: [
            ETooltipPlacement.BottomCenter,
        ],
        [ETooltipPlacementDirection.Bottom]: [
            ETooltipPlacement.LeftCenter,
        ],
        [ETooltipPlacementDirection.BottomLeft]: [],
        [ETooltipPlacementDirection.Left]: [],
        [ETooltipPlacementDirection.TopLeft]: [],
    },
});
/**
 * Gets replacement depending on the given placement type and direction
 *
 * @param placement - Given placement to replace
 * @param replacementDirection - Direction of placement to replace
 * @param [excludedPlacements] - Placements which should not be considered as possible replacements
 * @returns Replacement
 */
export function getReplacement(placement, replacementDirection, excludedPlacements = new Set()) {
    const oppositePlacements = REPLACEMENT_GRAPH[placement][replacementDirection]
        .filter((placementCandidate) => !excludedPlacements.has(placementCandidate));
    if (oppositePlacements.length === 0) {
        throw new Error(`Replacement is not defined for the following placement type and direction: ${placement}, ${replacementDirection}`);
    }
    return oppositePlacements[0];
}
