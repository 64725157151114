// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LollipopPlotDomain__oddDomain__1QMIG{fill:var(--lollipop-plot-odd-domain-color)}.LollipopPlotDomain__oddDomainLabel__HA-jM{fill:var(--lollipop-plot-odd-domain-label-color);font-size:.875rem;transform:translate(-.3rem,.3rem)}.LollipopPlotDomain__evenDomain__oe2u1{fill:var(--lollipop-plot-even-domain-color)}.LollipopPlotDomain__evenDomainLabel__pcjsa{fill:var(--lollipop-plot-even-domain-label-color);font-size:.875rem;transform:translate(-.3rem,.3rem)}", "",{"version":3,"sources":["webpack://./shared/src/plots/components/LollipopPlot/components/LollipopPlotDomain/LollipopPlotDomain.css"],"names":[],"mappings":"AAAA,sCACE,0CACF,CAEA,2CACE,gDAAiD,CACjD,iBAAmB,CACnB,iCACF,CAEA,uCACE,2CACF,CAEA,4CACE,iDAAkD,CAClD,iBAAmB,CACnB,iCACF","sourcesContent":[".oddDomain {\n  fill: var(--lollipop-plot-odd-domain-color);\n}\n\n.oddDomainLabel {\n  fill: var(--lollipop-plot-odd-domain-label-color);\n  font-size: 0.875rem;\n  transform: translate(-0.3rem, 0.3rem);\n}\n\n.evenDomain {\n  fill: var(--lollipop-plot-even-domain-color);\n}\n\n.evenDomainLabel {\n  fill: var(--lollipop-plot-even-domain-label-color);\n  font-size: 0.875rem;\n  transform: translate(-0.3rem, 0.3rem);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"oddDomain": "LollipopPlotDomain__oddDomain__1QMIG",
	"oddDomainLabel": "LollipopPlotDomain__oddDomainLabel__HA-jM",
	"evenDomain": "LollipopPlotDomain__evenDomain__oe2u1",
	"evenDomainLabel": "LollipopPlotDomain__evenDomainLabel__pcjsa"
};
export default ___CSS_LOADER_EXPORT___;
