// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SyntheticScrollY__container__1LDx6{overflow:auto;position:absolute;width:1rem;z-index:var(--base-z-index)}.SyntheticScrollY__content__58hNM{width:6px}.SyntheticScrollY__custom__OkNzM::-webkit-scrollbar{height:6px;width:6px}.SyntheticScrollY__custom__OkNzM::-webkit-scrollbar-track{background:var(--scrollbar-track-color);border-radius:6px;overflow:auto}.SyntheticScrollY__custom__OkNzM::-webkit-scrollbar-thumb{background:var(--scrollbar-thumb-color);border-radius:8px}", "",{"version":3,"sources":["webpack://./shared/src/plots/components/common/components/SyntheticScrollY/SyntheticScrollY.css"],"names":[],"mappings":"AAAA,oCAEE,aAAc,CACd,iBAAkB,CAFlB,UAAW,CAGX,2BACF,CAEA,kCACE,SACF,CAEA,oDAEE,UAAW,CADX,SAEF,CAEA,0DAEE,uCAAwC,CACxC,iBAAkB,CAFlB,aAGF,CAEA,0DACE,uCAAwC,CACxC,iBACF","sourcesContent":[".container {\n  width: 1rem;\n  overflow: auto;\n  position: absolute;\n  z-index: var(--base-z-index);\n}\n\n.content {\n  width: 6px;\n}\n\n.custom::-webkit-scrollbar {\n  width: 6px;\n  height: 6px;\n}\n\n.custom::-webkit-scrollbar-track {\n  overflow: auto;\n  background: var(--scrollbar-track-color);\n  border-radius: 6px;\n}\n\n.custom::-webkit-scrollbar-thumb {\n  background: var(--scrollbar-thumb-color);\n  border-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SyntheticScrollY__container__1LDx6",
	"content": "SyntheticScrollY__content__58hNM",
	"custom": "SyntheticScrollY__custom__OkNzM"
};
export default ___CSS_LOADER_EXPORT___;
