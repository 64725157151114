import { DEFAULT_BORDER_WIDTH } from '../../consts';
export const inlineStyles = {
    defaultLine: {
        stroke: '#cad7e1',
        strokeWidth: DEFAULT_BORDER_WIDTH,
    },
    dashedLine: {
        stroke: '#335f83',
        strokeWidth: 2,
        strokeDasharray: '6px 8px',
        strokeDashoffset: '-4px',
    },
};
