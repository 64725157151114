import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, useMemo, forwardRef, cloneElement, } from 'react';
import classnames from 'classnames';
import { useResizeScreen, useScrolledIndicator } from '../../hooks';
import { TableSettings } from '../TableSettings/TableSettings';
import styles from './TableLayout.css';
const TableLayoutBase = (props, ref) => {
    const { containerRef, columns, children, pagination, onMouseOver, onMouseOut, hasStickyHeader, stickyIndicator, scrollThreshold = 0, scrollTargetRef, tableHeaderRef, withColumnNumbers = true, hasConfig, onConfigChange, onConfigUnHide, onConfigReset, } = props;
    const fixedColumnsWidth = useMemo(() => columns
        .filter(({ fixed = false }) => fixed)
        .reduce((acc, item) => { var _a; return acc + ((_a = item.width) !== null && _a !== void 0 ? _a : 0); }, 0), [columns]);
    const { fixedHeaderRef, isScrolled, handleScroll, } = useScrolledIndicator();
    const { scrollWidth } = useResizeScreen(containerRef, tableHeaderRef);
    const layoutClassName = classnames(styles.layout, {
        [styles.topScrollUp]: hasStickyHeader,
    });
    const fixedAreaClassName = classnames(styles.fixed, {
        [styles.shadowVertical]: isScrolled,
        [styles.addTopPadding]: hasStickyHeader,
    });
    const fixedTopWrapperClassName = classnames(styles.fixedTopWrapper, {
        [styles.addTopPadding]: hasStickyHeader,
    });
    const fixedAreaTopClassName = classnames(styles.fixedTop, {
        [styles.fixedTopHigh]: withColumnNumbers,
        [styles.fixedTopLow]: !withColumnNumbers,
        [styles.shadowVertical]: isScrolled,
        [styles.addTopMargin]: hasStickyHeader,
    });
    const stickyAreaTopClassName = classnames(styles.stickyTop, {
        [styles.stickyTopHigh]: withColumnNumbers,
        [styles.stickyTopLow]: !withColumnNumbers,
        [styles.shadowHorizontal]: stickyIndicator,
    });
    const bottomModule = (_jsxs("div", Object.assign({ className: styles.paginationContainer }, { children: [pagination, hasConfig && (_jsx(TableSettings, { columns: columns, onConfigChange: onConfigChange, onConfigUnHide: onConfigUnHide, onConfigReset: onConfigReset }, void 0))] }), void 0));
    return (_jsxs("div", Object.assign({ ref: ref, onMouseOver: onMouseOver, onMouseOut: onMouseOut, className: layoutClassName }, { children: [fixedColumnsWidth > 0 && (_jsx("div", Object.assign({ className: fixedAreaClassName, style: {
                    width: fixedColumnsWidth,
                }, "data-testid": "fixed-area-left" }, { children: children }), void 0)), hasStickyHeader && (_jsxs("div", Object.assign({ "data-testid": "fixed-area-top-wrap", className: fixedTopWrapperClassName, style: {
                    top: `${scrollThreshold}px`,
                } }, { children: [_jsx("div", Object.assign({ "data-testid": "sticky-area-top", ref: fixedHeaderRef, className: stickyAreaTopClassName, style: {
                            top: `${scrollThreshold}px`,
                            maxWidth: `${scrollWidth + 1}px`,
                        } }, { children: _jsx("div", Object.assign({ "data-testid": "sticky-area-top-inner", style: {
                                width: `${scrollWidth + 1}px`,
                                height: `${scrollThreshold}px`,
                            } }, { children: cloneElement(children, { onlyHeader: true }) }), void 0) }), void 0), fixedColumnsWidth > 0 && (_jsx("div", Object.assign({ className: fixedAreaTopClassName, style: {
                            width: fixedColumnsWidth,
                        }, "data-testid": "fixed-area-top" }, { children: cloneElement(children, { onlyHeader: true }) }), void 0))] }), void 0)), hasStickyHeader ? (_jsxs("div", Object.assign({ ref: scrollTargetRef, className: classnames({
                    [styles.mainTableStickyWrapperHigh]: withColumnNumbers,
                    [styles.mainTableStickyWrapperLow]: !withColumnNumbers,
                }), "data-testid": "table" }, { children: [cloneElement(children, { handleScroll }), bottomModule] }), void 0)) : (_jsxs(_Fragment, { children: [cloneElement(children, { handleScroll }), bottomModule] }, void 0))] }), void 0));
};
export const TableLayout = memo(forwardRef(TableLayoutBase));
