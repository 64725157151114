// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DisplayValues__item__17JU1{display:flex;flex-direction:column;justify-content:center;text-align:center}.DisplayValues__value__3S_1o{font-size:3.5rem}.DisplayValues__tag__K4lVG{font-size:1rem}", "",{"version":3,"sources":["webpack://./shared/src/components/CountdownTimer/components/DisplayValues/DisplayValues.css"],"names":[],"mappings":"AAAA,4BACE,YAAa,CACb,qBAAsB,CACtB,sBAAuB,CACvB,iBACF,CAEA,6BACE,gBACF,CAEA,2BACE,cACF","sourcesContent":[".item {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  text-align: center;\n}\n\n.value {\n  font-size: 3.5rem;\n}\n\n.tag {\n  font-size: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "DisplayValues__item__17JU1",
	"value": "DisplayValues__value__3S_1o",
	"tag": "DisplayValues__tag__K4lVG"
};
export default ___CSS_LOADER_EXPORT___;
