// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Label__label__166fe{font-size:.875rem;font-weight:400;line-height:1.125rem;margin-left:.25rem}.Label__icon__6X4rq{line-height:1rem}", "",{"version":3,"sources":["webpack://./shared/src/components/FilterByDirectionOfEffect/components/Label/Label.css"],"names":[],"mappings":"AAAA,qBAEE,iBAAmB,CADnB,eAAgB,CAEhB,oBAAqB,CACrB,kBACF,CAEA,oBACE,gBACF","sourcesContent":[".label {\n  font-weight: 400;\n  font-size: 0.875rem;\n  line-height: 1.125rem;\n  margin-left: 0.25rem;\n}\n\n.icon {\n  line-height: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "Label__label__166fe",
	"icon": "Label__icon__6X4rq"
};
export default ___CSS_LOADER_EXPORT___;
