// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LollipopPlotDomains__border__Uur0L{fill:var(--border-color)}", "",{"version":3,"sources":["webpack://./shared/src/plots/components/LollipopPlot/components/LollipopPlotDomains/LollipopPlotDomains.css"],"names":[],"mappings":"AAAA,oCACE,wBACF","sourcesContent":[".border {\n  fill: var(--border-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"border": "LollipopPlotDomains__border__Uur0L"
};
export default ___CSS_LOADER_EXPORT___;
