import { logger } from '../..';
import { DEFAULT_NUMBER_FRACTION_DIGITS } from '../../../consts';
/**
 * Formats value as a numeric percent
 *
 * @param props - The properties for percentFormatter.
 * @param props.value - Formatted number or string value
 * @param props.defaultFractionDigits [2]- The default usable number of digits
 */
export const percentFormatter = ({ value, defaultFractionDigits = DEFAULT_NUMBER_FRACTION_DIGITS, }) => {
    if (value === null) {
        return null;
    }
    if (value === '') {
        logger.warn('Percent formatter can not format an empty strings!');
        return null;
    }
    const valueNumber = (typeof value === 'string')
        ? parseFloat(value)
        : value;
    if (typeof valueNumber !== 'number' || Number.isNaN(valueNumber)) {
        const valueToReturn = Array.isArray(value) ? '' : `${value} `;
        logger.warn(`Value ${valueToReturn}for formatting is invalid. Percent formatter can not format such kind of values!`);
        return null;
    }
    const formattedNumber = valueNumber.toLocaleString('en-GB', {
        style: 'percent',
        minimumFractionDigits: defaultFractionDigits,
        maximumFractionDigits: defaultFractionDigits,
    });
    return formattedNumber;
};
