import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { HighlightedLabel } from './components/HighlightedLabel/HighlightedLabel';
import { escapeRegExp } from '../../utils/escapeRegExp/escapeRegExp';
export const HighlightedText = memo(function HighlightedText({ text, pattern }) {
    const patternTrim = pattern.trim();
    if (!patternTrim)
        return _jsx(_Fragment, { children: text }, void 0);
    const searchPattern = escapeRegExp(patternTrim);
    const splitText = text
        .split(new RegExp(`(${searchPattern})`, 'gim'))
        .map((substr, index) => {
        const key = `${substr}-${index}`;
        return (substr.toLowerCase() === patternTrim.toLowerCase()
            ? _jsx(HighlightedLabel, { value: substr }, key)
            : substr);
    });
    return _jsx(_Fragment, { children: splitText }, void 0);
});
