import { useLayoutEffect, useState, } from 'react';
const useHeaderWidthArray = (tableHeaderRef, 
// eslint-disable-next-line @typescript-eslint/no-explicit-any
page) => {
    const [headerWidthArray, setHeaderWidthArray] = useState([]);
    useLayoutEffect(() => {
        var _a, _b, _c;
        if ((_b = (_a = tableHeaderRef.current) === null || _a === void 0 ? void 0 : _a.lastElementChild) === null || _b === void 0 ? void 0 : _b.children.length) {
            setHeaderWidthArray(Array.from((_c = tableHeaderRef.current) === null || _c === void 0 ? void 0 : _c.lastElementChild.children)
                .map((child) => { var _a; return ((_a = child.getClientRects()[0]) === null || _a === void 0 ? void 0 : _a.width) || 0; }));
        }
    }, [page, tableHeaderRef]);
    return headerWidthArray;
};
export default useHeaderWidthArray;
