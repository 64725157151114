import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import { DEFAULT_PLOT_HEIGHT, DATA_POINT_STEP, PLOT_MIN_WIDTH, DEFAULT_UPPER_DATA_LIMIT, DEFAULT_LOWER_DATA_LIMIT, } from './consts';
import { useDimensions } from './hooks';
import ManhattanPlotAxis from './components/ManhattanPlotAxis/ManhattanPlotAxis';
import ManhattanPlotData from './components/ManhattanPlotData/ManhattanPlotData';
import ManhattanPlotAxisScaleLines from './components/ManhattanPlotAxisScaleLines/ManhattanPlotAxisScaleLines';
import ManhattanPlotAxisScaleLabels from './components/ManhattanPlotAxisScaleLabels/ManhattanPlotAxisScaleLabels';
import ManhattanPlotThresholds from './components/ManhattanPlotThresholds/ManhattanPlotThresholds';
import ManhattanPlotThresholdsArea from './components/ManhattanPlotThresholdsArea/ManhattanPlotThresholdsArea';
import ManhattanPlotGroupsLabels from './components/ManhattanPlotGroupsLabels/ManhattanPlotGroupsLabels';
import { importInlineFont } from '../../../utils/importInlineFont/importInlineFont';
import fonts from '../../../assets/fonts/inline.json';
import inlineStyles from './ManhattanPlot.style';
const ManhattanPlot = (props) => {
    const { axis, data, lowerThreshold, upperThreshold, upperDataLimit = DEFAULT_UPPER_DATA_LIMIT, lowerDataLimit = DEFAULT_LOWER_DATA_LIMIT, dataPointStep = DATA_POINT_STEP, height = DEFAULT_PLOT_HEIGHT, useInlineStyles, withChunkedData = false, counters, getTooltipContent, getDataPointShape, renderGroupBorder, legend, } = props;
    const thresholds = useMemo(() => ([
        lowerThreshold,
        upperThreshold,
    ]), [lowerThreshold, upperThreshold]);
    const dataLimits = useMemo(() => ([
        lowerDataLimit,
        upperDataLimit,
    ]), [lowerDataLimit, upperDataLimit]);
    const { SVGHeight, SVGWidth, scale, scaleRange, width, groupsDimensions, } = useDimensions({
        data,
        height,
        containerHeight: height,
        thresholds,
        limits: dataLimits,
        dataPointStep,
        counters,
        minWidth: PLOT_MIN_WIDTH,
    });
    return (_jsxs("svg", Object.assign({ height: SVGHeight, width: SVGWidth, viewBox: `0 0 ${SVGWidth} ${SVGHeight}`, xmlns: "http://www.w3.org/2000/svg" }, useInlineStyles && {
        style: inlineStyles.plot,
    }, { children: [useInlineStyles && (_jsx("defs", { children: _jsxs("style", Object.assign({ type: "text/css" }, { children: [importInlineFont(fonts.Roboto500), importInlineFont(fonts.Roboto400)] }), void 0) }, void 0)), _jsx(ManhattanPlotGroupsLabels, { data: data, height: height, groupsDimensions: groupsDimensions, renderGroupBorder: renderGroupBorder, forExport: true }, void 0), _jsx(ManhattanPlotThresholdsArea, { scale: scale, width: width, height: height, useInlineStyles: useInlineStyles }, void 0), _jsx(ManhattanPlotAxisScaleLines, { scale: scale, width: width, useInlineStyles: useInlineStyles }, void 0), _jsx(ManhattanPlotData, { data: data, height: height, dataPointStep: dataPointStep, scaleRange: scaleRange, dataLimits: dataLimits, getTooltipContent: getTooltipContent, groupsDimensions: groupsDimensions, useInlineStyles: useInlineStyles, withChunkedData: withChunkedData, getDataPointShape: getDataPointShape }, void 0), _jsx(ManhattanPlotAxisScaleLabels, { scale: scale, useInlineStyles: useInlineStyles, forExport: true }, void 0), _jsx(ManhattanPlotThresholds, { scale: scale, width: width, useInlineStyles: useInlineStyles }, void 0), _jsx(ManhattanPlotAxis, { axis: axis, height: height, width: width, useInlineStyles: useInlineStyles, legend: legend }, void 0)] }), void 0));
};
/** Since memo loses generic props */
export default memo(ManhattanPlot);
