/**
 * Returns date and time in ISO format without separators
 *
 * @param withoutYear slices the year part from the date
 * @returns date in ISO format
 */
export const getISODateTime = (withoutYear = false) => {
    const ISODate = new Date().toISOString();
    return withoutYear ? `${ISODate.replace(/[-:.]/g, '').slice(4, -4)}Z` : ISODate.replace(/-|:/g, '');
};
