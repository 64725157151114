// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Delimiter__delimiter__1XBoA{display:flex;flex-direction:column;font-size:2rem;justify-content:center;margin:0 1rem;padding-bottom:1rem}", "",{"version":3,"sources":["webpack://./shared/src/components/CountdownTimer/components/Delimiter/Delimiter.css"],"names":[],"mappings":"AAAA,6BAEE,YAAa,CACb,qBAAsB,CAEtB,cAAe,CADf,sBAAuB,CAHvB,aAAc,CAKd,mBACF","sourcesContent":[".delimiter {\n  margin: 0 1rem;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  font-size: 2rem;\n  padding-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"delimiter": "Delimiter__delimiter__1XBoA"
};
export default ___CSS_LOADER_EXPORT___;
