import { range } from '../../../../../utils';
export const calculateScaleValues = (maxScaleValue, tickInterval, roundLastValue = true) => {
    if (tickInterval <= 0 || maxScaleValue <= 0) {
        return [];
    }
    const numberOfTicks = Math.ceil(maxScaleValue / tickInterval) || 1;
    const updatedMaxScaleValue = numberOfTicks * tickInterval;
    return range(0, updatedMaxScaleValue, tickInterval)
        .concat(roundLastValue ? updatedMaxScaleValue : maxScaleValue);
};
