// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PlotSettings__wrapper__7ixSZ{border-bottom:1px solid var(--content-shadow-color);display:flex;margin-bottom:.8rem;padding:0 80px 1.5rem}", "",{"version":3,"sources":["webpack://./shared/src/plots/components/ManhattanPlot/components/PlotSettings/PlotSettings.css"],"names":[],"mappings":"AAAA,8BAIE,mDAAoD,CAHpD,YAAa,CAEb,mBAAqB,CADrB,qBAGF","sourcesContent":[".wrapper {\n  display: flex;\n  padding: 0 80px 1.5rem;\n  margin-bottom: 0.8rem;\n  border-bottom: 1px solid var(--content-shadow-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "PlotSettings__wrapper__7ixSZ"
};
export default ___CSS_LOADER_EXPORT___;
