import { convertPValueToNumber } from '../convertPValueToNumber/convertPValueToNumber';
import { logger } from '../..';
import { DEFAULT_NUMBER_FRACTION_DIGITS, MAX_NUMERIC_P_VALUE } from '../../../consts';
import { formatToExponential } from '../formatToExponential';
/**
 * Formats value as a pValue
 *
 * @param props - The properties for pValueFormatter.
 * @param props.value - Formatted number or string value
 * @param props.defaultFractionDigits [2] - The default usable number of digits
 * @param props.minFractionDigits [0] - The minimum number of symbols
 */
export const pValueFormatter = ({ value, defaultFractionDigits = DEFAULT_NUMBER_FRACTION_DIGITS, minFractionDigits = 0, minScientificValueThreshold = MAX_NUMERIC_P_VALUE, }) => {
    if (value === null) {
        return null;
    }
    if (value === '') {
        logger.warn('PValue formatter can not format an empty strings!');
        return null;
    }
    const valueNumber = (typeof value === 'string')
        ? convertPValueToNumber(value)
        : value;
    if (typeof valueNumber !== 'number' || Number.isNaN(valueNumber)) {
        const valueToReturn = Array.isArray(value) ? '' : `${value} `;
        logger.warn(`Value ${valueToReturn}for formatting is invalid. PValue formatter can not format such kind of values!`);
        return null;
    }
    if (valueNumber === 0) {
        return '0';
    }
    const formattedNumber = valueNumber < minScientificValueThreshold
        ? formatToExponential(valueNumber, defaultFractionDigits)
        : valueNumber.toLocaleString('en-GB', {
            minimumFractionDigits: minFractionDigits,
            maximumFractionDigits: defaultFractionDigits,
        });
    return formattedNumber;
};
