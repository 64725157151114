/**
 * Custom common props for every column in the table
 *
 * @param column - Metadata used to render columns
 * @param presettedtValue - presetted value
 * @returns object - Shape of props to pass them to react component
 */
export default function getColumnProps(column, presettedtValue) {
    var _a, _b;
    const { width, maxWidth, minWidth } = column;
    return {
        style: {
            width: width !== null && width !== void 0 ? width : presettedtValue,
            minWidth: (_a = minWidth !== null && minWidth !== void 0 ? minWidth : width) !== null && _a !== void 0 ? _a : presettedtValue,
            maxWidth: (_b = maxWidth !== null && maxWidth !== void 0 ? maxWidth : width) !== null && _b !== void 0 ? _b : presettedtValue,
        },
    };
}
