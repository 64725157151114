import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import classnames from 'classnames';
import { ESize } from './consts';
import styles from './SkeletonItem.css';
export const SkeletonItem = memo(function SkeletonItem({ size, count = false, className, }) {
    const countClassName = classnames(styles.loader, styles.count);
    const classes = {
        [ESize.S]: classnames(styles.loader, styles.childSecond),
        [ESize.M]: classnames(styles.loader, styles.childFirst),
        [ESize.L]: classnames(styles.loader, styles.label),
    };
    const stringClassName = classnames(styles.string, className);
    return (_jsxs("div", Object.assign({ className: stringClassName }, { children: [_jsx("div", { className: classes[size] }, void 0), count && _jsx("div", { className: countClassName }, void 0)] }), void 0));
});
