import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { memo, useCallback, useEffect, useMemo, useRef, } from 'react';
import classNames from 'classnames';
import styles from './SyntheticScrollY.css';
export const SyntheticScrollY = memo(function SyntheticScrollY(props) {
    const { containerHeight, contentHeight, contentRef, yAxisRef, customStyled, marginTop = 0, marginBottom = 0, rightShift = 0, } = props;
    const syntheticScrollRef = useRef(null);
    const calculatedContainerHeight = (containerHeight - marginTop - marginBottom) > 0
        ? containerHeight - marginTop - marginBottom
        : 0;
    const ratio = containerHeight > 0 ? calculatedContainerHeight / containerHeight : 1;
    const calculatedContentHeight = contentHeight * ratio;
    const containerStyle = useMemo(() => ({
        height: calculatedContainerHeight,
        top: marginTop,
        right: rightShift,
    }), [calculatedContainerHeight, marginTop, rightShift]);
    const contentStyle = useMemo(() => ({
        height: calculatedContentHeight,
    }), [calculatedContentHeight]);
    const handleScroll = useCallback((e) => {
        var _a;
        const { scrollTop } = e.target;
        const scrollValue = Math.round(scrollTop / ratio);
        const isChanged = ((_a = contentRef.current) === null || _a === void 0 ? void 0 : _a.scrollTop) !== scrollValue;
        if (contentRef.current && isChanged) {
            contentRef.current.scrollTop = scrollValue;
        }
    }, [contentRef, ratio]);
    const onContentScroll = useCallback(() => {
        var _a, _b;
        const isChanged = contentRef.current && (Math.round(((_a = contentRef.current) === null || _a === void 0 ? void 0 : _a.scrollTop) * ratio) !== ((_b = syntheticScrollRef.current) === null || _b === void 0 ? void 0 : _b.scrollTop));
        if (contentRef.current && syntheticScrollRef.current && isChanged && yAxisRef.current) {
            const { scrollTop } = contentRef.current;
            const scrollValue = Math.round(scrollTop * ratio);
            contentRef.current.style.transform = '';
            yAxisRef.current.style.transform = '';
            syntheticScrollRef.current.scrollTop = scrollValue;
        }
    }, [contentRef, yAxisRef, ratio]);
    useEffect(() => {
        const ref = contentRef.current;
        if (ref) {
            ref.addEventListener('scroll', onContentScroll);
        }
        return () => {
            ref === null || ref === void 0 ? void 0 : ref.removeEventListener('scroll', onContentScroll);
        };
    }, [contentRef, onContentScroll]);
    const className = classNames(styles.container, {
        [styles.custom]: customStyled,
    });
    return (_jsx("div", Object.assign({ className: className, style: containerStyle, onScroll: handleScroll, ref: syntheticScrollRef }, { children: _jsx("div", { className: styles.content, style: contentStyle }, void 0) }), void 0));
});
