/**
 * Converts value to 10 ** value amd then
 * formats value to stringified format with specific number of fraction digits
 *
 * with 1 fraction digit for values < 1 or > -1 and
 * without fraction digits for values > 1 or < -1
 * returns an empty string for rounded to 1 and 0 values, because such labels
 * should be unique
 *
 * @param value - Formatted number
 * @returns Formatted number string
 */
export const getScaledYLabel = (value) => {
    const actualValue = Math.pow(10, value);
    if (actualValue === 1 || actualValue === -1 || actualValue === 0) {
        return actualValue.toLocaleString('en-GB');
    }
    const options = Math.abs(actualValue) < 1
        ? { maximumFractionDigits: 2 }
        : { maximumFractionDigits: 1 };
    const stringifiedValue = actualValue.toLocaleString('en-GB', options);
    if (stringifiedValue === '0' || stringifiedValue === '1' || stringifiedValue === '-0') {
        return '';
    }
    return stringifiedValue;
};
