/**
 * Calculates position of the scale tick on axis
 *
 * @param value - Tick value
 * @param maxTickValue - Max tick value
 * @param scaleLength - Total length of scale
 * @returns Position of the tick value on axis
 */
export default function getPositionOnAxis(value, maxTickValue, scaleLength) {
    if (maxTickValue === 0) {
        return 0;
    }
    return (value / maxTickValue) * scaleLength;
}
