// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".search__layout__2BNlf{max-width:45rem}.search__box__1gnJD{box-shadow:0 11px 25px 0 #1d2a441f}.search__field__3Brp9{border-radius:4px;height:72px}.search__input__3qqqS{font-size:1rem;line-height:1.1875rem;padding:0 3.3rem}.search__iconSearch__3C8jl{left:1.5rem}.search__highlighted__2N-zE,.search__selected__1D1Lm{background-color:#0000000d}", "",{"version":3,"sources":["webpack://./shared/src/components/SearchBoxField/styles/search.css"],"names":[],"mappings":"AAAA,uBACE,eACF,CAEA,oBACE,kCACF,CAEA,sBAEE,iBAAkB,CADlB,WAEF,CAEA,sBAEE,cAAe,CACf,qBAAsB,CAFtB,gBAGF,CAEA,2BACE,WACF,CAMA,qDACE,0BACF","sourcesContent":[".layout {\n  max-width: 45rem;\n}\n\n.box {\n  box-shadow: 0 11px 25px 0 rgba(29, 42, 68, 0.12);\n}\n\n.field {\n  height: 72px;\n  border-radius: 4px;\n}\n\n.input {\n  padding: 0 3.3rem;\n  font-size: 1rem;\n  line-height: 1.1875rem;\n}\n\n.iconSearch {\n  left: 1.5rem;\n}\n\n.selected {\n  background-color: rgba(0, 0, 0, 0.05);\n}\n\n.highlighted {\n  background-color: rgba(0, 0, 0, 0.05);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": "search__layout__2BNlf",
	"box": "search__box__1gnJD",
	"field": "search__field__3Brp9",
	"input": "search__input__3qqqS",
	"iconSearch": "search__iconSearch__3C8jl",
	"highlighted": "search__highlighted__2N-zE",
	"selected": "search__selected__1D1Lm"
};
export default ___CSS_LOADER_EXPORT___;
