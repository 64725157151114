// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BackButton__btnBack__2_hhd{background-color:var(--table-row-hover-background);border:none;border-radius:.25rem;color:var(--font-color);cursor:pointer;display:flex;font-size:.875rem;height:1.5rem;line-height:1rem;max-width:100%;outline:none;overflow:hidden;padding:5px 10px 5px 5px;white-space:nowrap}.BackButton__arrowLeft__3ExFV{fill:var(--font-color);align-self:baseline;height:.75rem;margin-right:2px;min-width:.75rem;transform:rotate(90deg);width:.75rem}.BackButton__wrapper__2a9f4{display:inline;overflow:hidden;text-overflow:ellipsis}", "",{"version":3,"sources":["webpack://./shared/src/components/BackButton/BackButton.css"],"names":[],"mappings":"AAAA,4BASE,kDAAmD,CACnD,WAAY,CAFZ,oBAAsB,CAHtB,uBAAwB,CAHxB,cAAe,CADf,YAAa,CAKb,iBAAmB,CAHnB,aAAc,CAId,gBAAiB,CAOjB,cAAe,CAHf,YAAa,CACb,eAAgB,CARhB,wBAAyB,CASzB,kBAEF,CAEA,8BAKE,sBAAuB,CAEvB,mBAAoB,CAJpB,aAAe,CAGf,gBAAiB,CAJjB,gBAAkB,CAElB,uBAAwB,CAHxB,YAOF,CAEA,4BACE,cAAe,CACf,eAAgB,CAChB,sBACF","sourcesContent":[".btnBack {\n  display: flex;\n  cursor: pointer;\n  height: 1.5rem;\n  padding: 5px 10px 5px 5px;\n  color: var(--font-color);\n  font-size: 0.875rem;\n  line-height: 1rem;\n  border-radius: 0.25rem;\n  background-color: var(--table-row-hover-background);\n  border: none;\n  outline: none;\n  overflow: hidden;\n  white-space: nowrap;\n  max-width: 100%;\n}\n\n.arrowLeft {\n  width: 0.75rem;\n  min-width: 0.75rem;\n  height: 0.75rem;\n  transform: rotate(90deg);\n  fill: var(--font-color);\n  margin-right: 2px;\n  align-self: baseline;\n}\n\n.wrapper {\n  display: inline;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnBack": "BackButton__btnBack__2_hhd",
	"arrowLeft": "BackButton__arrowLeft__3ExFV",
	"wrapper": "BackButton__wrapper__2a9f4"
};
export default ___CSS_LOADER_EXPORT___;
