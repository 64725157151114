const inlineStyles = {
    line: {
        stroke: '#242b36',
        strokeWidth: '1px',
    },
    lineInactive: {
        stroke: '#e4eaf0',
        strokeWidth: '1px',
    },
};
export default inlineStyles;
