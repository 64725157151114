import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import classnames from 'classnames';
import { Button } from '../Button';
import { EIconType } from './consts';
import styles from './ExportButton.css';
import { ExportButtonIcon } from './components/ExportButtonIcon';
export const ExportButton = memo(function ExportButton(props) {
    const { onClick, isLoading = false, disabled = false, tooltipTitle, spinnerClassName, } = props;
    const btnClassNames = classnames(styles.exportBtn, {
        [styles.buttonDisabled]: isLoading || disabled,
    });
    const iconType = useMemo(() => {
        if (isLoading) {
            return EIconType.IsLoading;
        }
        if (disabled) {
            return EIconType.Disabled;
        }
        return EIconType.InfoTooltip;
    }, [isLoading, disabled]);
    return (_jsxs(Button, Object.assign({ className: btnClassNames, onClick: onClick, disabled: isLoading || disabled }, { children: ["Export", _jsx(ExportButtonIcon, { iconType: iconType, title: tooltipTitle, spinnerClassName: spinnerClassName }, void 0)] }), void 0));
});
