import { logger } from '../../../../../utils';
import getTextWidth from '../../../../utils/getTextWidth';
import { DEFAULT_GROUP_LABEL_FONT_SIZE, LEFT_AXIS_SCALE_WIDTH, DEFAULT_GROUP_LABEL_ANGLE, PLOT_LEFT_PADDING, DEFAULT_GROUP_LABEL_FONT, } from '../../consts';
import { calculateGroupLabelLength } from '../calculateGroupLabelLength/calculateGroupLabelLength';
/**
 * Calculates min font size which will let to fit the longest group label
 * to the left most position on the plot
 *
 * In such case, it's guaranteed that the longest label won't be partially hiden
 * on any filter applied or any position scrolled
 *
 * @param data - Plot's data
 * @param labelAngle - Rotation angle of the group label
 * @returns Group label min font size
 */
export function calculateGroupLabelFontSize(data, labelAngle = DEFAULT_GROUP_LABEL_ANGLE) {
    const MAX_ALLOWED_LENGTH = (PLOT_LEFT_PADDING
        + LEFT_AXIS_SCALE_WIDTH);
    const maxGroupLabelLength = calculateGroupLabelLength(data
        .getGroups()
        .map(({ groupLabel }) => (getTextWidth(groupLabel, DEFAULT_GROUP_LABEL_FONT)))
        .reduce((accMaxLength, labelLength) => (Math.max(accMaxLength, labelLength)), 0), DEFAULT_GROUP_LABEL_FONT_SIZE, labelAngle);
    const angleCos = Math.cos(DEFAULT_GROUP_LABEL_ANGLE);
    if (angleCos === 0) {
        logger.warn('Cos of the given angle equals 0: replacing with default font size');
        return DEFAULT_GROUP_LABEL_FONT_SIZE;
    }
    const fontFactor = Math.min(MAX_ALLOWED_LENGTH / (maxGroupLabelLength * angleCos), 1);
    return DEFAULT_GROUP_LABEL_FONT_SIZE * fontFactor;
}
