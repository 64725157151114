import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback, useMemo, } from 'react';
import ImpactDescriptionSVG from '../../../ImpactDescriptionsSVG';
import { DEFAULT_PLOT_HEIGHT, DEFAULT_PLOT_WIDTH, } from '../../consts';
import { useDimensions } from '../../hooks';
import { LollipopVariantType, } from '../../types';
import { getPositionOnAxis, getScale } from '../../utils';
import LollipopPlotAxisY from '../LollipopPlotAxisY';
import LollipopPlotAxisX from '../LollipopPlotAxisX';
import LollipopPlotDomains from '../LollipopPlotDomains';
import LollipopPlotData from '../LollipopPlotData';
import { inlineStyles } from './LollipopPlotSVG.style';
import { importInlineFont } from '../../../../../utils/importInlineFont/importInlineFont';
import fonts from '../../../../../assets/fonts/inline.json';
const LollipopPlotSVG = memo(function LollipopPlotSVG(props) {
    const { axis = {
        x: { label: 'Amino acids' },
        y: { label: 'Minor allele count' },
    }, data, height = DEFAULT_PLOT_HEIGHT, width = DEFAULT_PLOT_WIDTH, maxTickValues, getTooltipContent, useInlineStyles, } = props;
    const { scaleXLength, scaleYLength, axisXPositionX, axisXPositionY, axisYPositionX, axisYPositionY, maxTickValueX, maxTickValueY, tickIntervalX, tickIntervalY, } = useDimensions(data, height, width, maxTickValues);
    const getPositionOnAxisX = useCallback((value) => (getPositionOnAxis(value, maxTickValueX, scaleXLength)), [maxTickValueX, scaleXLength]);
    const getPositionOnAxisY = useCallback((value) => (getPositionOnAxis(value, maxTickValueY, scaleYLength)), [maxTickValueY, scaleYLength]);
    const scaleX = useMemo(() => getScale(tickIntervalX, scaleXLength, maxTickValueX), [
        tickIntervalX,
        scaleXLength,
        maxTickValueX,
    ]);
    const scaleY = useMemo(() => getScale(tickIntervalY, scaleYLength, maxTickValueY, true), [
        tickIntervalY,
        scaleYLength,
        maxTickValueY,
    ]);
    const plotStyle = useInlineStyles
        ? inlineStyles.plot
        : undefined;
    return (_jsxs("svg", Object.assign({ height: height, width: width, viewBox: `0 0 ${width} ${height}`, style: plotStyle, xmlns: "http://www.w3.org/2000/svg", "data-testid": "LollipopPlotSVG" }, { children: [useInlineStyles && (_jsx("style", Object.assign({ type: "text/css" }, { children: importInlineFont(fonts.Roboto500) }), void 0)), _jsx(LollipopPlotAxisX, { data: axis === null || axis === void 0 ? void 0 : axis.x, scale: scaleX, scaleLength: scaleXLength, positionX: axisXPositionX, positionY: axisXPositionY, useInlineStyles: useInlineStyles }, void 0), _jsx(LollipopPlotAxisY, { data: axis === null || axis === void 0 ? void 0 : axis.y, scale: scaleY, scaleLength: scaleYLength, positionX: axisYPositionX, positionY: axisYPositionY, useInlineStyles: useInlineStyles }, void 0), data.variants.inactive && (_jsx(LollipopPlotData, { type: LollipopVariantType.Inactive, data: data.variants.inactive, axisXPositionY: axisXPositionY, axisYPositionX: axisYPositionX, getPositionOnAxisX: getPositionOnAxisX, getPositionOnAxisY: getPositionOnAxisY, useInlineStyles: useInlineStyles }, void 0)), _jsx(LollipopPlotData, { data: data.variants.active, axisXPositionY: axisXPositionY, axisYPositionX: axisYPositionX, getPositionOnAxisX: getPositionOnAxisX, getPositionOnAxisY: getPositionOnAxisY, getTooltipContent: getTooltipContent, useInlineStyles: useInlineStyles }, void 0), _jsx(LollipopPlotDomains, { data: data.domains, scaleXLength: scaleXLength, axisXPositionX: axisXPositionX, axisXPositionY: axisXPositionY, getPositionOnAxisX: getPositionOnAxisX, useInlineStyles: useInlineStyles }, void 0), useInlineStyles && _jsx(ImpactDescriptionSVG, {}, void 0)] }), void 0));
});
export default LollipopPlotSVG;
