import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useMemo, useCallback, } from 'react';
import { useTooltip } from '../../../../hooks';
import { DOMAINS_HEIGHT, DOMAINS_BORDER_PADDING, DOMAINS_BORDER_HEIGHT, Y_AXIS_BORDER_WIDTH, X_AXIS_BORDER_WIDTH, X_CANVAS_PADDING, DOMAIN_BLOCK_HEIGHT, TOOLTIP_DOMAINS_ID, } from '../../consts';
import LollipopPlotDomainGroup from '../LollipopPlotDomainGroup';
import LollipopPlotTooltip from '../LollipopPlotTooltip';
import styles from './LollipopPlotDomains.css';
import inlineStyles from './LollipopPlotDomains.style';
const LollipopPlotDomains = memo(function LollipopPlotDomains(props) {
    const { data, axisXPositionX, axisXPositionY, scaleXLength, getPositionOnAxisX, useInlineStyles, } = props;
    const { tooltipId, tooltipAPI } = useTooltip(TOOLTIP_DOMAINS_ID);
    const { handleTooltipShowWithDelay: handleTooltipShow, handleTooltipHideWithDelay: handleTooltipHide, } = tooltipAPI;
    const borderStyle = useInlineStyles ? inlineStyles.border : undefined;
    const x = axisXPositionX;
    const y = axisXPositionY - X_AXIS_BORDER_WIDTH / 2 - DOMAINS_HEIGHT + DOMAINS_BORDER_PADDING;
    const height = DOMAINS_BORDER_HEIGHT;
    // Plot width could be 0 on initial render
    // Hence, calculated width might become negative
    const width = Math.max(scaleXLength + X_CANVAS_PADDING + Y_AXIS_BORDER_WIDTH + 1, // Additional pixel for the last tick
    0);
    const domainPositionX = axisXPositionX + Y_AXIS_BORDER_WIDTH + X_CANVAS_PADDING;
    const domainPositionY = y + DOMAINS_BORDER_HEIGHT / 2 - DOMAIN_BLOCK_HEIGHT / 2;
    const domains = useMemo(() => data.map((domain, index) => (_jsx(LollipopPlotDomainGroup, { index: index, data: domain, handleTooltipShow: handleTooltipShow, handleTooltipHide: handleTooltipHide, positionX: domainPositionX, positionY: domainPositionY, getPositionOnAxisX: getPositionOnAxisX, useInlineStyles: useInlineStyles }, domain.groupId))), [
        data,
        domainPositionX,
        domainPositionY,
        getPositionOnAxisX,
        handleTooltipHide,
        handleTooltipShow,
        useInlineStyles,
    ]);
    const getTooltipContent = useCallback((domainName) => domainName, []);
    return (_jsxs(_Fragment, { children: [
            // Indicates the exported version
            !useInlineStyles && (_jsx(LollipopPlotTooltip, { tooltipId: tooltipId, tooltipContent: getTooltipContent, tooltipAPI: tooltipAPI }, void 0)), _jsx("rect", { className: styles.border, style: borderStyle, x: x, y: y, height: height, width: width }, void 0), domains] }, void 0));
});
export default LollipopPlotDomains;
