/**
 * Returns a string of import font
 *
 * @param props - the properties for import font
 * @param props.fontFamily - fontFamily property
 * @param props.fontWeight - fontWeight property
 * @param props.base64 - base64 property
 * @returns String of import font
 */
export const importInlineFont = ({ fontFamily, fontWeight = 400, base64, }) => (`@font-face{font-family:"${fontFamily}";src:url(data:application/font-woff;charset=utf-8;base64,${base64}) format("woff"); font-weight:${fontWeight};}`);
