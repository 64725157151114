import { cropFileName } from './cropFileName';
import { escapeFileName } from './escapeFileName';
import { getFileNameExtension } from './getFileNameExtension';
import { FILENAME_LENGTH_LIMIT } from './consts';
const getLength = (str) => (str ? str.length : 0);
/**
 * Construction fileName from three part that will be cropped to 255 characters
 * and replacement all of reserved characters.
 *
 * @param prefix - First part of file name
 * @param str - Main part of file name that will be crop
 * @param suffix - End part of file name with extension
 * @returns Constructed file name
 */
export const constructFileName = ({ prefix, str, suffix, }, limit = FILENAME_LENGTH_LIMIT) => {
    const fileNamePrefix = prefix ? `${prefix}_` : '';
    const fileNameSuffix = suffix ? `_${suffix}` : '';
    const cropFileNameLength = limit - (getLength(fileNamePrefix) + getLength(fileNameSuffix));
    let fileName = (cropFileNameLength > 0) ? cropFileName(str, cropFileNameLength) : str;
    if (fileNamePrefix) {
        fileName = `${fileNamePrefix}${fileName}`;
    }
    if (fileNameSuffix) {
        fileName = `${fileName}${fileNameSuffix}`;
    }
    if (cropFileNameLength > 0) {
        return escapeFileName(fileName);
    }
    const fileNameExtension = getFileNameExtension(fileName);
    if (!fileNameExtension) {
        const croppedFileName = cropFileName(fileName, limit);
        return escapeFileName(croppedFileName);
    }
    const fileNameWithoutExtension = fileName.length - (getLength(fileNameExtension) + 1);
    fileName = `${fileName.substring(0, fileNameWithoutExtension)}.${fileNameExtension}`;
    const croppedFileName = cropFileName(fileName, limit - (fileNameExtension.length + 1));
    return escapeFileName(`${croppedFileName}.${fileNameExtension}`);
};
