import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { DEFAULT_BORDER_WIDTH } from '../../../consts';
const QQPlotReferenceLine = ({ dataZoneRect, maxAxisScales, }) => {
    const [maxValueX, maxValueY] = maxAxisScales;
    let x2 = dataZoneRect.x + dataZoneRect.width;
    let y2 = dataZoneRect.y;
    if (maxValueX !== 0 && maxValueX > maxValueY) {
        x2 = dataZoneRect.x + dataZoneRect.width * (maxValueY / maxValueX);
    }
    if (maxValueY !== 0 && maxValueY > maxValueX) {
        y2 = dataZoneRect.y + dataZoneRect.height * (1 - maxValueX / maxValueY);
    }
    return (_jsx("line", { x1: dataZoneRect.x, y1: dataZoneRect.y + dataZoneRect.height, x2: x2, y2: y2, style: {
            stroke: '#cad7e1',
            strokeWidth: DEFAULT_BORDER_WIDTH,
        } }, void 0));
};
export default memo(QQPlotReferenceLine);
