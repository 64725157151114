import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, memo, useRef, useMemo, } from 'react';
import classnames from 'classnames';
import SearchIcon from '../../assets/icons/search.svg';
import ResetIcon from '../../assets/icons/tagClose.svg';
import { TextField, EInputTextType } from '../TextField';
import styles from './SearchField.css';
export const SearchField = memo(function SearchField(props) {
    const { value, disabled = false, placeholder = '', onChange, onReset, customBaseClassName, customInputClassName, customIconSearchClassName, customIconResetClassName, onBlur, onFocus, onKeyDown, } = props;
    const searchInput = useRef(null);
    const resetIconClassName = useMemo(() => (classnames(styles.icon, styles.iconReset, customIconResetClassName, {
        [styles.hideIconReset]: value.length === 0 || disabled,
    })), [customIconResetClassName, disabled, value.length]);
    const handleChange = useCallback(({ target: { value: inputValue } }) => {
        onChange(inputValue);
    }, [onChange]);
    const handleClickResetIcon = useCallback((event) => {
        event.stopPropagation();
        onChange('');
        if (onReset) {
            onReset();
        }
        if (searchInput === null || searchInput === void 0 ? void 0 : searchInput.current) {
            searchInput.current.focus();
        }
    }, [onChange, onReset, searchInput]);
    return (_jsxs("div", Object.assign({ className: classnames(styles.controls, customBaseClassName) }, { children: [_jsx(SearchIcon, { className: classnames(styles.icon, customIconSearchClassName) }, void 0), _jsx(TextField, { className: classnames(styles.input, customInputClassName), type: EInputTextType.Search, value: value, onChange: handleChange, onKeyDown: onKeyDown, onBlur: onBlur, "aria-label": placeholder, disabled: disabled, placeholder: placeholder, onFocus: onFocus, ref: searchInput, "data-testid": "search" }, void 0), _jsx(ResetIcon, { "data-testid": "resetIcon", className: resetIconClassName, onClick: handleClickResetIcon }, void 0)] }), void 0));
});
