import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback } from 'react';
import ErrorImage from '../../assets/images/404.svg';
import DefaultContactText from './components/DefaultContactText';
import styles from './ErrorPage.css';
export const ErrorPage = memo(function ErrorPage({ title, message, showContactText = true, contactText, }) {
    const renderContactText = useCallback(() => {
        if (!showContactText) {
            return null;
        }
        if (contactText) {
            return contactText;
        }
        return _jsx(DefaultContactText, {}, void 0);
    }, [showContactText, contactText]);
    return (_jsx("article", Object.assign({ className: styles.layout }, { children: _jsxs("main", Object.assign({ className: styles.content }, { children: [_jsx("p", Object.assign({ className: styles.statusCode }, { children: title }), void 0), _jsx("h1", Object.assign({ className: styles.primaryMessage, "data-testid": "ErrorPage.primaryMessage" }, { children: message }), void 0), renderContactText(), _jsx(ErrorImage, { className: styles.errorImage }, void 0)] }), void 0) }), void 0));
});
