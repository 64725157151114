/**
 * Calculates percentile of the given rank for sorted list of values
 * This function **does not** sort the given values, therefore they should be sorted in advance
 *
 * @param values - Sorted list of values
 * @param rank - Rank of percentile (number between 0 and 1 not inclusively)
 * @return Calculated percentile
 */
export const getPercentile = (values, rank) => {
    if (Number.isNaN(rank) || rank < 0 || rank > 1) {
        throw Error(`Invalid percentile rank: ${rank}`);
    }
    if (values.length === 0) {
        return 0;
    }
    let pos;
    if (rank <= 1 / (values.length + 1)) {
        pos = 0;
    }
    else if (rank < values.length / (values.length + 1)) {
        pos = rank * (values.length + 1) - 1;
    }
    else {
        pos = values.length - 1;
    }
    const index = Math.floor(pos);
    const frac = pos - index;
    return values[index] + ((frac > 0) ? frac * (values[index + 1] - values[index]) : 0);
};
