import { useEffect, useRef, useState, } from 'react';
const useVerticalScrollIndicator = (targetPosition) => {
    const scrollTargetRef = useRef(null);
    const [stickyIndicator, setIndicator] = useState(false);
    useEffect(() => {
        const handler = () => {
            if (scrollTargetRef.current) {
                setIndicator(scrollTargetRef.current.getBoundingClientRect().y < targetPosition);
            }
        };
        window.addEventListener('scroll', handler);
        return () => window.removeEventListener('scroll', handler);
    }, [scrollTargetRef, targetPosition]);
    return {
        stickyIndicator,
        scrollTargetRef,
    };
};
export default useVerticalScrollIndicator;
