// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AutocompleteField__container__Yafwq{position:relative}.AutocompleteField__iconSearch__GrPSu{left:.2rem}.AutocompleteField__searchSuggestionsContainer__2QY1i{background-color:var(--content-background-color);border-radius:4px;box-shadow:0 .75rem 1.625rem 0 var(--box-shadow-color);left:0;margin:0;max-height:21.25rem;overflow-y:auto;padding:0;position:absolute;top:100%;width:100%;z-index:var(--base-z-index)}.AutocompleteField__searchSuggestion__1LZ1I{align-items:center;display:flex;font-size:.875rem;justify-content:space-between;list-style:none;padding:.5rem 1rem}.AutocompleteField__searchSuggestion__1LZ1I:hover{background-color:var(--dropdown-hover);cursor:pointer}", "",{"version":3,"sources":["webpack://./shared/src/components/AutocompleteField/AutocompleteField.css"],"names":[],"mappings":"AAAA,qCACE,iBACF,CAEA,sCACE,UACF,CAEA,sDAKE,gDAAiD,CAGjD,iBAAkB,CAClB,sDAAwD,CANxD,MAAO,CAGP,QAAS,CAIT,mBAAoB,CAEpB,eAAgB,CALhB,SAAU,CANV,iBAAkB,CAClB,QAAS,CAET,UAAW,CAOX,2BAEF,CAEA,4CAME,kBAAmB,CAFnB,YAAa,CADb,iBAAmB,CAEnB,6BAA8B,CAJ9B,eAAgB,CAChB,kBAUF,CAJE,kDACE,sCAAuC,CACvC,cACF","sourcesContent":[".container {\n  position: relative;\n}\n\n.iconSearch {\n  left: 0.2rem;\n}\n\n.searchSuggestionsContainer {\n  position: absolute;\n  top: 100%;\n  left: 0;\n  width: 100%;\n  background-color: var(--content-background-color);\n  margin: 0;\n  padding: 0;\n  border-radius: 4px;\n  box-shadow: 0 0.75rem 1.625rem 0 var(--box-shadow-color);\n  max-height: 21.25rem;\n  z-index: var(--base-z-index);\n  overflow-y: auto;\n}\n\n.searchSuggestion {\n  list-style: none;\n  padding: 0.5rem 1rem;\n  font-size: 0.875rem;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n\n  &:hover {\n    background-color: var(--dropdown-hover);\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "AutocompleteField__container__Yafwq",
	"iconSearch": "AutocompleteField__iconSearch__GrPSu",
	"searchSuggestionsContainer": "AutocompleteField__searchSuggestionsContainer__2QY1i",
	"searchSuggestion": "AutocompleteField__searchSuggestion__1LZ1I"
};
export default ___CSS_LOADER_EXPORT___;
