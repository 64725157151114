import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { AXIS_LABEL_FONT_SIZE, DEFAULT_SCALE_LABEL_WIDTH, DEFAULT_SCALE_LETTER_WIDTH, THRESHOLD_AXIS_RECT_HEIGHT, THRESHOLD_LABEL_BORDER_RADIUS, THRESHOLD_LABEL_ICON_SPACE, } from '../../consts';
import ManhattanPlotThresholdTooltip from '../ManhattanPlotThresholdTooltip/ManhattanPlotThresholdTooltip';
import styles from './ManhattanPlotThresholdLabel.css';
import inlineStyles from './ManhattanPlotThresholdLabel.style';
const ICON_TRANSLATE_Y_SHIFT = 6;
const ManhattanPlotThresholdLabel = (props) => {
    const { x, y, label, width = DEFAULT_SCALE_LABEL_WIDTH, description, useInlineStyles, } = props;
    return (_jsxs("g", { children: [_jsx("rect", { style: inlineStyles.thresholdRect, x: x - DEFAULT_SCALE_LETTER_WIDTH / 2, y: y - THRESHOLD_AXIS_RECT_HEIGHT / 2, rx: THRESHOLD_LABEL_BORDER_RADIUS, ry: THRESHOLD_LABEL_BORDER_RADIUS, height: THRESHOLD_AXIS_RECT_HEIGHT, width: width + THRESHOLD_LABEL_ICON_SPACE + DEFAULT_SCALE_LETTER_WIDTH / 2 }, void 0), _jsx("text", Object.assign({ style: inlineStyles.thresholdLabel, x: x, y: y + AXIS_LABEL_FONT_SIZE / 2, textAnchor: "start" }, { children: label }), void 0), description && (_jsx(ManhattanPlotThresholdTooltip, Object.assign({ description: description }, { children: _jsxs("g", Object.assign({ className: styles.thresholdIcon, transform: `translate(4, ${y - ICON_TRANSLATE_Y_SHIFT}) scale(0.7)`, style: Object.assign({ transform: `translate(4px, ${y - ICON_TRANSLATE_Y_SHIFT}px) scale(0.7)` }, useInlineStyles && inlineStyles.thresholdIcon) }, { children: [_jsx("circle", { className: styles.thresholdPlaceholder, transform: "translate(9, 9)", x: x, y: y, r: 10 }, void 0), _jsx("path", { d: "M9,0 C13.9705627,0 18,4.02943725 18,9 C18,13.9705627 13.9705627,18 9,18 C4.02943725,18 0,13.9705627 0,9 C0,4.02943725 4.02943725,0 9,0 Z M9,1.5 C4.85786438,1.5 1.5,4.85786438 1.5,9 C1.5,13.1421356 4.85786438,16.5 9,16.5 C13.1421356,16.5 16.5,13.1421356 16.5,9 C16.5,4.85786438 13.1421356,1.5 9,1.5 Z M9,7 C9.55228475,7 10,7.44771525 10,8 L10,13 C10,13.5522847 9.55228475,14 9,14 C8.44771525,14 8,13.5522847 8,13 L8,8 C8,7.44771525 8.44771525,7 9,7 Z M9,4 C9.55228475,4 10,4.44771525 10,5 C10,5.55228475 9.55228475,6 9,6 C8.44771525,6 8,5.55228475 8,5 C8,4.44771525 8.44771525,4 9,4 Z", id: "Combined-Shape" }, void 0)] }), void 0) }), void 0))] }, void 0));
};
export default memo(ManhattanPlotThresholdLabel);
