// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ScaleLabelsX__axisHiding__57rU0{fill:var(--content-background-color)}.ScaleLabelsX__axisXLabels__3YZRj{pointer-events:none;position:absolute;z-index:var(--base-z-index)}", "",{"version":3,"sources":["webpack://./shared/src/plots/components/ScatterPlots/components/ScaleLabelsX/ScaleLabelsX.css"],"names":[],"mappings":"AAAA,iCACE,oCACF,CAEA,kCAEE,mBAAoB,CADpB,iBAAkB,CAElB,2BACF","sourcesContent":[".axisHiding {\n  fill: var(--content-background-color);\n}\n\n.axisXLabels {\n  position: absolute;\n  pointer-events: none;\n  z-index: var(--base-z-index);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"axisHiding": "ScaleLabelsX__axisHiding__57rU0",
	"axisXLabels": "ScaleLabelsX__axisXLabels__3YZRj"
};
export default ___CSS_LOADER_EXPORT___;
