import { DEFAULT_NUMBER_FRACTION_DIGITS, DEFAULT_SCIENTIFIC_THRESHOLD_VALUE } from '../../../consts';
import { logger } from '../..';
import { formatToExponential } from '../formatToExponential';
/**
 * Formats value to scientific format or to a number
 *
 * @param props - The properties for scientificNumberFormatter.
 * @param props.value - Formatted number or string value
 * @param props.scientificValueThreshold [1000] - Value of threshold for formatting to scientific
 * format
 * @param props.minFractionDigits [2] - The min usable number of digits
 * of the fractional part of a number
 * @param props.maxFractionDigits [2] - The max usable number of digits
 * of the fractional part of a number
 * @param props.minScientificValueThreshold [-Infinity] - Min value of threshold for formatting
 * to scientific format
 * @param props.useGrouping [true] - Boolean value to enable/disable grouping of digits
 * @returns stringified scientific format of number or number
 */
export const scientificNumberFormatter = ({ value, scientificValueThreshold = DEFAULT_SCIENTIFIC_THRESHOLD_VALUE, minFractionDigits = DEFAULT_NUMBER_FRACTION_DIGITS, maxFractionDigits = DEFAULT_NUMBER_FRACTION_DIGITS, minScientificValueThreshold = -Infinity, useGrouping = true, }) => {
    if (value === null) {
        return null;
    }
    if (value === '') {
        logger.warn('Scientific number formatter can not format an empty strings!');
        return null;
    }
    const valueNumber = (typeof value === 'string')
        ? parseFloat(value)
        : value;
    if (typeof valueNumber !== 'number' || Number.isNaN(valueNumber)) {
        const valueToReturn = Array.isArray(value) ? '' : `${value} `;
        logger.warn(`Value ${valueToReturn}for formatting is invalid. Scientific number formatter can not format such kind of values!`);
        return null;
    }
    const isForExponential = valueNumber
        && (Math.abs(valueNumber) > scientificValueThreshold
            || Math.abs(valueNumber) < minScientificValueThreshold);
    const formattedNumber = isForExponential
        ? formatToExponential(valueNumber, maxFractionDigits)
        : valueNumber.toLocaleString('en-GB', {
            minimumFractionDigits: minFractionDigits,
            maximumFractionDigits: maxFractionDigits,
            useGrouping,
        });
    return formattedNumber;
};
