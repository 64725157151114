import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import SpliceIcon from '../../../assets/icons/arrowDown.svg';
import { EImpactLevel } from '../../types';
import { DEFAULT_PLOT_HEIGHT } from '../LollipopPlot/consts';
import ImpactItem from './ImpactItem';
// eslint-disable-next-line import/no-named-as-default
import inlineStyles from './ImpactDescriptionsSVG.style';
const DEFAULT_SVG_WIDTH = 400;
const ImpactDescriptionSVG = (props) => {
    const { height = DEFAULT_PLOT_HEIGHT, width = DEFAULT_SVG_WIDTH } = props;
    return (_jsxs("svg", Object.assign({ height: height, width: width, viewBox: `-${width} -14 ${width} ${height}`, style: inlineStyles.impactDescription, xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx(ImpactItem, Object.assign({ id: "splice_variants", icon: SpliceIcon }, { children: "splice variants" }), void 0), _jsx(ImpactItem, Object.assign({ id: "high_impact", impactLevel: EImpactLevel.High, leftPadding: 107 }, { children: "high impact variants" }), void 0), _jsx(ImpactItem, Object.assign({ id: "moderate_impact", impactLevel: EImpactLevel.Moderate, leftPadding: 248 }, { children: "moderate impact variants" }), void 0), _jsx(ImpactItem, Object.assign({ id: "low_impact", impactLevel: EImpactLevel.Low, leftPadding: 415 }, { children: "low impact variants" }), void 0)] }), void 0));
};
export default memo(ImpactDescriptionSVG);
