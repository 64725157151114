import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import classNames from 'classnames';
import { CheckboxField } from '../../../../../../../components/CheckboxField';
import MoveIcon from '../../../../../../../assets/icons/burgerMenu.svg';
import styles from '../../PlotSettings.css';
export const CheckBoxListItem = memo(function CheckBoxListItem({ id, checked, label = '', withMoveIcon, withEye, handleChange, className, }) {
    const checkboxClassName = classNames(styles.checkbox, {
        [styles.wide]: !withMoveIcon,
    });
    return (_jsxs("li", Object.assign({ "data-testid": "CheckBoxListItem", className: className }, { children: [withMoveIcon && (_jsx("div", Object.assign({ className: styles.moveIconWrapper }, { children: _jsx(MoveIcon, {}, void 0) }), void 0)), _jsx(CheckboxField, { id: id, className: checkboxClassName, onChange: handleChange, label: label, checked: checked, withEye: withEye }, void 0)] }), void 0));
});
