/**
 * Custom common props for every column in the table
 *
 * @param column - Metadata used to render columns
 * @returns object - Shape of props to pass them to react component
 */
export const getColumnProps = (column) => {
    const { width, maxWidth, minWidth } = column;
    return {
        style: {
            width,
            minWidth: minWidth !== null && minWidth !== void 0 ? minWidth : width,
            maxWidth: maxWidth !== null && maxWidth !== void 0 ? maxWidth : width,
        },
    };
};
