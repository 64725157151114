import { useRef, useCallback, } from 'react';
import styles from '../../components/TableView/TableView.css';
export const ROW_INDEX_ATTRIBUTE = 'data-row-index';
const useRowHover = ({ className = styles.hoverRow, } = {}) => {
    const containerRef = useRef(null);
    const getRows = useCallback((e) => {
        const targetElem = e.target;
        const rowElem = targetElem.closest('tr');
        const currentRowIndex = rowElem === null || rowElem === void 0 ? void 0 : rowElem.getAttribute(ROW_INDEX_ATTRIBUTE);
        const rowsSelector = `tr[${ROW_INDEX_ATTRIBUTE}="${currentRowIndex}"]`;
        return (containerRef.current && currentRowIndex)
            ? [...containerRef.current.querySelectorAll(rowsSelector)]
            : [];
    }, []);
    const handleMouseOver = useCallback((e) => {
        const rows = getRows(e);
        if (rows) {
            rows.forEach((row) => {
                row.classList.add(className);
            });
        }
    }, [className, getRows]);
    const handleMouseOut = useCallback((e) => {
        const rows = getRows(e);
        if (rows) {
            rows.forEach((row) => {
                row.classList.remove(className);
            });
        }
    }, [className, getRows]);
    return {
        ref: containerRef,
        containerRef,
        onMouseOver: handleMouseOver,
        onMouseOut: handleMouseOut,
    };
};
export default useRowHover;
