// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Tabs__tabsLabels__1AOTF{display:flex;flex-direction:row;list-style:none;margin:0;padding:0}.Tabs__tabsLabels__1AOTF>li{display:flex;font-size:1rem;font-weight:700;line-height:1.25rem}.Tabs__label__1thtD{align-items:center;border-bottom:2px solid #0000;cursor:pointer;display:flex;justify-content:space-between;line-height:20px;outline:none;padding:.875rem .75rem;text-decoration:none}.Tabs__label__1thtD.Tabs__active__1h8Fv{border-bottom:2px solid var(--link-color);color:var(--link-color)}.Tabs__label__1thtD.Tabs__disabled__1lgp0{font-weight:400}.Tabs__tabs__2lLBv{display:contents}", "",{"version":3,"sources":["webpack://./shared/src/components/Tabs/Tabs.css"],"names":[],"mappings":"AAAA,yBACE,YAAa,CACb,kBAAmB,CACnB,eAAgB,CAChB,QAAS,CACT,SACF,CAEA,4BAIE,YAAa,CAFb,cAAe,CACf,eAAiB,CAFjB,mBAIF,CAEA,oBAQE,kBAAmB,CACnB,6BAAoC,CAPpC,cAAe,CAIf,YAAa,CACb,6BAA8B,CAF9B,gBAAiB,CAJjB,YAAa,CAGb,sBAAyB,CADzB,oBAOF,CAEA,wCAEE,yCAA0C,CAD1C,uBAEF,CAEA,0CACE,eACF,CAEA,mBACE,gBACF","sourcesContent":[".tabsLabels {\n  display: flex;\n  flex-direction: row;\n  list-style: none;\n  margin: 0;\n  padding: 0;\n}\n\n.tabsLabels > li {\n  line-height: 1.25rem;\n  font-size: 1rem;\n  font-weight: bold;\n  display: flex;\n}\n\n.label {\n  outline: none;\n  cursor: pointer;\n  text-decoration: none;\n  padding: 0.875rem 0.75rem;\n  line-height: 20px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  border-bottom: 2px solid transparent;\n}\n\n.label.active {\n  color: var(--link-color);\n  border-bottom: 2px solid var(--link-color);\n}\n\n.label.disabled {\n  font-weight: initial;\n}\n\n.tabs {\n  display: contents;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabsLabels": "Tabs__tabsLabels__1AOTF",
	"label": "Tabs__label__1thtD",
	"active": "Tabs__active__1h8Fv",
	"disabled": "Tabs__disabled__1lgp0",
	"tabs": "Tabs__tabs__2lLBv"
};
export default ___CSS_LOADER_EXPORT___;
