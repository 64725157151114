import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback } from 'react';
import { ETraitsTypes } from '../../types';
import { ETooltipAction, ETooltipAlign, ETooltipPlacement } from '../Tooltip';
import Label from './components/Label/Label';
import { CheckboxField } from '../CheckboxField';
import { InfoTooltip } from '../InfoTooltip';
import { ELEMENT_PREFIX, EFFECT_SIZE_FILTER_LABEL, EFFECT_SIZE_TOOLTIP_TEXT, ODDS_RATIO_FILTER_LABEL, ODDS_RATIO_TOOLTIP_TEXT, } from './consts';
import styles from './FilterByDirectionOfEffect.css';
const FilterByDirectionOfEffect = ({ value, onChange, traitsType, }) => {
    const label = traitsType === ETraitsTypes.Binary
        ? ODDS_RATIO_FILTER_LABEL
        : EFFECT_SIZE_FILTER_LABEL;
    const tooltipText = traitsType === ETraitsTypes.Binary
        ? ODDS_RATIO_TOOLTIP_TEXT
        : EFFECT_SIZE_TOOLTIP_TEXT;
    const handleChange = useCallback((_id, newValue) => onChange(newValue), [onChange]);
    return (_jsxs("div", Object.assign({ className: styles.container }, { children: [_jsx(CheckboxField, { id: "directionOfEffectFilter", label: _jsx(Label, { label: label }, void 0), onChange: handleChange, checked: value }, void 0), _jsx("div", Object.assign({ className: styles.tooltipContainer }, { children: _jsx(InfoTooltip, Object.assign({ targetEvent: ETooltipAction.Hover, position: ETooltipPlacement.BottomCenter, id: `${ELEMENT_PREFIX}_tooltip`, overlayClassName: styles.tooltip, align: ETooltipAlign.Center }, { children: tooltipText }), void 0) }), void 0)] }), void 0));
};
const MemoizedFilterByDirectionOfEffect = memo(FilterByDirectionOfEffect);
export { MemoizedFilterByDirectionOfEffect as FilterByDirectionOfEffect };
