import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback, useEffect, useRef, } from 'react';
import classNames from 'classnames';
import { MAX_RANGE_VALUE, MIN_RANGE_VALUE, RANGE_STEP } from './consts';
import styles from './RangeListItem.css';
export const RangeListItem = memo(function SettingsListItem({ id, value, label = '', disabled, isColorGrey, handleChange, }) {
    var _a;
    const sliderRef = useRef(null);
    const handleRangeChange = useCallback(({ target }) => {
        if (handleChange) {
            handleChange(target.id, parseInt(target.value, 10));
        }
    }, [handleChange]);
    useEffect(() => {
        if (sliderRef.current) {
            sliderRef.current.style.background = `linear-gradient(
        to right,
        #830051 0%, #830051 ${sliderRef.current.value}%,
        #99afc1 ${sliderRef.current.value}%, #99afc1 100%
      )`;
        }
    }, [(_a = sliderRef.current) === null || _a === void 0 ? void 0 : _a.value, value]);
    const labelClassName = classNames(styles.checkbox, {
        [styles.inactive]: isColorGrey,
    });
    return (_jsxs("div", Object.assign({ "data-handler-id": id, className: styles.container }, { children: [_jsx("label", Object.assign({ htmlFor: id }, { children: label }), void 0), _jsx("input", { ref: sliderRef, type: "range", id: id, name: id, className: labelClassName, onChange: handleRangeChange, min: MIN_RANGE_VALUE, max: MAX_RANGE_VALUE, step: RANGE_STEP, "data-testid": "RangeListItem", value: value, disabled: disabled }, void 0), _jsxs("div", Object.assign({ className: styles.percentLabels }, { children: [_jsx("span", { children: `${MIN_RANGE_VALUE}%` }, void 0), _jsx("span", { children: `${MAX_RANGE_VALUE}%` }, void 0)] }), void 0)] }), void 0));
});
