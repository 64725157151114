/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { numberFormatter, pValueFormatter, percentFormatter, integerFormatter, scientificNumberFormatter, joinWithCommaFormatter, } from '../../../utils/formatters';
import { tableCellDecorator } from '../decorators/tableCell/tableCell';
export const TableCellPValue = tableCellDecorator(pValueFormatter);
export const TableCellPercent = tableCellDecorator(percentFormatter);
export const TableCellNumeric = tableCellDecorator(numberFormatter);
export const TableCellInteger = tableCellDecorator(integerFormatter);
export const TableCellScientificNumeric = tableCellDecorator(scientificNumberFormatter);
export const TableCellJoinWithComma = tableCellDecorator(joinWithCommaFormatter);
export const TableCellLink = (args, createTableLinkCell) => tableCellDecorator(createTableLinkCell(args));
