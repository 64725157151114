import convertToNumber from '../convertToNumber/convertToNumber';
const DEFAULT_GENE_LINK_VALUE = 'variant';
const isChromosomeX = (value) => value.toLowerCase() === 'x';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isNotANumber = (value) => Number.isNaN(Number(value));
const getValidatedChromosomeValue = (value) => {
    if (isChromosomeX(value))
        return '23';
    return value;
};
const getSplitLabel = (label, sign = '-') => ((label !== null && label !== void 0 ? label : DEFAULT_GENE_LINK_VALUE).split(sign));
export const variantLinkSort = (rowA, rowB) => {
    var _a, _b, _c, _d, _e, _f;
    const labelASplit = getSplitLabel(((_b = (_a = rowA.original) === null || _a === void 0 ? void 0 : _a.variant) === null || _b === void 0 ? void 0 : _b.name) || ((_c = rowA.original) === null || _c === void 0 ? void 0 : _c.variant_name));
    const labelBSplit = getSplitLabel(((_e = (_d = rowB.original) === null || _d === void 0 ? void 0 : _d.variant) === null || _e === void 0 ? void 0 : _e.name) || ((_f = rowB.original) === null || _f === void 0 ? void 0 : _f.variant_name));
    let [chromosomeA] = labelASplit;
    const [, positionA, referenceA, altAllellesA] = labelASplit;
    let [chromosomeB] = labelBSplit;
    const [, positionB, referenceB, altAllellesB] = labelBSplit;
    chromosomeA = getValidatedChromosomeValue(chromosomeA);
    chromosomeB = getValidatedChromosomeValue(chromosomeB);
    if (isNotANumber(chromosomeA) || isNotANumber(chromosomeB)) {
        return chromosomeA.localeCompare(chromosomeB);
    }
    if (chromosomeA !== chromosomeB) {
        return convertToNumber(chromosomeA) - convertToNumber(chromosomeB);
    }
    if (positionA !== positionB) {
        return convertToNumber(positionA) - convertToNumber(positionB);
    }
    if (referenceA !== referenceB) {
        return referenceA.localeCompare(referenceB);
    }
    if (altAllellesA !== altAllellesB) {
        return altAllellesA.localeCompare(altAllellesB);
    }
    return 0;
};
