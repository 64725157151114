/**
 * get Random float in specific range
 *
 * @params maxPrecision - Max precision of random float
 * @returns - Random float
 */
export const getRandomFloat = (maxPrecision) => {
    const random = Math.random();
    return random < 0.1
        ? getRandomFloat(maxPrecision)
        : Math.pow(random, maxPrecision);
};
