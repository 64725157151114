import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useCallback } from 'react';
import { ESortType } from '../../types';
import SortedIcon from '../../../../assets/icons/sorted.svg';
import SortingToggleIcon from '../../../../assets/icons/sortingToggle.svg';
import styles from './TableSortingIcon.css';
const SortingUpIcon = (props) => (_jsx(SortedIcon, Object.assign({ className: styles.sortingUp }, props), void 0));
const SortingDownIcon = (props) => (_jsx(SortedIcon, Object.assign({}, props), void 0));
const SortingToggleIconWrapped = (props) => (_jsx(SortingToggleIcon, Object.assign({}, props), void 0));
export const SortingUpIconMemoized = memo(SortingUpIcon);
export const SortingDownIconMemoized = memo(SortingDownIcon);
export const SortingToggleIconMemoized = memo(SortingToggleIconWrapped);
export const TableSortingIcon = memo(function TableSortingIcon(props) {
    const { columnId, toggleSortBy, clearSortBy, isSorted = false, isSortedDesc = false, onSortByChange, } = props;
    const sortAsc = useCallback(() => {
        toggleSortBy(false, false);
        if (onSortByChange) {
            onSortByChange(columnId, ESortType.Asc);
        }
    }, [toggleSortBy, onSortByChange, columnId]);
    const sortDesc = useCallback(() => {
        toggleSortBy(true, false);
        if (onSortByChange) {
            onSortByChange(columnId, ESortType.Desc);
        }
    }, [toggleSortBy, onSortByChange, columnId]);
    const clearSort = useCallback(() => {
        clearSortBy();
        if (onSortByChange) {
            onSortByChange(columnId, ESortType.None);
        }
    }, [clearSortBy, onSortByChange, columnId]);
    let icon = _jsx(SortingToggleIconMemoized, { onClick: sortAsc }, void 0);
    if (isSorted) {
        icon = isSortedDesc ? (_jsx(SortingDownIconMemoized, { onClick: clearSort }, void 0)) : (_jsx(SortingUpIconMemoized, { onClick: sortDesc }, void 0));
    }
    return _jsx("div", Object.assign({ className: styles.icon }, { children: icon }), void 0);
});
