// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SyntheticScrollX__container__3RXrP{height:1rem;margin-top:.5rem;overflow:auto}.SyntheticScrollX__content__3mlQO{height:6px}.SyntheticScrollX__custom__2vu5L::-webkit-scrollbar{height:6px;width:6px}.SyntheticScrollX__custom__2vu5L::-webkit-scrollbar-track{background:var(--scrollbar-track-color);border-radius:6px;overflow:auto}.SyntheticScrollX__custom__2vu5L::-webkit-scrollbar-thumb{background:var(--scrollbar-thumb-color);border-radius:8px}", "",{"version":3,"sources":["webpack://./shared/src/plots/components/common/components/SyntheticScrollX/SyntheticScrollX.css"],"names":[],"mappings":"AAAA,oCACE,WAAY,CAEZ,gBAAkB,CADlB,aAEF,CAEA,kCACE,UACF,CAEA,oDAEE,UAAW,CADX,SAEF,CAEA,0DAEE,uCAAwC,CACxC,iBAAkB,CAFlB,aAGF,CAEA,0DACE,uCAAwC,CACxC,iBACF","sourcesContent":[".container {\n  height: 1rem;\n  overflow: auto;\n  margin-top: 0.5rem;\n}\n\n.content {\n  height: 6px;\n}\n\n.custom::-webkit-scrollbar {\n  width: 6px;\n  height: 6px;\n}\n\n.custom::-webkit-scrollbar-track {\n  overflow: auto;\n  background: var(--scrollbar-track-color);\n  border-radius: 6px;\n}\n\n.custom::-webkit-scrollbar-thumb {\n  background: var(--scrollbar-thumb-color);\n  border-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SyntheticScrollX__container__3RXrP",
	"content": "SyntheticScrollX__content__3mlQO",
	"custom": "SyntheticScrollX__custom__2vu5L"
};
export default ___CSS_LOADER_EXPORT___;
