/**
 * Gets appropriate tick interval for scale to contain only allowed max number of ticks
 *
 * If no interval from the pre-defined array satisfies the maximum number of ticks,
 * interval will be calculated based on the power of 10 required to cover the number of ticks
 *
 * @param tickIntervals - Pre-defined tick intervals to try to use
 * @param maxScaleValue - Max scale value
 * @param maxTicks - Max allowed number of ticks
 * @returns Tick interval
 */
export function getTickInterval(tickIntervals, maxScaleValue, maxTicks) {
    const tickIntervalCandidate = tickIntervals.find((interval) => ((maxScaleValue / interval) < (maxTicks - 1)));
    return tickIntervalCandidate || Math.pow(10, Math.ceil(Math.log10(maxScaleValue)));
}
