import { EDataPointShape } from '../../../types';
export const EFFECT_SIZE_NUMBER_EXPECT_ERROR = 'Invalid type: `effectSize` attribute is not a number';
export const EFFECT_SIZE_NUMBER_FINITE_EXPECT_ERROR = 'Invalid type: `effectSize` attribute is not a finite number';
/**
 * Get the shape of the plot's point
 *
 * @param data - object with property effectSize
 * @returns EDataPointShape
 */
export const getContinuousPlotDataPointShapeVLR = ({ effectSize, }) => {
    if (typeof effectSize !== 'number') {
        throw new Error(EFFECT_SIZE_NUMBER_EXPECT_ERROR);
    }
    if (Number.isNaN(effectSize) || !Number.isFinite(effectSize)) {
        throw new Error(EFFECT_SIZE_NUMBER_FINITE_EXPECT_ERROR);
    }
    if (effectSize > 0) {
        return EDataPointShape.TriangleUp;
    }
    if (effectSize < 0) {
        return EDataPointShape.TriangleDown;
    }
    return EDataPointShape.Circle;
};
