import { DEFAULT_GROUP_LABEL_FONT_SIZE, DEFAULT_GROUP_LABEL_ANGLE, } from '../../consts';
/**
 * Calculates the length of group label taking in account its font size
 * and the angle by which it will be rotated
 *
 * Basically, it just extends the length by additional corners of rectangle
 * which fits the rotated label
 *
 * @param labelLength - Original length of the label
 * @param [labelFontSize] - Font size
 * @param [labelAngle] - Rotation angle
 * @returns Rotated label length with the given font size
 */
export function calculateGroupLabelLength(labelLength, labelFontSize = DEFAULT_GROUP_LABEL_FONT_SIZE, labelAngle = DEFAULT_GROUP_LABEL_ANGLE) {
    return labelLength + labelFontSize * Math.sin(2 * labelAngle);
}
