import { DEFAULT_Y_AXIS_WIDTH, MIN_Y_AXIS_SCALE_LINE_GAP } from '../../consts';
/**
 * Calculates width of Y axis zone
 *
 * Depending on width of the max scale value, it extends the total width
 * to comply min scale line width limits within axis zone
 *
 * @param maxAxisScaleValueWidth - Width of the max scale value
 * @returns Width of the Y axis zone
 */
export const calculateYAxisZoneWidth = (maxAxisScaleValueWidth) => (Math.max(maxAxisScaleValueWidth + MIN_Y_AXIS_SCALE_LINE_GAP, DEFAULT_Y_AXIS_WIDTH));
