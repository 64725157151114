import { format } from 'mathjs';
import { DEFAULT_NUMBER_FRACTION_DIGITS } from '../../../consts';
/**
 * Removes trailing 0s from a string
 * @param value - the string to remove trailing 0s from
 *
 * @returns the string without trailing 0s
 */
export const removeTrailing0s = (value) => value.replace(/(\.[0-9]*[1-9])0*|(\.0*)/, '$1');
/**
 * Formats number to stringified exponential format
 *
 * @param numberToFormat - the number to format
 * @param defaultFractionDigits [2] - The default usable number of digits
 * of the fractional part of a number
 * @returns stringified exponential format of number
 */
export const formatToExponential = (numberToFormat, defaultFractionDigits = DEFAULT_NUMBER_FRACTION_DIGITS) => {
    if (Number.isNaN(numberToFormat)) {
        return '';
    }
    return removeTrailing0s(format(numberToFormat, {
        notation: 'exponential',
        precision: defaultFractionDigits + 1,
    }));
};
