// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TableViewCell__tableTooltip__26HkF{max-width:22rem;word-break:break-word}.TableViewCell__tableTooltip__26HkF>*{pointer-events:none;text-decoration:none}.TableViewCell__cellContainer__NWr_T{-webkit-line-clamp:5;-webkit-box-orient:vertical;display:-webkit-box;max-height:100px;overflow:hidden;text-overflow:ellipsis;word-break:break-word}", "",{"version":3,"sources":["webpack://./shared/src/components/Table/components/TableView/components/TableViewCell/TableViewCell.css"],"names":[],"mappings":"AAAA,oCACE,eAAgB,CAChB,qBACF,CAEA,sCAEE,mBAAoB,CADpB,oBAEF,CAEA,qCAKE,oBAAqB,CACrB,2BAA4B,CAF5B,mBAAoB,CAHpB,gBAAiB,CACjB,eAAgB,CAKhB,sBAAuB,CAJvB,qBAKF","sourcesContent":[".tableTooltip {\n  max-width: 22rem;\n  word-break: break-word;\n}\n\n.tableTooltip > * {\n  text-decoration: none;\n  pointer-events: none;\n}\n\n.cellContainer {\n  max-height: 100px;\n  overflow: hidden;\n  word-break: break-word;\n  display: -webkit-box;\n  -webkit-line-clamp: 5;\n  -webkit-box-orient: vertical;\n  text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableTooltip": "TableViewCell__tableTooltip__26HkF",
	"cellContainer": "TableViewCell__cellContainer__NWr_T"
};
export default ___CSS_LOADER_EXPORT___;
