export * from './FESnQQconsts';
export * from './GPAManhattanPlot';
/**
 * Font which is used for drawing text in SVG
 */
export const DEFAULT_SVG_FONT = '16px Roboto';
/**
 * Container id used to calculate approximate width of text
 */
export const TEXT_WIDTH_APPROXIMATOR_ID = 'az__textWidthApprox';
export var EMouseButton;
(function (EMouseButton) {
    EMouseButton[EMouseButton["Left"] = 0] = "Left";
    EMouseButton[EMouseButton["Middle"] = 1] = "Middle";
    EMouseButton[EMouseButton["Right"] = 2] = "Right";
})(EMouseButton || (EMouseButton = {}));
/**
 * Difference between the width/height ratio of the plot
 * and the ratio of the selected aria of this plot
 */
export const MAX_RATIO_DIFFERENCE = 0.0001;
