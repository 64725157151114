/**
 * Converts input value (percentage) into zoom value (factor)
 * If the entered input value is not a valid number, exception will be thrown
 *
 * @throws Error if input value could not be converted into a valid number
 *
 * @param percentValue - Input percentage value
 * @returns Zoom value (factor)
 */
export const convertFromPercentage = (percentValue) => {
    const percentNumber = Number.parseFloat(percentValue);
    if (Number.isNaN(percentNumber)) {
        throw new Error(`Converted percent is not a valid number: ${percentValue}`);
    }
    return percentNumber / 100;
};
