// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FilterWrapper__content__1dj43{align-items:stretch;color:var(--font-color);display:flex;height:100%}.FilterWrapper__label__1KJnD{background-color:var(--content-background-color);bottom:2.2rem;font-size:.75rem;font-weight:500;left:.375rem;line-height:.875rem;padding:0 .375rem;position:absolute;z-index:var(--base-z-index)}.FilterWrapper__layout__s59lY{height:2.625rem;position:relative;white-space:nowrap}", "",{"version":3,"sources":["webpack://./shared/src/components/FilterWrapper/FilterWrapper.css"],"names":[],"mappings":"AAAA,+BAEE,mBAAoB,CACpB,uBAAwB,CAFxB,YAAa,CAGb,WACF,CAEA,6BAKE,gDAAiD,CADjD,aAAc,CAId,gBAAkB,CADlB,eAAgB,CAJhB,YAAc,CAMd,mBAAqB,CAHrB,iBAAmB,CALnB,iBAAkB,CAClB,2BAQF,CAEA,8BAGE,eAAgB,CAFhB,iBAAkB,CAClB,kBAEF","sourcesContent":[".content {\n  display: flex;\n  align-items: stretch;\n  color: var(--font-color);\n  height: 100%;\n}\n\n.label {\n  position: absolute;\n  z-index: var(--base-z-index);\n  left: 0.375rem;\n  bottom: 2.2rem;\n  background-color: var(--content-background-color);\n  padding: 0 0.375rem;\n  font-weight: 500;\n  font-size: 0.75rem;\n  line-height: 0.875rem;\n}\n\n.layout {\n  position: relative;\n  white-space: nowrap;\n  height: 2.625rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "FilterWrapper__content__1dj43",
	"label": "FilterWrapper__label__1KJnD",
	"layout": "FilterWrapper__layout__s59lY"
};
export default ___CSS_LOADER_EXPORT___;
