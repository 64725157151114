import { ManhattanPlotData } from '../ManhattanPlotData/ManhattanPlotData';
import { ManhattanPlotDataGroup } from '../ManhattanPlotDataGroup/ManhattanPlotDataGroup';
/**
 * Class which is used by GPAManhattanPlot
 *
 * @extends ManhattanPlotData<T>
 */
export class GPAManhattanPlotData extends ManhattanPlotData {
    /**
     * Returns empty instance
     *
     * @override
     * @static
     */
    static get empty() {
        return new GPAManhattanPlotData([]);
    }
    /**
     * Creates new instance from serialized JSON
     *
     * @override
     * @static
     */
    static fromJSON(rawData) {
        return new GPAManhattanPlotData(rawData.map((rawGroupData) => (ManhattanPlotDataGroup.fromJSON(rawGroupData))));
    }
    /**
     * Returns array of visible groups
     * Group is considered to be a hidden if there are no points inside it
     *
     * @private
     * @returns Array of visible groups
     */
    getVisibleGroups() {
        return this
            .getGroups()
            .filter((group) => group.getPoints().length > 0);
    }
    /**
     * Number of groups which are visible (should be displayed) on the plot
     *
     * @override
     */
    get visibleGroupsCount() {
        return this.getVisibleGroups().length;
    }
    /**
     * Number of points which are visible (should be displayed) on the plot
     *
     * @override
     */
    get visiblePointsCount() {
        return this.getVisibleGroups().reduce((currentTotalCount, group) => (currentTotalCount + group.length), 0);
    }
}
