import { DATA_POINT_RADIUS, ZERO_POINT_OFFSET, } from '../../consts';
/**
 * Calculates scroll position based on plot's elements which need to be scrolled out from the plot
 *
 * Basically, this function converts coordinates based on plot's elements
 * into numeric scroll position relative to plot's "zero point" (start of coordinates)
 *
 * @see 'scrolledElementsCounter.ts'
 *
 * @param dataPointStep - Step between two data points
 * @param [elementsCount = [0, 0, 0]] - Number of plot elements need to be scrolled out from plot
 * @returns Scroll position
 */
export function scrolledPositionCounter(dataPointStep, elementsCount = [0, 0, 0]) {
    const [dataPointStepsCount, dataPointRadiusCount, groupBorderCount,] = elementsCount;
    if (dataPointStepsCount === 0) {
        return 0;
    }
    const scrollOffset = (dataPointStepsCount * dataPointStep
        + dataPointRadiusCount * DATA_POINT_RADIUS
        + groupBorderCount);
    return scrollOffset + ZERO_POINT_OFFSET;
}
