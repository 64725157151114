/**
 * Converts zoom value (factor) into input value (percentage)
 * Result value would become rounded down with maximum one digit in fraction part allowed
 *
 * @param factorValue - Factor value
 * @returns Percentage string
 */
export const convertToPercentage = (factorValue) => ((factorValue * 100).toLocaleString('en-GB', {
    maximumFractionDigits: 1,
}));
