// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LollipopPlotData__tooltip__35JzR{padding-right:1.25rem;width:18.75rem}", "",{"version":3,"sources":["webpack://./shared/src/plots/components/LollipopPlot/components/LollipopPlotData/LollipopPlotData.css"],"names":[],"mappings":"AAAA,kCAEE,qBAAsB,CADtB,cAEF","sourcesContent":[".tooltip {\n  width: 18.75rem;\n  padding-right: 1.25rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": "LollipopPlotData__tooltip__35JzR"
};
export default ___CSS_LOADER_EXPORT___;
