export const inlineStyles = {
    legend: {
        fontSize: '12px',
        fontFamily: 'Roboto500',
    },
    combinedLegendText: {
        fontSize: '14px',
    },
    triangle: {
        fill: 'rgb(0, 0, 0)',
        fillOpacity: 0.3,
        stroke: 'rgb(0, 0, 0)',
        strokeWidth: '1px',
    },
};
