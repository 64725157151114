// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TableSortingIcon__icon__VH4EK{cursor:pointer;display:flex;flex:0 0 auto;flex-direction:column;justify-content:center;margin-left:.5rem}.TableSortingIcon__sortingUp__F4jxy{transform:rotate(180deg)}", "",{"version":3,"sources":["webpack://./shared/src/components/Table/components/TableSortingIcon/TableSortingIcon.css"],"names":[],"mappings":"AAAA,+BACE,cAAe,CAGf,YAAa,CADb,aAAc,CAEd,qBAAsB,CACtB,sBAAuB,CAJvB,iBAKF,CAEA,oCACE,wBACF","sourcesContent":[".icon {\n  cursor: pointer;\n  margin-left: 0.5rem;\n  flex: 0 0 auto;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n\n.sortingUp {\n  transform: rotate(180deg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "TableSortingIcon__icon__VH4EK",
	"sortingUp": "TableSortingIcon__sortingUp__F4jxy"
};
export default ___CSS_LOADER_EXPORT___;
