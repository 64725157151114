import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback } from 'react';
import classnames from 'classnames';
import { DOMAIN_BLOCK_HEIGHT, DOMAIN_BLOCK_BORDER_RADIUS } from '../../consts';
import styles from './LollipopPlotDomain.css';
import inlineStyles from './LollipopPlotDomain.style';
const LollipopPlotDomain = memo(function LollipopPlotDomain(props) {
    const { codonStart, codonEnd, handleTooltipShow, handleTooltipHide, positionX, positionY, getPositionOnAxisX, label, name, index = 0, useInlineStyles, } = props;
    const handleMouseEnter = useCallback((event) => {
        if (handleTooltipShow) {
            handleTooltipShow(event, name);
        }
    }, [handleTooltipShow, name]);
    const handleMouseLeave = useCallback(() => {
        if (handleTooltipHide) {
            handleTooltipHide();
        }
    }, [handleTooltipHide]);
    const xRelative = getPositionOnAxisX(codonStart);
    // To wrap the left border of the lollipop item of the same codon
    const x = positionX + xRelative - 1;
    const y = positionY;
    const height = DOMAIN_BLOCK_HEIGHT;
    // Plot width could be 0 on initial render
    // Hence, calculated width might become negative
    const width = Math.max(
    // The same px adding for wrapping the right border of the lollipop item as above
    getPositionOnAxisX(Math.abs(codonEnd - codonStart)) + 2, 0);
    let domainStyle;
    if (useInlineStyles) {
        domainStyle = index % 2 === 0 ? inlineStyles.evenDomain : inlineStyles.oddDomain;
    }
    const domainClassName = classnames({
        [styles.evenDomain]: index % 2 === 0,
        [styles.oddDomain]: index % 2 === 1,
    });
    let domainLabelStyle;
    if (useInlineStyles) {
        domainLabelStyle = index % 2 === 0 ? inlineStyles.evenDomainLabel : inlineStyles.oddDomainLabel;
    }
    const domainLabelClassName = classnames({
        [styles.evenDomainLabel]: index % 2 === 0,
        [styles.oddDomainLabel]: index % 2 === 1,
    });
    return (_jsxs("g", Object.assign({ onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave }, { children: [_jsx("rect", { className: domainClassName, style: domainStyle, x: x, y: y, height: height, width: width, rx: DOMAIN_BLOCK_BORDER_RADIUS, ry: DOMAIN_BLOCK_BORDER_RADIUS }, void 0), label && (_jsx("text", Object.assign({ className: domainLabelClassName, style: domainLabelStyle, x: x + width / 2, y: y + height / 2 }, { children: label }), void 0))] }), void 0));
});
export default LollipopPlotDomain;
