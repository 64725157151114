// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TooltipDataItem__item__3NHk7{line-height:1.2rem;margin-bottom:.25rem}.TooltipDataItem__label__3wwTZ{margin-right:.625rem}.TooltipDataItem__label__3wwTZ,.TooltipDataItem__value__23aKn{word-wrap:break-word}", "",{"version":3,"sources":["webpack://./shared/src/components/Tooltip/components/TooltipDataItem/TooltipDataItem.css"],"names":[],"mappings":"AAAA,8BACE,kBAAmB,CACnB,oBACF,CAEA,+BACE,oBAEF,CAEA,8DAHE,oBAKF","sourcesContent":[".item {\n  line-height: 1.2rem;\n  margin-bottom: 0.25rem;\n}\n\n.label {\n  margin-right: 0.625rem;\n  word-wrap: break-word;\n}\n\n.value {\n  word-wrap: break-word;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "TooltipDataItem__item__3NHk7",
	"label": "TooltipDataItem__label__3wwTZ",
	"value": "TooltipDataItem__value__23aKn"
};
export default ___CSS_LOADER_EXPORT___;
