import { PORTAL_ID } from './consts';
/**
 * Returns an existing HTMLElement with specify id or create a new HTMLElement
 *
 * @param id - portal id
 * @returns HTMLElement with specify id
 */
export const getPortalContainer = (id = PORTAL_ID) => {
    let portalContainerNode = document.getElementById(id);
    if (!portalContainerNode) {
        portalContainerNode = document.createElement('div');
        portalContainerNode.setAttribute('id', id);
        portalContainerNode.setAttribute('data-testid', 'portalContainer');
        document.body.appendChild(portalContainerNode);
    }
    return portalContainerNode;
};
