// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LollipopPlotDataLine__line__2PT9H{stroke:var(--lollipop-plot-data-line-color);stroke-width:1px}.LollipopPlotDataLine__lineInactive__2A9po{stroke:var(--lollipop-plot-data-inactive-border-color);stroke-width:1px}", "",{"version":3,"sources":["webpack://./shared/src/plots/components/LollipopPlot/components/LollipopPlotDataLine/LollipopPlotDataLine.css"],"names":[],"mappings":"AAAA,mCACE,2CAA4C,CAC5C,gBACF,CAEA,2CACE,sDAAuD,CACvD,gBACF","sourcesContent":[".line {\n  stroke: var(--lollipop-plot-data-line-color);\n  stroke-width: 1px;\n}\n\n.lineInactive {\n  stroke: var(--lollipop-plot-data-inactive-border-color);\n  stroke-width: 1px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"line": "LollipopPlotDataLine__line__2PT9H",
	"lineInactive": "LollipopPlotDataLine__lineInactive__2A9po"
};
export default ___CSS_LOADER_EXPORT___;
