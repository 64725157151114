// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DefinitionModel__description__1POwZ{font-size:.875rem;line-height:1.125rem;margin-bottom:9px}.DefinitionModel__description__1POwZ:only-child{margin-bottom:0}.DefinitionModel__list__2rL7L{line-height:1.125rem;margin:0;padding-left:22px}.DefinitionModel__bold__37xjm p{font-weight:700}", "",{"version":3,"sources":["webpack://./shared/src/components/DefinitionModel/DefinitionModel.css"],"names":[],"mappings":"AAAA,qCACE,iBAAmB,CACnB,oBAAqB,CACrB,iBACF,CAEA,gDACE,eACF,CAEA,8BAGE,oBAAqB,CAFrB,QAAS,CACT,iBAEF,CAEA,gCACE,eACF","sourcesContent":[".description {\n  font-size: 0.875rem;\n  line-height: 1.125rem;\n  margin-bottom: 9px;\n}\n\n.description:only-child {\n  margin-bottom: 0;\n}\n\n.list {\n  margin: 0;\n  padding-left: 22px;\n  line-height: 1.125rem;\n}\n\n.bold p {\n  font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"description": "DefinitionModel__description__1POwZ",
	"list": "DefinitionModel__list__2rL7L",
	"bold": "DefinitionModel__bold__37xjm"
};
export default ___CSS_LOADER_EXPORT___;
