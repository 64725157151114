import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback, useEffect, useRef, useState, } from 'react';
import classnames from 'classnames';
import Angle from '../../assets/icons/dropdown.svg';
import { useExportSettings, useZipArchive } from '../../hooks';
import { Button } from '../Button';
import { InfoTooltip } from '../InfoTooltip';
import { ExportSettingsForm } from '../ExportSettingsForm';
import { EXPORT_BUTTON_LABEL, TOOLTIP_MESSAGE } from './consts';
import styles from './ExportSettingsControl.css';
export const ExportSettingsControl = memo(function ExportControl({ allData, getArchive, checkReset, outdated, shouldRerender, disabled = false, withTooltip = false, withQueryDescription = false, onExport, }) {
    const wrapperRef = useRef(null);
    const [settingsIsVisible, setSettingsIsVisible] = useState(false);
    const { setAllData, setTableColumnSettings, setTableDownload, setPlotDownload, setQueryDescriptionDownload, settings, resetSettings, } = useExportSettings(allData, withQueryDescription, shouldRerender);
    const { isLoading, downloadArchive } = useZipArchive();
    const handleClick = () => {
        setSettingsIsVisible((currentSettingsIsVisible) => !currentSettingsIsVisible);
    };
    useEffect(() => {
        checkReset(resetSettings);
    }, [checkReset, resetSettings]);
    const handleClose = useCallback(() => setSettingsIsVisible(false), [setSettingsIsVisible]);
    const handleExportClick = useCallback(() => {
        const [files, archiveName] = getArchive(settings);
        downloadArchive(files, archiveName);
        if (onExport)
            onExport();
    }, [
        getArchive,
        settings,
        downloadArchive,
        onExport,
    ]);
    const renderExportButton = useCallback(() => (_jsxs(Button, Object.assign({ onClick: handleClick, className: styles.button, disabled: disabled, withHover: true }, { children: [EXPORT_BUTTON_LABEL, _jsx(Angle, { className: classnames(styles.icon, { [styles.activeArrow]: settingsIsVisible }), tabIndex: -1, "data-testid": "arrow-icon" }, void 0)] }), void 0)), [disabled, settingsIsVisible]);
    return (_jsxs("div", Object.assign({ ref: wrapperRef }, { children: [settingsIsVisible && (_jsx(ExportSettingsForm, { handleClose: handleClose, isFiltersNotChanged: allData, wrapperRef: wrapperRef, setTableDownload: setTableDownload, setAllData: setAllData, setQueryDescriptionDownload: setQueryDescriptionDownload, setPlotDownload: setPlotDownload, setTableColumnSettings: setTableColumnSettings, settings: settings, handleExportClick: handleExportClick, isLoading: isLoading, spinnerClassName: styles.spinner }, void 0)), withTooltip ? (_jsx(InfoTooltip, Object.assign({ className: styles.infoTooltip, icon: renderExportButton(), id: "ExportButton" }, { children: outdated && TOOLTIP_MESSAGE }), void 0)) : (renderExportButton())] }), void 0));
});
