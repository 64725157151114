import getTextWidth from '../../../../utils/getTextWidth';
import { DATA_POINT_STEP, DEFAULT_GROUP_LABEL_ANGLE, DEFAULT_GROUP_LABEL_FONT_FAMILY, } from '../../consts';
import { calculateGroupLabelLength } from '../calculateGroupLabelLength/calculateGroupLabelLength';
import { calculateGroupLabelFontSize } from '../calculateGroupLabelFontSize/calculateGroupLabelFontSize';
import { widthCounter } from '../widthCounter/widthCounter';
/**
 * Calculates groups dimensions (width, height, offsets, etc.) required to
 * paint specific group labels on the plot
 *
 * @param data - Plot's data
 * @param dataPointStep - Step between points
 * @param labelAngle - Angle of group label rotation
 * @returns Array of groups (labels) dimensions
 */
export function calculateGroupsDimensions(data, dataPointStep = DATA_POINT_STEP, labelAngle = DEFAULT_GROUP_LABEL_ANGLE) {
    let prevGroupDimensions;
    let totalItems = 0;
    const labelAngleSin = Math.sin(labelAngle);
    const labelAngleCos = Math.cos(labelAngle);
    /**
     * Calculate min font size which will let to fit the longest
     * group labels into the left most position on the plot
     */
    const groupLabelFontSize = calculateGroupLabelFontSize(data, labelAngle);
    const groupsDimensions = data
        .getGroups()
        .map(({ groupLabel, length: groupLength }, index) => {
        const length = calculateGroupLabelLength(getTextWidth(groupLabel, `${groupLabelFontSize}px ${DEFAULT_GROUP_LABEL_FONT_FAMILY}`), groupLabelFontSize, labelAngle);
        const width = labelAngleCos
            ? length * labelAngleCos
            : groupLabelFontSize;
        const height = labelAngleSin
            ? length * labelAngleSin
            : groupLabelFontSize;
        const widthOffset = widthCounter(data, dataPointStep, totalItems > 0 ? index : 0, totalItems);
        const prevGroupDistance = prevGroupDimensions
            ? widthOffset - prevGroupDimensions.widthOffset
            : null;
        /**
         * Min threshold distance between two sibling group labels which
         * lets placement of these labels w/o addition height offset
         */
        const minWidthOffsetForShifting = (labelAngleSin === 0 || !prevGroupDimensions)
            ? null
            : (groupLabelFontSize - prevGroupDimensions.heightOffset * labelAngleCos) / labelAngleSin;
        let heightOffset = 0;
        if (minWidthOffsetForShifting === null
            && prevGroupDimensions
            && width > prevGroupDimensions.widthOffset
            && height > prevGroupDimensions.heightOffset) {
            /**
             * This is the case when rotation angle is (PI * n) and the current label
             * overlaps the previous one (i.e. needs to be shifted down)
             */
            heightOffset = prevGroupDimensions.heightOffset + height;
        }
        else if (prevGroupDistance !== null) {
            /**
             * This is the basic case which calculates required vertical shift
             * in order to fit the current label in such way that it won't overlap the previous one
             */
            heightOffset = prevGroupDimensions.heightOffset + (Math.max((groupLabelFontSize / labelAngleSin) - prevGroupDistance, 0) * Math.tan(labelAngle));
        }
        const dimensions = {
            length,
            height,
            width,
            heightOffset,
            widthOffset,
        };
        totalItems += groupLength;
        prevGroupDimensions = dimensions;
        return dimensions;
    });
    return {
        groupsDimensions,
        groupLabelFontSize,
    };
}
