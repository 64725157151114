import { DEFAULT_X_AXIS_HEIGHT, DEFAULT_DATA_ZONE_RIGHT_PADDING, DEFAULT_DATA_POINT_RADIUS, } from '../../consts';
/**
 * Calculates geometry rect for X axis zone
 *
 * @param plotHeight - Plot's height
 * @param plotWidth - Plot's width
 * @param YAxisZoneWidth - Width of the X axis zone
 * @param maxXAxisScaleValuesWidth  - Width of the max scale value on X axis
 * @returns Geometry rect
 */
export const calculateXAxisZoneRect = (plotHeight, plotWidth, YAxisZoneWidth, maxXAxisScaleValuesWidth) => {
    const x = YAxisZoneWidth;
    const y = plotHeight - DEFAULT_X_AXIS_HEIGHT;
    /**
     * There could be a case when default data zone right padding is not enough for scale
     * to contain either max scale value text or data point on the plot's right border
     *
     * To allocate enough space, additional width which might become necessary is calculated
     * based on maximum between a data point radius or width of the max scale value
     *
     * Note: due to inaccurate SVG positioning the full width of scale value is considered
     * instead of its half (as it should be considering middle `textAnchor`)
     */
    const maxPossibleOuterWidth = Math.max(maxXAxisScaleValuesWidth, DEFAULT_DATA_POINT_RADIUS);
    let additionalWidth = 0;
    if (maxPossibleOuterWidth > DEFAULT_DATA_ZONE_RIGHT_PADDING) {
        additionalWidth = maxPossibleOuterWidth - DEFAULT_DATA_ZONE_RIGHT_PADDING;
    }
    const width = plotWidth - YAxisZoneWidth - additionalWidth;
    const height = DEFAULT_X_AXIS_HEIGHT;
    return {
        x,
        y,
        width,
        height,
    };
};
