// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MultiSelectField__layout__2uc1Q{position:relative;width:100%}.MultiSelectField__dropdown__3TzjU{background-color:var(--dropdown-node-background-color);border-radius:2px;box-shadow:0 11px 25px 0 var(--box-shadow-color);display:none;left:0;min-width:100%;position:absolute;top:100%;z-index:var(--dropdown-z-index)}.MultiSelectField__dropdownOpen__2945O{display:block}.MultiSelectField__searchContainer__2iwjA{padding:.5rem .375rem}.MultiSelectField__searchContainer__2iwjA .MultiSelectField__search__2Jy1W{height:2.25rem}", "",{"version":3,"sources":["webpack://./shared/src/components/MultiSelectField/MultiSelectField.css"],"names":[],"mappings":"AAAA,iCAEE,iBAAkB,CADlB,UAEF,CAEA,mCAOE,sDAAuD,CAEvD,iBAAkB,CADlB,gDAAiD,CANjD,YAAa,CAGb,MAAO,CAJP,cAAe,CAEf,iBAAkB,CAClB,QAAS,CAET,+BAIF,CAEA,uCACE,aACF,CAEA,0CACE,qBAKF,CAHE,2EACE,cACF","sourcesContent":[".layout {\n  width: 100%;\n  position: relative;\n}\n\n.dropdown {\n  min-width: 100%;\n  display: none;\n  position: absolute;\n  top: 100%;\n  left: 0;\n  z-index: var(--dropdown-z-index);\n  background-color: var(--dropdown-node-background-color);\n  box-shadow: 0 11px 25px 0 var(--box-shadow-color);\n  border-radius: 2px;\n}\n\n.dropdownOpen {\n  display: block;\n}\n\n.searchContainer {\n  padding: 0.5rem 0.375rem;\n\n  & .search {\n    height: 2.25rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": "MultiSelectField__layout__2uc1Q",
	"dropdown": "MultiSelectField__dropdown__3TzjU",
	"dropdownOpen": "MultiSelectField__dropdownOpen__2945O",
	"searchContainer": "MultiSelectField__searchContainer__2iwjA",
	"search": "MultiSelectField__search__2Jy1W"
};
export default ___CSS_LOADER_EXPORT___;
