import { useState, useRef, useLayoutEffect } from 'react';
import { useElementResize } from '../../../../hooks';
import splitTags from './utils/splitTags';
const useHiddenTags = (props) => {
    var _a, _b, _c, _d, _e, _f;
    const { value, disabled, getMaxWidth: getContainerMaxWidth } = props;
    const containerRef = useRef(null);
    const hiddenTagsRef = useRef(null);
    const visibleTagsRef = useRef(null);
    const labelRef = useRef(null);
    const childNodeRef = useRef(null);
    const [visibleTags, setVisibleTags] = useState(value);
    const [hiddenTags, setHiddenTags] = useState([]);
    const currentLabelWidth = (_b = (_a = labelRef.current) === null || _a === void 0 ? void 0 : _a.offsetWidth) !== null && _b !== void 0 ? _b : 0;
    const containerWidth = (_d = (_c = containerRef.current) === null || _c === void 0 ? void 0 : _c.offsetWidth) !== null && _d !== void 0 ? _d : 0;
    const childNodeWidth = (_f = (_e = childNodeRef.current) === null || _e === void 0 ? void 0 : _e.offsetWidth) !== null && _f !== void 0 ? _f : 0;
    useElementResize({ target: containerRef.current });
    useLayoutEffect(() => {
        const containerMaxWidth = getContainerMaxWidth && getContainerMaxWidth();
        const maxWidth = disabled ? 0 : (containerMaxWidth || containerWidth);
        const [nextVisibleTags, nextHiddenTags] = splitTags({
            value,
            maxWidth,
            labelWidth: currentLabelWidth,
            listRef: hiddenTagsRef,
            childNodeWidth,
        });
        setVisibleTags(nextVisibleTags);
        setHiddenTags(nextHiddenTags);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, disabled, containerWidth, getContainerMaxWidth]);
    return {
        visibleTags,
        hiddenTags,
        containerRef,
        hiddenTagsRef,
        visibleTagsRef,
        labelRef,
        childNodeRef,
    };
};
export default useHiddenTags;
