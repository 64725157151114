import { useState, useCallback, useMemo, useRef, } from 'react';
import { EMouseButton } from '../../../../consts';
import { limitByRange } from '../../../../../utils';
const useSelectRegion = (props) => {
    const { zoomMinValue, zoomMaxValue, handleZoomChange, scaleXStepWidth, scale, isDataRegion, zoomIndex, } = props;
    const [selectRegionStart, setSelectRegionStart] = useState({ x: 0, y: 0 });
    const [selectRegionFinish, setSelectRegionFinish] = useState({ x: 0, y: 0 });
    const selectRegionHeightRef = useRef(0);
    const selectRegionTopRef = useRef(0);
    const scrollCoeff = useRef(0);
    const plotLength = scale.x[scale.x.length - 1].x - scale.x[0].x;
    const coeffSizes = plotLength / (scale.y[scale.y.length - 1].y - scale.y[0].y);
    const changeScrollCoeff = useCallback(() => {
        const coeff = scrollCoeff.current - window.scrollY;
        setSelectRegionStart(({ x, y }) => ({ x, y: y + coeff }));
        setSelectRegionFinish(({ x, y }) => ({ x, y: y + coeff }));
        scrollCoeff.current = window.scrollY;
    }, []);
    const isZoomRange = zoomIndex !== zoomMaxValue;
    const getHeight = useCallback((x, xFirstPoint) => ((Math.max(x, xFirstPoint) - Math.min(x, xFirstPoint)) / coeffSizes), [coeffSizes]);
    const resetSelect = useCallback(() => {
        scrollCoeff.current = 0;
        setSelectRegionFinish({ x: 0, y: 0 });
        setSelectRegionStart({ x: 0, y: 0 });
        selectRegionHeightRef.current = 0;
    }, []);
    const handleMouseDown = useCallback((e) => {
        if (isDataRegion(e.pageX, e.pageY + scrollCoeff.current)
            && isZoomRange
            && e.button === EMouseButton.Left) {
            selectRegionTopRef.current = e.pageY;
            setSelectRegionStart({ x: e.pageX, y: e.pageY });
            setSelectRegionFinish({ x: e.pageX, y: e.pageY });
            window.addEventListener('scroll', changeScrollCoeff);
        }
    }, [changeScrollCoeff, isDataRegion, isZoomRange]);
    const handleMouseMove = useCallback((e) => {
        selectRegionTopRef.current = selectRegionStart.y > e.pageY
            ? selectRegionStart.y - Math.abs(selectRegionStart.x - e.pageX) / coeffSizes
            : selectRegionStart.y;
        selectRegionHeightRef.current = getHeight(e.pageX, selectRegionStart.x);
        if (isDataRegion(Math.max(selectRegionStart.x, e.pageX), selectRegionTopRef.current)
            && isDataRegion(Math.min(selectRegionStart.x, e.pageX), selectRegionTopRef.current + selectRegionHeightRef.current)) {
            setSelectRegionFinish({ x: e.pageX, y: e.pageY });
        }
    }, [
        coeffSizes,
        getHeight,
        isDataRegion,
        selectRegionStart,
    ]);
    const handleMouseUp = useCallback(() => {
        window.removeEventListener('scroll', changeScrollCoeff);
        if (Math.abs(selectRegionFinish.x - selectRegionStart.x) < scaleXStepWidth / 2) {
            return resetSelect();
        }
        const zoomValue = limitByRange(plotLength / Math.abs(selectRegionFinish.x - selectRegionStart.x), zoomMinValue, zoomMaxValue);
        scrollCoeff.current = 0;
        return handleZoomChange(zoomValue);
    }, [
        changeScrollCoeff,
        selectRegionFinish.x,
        selectRegionStart.x,
        scaleXStepWidth,
        plotLength,
        zoomMinValue,
        zoomMaxValue,
        handleZoomChange,
        resetSelect,
    ]);
    return useMemo(() => ({
        handleMouseDown,
        handleMouseUp,
        handleMouseMove,
        selectRegionStart: Math.min(selectRegionStart.x, selectRegionFinish.x),
        selectRegionWidth: Math.abs(selectRegionFinish.x - selectRegionStart.x),
        selectRegionHeightRef,
        selectRegionTopRef,
        resetSelect,
    }), [
        handleMouseDown,
        handleMouseUp,
        handleMouseMove,
        selectRegionStart,
        selectRegionFinish,
        resetSelect,
    ]);
};
export default useSelectRegion;
