import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import classname from 'classnames';
import { LoadingIcon } from '../LoadingIcon';
import styles from './Loading.css';
export const Loading = memo(function Loading({ label, message, fullScreen = true, }) {
    const layout = classname(styles.layout, {
        [styles.layoutFullScreen]: fullScreen,
        [styles.layoutEmbedded]: !fullScreen,
    });
    const titleContainer = classname(styles.titleContainer, {
        [styles.titleContainerFullScreen]: fullScreen,
    });
    return (_jsxs("article", Object.assign({ className: layout }, { children: [_jsxs("div", Object.assign({ className: titleContainer }, { children: [_jsx("h1", Object.assign({ className: styles.title }, { children: label }), void 0), _jsx(LoadingIcon, { width: 120 }, void 0)] }), void 0), Boolean(message) && _jsx("p", Object.assign({ className: styles.message }, { children: message }), void 0)] }), void 0));
});
