import { EDataPointShape } from '../../../types';
export const BETA_NUMBER_EXPECT_ERROR = 'Invalid type: `beta` attribute is not a number';
export const BETA_NUMBER_FINITE_EXPECT_ERROR = 'Invalid type: `beta` attribute is not a finite number';
/**
 * Get the shape of the plot's point
 *
 * @param data - object with property beta
 * @returns EDataPointShape
 */
export const getContinuousPlotDataPointShapeGLR = ({ beta, }) => {
    if (typeof beta !== 'number') {
        throw new Error(BETA_NUMBER_EXPECT_ERROR);
    }
    if (Number.isNaN(beta) || !Number.isFinite(beta)) {
        throw new Error(BETA_NUMBER_FINITE_EXPECT_ERROR);
    }
    if (beta > 0) {
        return EDataPointShape.TriangleUp;
    }
    if (beta < 0) {
        return EDataPointShape.TriangleDown;
    }
    return EDataPointShape.Circle;
};
