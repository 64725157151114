import { useMemo } from 'react';
import { filledArray } from '../../../../../utils';
/**
 * Hook calculates number of items for each column
 *
 * @param itemsCount - Total number of items
 * @param columnsCount - Maximum number of allowed columns
 * @returns Array of slice indexes
 */
export default function useColumnDistribution(itemsCount, columnsCount) {
    return useMemo(() => {
        const minElementsCount = Math.floor(itemsCount / columnsCount);
        const maximizedColumnsCount = itemsCount % columnsCount;
        let prevEndIndex = 0;
        return filledArray(columnsCount, (index) => {
            const currentItemsCount = (index < maximizedColumnsCount)
                ? minElementsCount + 1
                : minElementsCount;
            const slice = {
                startIndex: prevEndIndex,
                endIndex: prevEndIndex + currentItemsCount,
            };
            prevEndIndex += currentItemsCount;
            return slice;
        })
            .filter(({ startIndex, endIndex }) => startIndex < endIndex);
    }, [itemsCount, columnsCount]);
}
