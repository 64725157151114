import { range } from '../../../../../../utils';
import { getAxisDataPointValues } from '../getAxisDataPointValues/getAxisDataPointValues';
/**
 * Calculates step for axis scale
 *
 * @param difference - Difference between min and max values at the axis
 * @returns step as number
 *
 */
const getStep = (difference) => {
    if (difference <= 1) {
        return 0.1;
    }
    if (difference <= 2) {
        return 0.2;
    }
    if (difference <= 10) {
        return 1;
    }
    if (difference <= 20) {
        return 2;
    }
    if (difference <= 100) {
        return 10;
    }
    if (difference <= 200) {
        return 20;
    }
    if (difference <= 1000) {
        return 100;
    }
    return 200;
};
/**
 * Calculates scale values from 0 to `maxValue`
 *
 * @param min - Min value for the scale range
 * @param max - Max value for the scale range
 * @returns Array of scale numbers/values
 *
 */
export const calculateScaleValues = (min, max) => {
    const [normalizedMinValue, normalizedMaxValue,] = getAxisDataPointValues(min, max);
    const step = getStep(Math.max(normalizedMaxValue, Math.abs(normalizedMinValue)));
    return range(Math.trunc(normalizedMinValue / step) - normalizedMinValue / step === 0
        ? Math.trunc(normalizedMinValue / step) * step
        : (Math.trunc(normalizedMinValue / step) - 1) * step, normalizedMaxValue + step, step).map((x) => Math.round(x * 10) / 10);
};
