// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SpliceDescription__icon__ffs9i{stroke:var(--text-disabled-dark-color);stroke-linecap:round;stroke-width:1.5;stroke-linejoin:round;margin-right:.3rem}", "",{"version":3,"sources":["webpack://./shared/src/components/ImpactDescriptions/components/SpliceDescription/SpliceDescription.css"],"names":[],"mappings":"AAAA,gCACE,sCAAuC,CACvC,oBAAqB,CACrB,gBAAiB,CACjB,qBAAsB,CACtB,kBACF","sourcesContent":[".icon {\n  stroke: var(--text-disabled-dark-color);\n  stroke-linecap: round;\n  stroke-width: 1.5;\n  stroke-linejoin: round;\n  margin-right: 0.3rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "SpliceDescription__icon__ffs9i"
};
export default ___CSS_LOADER_EXPORT___;
