// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ScaleLabelsY__axisHiding__29Eat{fill:var(--content-background-color)}.ScaleLabelsY__axisYLabels__1prvI{pointer-events:none;position:fixed;z-index:var(--plot-y-axis-label-z-index)}", "",{"version":3,"sources":["webpack://./shared/src/plots/components/ScatterPlots/components/ScaleLabelsY/ScaleLabelsY.css"],"names":[],"mappings":"AAAA,iCACE,oCACF,CAEA,kCAEE,mBAAoB,CADpB,cAAe,CAEf,wCACF","sourcesContent":[".axisHiding {\n  fill: var(--content-background-color);\n}\n\n.axisYLabels {\n  position: fixed;\n  pointer-events: none;\n  z-index: var(--plot-y-axis-label-z-index);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"axisHiding": "ScaleLabelsY__axisHiding__29Eat",
	"axisYLabels": "ScaleLabelsY__axisYLabels__1prvI"
};
export default ___CSS_LOADER_EXPORT___;
