import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import { getPercentile, transformToLinePlotData } from './utils';
import { LinePlot } from '../LinePlot/LinePlot';
const axisLabels = ['Protein position', 'MTR'];
export const MTRPlot = memo(function MTRPlot({ data, width, height, maxTickValues, tooltipContent, forExport, }) {
    const linePlotData = transformToLinePlotData(data);
    const thresholds = useMemo(() => {
        const MTRValues = data.filter((dataValue) => dataValue !== null)
            .map(({ mtr }) => mtr)
            .sort();
        return [
            {
                id: 'neutrality',
                value: 1,
                description: 'Neutrality',
                color: '#830051',
            },
            {
                id: 'median',
                value: getPercentile(MTRValues, 0.5),
                description: 'Median',
                color: '#3c1053',
            },
            {
                id: '25',
                value: getPercentile(MTRValues, 0.25),
                description: '25%',
                color: '#c4d600',
            },
            {
                id: '5',
                value: getPercentile(MTRValues, 0.05),
                description: '5%',
                color: '#5e6a75',
            },
        ];
    }, [data]);
    return (_jsx(LinePlot, { data: linePlotData, height: height, width: width, axisLabels: axisLabels, thresholds: thresholds, maxTickValues: maxTickValues, unitLabel: "aa", tooltipContent: tooltipContent, forExport: forExport }, void 0));
});
