/**
 * Returns the width of element
 *
 * @param element - HTMLDivElement
 * @returns  width of element
 */
export const getElementWidth = (element) => {
    const styles = window.getComputedStyle(element);
    const margin = (parseFloat(styles.marginLeft) + parseFloat(styles.marginRight)) || 0;
    return element.offsetWidth + margin;
};
