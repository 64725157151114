import { jsx as _jsx } from "react/jsx-runtime";
import { useContext, memo, } from 'react';
import classnames from 'classnames';
import { Context } from '../../Tabs';
import styles from './TabContent.css';
export const TabContent = memo(function TabContent(props) {
    const { children, id, className, activeClassName, } = props;
    const { activeTabId, keepTabsRendered, tabContentClassName, activeTabContentClassName, } = useContext(Context);
    const isActiveTab = activeTabId === id;
    const tabClassName = classnames(styles.tabContent, tabContentClassName, className, {
        [styles.activeTabContent]: isActiveTab,
        [activeTabContentClassName]: isActiveTab && activeTabContentClassName,
        [activeClassName]: activeClassName && isActiveTab,
    });
    if (!isActiveTab && !keepTabsRendered)
        return null;
    return (_jsx("div", Object.assign({ className: tabClassName }, { children: children }), void 0));
});
