import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback, } from 'react';
import classnames from 'classnames';
import SelectButtonIcon from '../../../../assets/icons/select.svg';
import styles from './SelectOption.css';
export const SelectOption = memo(function SelectOption(props) {
    const { value, label, isSelected, isActive, onClick, optionClassName, selectedOptionClassName = '', isWithTag, onMouseEnter, onMouseLeave, } = props;
    const className = classnames(styles.option, optionClassName, {
        [selectedOptionClassName]: isSelected,
        [styles.activeOption]: isActive,
    });
    const handleMouseEnter = useCallback(() => {
        if (onMouseEnter) {
            onMouseEnter(value);
        }
    }, [onMouseEnter, value]);
    const handleMouseLeave = useCallback(() => {
        if (onMouseLeave) {
            onMouseLeave();
        }
    }, [onMouseLeave]);
    const handleClick = useCallback(() => {
        if (value) {
            onClick(value);
        }
    }, [value, onClick]);
    return (_jsx("li", Object.assign({ className: classnames({ [styles.optionWithTag]: isWithTag }), onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave }, { children: _jsxs("button", Object.assign({ type: "button", className: className, onClick: handleClick }, { children: [label, isSelected && _jsx(SelectButtonIcon, { className: styles.optionIconClassName }, void 0)] }), void 0) }), void 0));
});
