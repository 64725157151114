import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { memo } from 'react';
import AZLogoSrc from '../../assets/images/azLogo.png';
import AZLogoWhiteSrc from '../../assets/images/azLogoWhite.png';
export var ELogoType;
(function (ELogoType) {
    ELogoType[ELogoType["Colored"] = 0] = "Colored";
    ELogoType[ELogoType["White"] = 1] = "White";
})(ELogoType || (ELogoType = {}));
export const AZLogo = memo(function AZLogo({ className, type = ELogoType.Colored, altText = 'CGR Public Data Portal', }) {
    const src = type === ELogoType.Colored ? AZLogoSrc : AZLogoWhiteSrc;
    return (_jsx("img", { className: className, src: src, alt: altText }, void 0));
});
