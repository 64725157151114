// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FilterByDirectionOfEffect__tooltip__14Xoa{font-weight:400;line-height:1rem;width:15.5rem}.FilterByDirectionOfEffect__tooltipContainer__1Sy2G{align-items:center;display:flex;height:100%}.FilterByDirectionOfEffect__container__kwLQd{display:flex;flex-direction:row}", "",{"version":3,"sources":["webpack://./shared/src/components/FilterByDirectionOfEffect/FilterByDirectionOfEffect.css"],"names":[],"mappings":"AAAA,2CAEE,eAAmB,CACnB,gBAAiB,CAFjB,aAGF,CAEA,oDAGE,kBAAmB,CADnB,YAAa,CADb,WAGF,CAEA,6CACE,YAAa,CACb,kBACF","sourcesContent":[".tooltip {\n  width: 15.5rem;\n  font-weight: normal;\n  line-height: 1rem;\n}\n\n.tooltipContainer {\n  height: 100%;\n  display: flex;\n  align-items: center;\n}\n\n.container {\n  display: flex;\n  flex-direction: row;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": "FilterByDirectionOfEffect__tooltip__14Xoa",
	"tooltipContainer": "FilterByDirectionOfEffect__tooltipContainer__1Sy2G",
	"container": "FilterByDirectionOfEffect__container__kwLQd"
};
export default ___CSS_LOADER_EXPORT___;
