import { getElementWidth } from '../../../../../utils';
const getContainerWidthAtPosition = (list, position) => list
    .reduce((acc, item, index) => (position >= index ? acc + item : acc), 0);
const splitTags = (props) => {
    var _a;
    const { value, maxWidth, labelWidth, listRef, childNodeWidth, } = props;
    const children = ((_a = listRef === null || listRef === void 0 ? void 0 : listRef.current) === null || _a === void 0 ? void 0 : _a.children) || {};
    const itemsWidth = Array
        .from(children)
        .map((node) => getElementWidth(node));
    const positionWidthList = itemsWidth
        .map((width, index) => getContainerWidthAtPosition(itemsWidth, index)
        + labelWidth
        + childNodeWidth);
    if (!maxWidth) {
        return [value, []];
    }
    return [
        value.filter((item, index) => positionWidthList[index] <= maxWidth),
        value.filter((item, index) => positionWidthList[index] > maxWidth),
    ];
};
export default splitTags;
