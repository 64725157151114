import { useEffect, useState } from 'react';
import { dataStepCounter as defaultDataStepCounter } from '../../utils';
import { DATA_POINT_STEP } from '../../consts';
/**
 * Hooks implements auto-resize (auto-zoom) functionality
 * Basically, it calculates basic step between data points (which represents 100% zoom)
 *  and sets effects for updating zoom factor to fit updated data into given container's width
 *
 * @param data - Plot's data instance
 * @param containerWidth - Width of the container to fit updated data into
 * @param [handleZoomChange] - Callback for updating zoom factor
 * @param [defaultDataStep] - Default data point step used as a fallback
 * @param [dataStepCounter] - Counter function which are used to calculate step between data points
 * @returns Basic step between data points which represents 100% zoom
 */
export function useAutoZoom(data, containerWidth, handleZoomChange, defaultDataStep = DATA_POINT_STEP, dataStepCounter = defaultDataStepCounter) {
    const [basicDataPointStep, setBasicDataPointStep] = useState(dataStepCounter(data, containerWidth, defaultDataStep));
    /**
     * Only triggers basic data point step recalculation when container width or data changes
     * Current implementation considers initial portion of data as a basic one
     * For more common case plot component should receive `totalDataCount` and `totalDataGroupCount`
     *  variables for non filtered data separately
     */
    useEffect(() => {
        setBasicDataPointStep(dataStepCounter(data, containerWidth, defaultDataStep));
    }, [
        containerWidth,
        data,
    ]);
    /**
     * Updates zoom factor when number of data or its groups changes
     */
    useEffect(() => {
        if (!handleZoomChange) {
            return;
        }
        /**
         * If there are no data points to resize, update zoom to 100%
         */
        if (data.visiblePointsCount === 0) {
            handleZoomChange(1, 1);
            return;
        }
        const updatedDataPointStep = dataStepCounter(data, containerWidth, defaultDataStep);
        const updatedZoomFactor = basicDataPointStep
            ? updatedDataPointStep / basicDataPointStep
            : 1;
        handleZoomChange(updatedZoomFactor, 1);
    }, [
        handleZoomChange,
        data,
        containerWidth,
        basicDataPointStep,
        dataStepCounter,
        defaultDataStep,
    ]);
    return basicDataPointStep;
}
