import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useMemo, } from 'react';
import { getTickInterval } from '../../utils';
import { LinePlotAxis, LinePlotData, LinePlotThresholds } from './components';
import { DEFAULT_PLOT_HEIGHT, DEFAULT_PLOT_WIDTH, X_SCALE_TICK_INTERVALS, Y_SCALE_TICK_INTERVALS, X_SCALE_MAX_TICKS, Y_SCALE_MAX_TICKS, X_SCALE_SUBTICKS, Y_SCALE_SUBTICKS, } from './consts';
import { useZoneConstructor } from './hooks';
import { calculateScaleValues } from './utils';
function LinePlotBase({ data, height = DEFAULT_PLOT_HEIGHT, width = DEFAULT_PLOT_WIDTH, axisLabels, thresholds, unitLabel, tooltipContent, maxTickValues, forExport = false, }) {
    var _a, _b;
    const maxValue = useMemo(() => {
        let maxXValue = 0;
        let maxYValue = 0;
        data.forEach((dataGroup) => {
            dataGroup.values.forEach(({ x, y }) => {
                if (x > maxXValue) {
                    maxXValue = x;
                }
                if (y > maxYValue) {
                    maxYValue = y;
                }
            });
        });
        return {
            x: maxXValue,
            y: maxYValue,
        };
    }, [data]);
    const XScaleTickInterval = getTickInterval(X_SCALE_TICK_INTERVALS, (_a = maxTickValues === null || maxTickValues === void 0 ? void 0 : maxTickValues.x) !== null && _a !== void 0 ? _a : maxValue.x, X_SCALE_MAX_TICKS);
    const YScaleTickInterval = getTickInterval(Y_SCALE_TICK_INTERVALS, (_b = maxTickValues === null || maxTickValues === void 0 ? void 0 : maxTickValues.y) !== null && _b !== void 0 ? _b : maxValue.y, Y_SCALE_MAX_TICKS);
    const XScaleValues = useMemo(() => {
        var _a;
        return calculateScaleValues((_a = maxTickValues === null || maxTickValues === void 0 ? void 0 : maxTickValues.x) !== null && _a !== void 0 ? _a : maxValue.x, XScaleTickInterval, (maxTickValues === null || maxTickValues === void 0 ? void 0 : maxTickValues.x) === undefined);
    }, [maxValue.x, XScaleTickInterval, maxTickValues]);
    const YScaleValues = useMemo(() => {
        var _a;
        return calculateScaleValues((_a = maxTickValues === null || maxTickValues === void 0 ? void 0 : maxTickValues.y) !== null && _a !== void 0 ? _a : maxValue.y, YScaleTickInterval, (maxTickValues === null || maxTickValues === void 0 ? void 0 : maxTickValues.y) === undefined);
    }, [maxValue.y, YScaleTickInterval, maxTickValues]);
    const maxScaleValue = useMemo(() => {
        var _a, _b;
        return ({
            x: (_a = maxTickValues === null || maxTickValues === void 0 ? void 0 : maxTickValues.x) !== null && _a !== void 0 ? _a : XScaleValues[XScaleValues.length - 1],
            y: (_b = maxTickValues === null || maxTickValues === void 0 ? void 0 : maxTickValues.y) !== null && _b !== void 0 ? _b : YScaleValues[YScaleValues.length - 1],
        });
    }, [maxTickValues, XScaleValues, YScaleValues]);
    const { XAxisLabelZoneRect, YAxisLabelZoneRect, XAxisZoneRect, YAxisZoneRect, XScaleZoneRect, YScaleZoneRect, dataZoneRect, thresholdsZoneRect, thresholdIconsZoneRect, } = useZoneConstructor(height, width, YScaleValues, axisLabels);
    return (_jsxs("svg", Object.assign({ height: height, width: width, viewBox: `0 0 ${width} ${height}`, xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx(LinePlotAxis, { rect: XAxisZoneRect, labelRect: XAxisLabelZoneRect, label: axisLabels === null || axisLabels === void 0 ? void 0 : axisLabels[0], unitLabel: unitLabel, scale: XScaleValues, scaleRect: XScaleZoneRect, numberOfSubTicks: X_SCALE_SUBTICKS }, void 0), _jsx(LinePlotAxis, { rect: YAxisZoneRect, labelRect: YAxisLabelZoneRect, label: axisLabels === null || axisLabels === void 0 ? void 0 : axisLabels[1], scale: YScaleValues, scaleRect: YScaleZoneRect, numberOfSubTicks: Y_SCALE_SUBTICKS, isVertical: true }, void 0), maxScaleValue.x > 0 && maxScaleValue.y > 0 && (_jsx(LinePlotData, { rect: dataZoneRect, maxScaleValue: maxScaleValue, data: data, tooltipContent: tooltipContent, forExport: forExport }, void 0)), thresholds && maxScaleValue.y > 0 && (_jsx(LinePlotThresholds, { rect: thresholdsZoneRect, dataRect: dataZoneRect, maxScaleValue: maxScaleValue, data: thresholds, iconsRect: thresholdIconsZoneRect, forExport: forExport }, void 0))] }), void 0));
}
export const LinePlot = memo(LinePlotBase);
