export const inlineStyles = {
    legend: {
        fontSize: '16px',
        fontWeight: 500,
        transform: 'translate(0, 15px)',
    },
    circleGLR: {
        fill: 'rgb(213, 174, 198)',
        fillOpacity: 0.5,
        stroke: 'rgb(131, 0, 81)',
        strokeWidth: '1px',
    },
    circleVLR: {
        fill: 'rgb(205, 237, 241)',
        fillOpacity: 0.5,
        stroke: 'rgb(104, 210, 223)',
        strokeWidth: '1px',
    },
};
