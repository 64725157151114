// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DefaultOption__layout__2lHri{align-items:center;cursor:pointer;display:flex;justify-content:space-between;line-height:1.25rem;padding:.375rem 1.5rem;width:100%}.DefaultOption__highlighted__1da-P,.DefaultOption__selected__YG1f1{background-color:#0000000d}.DefaultOption__disabled__1pWOi{color:var(--text-disabled-color);cursor:default}", "",{"version":3,"sources":["webpack://./shared/src/components/SearchBoxField/components/DefaultOption/DefaultOption.css"],"names":[],"mappings":"AAAA,8BAKE,kBAAmB,CAEnB,cAAe,CAJf,YAAa,CACb,6BAA8B,CAE9B,mBAAoB,CAJpB,sBAAwB,CADxB,UAOF,CAMA,mEACE,0BACF,CAEA,gCACE,gCAAiC,CACjC,cACF","sourcesContent":[".layout {\n  width: 100%;\n  padding: 0.375rem 1.5rem;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  line-height: 1.25rem;\n  cursor: pointer;\n}\n\n.selected {\n  background-color: rgba(0, 0, 0, 0.05);\n}\n\n.highlighted {\n  background-color: rgba(0, 0, 0, 0.05);\n}\n\n.disabled {\n  color: var(--text-disabled-color);\n  cursor: default;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": "DefaultOption__layout__2lHri",
	"highlighted": "DefaultOption__highlighted__1da-P",
	"selected": "DefaultOption__selected__YG1f1",
	"disabled": "DefaultOption__disabled__1pWOi"
};
export default ___CSS_LOADER_EXPORT___;
