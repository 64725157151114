import { RomanNumeral } from '../../types';
import { isValidRomanNumeral } from '../isValidRomanNumeral';
export const romanToArabic = (romanNumeral) => {
    if (!isValidRomanNumeral(romanNumeral))
        return -1;
    let result = 0;
    for (let i = 0; i < romanNumeral.length; i += 1) {
        const currentChar = RomanNumeral[romanNumeral[i]];
        const nextChar = RomanNumeral[romanNumeral[i + 1]];
        if (nextChar && currentChar < nextChar) {
            result += nextChar - currentChar;
            i += 1;
        }
        else {
            result += currentChar;
        }
    }
    return result;
};
