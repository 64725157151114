// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Modal__layout__3RC8I{align-items:center;animation:Modal__softAppear__F-pOZ .2s;background-color:#242b364d;display:flex;height:100vh;justify-content:center;left:0;overflow-x:hidden;overflow-y:auto;padding:4rem;position:fixed;top:0;width:100vw;z-index:var(--modal-z-index)}.Modal__content__3Fyo3{margin:auto 0;max-width:100%}.Modal__scrollLock__3LOg1{overflow:hidden}@keyframes Modal__softAppear__F-pOZ{0%{opacity:0}to{opacity:1}}", "",{"version":3,"sources":["webpack://./shared/src/components/Modal/Modal.css"],"names":[],"mappings":"AAAA,sBAGE,kBAAmB,CAWnB,sCAA2B,CAF3B,0BAAuC,CAXvC,YAAa,CAOb,YAAa,CANb,sBAAuB,CAIvB,MAAO,CAKP,iBAAkB,CADlB,eAAgB,CADhB,YAAa,CALb,cAAe,CACf,KAAM,CAEN,WAAY,CAMZ,4BAEF,CAEA,uBACE,aAAc,CACd,cACF,CAEA,0BACE,eACF,CAEA,oCACE,GACE,SACF,CAEA,GACE,SACF,CACF","sourcesContent":[".layout {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100vw;\n  height: 100vh;\n  padding: 4rem;\n  overflow-y: auto;\n  overflow-x: hidden;\n  background-color: rgba(36, 43, 54, 0.3);\n  z-index: var(--modal-z-index);\n  animation: softAppear 200ms;\n}\n\n.content {\n  margin: auto 0;\n  max-width: 100%;\n}\n\n.scrollLock {\n  overflow: hidden;\n}\n\n@keyframes softAppear {\n  from {\n    opacity: 0;\n  }\n\n  to {\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": "Modal__layout__3RC8I",
	"softAppear": "Modal__softAppear__F-pOZ",
	"content": "Modal__content__3Fyo3",
	"scrollLock": "Modal__scrollLock__3LOg1"
};
export default ___CSS_LOADER_EXPORT___;
