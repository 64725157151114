import { useState, useCallback } from 'react';
/**
 * Hook implements LRU based structure over array of data items
 *
 * @template T
 *
 * @param initValue - Default value
 * @param limit - Maximum number of elements
 * @param findIndexPredicateFactory - Factory which returns
 *  a predicate to be used in `Array.prototype.findIndex` for searching inserted value
 * @param updateCallback - Callback which will be run after
 *  each update (add, remove) operation
 * @returns Public API for LRU cache hook
 *  - LRU collection
 *  - method which adds new value
 *  - method which removes value
 */
export function useLRUCache(initValue, limit, findIndexPredicateFactory, updateCallback) {
    const [cache, setCache] = useState(() => (limit > 0 ? initValue.slice(0, limit) : initValue));
    /**
     * Adds new value into the structure
     *
     * @param value - Inserted value
     * @returns Updated LRU structure
     */
    const addValue = useCallback((value) => {
        const valueIndex = findIndexPredicateFactory
            ? cache.findIndex(findIndexPredicateFactory(value))
            : cache.indexOf(value);
        const updatedCache = [value, ...cache];
        if (valueIndex > -1) {
            updatedCache.splice(valueIndex + 1, 1);
        }
        if (updatedCache.length > limit) {
            updatedCache.pop();
        }
        setCache(updatedCache);
        if (updateCallback) {
            updateCallback(updatedCache);
        }
    }, [
        cache,
        limit,
        updateCallback,
        findIndexPredicateFactory,
    ]);
    /**
     * Removes items from the structure by its index
     *
     * @param index - Index of the item which will be removed
     * @returns Updated LRU structure
     */
    const removeValue = useCallback((index) => {
        const updatedCache = [...cache];
        updatedCache.splice(index, 1);
        setCache(updatedCache);
        if (updateCallback) {
            updateCallback(updatedCache);
        }
    }, [cache, updateCallback]);
    return [
        cache,
        addValue,
        removeValue,
    ];
}
