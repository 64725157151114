import { jsx as _jsx } from "react/jsx-runtime";
import { transformSVGToString } from '../../../utils/transformSVGToString';
import { EDataPointShape } from '../../types';
import { GPAManhattanPlot } from '../../components/GPAManhattanPlot';
import { Legend } from '../../components/ManhattanPlot/components/Legend';
import { DEFAULT_GPA_MANHATTAN_PLOT_AXIS, DEFAULT_GPA_MANHATTAN_PLOT_UPPER_THRESHOLD, DEFAULT_GPA_MANHATTAN_PLOT_LOWER_THRESHOLD, } from '../../consts';
export const transformGPAManhattanPlotToString = (config) => {
    const { plotData, axis = DEFAULT_GPA_MANHATTAN_PLOT_AXIS, upperThreshold = DEFAULT_GPA_MANHATTAN_PLOT_UPPER_THRESHOLD, lowerThreshold = DEFAULT_GPA_MANHATTAN_PLOT_LOWER_THRESHOLD, getDataPointShape = () => EDataPointShape.Circle, labelsConfig, } = config;
    return Promise.resolve(transformSVGToString(_jsx(GPAManhattanPlot, { data: plotData, axis: axis, upperThreshold: upperThreshold, lowerThreshold: lowerThreshold, useInlineStyles: true, getDataPointShape: getDataPointShape, legend: _jsx(Legend, { labelsConfig: labelsConfig, useInlineStyles: true }, void 0) }, void 0)));
};
