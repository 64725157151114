import { recursiveDeep } from './consts';
/**
 * Calculates the value in milliseconds up to the specified point in time,
 * or expands this point if it has already occurred.
 *
 * @param time - A moment in the future
 * @param addedTime - The amount of time for automatic extension
 * @returns - Number the value in milliseconds
 */
export const getTotalMs = (time, addedTime) => {
    const maxTimePointInTheFuture = (addedTime * recursiveDeep) + time;
    if (maxTimePointInTheFuture < Date.now()) {
        return 0;
    }
    const totalMs = time - Date.now();
    return totalMs > 0 ? totalMs : getTotalMs(time + addedTime, addedTime);
};
