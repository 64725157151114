import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, memo, } from 'react';
import classnames from 'classnames';
import ArrowIcon from '../../../../assets/icons/dropdown.svg';
import CrossIcon from '../../../../assets/icons/closeDropdown.svg';
import styles from './MultiSelectFieldFacade.css';
export const MultiSelectFieldFacadeBase = forwardRef(function MultiSelectFieldFacade(props, ref) {
    const { children, onClick, onClear, isEmpty, disabled = false, active = false, } = props;
    const facadeProps = disabled && !active ? {} : { onClick };
    return (_jsxs("div", Object.assign({ ref: ref, className: classnames(styles.layout, {
            [styles.active]: active,
            [styles.empty]: isEmpty,
        }), role: "presentation", "data-testid": "multi-select-field-facade" }, facadeProps, { children: [_jsx("div", Object.assign({ className: styles.value }, { children: children }), void 0), _jsxs("div", Object.assign({ className: styles.icons }, { children: [_jsx(ArrowIcon, { className: classnames(styles.icon, { [styles.activeArrow]: active }), tabIndex: -1, "data-testid": "arrow-icon" }, void 0), !isEmpty
                        && (_jsx(CrossIcon, { onClick: onClear, className: classnames(styles.icon, { [styles.disableClear]: disabled }), tabIndex: -1, "data-testid": "cross-icon" }, void 0))] }), void 0)] }), void 0));
});
export const MultiSelectFieldFacade = memo(MultiSelectFieldFacadeBase);
