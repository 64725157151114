import { ETooltipPlacement, ETooltipAlign, ETooltipPlacementType } from '../../consts';
/**
 * Calculates tooltip position
 *
 * @param targetElement - Target of the tooltip appearance
 * @param tooltipElement - Element representing tooltip
 * @param placement - Placement type of tooltip
 * @param align - Align of tooltip
 * @param [offset] - Pair of offsets
 * @returns Tooltip's position coordinates
 */
export function getTooltipPosition(targetElement, tooltipElement, placement, align, offset) {
    /**
     * First offset represents distance from target to tooltip itself (by placement axis)
     *
     * Second offset represent side shift relative to placement axis
     * (positive number corresponds the right side from axis)
     */
    const [offsetDistance = 0, offsetSide = 0] = offset !== null && offset !== void 0 ? offset : [];
    let position = {
        top: 0,
        left: 0,
    };
    if (!targetElement || !tooltipElement) {
        return position;
    }
    const targetDimensions = targetElement.getBoundingClientRect();
    const tooltipSize = tooltipElement.getBoundingClientRect();
    let arrowPlacement;
    switch (placement) {
        case ETooltipPlacement.TopLeft:
            arrowPlacement = ETooltipPlacementType.Horizontal;
            position = {
                top: targetDimensions.top - tooltipSize.height - offsetDistance,
                left: targetDimensions.left + offsetSide,
            };
            break;
        case ETooltipPlacement.TopCenter:
            arrowPlacement = ETooltipPlacementType.Horizontal;
            position = {
                top: targetDimensions.top - tooltipSize.height - offsetDistance,
                left: targetDimensions.left + targetDimensions.width / 2 + offsetSide,
            };
            break;
        case ETooltipPlacement.TopRight:
            arrowPlacement = ETooltipPlacementType.Horizontal;
            position = {
                top: targetDimensions.top - tooltipSize.height - offsetDistance,
                left: targetDimensions.left + targetDimensions.width + offsetSide,
            };
            break;
        case ETooltipPlacement.RightTop:
            arrowPlacement = ETooltipPlacementType.Vertical;
            position = {
                top: targetDimensions.top + offsetSide,
                left: targetDimensions.left + targetDimensions.width + offsetDistance,
            };
            break;
        case ETooltipPlacement.RightCenter:
            arrowPlacement = ETooltipPlacementType.Vertical;
            position = {
                top: targetDimensions.top + targetDimensions.height / 2 + offsetSide,
                left: targetDimensions.left + targetDimensions.width + offsetDistance,
            };
            break;
        case ETooltipPlacement.RightBottom:
            arrowPlacement = ETooltipPlacementType.Vertical;
            position = {
                top: targetDimensions.top + targetDimensions.height + offsetSide,
                left: targetDimensions.left + targetDimensions.width + offsetDistance,
            };
            break;
        case ETooltipPlacement.BottomRight:
            arrowPlacement = ETooltipPlacementType.Horizontal;
            position = {
                top: targetDimensions.top + targetDimensions.height + offsetDistance,
                left: targetDimensions.left + targetDimensions.width - offsetSide,
            };
            break;
        case ETooltipPlacement.BottomCenter:
            arrowPlacement = ETooltipPlacementType.Horizontal;
            position = {
                top: targetDimensions.top + targetDimensions.height + offsetDistance,
                left: targetDimensions.left + targetDimensions.width / 2 - offsetSide,
            };
            break;
        case ETooltipPlacement.BottomLeft:
            arrowPlacement = ETooltipPlacementType.Horizontal;
            position = {
                top: targetDimensions.top + targetDimensions.height + offsetDistance,
                left: targetDimensions.left - offsetSide,
            };
            break;
        case ETooltipPlacement.LeftBottom:
            arrowPlacement = ETooltipPlacementType.Vertical;
            position = {
                top: targetDimensions.top + targetDimensions.height - offsetSide,
                left: targetDimensions.left - tooltipSize.width - offsetDistance,
            };
            break;
        case ETooltipPlacement.LeftCenter:
            arrowPlacement = ETooltipPlacementType.Vertical;
            position = {
                top: targetDimensions.top + targetDimensions.height / 2 - offsetSide,
                left: targetDimensions.left - tooltipSize.width - offsetDistance,
            };
            break;
        case ETooltipPlacement.LeftTop:
            arrowPlacement = ETooltipPlacementType.Vertical;
            position = {
                top: targetDimensions.top - offsetSide,
                left: targetDimensions.left - tooltipSize.width - offsetDistance,
            };
            break;
        default:
            throw new Error(`Unsupported 'placement' argument: '${placement}'`);
    }
    switch (align) {
        case ETooltipAlign.Start:
            // No need to adjust position, since it is already set properly
            break;
        case ETooltipAlign.Center:
            if (arrowPlacement === ETooltipPlacementType.Horizontal) {
                position.left -= tooltipSize.width / 2;
            }
            else {
                position.top -= tooltipSize.height / 2;
            }
            break;
        case ETooltipAlign.End:
            if (arrowPlacement === ETooltipPlacementType.Horizontal) {
                position.left -= tooltipSize.width;
            }
            else {
                position.top -= tooltipSize.height;
            }
            break;
        default:
            throw new Error(`Unsupported 'align' argument: '${align}'`);
    }
    position.top += window.pageYOffset;
    position.left += window.pageXOffset;
    return position;
}
