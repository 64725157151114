// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LollipopPlotSVGLegend__oddDomainLabelText__2DvM8{fill:var(--lollipop-plot-odd-domain-label-color)}.LollipopPlotSVGLegend__oddDomainLabelRect__1CLMy{fill:var(--lollipop-plot-odd-domain-color)}.LollipopPlotSVGLegend__evenDomainLabelText__3Lm9l{fill:var(--lollipop-plot-even-domain-label-color)}.LollipopPlotSVGLegend__evenDomainLabelRect__2Lod9{fill:var(--lollipop-plot-even-domain-color)}", "",{"version":3,"sources":["webpack://./shared/src/plots/components/LollipopPlot/components/LollipopPlotSVGLegend/LollipopPlotSVGLegend.css"],"names":[],"mappings":"AAAA,kDACE,gDACF,CAEA,kDACE,0CACF,CAEA,mDACE,iDACF,CAEA,mDACE,2CACF","sourcesContent":[".oddDomainLabelText {\n  fill: var(--lollipop-plot-odd-domain-label-color);\n}\n\n.oddDomainLabelRect {\n  fill: var(--lollipop-plot-odd-domain-color);\n}\n\n.evenDomainLabelText {\n  fill: var(--lollipop-plot-even-domain-label-color);\n}\n\n.evenDomainLabelRect {\n  fill: var(--lollipop-plot-even-domain-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"oddDomainLabelText": "LollipopPlotSVGLegend__oddDomainLabelText__2DvM8",
	"oddDomainLabelRect": "LollipopPlotSVGLegend__oddDomainLabelRect__1CLMy",
	"evenDomainLabelText": "LollipopPlotSVGLegend__evenDomainLabelText__3Lm9l",
	"evenDomainLabelRect": "LollipopPlotSVGLegend__evenDomainLabelRect__2Lod9"
};
export default ___CSS_LOADER_EXPORT___;
