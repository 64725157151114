import { useRef, useEffect, useCallback, useState, } from 'react';
/**
 * Hook splits array of data on multiple chunks and then continuously
 * updates state using available animation frames
 *
 * @param data - Original data array
 * @param chunkSize - Size of chunk to update state with
 * @param bypass? - Disables chunk processing
 * @returns
 *  - Currently accumulated data
 *  - Status if chunks are still accumulated
 */
export function useChunkedData(data, chunkSize, bypass = false) {
    const [accData, setAccData] = useState([]);
    const [isInProgress, setInProgress] = useState(false);
    useEffect(() => {
        if (!bypass) {
            setAccData([]);
        }
    }, [data, bypass]);
    // Use useRef for mutable variables that we want to persist
    // without triggering a re-render on their change
    const requestRef = useRef(null);
    const currentChunkIndex = useRef(0);
    const animate = useCallback(() => {
        if (currentChunkIndex.current < data.length) {
            const dataChunk = data.slice(currentChunkIndex.current, currentChunkIndex.current + chunkSize);
            currentChunkIndex.current += chunkSize;
            requestRef.current = requestAnimationFrame(animate);
            setAccData((prevData) => prevData.concat(dataChunk));
        }
        else {
            setInProgress(false);
        }
    }, [data, chunkSize]);
    useEffect(() => {
        if (bypass) {
            return () => { };
        }
        setInProgress(true);
        requestRef.current = requestAnimationFrame(animate);
        return () => {
            if (requestRef.current !== null) {
                cancelAnimationFrame(requestRef.current);
                requestRef.current = null;
                currentChunkIndex.current = 0;
            }
        };
    }, [animate, data, bypass]);
    return [
        bypass ? data : accData,
        isInProgress,
    ];
}
