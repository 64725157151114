import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, useMemo, useCallback, } from 'react';
import { EDataPointShape } from '../../../../types';
import { limitByRange, logger, } from '../../../../../utils';
import { DATA_POINT_RADIUS, DATA_POINT_STEP, TOP_LABEL_HEIGHT, BOTTOM_AXIS_SCALE_HEIGHT, CANVAS_PADDING_HEIGHT, } from '../../consts';
import { dataPointFactory } from '../ManhattanPlotDataPoint/dataPointFactory';
import { getDataPointOpacity } from '../../utils';
const ManhattanPlotDataGroup = (props) => {
    const { data, widthOffset, height, scaleRange, dataLimits = [], dataPointStep = DATA_POINT_STEP, useInlineStyles, handleTooltipShow, handleTooltipHide, getDataPointShape, dataPointOrder, datasets, levelResultsCollection, traitsTypesCollection, hasPlotSettings, } = props;
    const handleDataPointTooltipHide = useCallback(() => {
        if (handleTooltipHide) {
            handleTooltipHide();
        }
    }, [handleTooltipHide]);
    const canvasHeight = height
        - TOP_LABEL_HEIGHT
        - BOTTOM_AXIS_SCALE_HEIGHT
        - CANVAS_PADDING_HEIGHT;
    const getRelativeY = useCallback((value) => {
        const [lowerDataLimit, upperDataLimit] = dataLimits;
        const [minScaleValue, maxScaleValue] = scaleRange;
        const canvasOffset = (canvasHeight
            + TOP_LABEL_HEIGHT);
        /**
         * Corner case when plot is "collapsed"
         */
        if (minScaleValue === maxScaleValue) {
            return canvasOffset;
        }
        /**
         * Limit the given value position on plot by upper and lower limits if they are set
         * The point would be display not higher/lower then the limits
         */
        const limitedValue = limitByRange(value, lowerDataLimit, upperDataLimit);
        return (canvasOffset
            - canvasHeight * ((limitedValue - minScaleValue) / (maxScaleValue - minScaleValue)));
    }, [
        canvasHeight,
        dataLimits,
        scaleRange,
    ]);
    const dataPoints = useMemo(() => data.map((dataItem, dataPointIndex) => {
        const { x: positionX = dataPointIndex, y: positionY = 0, data: dataItemData, id, dataset = '', levelResult, traitsType, } = dataItem;
        let dataPointShape = EDataPointShape.Circle;
        if (getDataPointShape) {
            try {
                dataPointShape = getDataPointShape(dataItemData);
            }
            catch ({ message }) {
                logger.warn(`Error getting data point shape: ${message}. Circle shape will be used`);
            }
        }
        const DataPoint = dataPointFactory(dataPointShape);
        const opacity = hasPlotSettings
            ? getDataPointOpacity(dataset, datasets, levelResultsCollection, traitsTypesCollection, levelResult, traitsType)
            : 100;
        return (_jsx(DataPoint, { dataPointOrder: dataPointOrder, x: widthOffset + DATA_POINT_RADIUS + positionX * dataPointStep, y: getRelativeY(positionY), dataItem: dataItem, handleTooltipShow: handleTooltipShow, handleTooltipHide: handleDataPointTooltipHide, useInlineStyles: useInlineStyles, opacity: opacity }, id));
    }), [
        datasets,
        levelResultsCollection,
        traitsTypesCollection,
        data,
        getDataPointShape,
        dataPointOrder,
        widthOffset,
        dataPointStep,
        getRelativeY,
        handleTooltipShow,
        handleDataPointTooltipHide,
        useInlineStyles,
        hasPlotSettings,
    ]);
    return (_jsx(_Fragment, { children: dataPoints }, void 0));
};
/** Since memo loses generic props */
export default memo(ManhattanPlotDataGroup);
