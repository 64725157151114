import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { DEFAULT_BORDER_WIDTH, DEFAULT_AXIS_BORDER_WIDTH } from '../../consts';
const PlotBorders = ({ axisZone: { rect: axisZoneRect, }, }) => {
    /**
     * It appears that SVG rendering considers rounding down float numbers
     * However, 0 value should be rounded up as it won't be considered otherwise
     */
    const HALF_AXIS_BORDER_WIDTH = Math.floor(DEFAULT_AXIS_BORDER_WIDTH / 2) || 1;
    const HALF_BORDER_WIDTH = Math.floor(DEFAULT_BORDER_WIDTH / 2) || 1;
    return (_jsxs(_Fragment, { children: [_jsx("line", { x1: axisZoneRect.x - HALF_AXIS_BORDER_WIDTH, y1: axisZoneRect.y + HALF_BORDER_WIDTH, x2: axisZoneRect.x + axisZoneRect.width - HALF_BORDER_WIDTH, y2: axisZoneRect.y + HALF_BORDER_WIDTH, style: {
                    stroke: '#cad7e1',
                    strokeWidth: DEFAULT_BORDER_WIDTH,
                } }, void 0), _jsx("line", { x1: axisZoneRect.x + axisZoneRect.width - HALF_BORDER_WIDTH, y1: axisZoneRect.y, x2: axisZoneRect.x + axisZoneRect.width - HALF_BORDER_WIDTH, y2: axisZoneRect.y + axisZoneRect.height, style: {
                    stroke: '#cad7e1',
                    strokeWidth: DEFAULT_BORDER_WIDTH,
                } }, void 0)] }, void 0));
};
export default memo(PlotBorders);
