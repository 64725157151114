import { AXIS_BORDER_WIDTH, AXIS_LABEL_FONT_FAMILY, SCALE_LABEL_FONT_FAMILY, } from '../../consts';
const inlineStyles = {
    axisLine: {
        stroke: '#ccd7e0',
        strokeWidth: `${AXIS_BORDER_WIDTH}px`,
    },
    axisLabel: {
        font: `500 ${AXIS_LABEL_FONT_FAMILY}`,
        userSelect: 'none',
    },
    tickLine: {
        stroke: '#ccd7e0',
        strokeWidth: '1px',
    },
    tickLabel: {
        font: SCALE_LABEL_FONT_FAMILY,
        userSelect: 'none',
    },
};
export default inlineStyles;
