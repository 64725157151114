import { DATA_POINT_STEP, PLOT_BOTTOM_PADDING, PLOT_TOP_PADDING, PLOT_RIGHT_PADDING, PLOT_LEFT_PADDING, } from '../../consts';
import { widthCounter as defaultWidthCounter, calculateGroupsDimensions, } from '../../utils';
import useScale from '../useScale/useScale';
/**
 * Hook calculates and returns dimensions for SVG plot containers and elements
 *
 * @param data - Plot's data
 * @param height - Plot's height
 * @param containerHeight - Plot's container height
 * @param thresholds - Plot's thresholds
 * @param [limits] - Pair of lower and upper limits for data points placement
 * @param [dataPointStep = DATA_POINT_STEP] - Step between two data points
 * @param [counters] - Set of counter function which are used to calculate plot's basic metrics
 * @param [minWidth] - Min allowed width for the plot
 * @returns Hook API
 *  - Scale values
 *  - SVG container height
 *  - SVG container width
 *  - Range of scale values (min and max scale values pair)
 *  - Width of the plot
 *  - Array of group dimensions
 *  - Height of the groups labels section
 *  - Font size for group labels
 *  - Maximum height of label
 */
export default function useDimensions({ data, height, containerHeight, thresholds, limits, dataPointStep = DATA_POINT_STEP, counters = {}, minWidth, }) {
    const { widthCounter = defaultWidthCounter, } = counters;
    const { scale, scaleRange, } = useScale(data, height, containerHeight, limits, thresholds);
    /**
     * Width of plot's area consists of following blocks:
     * - horizontal paddings
     * - canvas padding
     * - axis width
     * - data group delimiters paddings
     * - data points and with the distance between them
     */
    let width = widthCounter(data, dataPointStep);
    let SVGWidth = width
        + PLOT_LEFT_PADDING
        + PLOT_RIGHT_PADDING;
    /**
     * Limit plot's width if corresponding prop is set
     */
    if (minWidth && SVGWidth < minWidth) {
        const widthDelta = minWidth - SVGWidth;
        SVGWidth += widthDelta;
        width += widthDelta;
    }
    const { groupsDimensions, groupLabelFontSize, } = calculateGroupsDimensions(data, dataPointStep);
    const groupsLabelsHeight = groupsDimensions.length > 0
        ? Math.max(...groupsDimensions.map(({ height: groupHeight, heightOffset }) => (groupHeight + heightOffset)))
        : 0;
    const SVGHeight = height
        + groupsLabelsHeight
        + PLOT_TOP_PADDING
        + PLOT_BOTTOM_PADDING;
    const maxGroupLabelHeight = groupsDimensions.length
        ? Math.max.apply(null, groupsDimensions.map((group) => group.length))
        : 1;
    return {
        SVGHeight,
        SVGWidth,
        scale,
        scaleRange,
        width,
        groupLabelFontSize,
        groupsLabelsHeight,
        groupsDimensions,
        maxGroupLabelHeight,
    };
}
