import { SCALE_LABEL_FONT_SIZE, X_SCALE_LABEL_PADDING, Y_SCALE_LABEL_PADDING } from '../../../../consts';
import { range } from '../../../../../../../utils';
export const getScaleCoordinates = (axisRect, scaleRect, scale, numberOfSubTicks, isVertical = false) => {
    const numberOfTicks = scale.length;
    if (numberOfTicks < 2) {
        return {
            ticks: [],
            subTicks: [],
        };
    }
    let subTicks = [];
    const ticks = scale.map((scaleValue, index) => {
        if (isVertical) {
            const tickDistance = scaleRect.height / scale[scale.length - 1];
            const y = scaleRect.y + scaleRect.height - scaleValue * tickDistance;
            const x1 = scaleRect.x + scaleRect.width + Y_SCALE_LABEL_PADDING;
            const x2 = axisRect.x + axisRect.width;
            if (numberOfSubTicks > 0 && index < numberOfTicks - 1) {
                subTicks = subTicks.concat(range(numberOfSubTicks).map((subTickIndex) => {
                    const subTickDistance = (scale[index + 1] - scaleValue) * tickDistance;
                    const subTickY = y - (subTickIndex + 1) * (subTickDistance / (numberOfSubTicks + 1));
                    return {
                        line: {
                            x1: x1 + (x2 - x1) / 2,
                            x2,
                            y1: subTickY,
                            y2: subTickY,
                        },
                    };
                }));
            }
            return {
                text: {
                    x: scaleRect.x,
                    y: y + SCALE_LABEL_FONT_SIZE / 2 - 2,
                },
                line: {
                    x1,
                    x2,
                    y1: y,
                    y2: y,
                },
            };
        }
        const tickDistance = scaleRect.width / scale[scale.length - 1];
        const x = scaleRect.x + scaleValue * tickDistance;
        const y1 = axisRect.y;
        const y2 = scaleRect.y - X_SCALE_LABEL_PADDING;
        if (numberOfSubTicks > 0 && index < numberOfTicks - 1) {
            subTicks = subTicks.concat(range(numberOfSubTicks).map((subTickIndex) => {
                const subTickDistance = (scale[index + 1] - scaleValue) * tickDistance;
                const subTickX = x + (subTickIndex + 1) * (subTickDistance / (numberOfSubTicks + 1));
                return {
                    line: {
                        x1: subTickX,
                        x2: subTickX,
                        y1,
                        y2: y1 + (y2 - y1) / 2,
                    },
                };
            }));
        }
        return {
            text: {
                x,
                y: scaleRect.y + scaleRect.height,
            },
            line: {
                x1: x,
                x2: x,
                y1,
                y2,
            },
        };
    });
    return {
        ticks,
        subTicks,
    };
};
