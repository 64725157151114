import { useMemo } from 'react';
/**
 * Hook creates a config for `TablePagination` component
 *
 * @param tableInstance - Initial config which `useTable` hook accepts
 * @returns Config for pagination component
 */
export default function usePaginationConfig(tableInstance) {
    const { canPreviousPage, canNextPage, pageCount, gotoPage, nextPage, previousPage, setPageSize, state: { pageSize, pageIndex, }, } = tableInstance;
    const paginationConfig = useMemo(() => ({
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        pageSize,
        pageIndex,
    }), [
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        pageSize,
        pageIndex,
    ]);
    return paginationConfig;
}
