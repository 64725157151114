import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { DEFAULT_LAMBDA_FONT_SIZE, DEFAULT_LAMBDA_BORDER_WIDTH } from '../../consts';
const QQPlotLambda = ({ lambdaZone: { value: lambdaText, rect: lambdaZoneRect, }, }) => {
    const textX = lambdaZoneRect.x + lambdaZoneRect.width / 2;
    const textY = (lambdaZoneRect.y
        + lambdaZoneRect.height / 2
        + DEFAULT_LAMBDA_FONT_SIZE / 2
        - 2 // Default text stroke * 2
    );
    return (_jsxs(_Fragment, { children: [_jsx("rect", { x: lambdaZoneRect.x, y: lambdaZoneRect.y, height: lambdaZoneRect.height, width: lambdaZoneRect.width, rx: 2, ry: 2, style: {
                    fill: 'rgba(196,214,0)',
                    fillOpacity: 0.3,
                    stroke: '#c4d600',
                    strokeWidth: DEFAULT_LAMBDA_BORDER_WIDTH,
                } }, void 0), _jsx("text", Object.assign({ x: textX, y: textY, textAnchor: "middle", style: {
                    fontSize: DEFAULT_LAMBDA_FONT_SIZE,
                    fontWeight: 500,
                    stroke: '#830051',
                } }, { children: lambdaText }), void 0)] }, void 0));
};
export default memo(QQPlotLambda);
