import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useTable } from 'react-table';
import classnames from 'classnames';
import { getColumnProps, isConfigObject } from './utils';
import styles from './StaticTable.css';
export const StaticTable = memo(function Table({ columns, data, }) {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, } = useTable({
        columns,
        data,
    });
    return (_jsx("div", Object.assign({ className: styles.tableWrapper }, { children: _jsxs("table", Object.assign({}, getTableProps(), { className: styles.table }, { children: [_jsx("thead", { children: headerGroups.map((headerGroup) => (
                    // `key` property is set by the spread
                    // eslint-disable-next-line react/jsx-key
                    _jsx("tr", Object.assign({}, headerGroup.getHeaderGroupProps(), { className: styles.headersRow }, { children: headerGroup.headers.map((column, columnIndex) => (
                        // eslint-disable-next-line react/jsx-key
                        _jsx("th", Object.assign({}, column.getHeaderProps(), getColumnProps(columns[columnIndex]), { "data-testid": "table-column" }, { children: column.render('Header') }), void 0))) }), void 0))) }, void 0), _jsx("tbody", Object.assign({}, getTableBodyProps(), { children: rows.map((row) => {
                        prepareRow(row);
                        return (
                        // eslint-disable-next-line react/jsx-key
                        _jsx("tr", Object.assign({}, row.getRowProps(), { "data-testid": "table-row" }, { children: row.cells.map((cell) => {
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                                if (isConfigObject(cell.value)) {
                                    const { display, className, rowspan } = cell.value;
                                    if (display === false) {
                                        return null;
                                    }
                                    const aggregatedClass = classnames(className, cell.column.className);
                                    return (
                                    // eslint-disable-next-line react/jsx-key
                                    _jsx("td", Object.assign({}, cell.getCellProps(), { rowSpan: rowspan, className: aggregatedClass }, { children: cell.render('Cell') }), void 0));
                                }
                                return (
                                // eslint-disable-next-line react/jsx-key
                                _jsx("td", Object.assign({}, cell.getCellProps(), { className: cell.column.className }, { children: cell.render('Cell') }), void 0));
                            }) }), void 0));
                    }) }), void 0)] }), void 0) }), void 0));
});
