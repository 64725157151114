import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import { useState, memo } from 'react';
import classNames from 'classnames';
import RadioButton from './RadioButton';
import styles from './Group.css';
function RadioButtonGroup(props) {
    const { defaultValue, groupLabel, onChange, buttonsOptions, id, className, } = props;
    const [value, setValue] = useState(defaultValue);
    const handleChange = (activeValue) => {
        setValue(activeValue);
        onChange(activeValue);
    };
    const renderButtons = () => (_jsx("div", { children: buttonsOptions.map((buttonOptions, index) => {
            const buttonId = `${id}_${index}`;
            return (_createElement(RadioButton, Object.assign({}, buttonOptions, { key: buttonId, id: buttonId, isActive: value === buttonOptions.value, onChange: handleChange })));
        }) }, void 0));
    return (_jsxs("div", Object.assign({ className: classNames(styles.radioGroup, className) }, { children: [groupLabel && _jsx("span", Object.assign({ className: styles.groupLabel }, { children: groupLabel }), void 0), renderButtons()] }), void 0));
}
export default memo(RadioButtonGroup);
