// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Footer__footer__1rquw{background-color:var(--footer-background-color);color:var(--footer-color);height:3.375rem;justify-content:space-between;margin-top:2.625rem;padding:0 5rem;width:100%}.Footer__footer__1rquw,.Footer__leftBlock__J3Xo2{align-items:center;display:flex}.Footer__logo__3qS0k{padding:0;transform:scale(.5) translate(-6rem,-.6rem)}", "",{"version":3,"sources":["webpack://./shared/src/components/Footer/Footer.css"],"names":[],"mappings":"AAAA,uBACE,+CAAgD,CAOhD,yBAA0B,CAN1B,eAAgB,CAIhB,6BAA8B,CAG9B,mBAAoB,CANpB,cAAe,CACf,UAMF,CAEA,iDALE,kBAAmB,CAFnB,YAUF,CAEA,qBAGE,SAAU,CADV,2CAEF","sourcesContent":[".footer {\n  background-color: var(--footer-background-color);\n  height: 3.375rem;\n  padding: 0 5rem;\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  color: var(--footer-color);\n  margin-top: 2.625rem;\n}\n\n.leftBlock {\n  display: flex;\n  align-items: center;\n}\n\n.logo {\n  /* Transform is used instead width change because of the resolution */\n  transform: scale(0.5) translate(-6rem, -0.6rem);\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "Footer__footer__1rquw",
	"leftBlock": "Footer__leftBlock__J3Xo2",
	"logo": "Footer__logo__3qS0k"
};
export default ___CSS_LOADER_EXPORT___;
