// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".field__field__WwsTe,.field__layout__1jA5C{height:32px}.field__input__3Rjsi{border:1px solid var(--placeholder-color);border-radius:4px;font-size:.875rem;line-height:1rem;padding:0 2.5rem 0 2rem}.field__input__3Rjsi:focus{border:1px solid var(--border-active-color)}.field__iconSearch__3RAAO{left:.25rem}.field__options__3f9pn{border:none;box-shadow:0 .75rem 1.625rem 0 var(--box-shadow-color);font-size:.875rem}.field__noOptions__2WZKB{font-size:.875rem;line-height:1rem}.field__highlighted__GnX1O{background-color:var(--dropdown-hover)}", "",{"version":3,"sources":["webpack://./shared/src/components/SearchBoxField/styles/field.css"],"names":[],"mappings":"AAAA,2CAEE,WACF,CAEA,qBAIE,yCAA0C,CAC1C,iBAAkB,CAHlB,iBAAmB,CACnB,gBAAiB,CAFjB,uBAKF,CAEA,2BACE,2CACF,CAEA,0BACE,WACF,CAEA,uBAEE,WAAY,CACZ,sDAAwD,CAFxD,iBAGF,CAEA,yBACE,iBAAmB,CACnB,gBACF,CAEA,2BACE,sCACF","sourcesContent":[".layout,\n.field {\n  height: 32px;\n}\n\n.input {\n  padding: 0 2.5rem 0 2rem;\n  font-size: 0.875rem;\n  line-height: 1rem;\n  border: 1px solid var(--placeholder-color);\n  border-radius: 4px;\n}\n\n.input:focus {\n  border: 1px solid var(--border-active-color);\n}\n\n.iconSearch {\n  left: 0.25rem;\n}\n\n.options {\n  font-size: 0.875rem;\n  border: none;\n  box-shadow: 0 0.75rem 1.625rem 0 var(--box-shadow-color);\n}\n\n.noOptions {\n  font-size: 0.875rem;\n  line-height: 1rem;\n}\n\n.highlighted {\n  background-color: var(--dropdown-hover);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field": "field__field__WwsTe",
	"layout": "field__layout__1jA5C",
	"input": "field__input__3Rjsi",
	"iconSearch": "field__iconSearch__3RAAO",
	"options": "field__options__3f9pn",
	"noOptions": "field__noOptions__2WZKB",
	"highlighted": "field__highlighted__GnX1O"
};
export default ___CSS_LOADER_EXPORT___;
