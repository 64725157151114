// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ManhattanPlotThresholdLabel__thresholdIcon__1rglR{fill:var(--plot-threshold-icon-color);cursor:pointer;pointer-events:all}.ManhattanPlotThresholdLabel__thresholdPlaceholder__10azr{fill:var(--plot-threshold-background-color);transform:translate(9px,9px)}", "",{"version":3,"sources":["webpack://./shared/src/plots/components/ManhattanPlot/components/ManhattanPlotThresholdLabel/ManhattanPlotThresholdLabel.css"],"names":[],"mappings":"AAAA,mDACE,qCAAsC,CACtC,cAAe,CACf,kBACF,CAEA,0DACE,2CAA4C,CAC5C,4BACF","sourcesContent":[".thresholdIcon {\n  fill: var(--plot-threshold-icon-color);\n  cursor: pointer;\n  pointer-events: all;\n}\n\n.thresholdPlaceholder {\n  fill: var(--plot-threshold-background-color);\n  transform: translate(9px, 9px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"thresholdIcon": "ManhattanPlotThresholdLabel__thresholdIcon__1rglR",
	"thresholdPlaceholder": "ManhattanPlotThresholdLabel__thresholdPlaceholder__10azr"
};
export default ___CSS_LOADER_EXPORT___;
