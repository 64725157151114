/**
 * Validates value entered in input
 * Entered text should be a number with only one allowed digit in fraction part
 *
 * @param value - Value to validate
 * @returns If a value is valid or not
 */
export function validateInput(value) {
    return /^[0-9]*\.?[0-9]?$/.test(value);
}
