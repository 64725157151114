import { EDataPointShape } from '../../../types';
export const OODS_RATIO_NUMBER_EXPECT_ERROR = 'Invalid type of `oddsRatio` attribute provided: number expected';
export const OODS_RATIO_NUMBER_FINITE_EXPECT_ERROR = 'Invalid type of `oddsRatio` attribute provided: finite number expected';
/**
 * Get the shape of the plot's point
 *
 * @param data - object with property oddsRatio
 * @returns EDataPointShape
 */
export const getBinaryPlotDataPointShape = ({ oddsRatio, }) => {
    if (typeof oddsRatio !== 'number') {
        throw new Error(OODS_RATIO_NUMBER_EXPECT_ERROR);
    }
    if (Number.isNaN(oddsRatio) || !Number.isFinite(oddsRatio)) {
        throw new Error(OODS_RATIO_NUMBER_FINITE_EXPECT_ERROR);
    }
    if (oddsRatio > 1) {
        return EDataPointShape.TriangleUp;
    }
    if (oddsRatio < 1) {
        return EDataPointShape.TriangleDown;
    }
    return EDataPointShape.Circle;
};
