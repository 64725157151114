import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { DEFAULT_AXIS_BORDER_WIDTH, DEFAULT_AXIS_LABEL_FONT_SIZE } from '../../consts';
import PlotAxisScale from '../PlotAxisScale/PlotAxisScale';
const PlotAxisBase = ({ axisZoneScale, axisLabelZone, lineX1, lineY1, lineX2, lineY2, scaleOrientation, labelTextAnchor, hideRect = null, scroll, inlineStylesGetter, woAxisLines, forExport, }) => {
    const labelStyle = forExport
        ? { fontSize: DEFAULT_AXIS_LABEL_FONT_SIZE, fontFamily: 'Roboto500' }
        : { fontSize: DEFAULT_AXIS_LABEL_FONT_SIZE, fontWeight: 500 };
    return (_jsxs(_Fragment, { children: [!woAxisLines && (_jsx("line", { x1: lineX1, y1: lineY1, x2: lineX2, y2: lineY2, style: {
                    stroke: '#cad7e1',
                    strokeWidth: DEFAULT_AXIS_BORDER_WIDTH,
                } }, void 0)), hideRect, _jsx(PlotAxisScale, { axisScale: axisZoneScale, orientation: scaleOrientation, scroll: scroll, inlineStylesGetter: inlineStylesGetter }, void 0), axisLabelZone && (_jsx("text", Object.assign({ x: axisLabelZone.rect.x, y: axisLabelZone.rect.y, textAnchor: labelTextAnchor, style: labelStyle }, { children: axisLabelZone.value }), void 0))] }, void 0));
};
export default memo(PlotAxisBase);
