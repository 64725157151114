import { getTextWidth } from '../../../../utils';
import { createZone } from '../createZone/createZone';
import { MIN_Y_AXIS_SCALE_LINE_GAP, DEFAULT_FONT_FAMILY, DEFAULT_DATA_ZONE_TOP_PADDING, DEFAULT_DATA_ZONE_BOTTOM_PADDING, DEFAULT_FONT_SIZE, DEFAULT_Y_AXIS_SCALE_PADDING, } from '../../consts';
/**
 * Calculates scale for Y axis
 *
 * Scale is represented by an array of zones for each scale value
 * aggregated by object which contains both text and line zones
 *
 * @param scaleValues - Array of scale values
 * @param axisYZoneRect - Geometry rect of Y axis zone
 * @param plotWidth - Plot's width
 * @param labelYFormatter - Optional formatter for y line labels
 * @returns Y axis scale
 */
export const calculateYAxisScale = (scaleValues, axisYZoneRect, plotWidth, labelYFormatter = (value) => `${value}`) => {
    const scaleHeight = (axisYZoneRect.height
        - DEFAULT_DATA_ZONE_TOP_PADDING
        - DEFAULT_DATA_ZONE_BOTTOM_PADDING);
    /**
     * If there are less than 2 scale values,
     * take the full allowed height as a step
     */
    const scaleValuesHeightStep = scaleValues.length > 1
        ? scaleHeight / (scaleValues.length - 1)
        : scaleHeight;
    return scaleValues.map((scaleValue, index) => {
        const positionY = (axisYZoneRect.y
            + DEFAULT_DATA_ZONE_TOP_PADDING
            + scaleHeight
            - scaleValuesHeightStep * index);
        const labelValue = labelYFormatter(scaleValue);
        // Height is unused since would be calculated based on style
        const labelHeight = DEFAULT_FONT_SIZE;
        const labelWidth = getTextWidth(labelValue, DEFAULT_FONT_FAMILY);
        const labelX = axisYZoneRect.x + DEFAULT_Y_AXIS_SCALE_PADDING;
        const labelY = positionY + labelHeight / 2 - 2; // To center vertical placement
        const lineX = axisYZoneRect.x + axisYZoneRect.width - MIN_Y_AXIS_SCALE_LINE_GAP;
        const lineY = positionY;
        const lineHeight = 1;
        const lineWidth = plotWidth - axisYZoneRect.width + MIN_Y_AXIS_SCALE_LINE_GAP;
        const labelRect = {
            x: labelX,
            y: labelY,
            height: labelHeight,
            width: labelWidth,
        };
        const lineRect = {
            x: lineX,
            y: lineY,
            height: lineHeight,
            width: lineWidth,
        };
        return {
            line: createZone(lineRect, null),
            label: createZone(labelRect, labelValue),
        };
    });
};
