export const DEFAULT_GPA_MANHATTAN_PLOT_AXIS = {
    x: { label: 'Positions (bp)' },
    y: { label: '-log10 (P-value)' },
};
export const DEFAULT_GPA_MANHATTAN_PLOT_UPPER_THRESHOLD = {
    value: 8.3,
    description: 'SIGNIFICANT RESULTS',
};
export const DEFAULT_GPA_MANHATTAN_PLOT_LOWER_THRESHOLD = {
    value: 5,
    description: 'SUGGESTIVE RESULTS',
};
