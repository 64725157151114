import { useState, useRef, useEffect, useLayoutEffect, } from 'react';
const useResizeScreen = (containerRef, tableHeaderRef) => {
    var _a;
    const [scrollWidth, setScrollWidth] = useState(0);
    useLayoutEffect(() => {
        if (tableHeaderRef.current) {
            // add 2 because of table border width
            setScrollWidth(tableHeaderRef.current.clientWidth + 4);
        }
    }, [scrollWidth, tableHeaderRef, (_a = tableHeaderRef.current) === null || _a === void 0 ? void 0 : _a.clientWidth]);
    const resizeObserverRef = useRef();
    useEffect(() => {
        resizeObserverRef.current = new ResizeObserver(() => {
            if (tableHeaderRef.current && containerRef.current) {
                // it needs because right border is not visible
                // when table wider than container and may be scolled
                // and visible when table have a width smaller then container
                const offset = containerRef.current.clientWidth > tableHeaderRef.current.clientWidth + 1
                    ? 2
                    : 1;
                setScrollWidth(tableHeaderRef.current.clientWidth + offset);
            }
        });
    }, [containerRef, tableHeaderRef]);
    useEffect(() => {
        var _a;
        const container = containerRef.current;
        if (container) {
            (_a = resizeObserverRef.current) === null || _a === void 0 ? void 0 : _a.observe(container);
        }
        return () => {
            var _a;
            if (container) {
                (_a = resizeObserverRef.current) === null || _a === void 0 ? void 0 : _a.unobserve(container);
            }
        };
    }, [containerRef, resizeObserverRef]);
    return {
        scrollWidth,
    };
};
export default useResizeScreen;
