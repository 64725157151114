// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ValidationWarning__validationWarning__3ZbEi{background-color:var(--warning-background-color);border-radius:4px;color:var(--button-active-font-color);font-size:.875rem;line-height:1rem;padding:.25rem .375rem .25rem .5rem;position:absolute;top:-2rem;width:100%;z-index:var(--tooltip-z-index)}.ValidationWarning__validationWarningArrow__1Gubo{height:1.125rem;position:absolute;right:.175rem;top:-.75rem;width:1.5rem;z-index:var(--base-z-index)}", "",{"version":3,"sources":["webpack://./shared/src/components/ValidationWarning/ValidationWarning.css"],"names":[],"mappings":"AAAA,6CAQE,gDAAiD,CAFjD,iBAAkB,CAClB,qCAAsC,CAFtC,iBAAmB,CAHnB,gBAAiB,CAOjB,mCAAwC,CANxC,iBAAkB,CAClB,SAAU,CAHV,UAAW,CASX,8BACF,CAEA,kDACE,eAAgB,CAEhB,iBAAkB,CAClB,aAAe,CACf,WAAa,CAHb,YAAa,CAIb,2BACF","sourcesContent":[".validationWarning {\n  width: 100%;\n  line-height: 1rem;\n  position: absolute;\n  top: -2rem;\n  font-size: 0.875rem;\n  border-radius: 4px;\n  color: var(--button-active-font-color);\n  background-color: var(--warning-background-color);\n  padding: 0.25rem 0.375rem 0.25rem 0.5rem;\n  z-index: var(--tooltip-z-index);\n}\n\n.validationWarningArrow {\n  height: 1.125rem;\n  width: 1.5rem;\n  position: absolute;\n  right: 0.175rem;\n  top: -0.75rem;\n  z-index: var(--base-z-index);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"validationWarning": "ValidationWarning__validationWarning__3ZbEi",
	"validationWarningArrow": "ValidationWarning__validationWarningArrow__1Gubo"
};
export default ___CSS_LOADER_EXPORT___;
