import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import SVGLegend from '../../../SVGLegend/SVGLegend';
import stylesCSS from './LollipopPlotSVGLegend.css';
import inlineStyles from './LollipopPlotSVGLegend.style';
const LollipopSVGPlotSVGLegend = memo(function LollipopSVGPlotSVGLegend(props) {
    const { data, maxWidth, title, } = props;
    const styles = useMemo(() => ({
        oddItemLabelText: {
            className: stylesCSS.oddDomainLabelText,
            style: inlineStyles.oddDomainLabelText,
        },
        oddItemLabelRect: {
            className: stylesCSS.oddDomainLabelRect,
            style: inlineStyles.oddDomainLabelRect,
        },
        evenItemLabelText: {
            className: stylesCSS.evenDomainLabelText,
            style: inlineStyles.evenDomainLabelText,
        },
        evenItemLabelRect: {
            className: stylesCSS.evenDomainLabelRect,
            style: inlineStyles.evenDomainLabelRect,
        },
    }), []);
    return (_jsx(SVGLegend, { data: data, maxWidth: maxWidth, title: title, styles: styles, useInlineStyles: true }, void 0));
});
export default LollipopSVGPlotSVGLegend;
