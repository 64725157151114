// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DefinitionModelWithSeparatedLists__description__XZrfN{font-size:.875rem;line-height:1.125rem;margin-bottom:9px}.DefinitionModelWithSeparatedLists__separator__1eSbh{margin-bottom:0}", "",{"version":3,"sources":["webpack://./shared/src/components/DefinitionModelWithSeparatedLists/DefinitionModelWithSeparatedLists.css"],"names":[],"mappings":"AAAA,uDACE,iBAAmB,CACnB,oBAAqB,CACrB,iBACF,CAEA,qDACE,eACF","sourcesContent":[".description {\n  font-size: 0.875rem;\n  line-height: 1.125rem;\n  margin-bottom: 9px;\n}\n\n.separator {\n  margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"description": "DefinitionModelWithSeparatedLists__description__XZrfN",
	"separator": "DefinitionModelWithSeparatedLists__separator__1eSbh"
};
export default ___CSS_LOADER_EXPORT___;
