export const phenotypeLinkSort = (rowA, rowB, columnId) => {
    var _a, _b, _c, _d, _e, _f;
    const labelA = ((_a = rowA.values[columnId]) === null || _a === void 0 ? void 0 : _a.name)
        || ((_c = (_b = rowA.original) === null || _b === void 0 ? void 0 : _b.phenotype) === null || _c === void 0 ? void 0 : _c.name)
        || '';
    const labelB = ((_d = rowB.values[columnId]) === null || _d === void 0 ? void 0 : _d.name)
        || ((_f = (_e = rowB.original) === null || _e === void 0 ? void 0 : _e.phenotype) === null || _f === void 0 ? void 0 : _f.name)
        || '';
    return labelA.localeCompare(labelB);
};
