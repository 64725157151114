import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { LEFT_AXIS_SCALE_WIDTH, TOP_LABEL_HEIGHT, BOTTOM_AXIS_SCALE_HEIGHT, BOTTOM_LABEL_PADDING, AXIS_BORDER_WIDTH, PLOT_LEFT_PADDING, PLOT_TOP_PADDING, PLOT_BOTTOM_PADDING, AXIS_BACKGROUND_HEIGHT, DATA_CONTAINER_DIFFERENCE, LABEL_Y_PADDING_TOP, } from '../../consts';
import ManhattanPlotLayer from '../ManhattanPlotLayer/ManhattanPlotLayer';
import styles from './ManhattanPlotAxis.css';
import inlineStyles from './ManhattanPlotAxis.style';
const ManhattanPlotAxis = (props) => {
    const { height, width, axis: { x: { label: axisXLabel, }, y: { label: axisYLabel, }, }, withOverflow = false, useInlineStyles, legend, } = props;
    const bottomAxisPosition = height - BOTTOM_AXIS_SCALE_HEIGHT;
    const overflowHeight = (height
        + PLOT_TOP_PADDING
        + 1 // Width of the border
    );
    return (_jsxs(ManhattanPlotLayer, { children: [withOverflow && (_jsxs(_Fragment, { children: [_jsx("rect", Object.assign({ className: styles.axisBackground }, useInlineStyles && {
                        style: inlineStyles.axisBackground,
                    }, { x: -PLOT_LEFT_PADDING, y: -PLOT_TOP_PADDING, width: PLOT_LEFT_PADDING + LEFT_AXIS_SCALE_WIDTH, height: overflowHeight }), void 0), _jsx("rect", Object.assign({ className: styles.axisBackground }, useInlineStyles && {
                        style: inlineStyles.axisBackground,
                    }, { x: -PLOT_LEFT_PADDING, y: -PLOT_TOP_PADDING - DATA_CONTAINER_DIFFERENCE, width: width + PLOT_LEFT_PADDING, height: AXIS_BACKGROUND_HEIGHT }), void 0), _jsx("rect", Object.assign({ className: styles.axisBackground }, useInlineStyles && {
                        style: inlineStyles.axisBackground,
                    }, { x: -PLOT_LEFT_PADDING, y: overflowHeight - PLOT_BOTTOM_PADDING - TOP_LABEL_HEIGHT, width: width + PLOT_LEFT_PADDING, height: AXIS_BACKGROUND_HEIGHT }), void 0)] }, void 0)), _jsx("line", Object.assign({ className: styles.axisLine }, useInlineStyles && {
                style: inlineStyles.axisLine,
            }, { x1: LEFT_AXIS_SCALE_WIDTH + AXIS_BORDER_WIDTH / 2, y1: TOP_LABEL_HEIGHT, x2: LEFT_AXIS_SCALE_WIDTH + AXIS_BORDER_WIDTH / 2, y2: bottomAxisPosition }), void 0), _jsx("line", Object.assign({ className: styles.axisLine }, useInlineStyles && {
                style: inlineStyles.axisLine,
            }, { x1: LEFT_AXIS_SCALE_WIDTH, y1: bottomAxisPosition, x2: width, y2: bottomAxisPosition }), void 0), _jsx("line", Object.assign({ className: styles.axisScaleLine }, useInlineStyles && {
                style: inlineStyles.axisScaleLine,
            }, { x1: width, y1: TOP_LABEL_HEIGHT, x2: width, y2: bottomAxisPosition }), void 0), axisXLabel && (_jsx("text", Object.assign({ className: styles.axisLabel }, useInlineStyles && {
                style: inlineStyles.axisLabel,
            }, { x: width, y: height - BOTTOM_AXIS_SCALE_HEIGHT + BOTTOM_LABEL_PADDING, textAnchor: "end" }, { children: axisXLabel }), void 0)), axisYLabel && (_jsx("text", Object.assign({ className: styles.axisLabel }, useInlineStyles && {
                style: inlineStyles.axisLabel,
            }, { x: LEFT_AXIS_SCALE_WIDTH, y: LABEL_Y_PADDING_TOP }, { children: axisYLabel }), void 0)), legend && (_jsx("g", Object.assign({ transform: `translate(${width}, ${LABEL_Y_PADDING_TOP})` }, { children: legend }), void 0))] }, void 0));
};
export default memo(ManhattanPlotAxis);
