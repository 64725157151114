import { v4 as uuid4 } from 'uuid';
/**
 * Class represents a point on Manhattan plot
 *
 * @implements IManhattanPlotDataPoint<T>
 * @implements ISerializable<TSerializedManhattanPlotDataPoint<T>>
 */
export class ManhattanPlotDataPoint {
    /**
     * Creates new instance
     *
     * @param data - Point's data object
     * @param coordinates - [X, Y] coordinates
     * @param [id]- Unique id
     * @returns New instance
     */
    constructor(data, coordinates, id = uuid4(), dataset, levelResult, traitsType) {
        [this.x, this.y] = coordinates;
        this.data = data;
        this.id = id;
        this.dataset = dataset;
        this.levelResult = levelResult;
        this.traitsType = traitsType;
    }
    /**
     * Creates new instance from serialized JSON data
     *
     * @static
     * @param rawData - Serialized JSON data
     * @returns New instance
     */
    static fromJSON(rawData) {
        return new ManhattanPlotDataPoint(rawData.data, [rawData.x, rawData.y], rawData.id, rawData.dataset, rawData.levelResult, rawData.traitsType);
    }
    toJSON() {
        return {
            id: this.id,
            x: this.x,
            y: this.y,
            data: this.data,
        };
    }
}
