import styles from './checkIfCellHasEllipsis.css';
export const checkIfCellElHasEllipsis = (node) => {
    if (node && node.current) {
        const el = node.current;
        const clone = el.cloneNode(true);
        const elPadding = getComputedStyle(el).padding;
        clone.style.position = 'absolute';
        clone.style.visibility = 'hidden';
        clone.style.overflow = 'hidden';
        clone.style.textOverflow = 'none';
        clone.style.padding = elPadding;
        clone.style.fontSize = '0.875rem';
        clone.style.lineHeight = '1rem';
        clone.style.top = '0';
        clone.style.left = '0';
        clone.style.borderRight = '1px solid var(--border-color)';
        clone.style.borderBottom = '1px solid var(--border-color)';
        if (clone.children.item(0) !== null) {
            clone.children.item(0).className = styles.childOfClonedElement;
        }
        document.body.appendChild(clone);
        const hasEllipsis = clone.offsetHeight > el.offsetHeight;
        document.body.removeChild(clone);
        return hasEllipsis;
    }
    return false;
};
