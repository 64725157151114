import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useTooltip } from '../../../../../hooks';
import { TOOLTIP_ID } from '../../consts';
import QQPlotReferenceLine from '../QQPlotReferenceLine/QQPlotReferenceLine';
import QQPlotTooltip from '../QQPlotTooltip/QQPlotTooltip';
import QQPlotDataPoint from '../QQPlotDataPoint/QQPlotDataPoint';
const QQPlotData = ({ dataZone: { value: data, rect: dataZoneRect, }, maxAxisScales, tooltipContent, forExport, }) => {
    const { tooltipId, tooltipAPI } = useTooltip(TOOLTIP_ID);
    let handleTooltipShow;
    let handleTooltipHide;
    if (!forExport) {
        const { handleTooltipShowWithDelay, handleTooltipHideWithDelay, } = tooltipAPI;
        handleTooltipShow = handleTooltipShowWithDelay;
        handleTooltipHide = handleTooltipHideWithDelay;
    }
    return (_jsxs(_Fragment, { children: [_jsx(QQPlotReferenceLine, { dataZoneRect: dataZoneRect, maxAxisScales: maxAxisScales }, void 0), !forExport && (_jsx(QQPlotTooltip, { tooltipId: tooltipId, tooltipContent: tooltipContent, tooltipAPI: tooltipAPI }, void 0)), data.map((dataPointZone, i) => (_jsx(QQPlotDataPoint, { dataPointZone: dataPointZone, handleTooltipShow: handleTooltipShow, handleTooltipHide: handleTooltipHide }, `${dataPointZone.value.id}-${i + 1}`)))] }, void 0));
};
export default memo(QQPlotData);
