import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import LollipopPlotAxisLine from '../LollipopPlotAxisLine';
import LollipopPlotAxisScale from '../LollipopPlotAxisScale';
import LollipopPlotAxisLabel from '../LollipopPlotAxisLabel';
const LollipopPlotAxis = memo(function LollipopPlotAxis(props) {
    const { label, labelPosition, labelAnchor, linePosition, lineWidth, scalePosition, scaleTextAnchor, useInlineStyles, } = props;
    return (_jsxs(_Fragment, { children: [linePosition && (_jsx(LollipopPlotAxisLine, { x1: linePosition.x1, x2: linePosition.x2, y1: linePosition.y1, y2: linePosition.y2, width: lineWidth, useInlineStyles: useInlineStyles }, void 0)), scalePosition && (_jsx(LollipopPlotAxisScale, { scalePosition: scalePosition, textAnchor: scaleTextAnchor, useInlineStyles: useInlineStyles }, void 0)), labelPosition && (_jsx(LollipopPlotAxisLabel, { x: labelPosition.x, y: labelPosition.y, textAnchor: labelAnchor, label: label, useInlineStyles: useInlineStyles }, void 0))] }, void 0));
});
export default LollipopPlotAxis;
