import { EVENT_TYPE_CLICK, EVENT_TYPE_VIEW, } from '../../types/analytics';
export const getUtagData = ({ tealiumEvent, eventType, pageType = 'react', }) => ({
    page_type: pageType,
    page_name: tealiumEvent,
    view_name: tealiumEvent,
    event_type: eventType,
    event_name: tealiumEvent,
    tealium_event: tealiumEvent,
});
export const getClickUtagData = ({ tealiumEvent, pageType = 'react', }) => getUtagData({
    eventType: EVENT_TYPE_CLICK,
    tealiumEvent,
    pageType,
});
export const getViewUtagData = ({ tealiumEvent, pageType = 'react', }) => getUtagData({
    eventType: EVENT_TYPE_VIEW,
    tealiumEvent,
    pageType,
});
export const getSearchClickUtagData = ({ query, tealiumEvent, pageType = 'react', }) => (Object.assign(Object.assign({}, getClickUtagData({ tealiumEvent, pageType })), { search_event: tealiumEvent, search_string: query }));
