// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TraitsOrganizer__container__3XCy3{margin:0;padding-left:0}.TraitsOrganizer__container__3XCy3 li{display:flex}.TraitsOrganizer__container__3XCy3 label{font-size:.875rem;font-weight:400}", "",{"version":3,"sources":["webpack://./shared/src/plots/components/ManhattanPlot/components/PlotSettings/components/TraitsOrganizer/TraitsOrganizer.css"],"names":[],"mappings":"AAAA,mCAEE,QAAS,CADT,cAEF,CAEA,sCACE,YACF,CAEA,yCACE,iBAAmB,CACnB,eACF","sourcesContent":[".container {\n  padding-left: 0;\n  margin: 0;\n}\n\n.container li {\n  display: flex;\n}\n\n.container label {\n  font-size: 0.875rem;\n  font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "TraitsOrganizer__container__3XCy3"
};
export default ___CSS_LOADER_EXPORT___;
