/**
 * Download data
 *
 * @param data - Data that will be put inside the File and will be download
 * @param fileName - FileName
 * @param type - A DOMString representing the MIME type of the content
 * that will be put into the file. Defaults to a value of "".
 */
export const downloadData = (data, fileName, type) => {
    if (window.navigator.msSaveOrOpenBlob && window.Blob) {
        const blob = new Blob([data], { type });
        window.navigator.msSaveOrOpenBlob(blob, fileName);
    }
    else {
        const file = new File([data], fileName, { type });
        const a = document.createElement('a');
        a.href = URL.createObjectURL(file);
        a.download = fileName;
        a.click();
        a.remove();
    }
};
