import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { memo, useState, useCallback, useMemo, useEffect, } from 'react';
import classnames from 'classnames';
import SelectButtonIcon from '../../assets/icons/select.svg';
import { getDefaultOption } from '../../utils';
import { SearchField } from '../SearchField';
import styles from './AutocompleteField.css';
function AutocompleteFieldBase(props) {
    const { value, onChange, onBlur, onKeyDown, options, getOptionLabel, getOptionValue, filterOptions = getDefaultOption, disabled = false, placeholder = '', className, optionsListClassName, inputClassName, optionClassName, selectedOptionClassName = '', optionIconClassName, } = props;
    const [searchValue, setSearchValue] = useState('');
    const [isSuggestionVisible, setIsSuggestionVisible] = useState(false);
    useEffect(() => {
        setSearchValue(value ? getOptionLabel(value) : '');
    }, [getOptionLabel, value]);
    useEffect(() => {
        if (disabled) {
            setIsSuggestionVisible(false);
        }
    }, [disabled]);
    const setFilterByItem = useCallback((item) => {
        setIsSuggestionVisible(false);
        onChange(item);
    }, [onChange]);
    const handleClick = useCallback((event, item) => {
        event.stopPropagation();
        setSearchValue(getOptionLabel(item));
        setFilterByItem(item);
    }, [setFilterByItem, getOptionLabel]);
    const handleBlur = useCallback((event) => {
        if (onBlur) {
            onBlur(event);
        }
        if (searchValue === '') {
            onChange(null);
        }
        setIsSuggestionVisible(false);
    }, [onBlur, searchValue, onChange]);
    const handleChangeSearchValue = useCallback((inputValue) => {
        setSearchValue(inputValue);
        setIsSuggestionVisible(inputValue.trim() !== '');
    }, []);
    const handleFocus = useCallback(() => {
        if (searchValue.length > 0) {
            setIsSuggestionVisible(true);
        }
    }, [searchValue.length]);
    const filteredOptions = useMemo(() => filterOptions(options, searchValue, getOptionLabel)
        .map((item, i) => {
        const isSelected = value ? getOptionValue(value) === getOptionValue(item) : false;
        return (_jsxs("li", Object.assign({ className: classnames(styles.searchSuggestion, optionClassName, {
                [selectedOptionClassName]: isSelected,
            }), onMouseDown: (event) => handleClick(event, item), "data-testid": getOptionValue(item), "aria-label": getOptionValue(item) }, { children: [getOptionLabel(item), isSelected && _jsx(SelectButtonIcon, { className: optionIconClassName }, void 0)] }), `${getOptionLabel(item)}-${i + 1}`));
    }), [
        filterOptions,
        getOptionLabel,
        getOptionValue,
        handleClick,
        optionClassName,
        optionIconClassName,
        options,
        searchValue,
        selectedOptionClassName,
        value,
    ]);
    return (_jsxs("div", Object.assign({ className: classnames(styles.container, className), onBlur: handleBlur, "data-testid": `AutocompleteField ${placeholder}` }, { children: [_jsx(SearchField, { value: searchValue, placeholder: placeholder, onChange: handleChangeSearchValue, onFocus: handleFocus, onKeyDown: onKeyDown, disabled: disabled, customBaseClassName: styles.controls, customInputClassName: classnames(styles.input, inputClassName), customIconSearchClassName: classnames(styles.icon, styles.iconSearch) }, void 0), isSuggestionVisible && filteredOptions.length > 0 && (_jsx("ul", Object.assign({ className: classnames(styles.searchSuggestionsContainer, optionsListClassName) }, { children: filteredOptions }), void 0))] }), void 0));
}
export const AutocompleteField = memo(AutocompleteFieldBase);
