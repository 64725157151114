// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FilterByMostSignificantModel__tooltip__15_P6{font-weight:400;line-height:1rem;width:15.5rem}.FilterByMostSignificantModel__tooltipContainer__3z9LZ{height:100%}.FilterByMostSignificantModel__container__ajXEE,.FilterByMostSignificantModel__tooltipContainer__3z9LZ{align-items:center;display:flex}.FilterByMostSignificantModel__container__ajXEE{flex-direction:row;gap:.375rem}.FilterByMostSignificantModel__label__1d5L5{font-size:.875rem;font-weight:400;line-height:1.25rem}.FilterByMostSignificantModel__togglerContainer__3TMXz{align-items:center;display:flex}.FilterByMostSignificantModel__toggler__39Wss{background-color:var(--neutral-400)}.FilterByMostSignificantModel__toggler__39Wss:before{background-color:var(--neutral-150)}", "",{"version":3,"sources":["webpack://./shared/src/components/FilterByMostSignificantModel/FilterByMostSignificantModel.css"],"names":[],"mappings":"AAAA,8CAEE,eAAmB,CACnB,gBAAiB,CAFjB,aAGF,CAEA,uDACE,WAGF,CAEA,uGAHE,kBAAmB,CADnB,YASF,CALA,gDAEE,kBAAmB,CACnB,WAEF,CAEA,4CAEE,iBAAmB,CADnB,eAAgB,CAEhB,mBACF,CAEA,uDAEE,kBAAmB,CADnB,YAEF,CAEA,8CACE,mCAKF,CAHE,qDACE,mCACF","sourcesContent":[".tooltip {\n  width: 15.5rem;\n  font-weight: normal;\n  line-height: 1rem;\n}\n\n.tooltipContainer {\n  height: 100%;\n  display: flex;\n  align-items: center;\n}\n\n.container {\n  display: flex;\n  flex-direction: row;\n  gap: 0.375rem;\n  align-items: center;\n}\n\n.label {\n  font-weight: 400;\n  font-size: 0.875rem;\n  line-height: 1.25rem;\n}\n\n.togglerContainer {\n  display: flex;\n  align-items: center;\n}\n\n.toggler {\n  background-color: var(--neutral-400);\n\n  &::before {\n    background-color: var(--neutral-150);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": "FilterByMostSignificantModel__tooltip__15_P6",
	"tooltipContainer": "FilterByMostSignificantModel__tooltipContainer__3z9LZ",
	"container": "FilterByMostSignificantModel__container__ajXEE",
	"label": "FilterByMostSignificantModel__label__1d5L5",
	"togglerContainer": "FilterByMostSignificantModel__togglerContainer__3TMXz",
	"toggler": "FilterByMostSignificantModel__toggler__39Wss"
};
export default ___CSS_LOADER_EXPORT___;
