import { DEFAULT_SVG_FONT, TEXT_WIDTH_APPROXIMATOR_ID } from '../../consts';
import styles from './getTextWidth.css';
let canvas;
let container;
/**
 * Gets width in px for the given text
 *
 * @param text - Text pattern to calculate width of
 * @param [font] - Font used by the text
 * @returns Width in px
 */
export default function getTextWidth(text, font = DEFAULT_SVG_FONT) {
    if (!canvas) {
        canvas = document.createElement('canvas');
    }
    const context = canvas.getContext('2d');
    if (!context) {
        if (!container) {
            container = document.createElement('div');
            container.id = TEXT_WIDTH_APPROXIMATOR_ID;
            container.classList.add(styles.container);
        }
        if (!document.getElementById(TEXT_WIDTH_APPROXIMATOR_ID)) {
            document.body.appendChild(container);
        }
        container.style.font = font;
        container.innerHTML = text;
        return container.clientWidth;
    }
    context.font = font;
    const { width } = context.measureText(text);
    return width;
}
