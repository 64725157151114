import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useRef } from 'react';
import { useDrag, useDrop, } from 'react-dnd';
import classNames from 'classnames';
import { CheckboxField } from '../../../../../CheckboxField';
import MoveIcon from '../../../../../../assets/icons/burgerMenu.svg';
import styles from '../../TableSettings.css';
export const SettingsListItem = memo(function SettingsListItem({ id, index, column, handleChange, handleMove, handleDrop, }) {
    const ref = useRef(null);
    const [{ handlerId }, drop] = useDrop({
        accept: 'column',
        collect: (monitor) => ({
            handlerId: monitor.getHandlerId(),
        }),
        hover: (item, monitor) => {
            var _a;
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            if (dragIndex === hoverIndex) {
                return;
            }
            const hoverBoundingRect = (_a = ref.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            handleMove(dragIndex, hoverIndex);
            /* eslint-disable-next-line no-param-reassign */
            item.index = hoverIndex;
        },
    });
    const [{ isDragging }, drag] = useDrag({
        type: 'column',
        item: () => ({ id, index }),
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: () => {
            if (handleDrop) {
                handleDrop();
            }
        },
    });
    const previewClass = classNames({
        [styles.preview]: isDragging,
    });
    drag(drop(ref));
    return (_jsxs("li", Object.assign({ ref: column.fixed ? null : ref, "data-handler-id": handlerId, "data-testid": "SettingsListItem", className: previewClass }, { children: [_jsx("div", Object.assign({ className: styles.moveIconWrapper }, { children: _jsx(MoveIcon, {}, void 0) }), void 0), _jsx(CheckboxField, { id: `${index}`, className: styles.checkbox, onChange: handleChange, label: column.Header, checked: !column.hidden, disabled: column.fixed }, void 0)] }), void 0));
});
