import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState, memo, } from 'react';
import classnames from 'classnames';
import SelectFieldIcon from '../../assets/icons/dropdown.svg';
import { Button } from '../Button';
import styles from './ExpandableSection.css';
export const ExpandableSection = memo(function ExpandableSection(props) {
    const { children, onClick, className, buttonClassName, isOpen = false, label = '', isShowChildrenPartly = false, } = props;
    const [isOpenSection, setIsOpenSection] = useState(isOpen);
    const onButtonClick = useCallback(() => {
        if (onClick) {
            onClick();
        }
        setIsOpenSection((value) => !value);
    }, [onClick]);
    const isExpandedPartly = isShowChildrenPartly && !isOpenSection;
    const expandableButtonClassName = classnames(styles.button, buttonClassName);
    const childrenClassName = classnames(styles.container, {
        [styles.expandedPartlyContainer]: isExpandedPartly,
        [styles.expandedContainer]: isOpenSection,
    });
    return (_jsxs("div", Object.assign({ className: className }, { children: [(isOpenSection || isExpandedPartly)
                && (_jsx("div", Object.assign({ className: childrenClassName }, { children: children }), void 0)), _jsxs(Button, Object.assign({ testId: "expandableButton", className: expandableButtonClassName, onClick: onButtonClick }, { children: [label, _jsx(SelectFieldIcon, { className: classnames(styles.icon, { [styles.openSection]: isOpenSection }) }, void 0)] }), void 0)] }), void 0));
});
