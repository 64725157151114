import { useState } from 'react';
import { logger, ELogEnvironment } from '../../utils/logger/logger';
/**
 * Hook for using localStorage as a state storage
 *
 * @template T
 *
 * @param key - Key to access value in localStorage
 * @param initialValue - Default value if localStorage
 *  does not contain any by a given key
 * @param [fallbackToState = false] - Use React state instead of localStorage
 * @returns Public API for LocalStorage hook:
 *  - stored value
 *  - value setter
 */
export function useLocalStorage(key, initialValue, fallbackToState = false) {
    const [storedValue, setStoredValue] = useState(() => {
        if (fallbackToState) {
            return initialValue;
        }
        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        }
        catch (error) {
            logger.error(error.message, ELogEnvironment.All);
            return initialValue;
        }
    });
    /**
     * Sets a new value to be stored in localStorage
     *
     * @param value - New value
     */
    const setValue = (value) => {
        const valueToStore = value instanceof Function
            ? value(storedValue)
            : value;
        setStoredValue(valueToStore);
        if (fallbackToState) {
            return;
        }
        try {
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
        }
        catch (error) {
            logger.error(error.message, ELogEnvironment.All);
        }
    };
    return [storedValue, setValue];
}
