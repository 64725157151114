import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import classnames from 'classnames';
import { filledArray } from '../../../../utils';
import getColumnProps from '../../utils/getColumnProps/getColumnProps';
import { ROW_INDEX_ATTRIBUTE } from '../../hooks';
import { TableViewCell } from './components';
import { TableSortingIcon } from '../TableSortingIcon';
import { Tooltip, ETooltipAction, ETooltipPlacement } from '../../../Tooltip';
import styles from './TableView.css';
export const NO_FOUND_RECORDS_MESSAGE = 'No matching records found';
const TableView = (props) => {
    const { getTableProps, getTableBodyProps, prepareRow, headerGroups, page, columns, hasSorting = true, onSortByChange, withColumnNumbers = true, startColumnNumber = 1, handleScroll, tableHeaderRef, onlyHeader = false, headerWidthArray, } = props;
    const columnNumbers = useMemo(() => {
        if (withColumnNumbers) {
            return (_jsx("tr", Object.assign({ className: styles.columnNumbers }, { children: filledArray(columns.length, (index) => (_jsx("th", Object.assign({ className: styles.columnNumber }, getColumnProps(columns[index], headerWidthArray === null || headerWidthArray === void 0 ? void 0 : headerWidthArray[index]), { children: startColumnNumber + index }), `column_${columns[index].accessor}`))) }), void 0));
        }
        return null;
    }, [columns, startColumnNumber, withColumnNumbers, headerWidthArray]);
    const headerTooltipDescriptionClassName = useMemo(() => (classnames(styles.tooltipSpacing, styles.tooltipParagraph)), []);
    return (_jsx("div", Object.assign({ className: styles.tableWrapper, onScroll: handleScroll }, { children: _jsxs("table", Object.assign({}, getTableProps(), { className: classnames(styles.table) }, { children: [_jsxs("thead", Object.assign({ "data-testid": "thead", ref: tableHeaderRef }, { children: [columnNumbers, headerGroups.map((headerGroup) => (
                        // `key` property is set by the spread
                        /* eslint-disable-next-line react/jsx-key */
                        _jsx("tr", Object.assign({}, headerGroup.getHeaderGroupProps(), { className: styles.headersRow }, { children: headerGroup.headers.map((column, columnIndex) => {
                                var _a, _b;
                                return (_jsx(Tooltip, Object.assign({ id: `tooltip_${column.id}`, action: ETooltipAction.Hover, placement: ETooltipPlacement.BottomCenter, className: styles.tableTooltip, tooltipContent: (_jsxs(_Fragment, { children: [_jsx("p", Object.assign({ className: styles.tooltipParagraph }, { children: _jsx("strong", { children: column.Header }, void 0) }), void 0), ((_a = columns[columnIndex]) === null || _a === void 0 ? void 0 : _a.description) && (_jsx("p", Object.assign({ className: headerTooltipDescriptionClassName }, { children: (_b = columns[columnIndex]) === null || _b === void 0 ? void 0 : _b.description }), void 0))] }, void 0)) }, { children: _jsx("th", Object.assign({}, getColumnProps(columns[columnIndex], headerWidthArray === null || headerWidthArray === void 0 ? void 0 : headerWidthArray[columnIndex]), column.getHeaderProps(), { onClick: (event) => event.stopPropagation() }, { children: _jsxs("div", Object.assign({ className: styles.headerCell }, { children: [_jsx("div", Object.assign({ className: styles.headerContent }, { children: column.render('Header') }), void 0), hasSorting && !column.disableSortBy && (_jsx(TableSortingIcon, { columnId: column.id, toggleSortBy: column.toggleSortBy, clearSortBy: column.clearSortBy, isSorted: column.isSorted, isSortedDesc: column.isSortedDesc, onSortByChange: onSortByChange }, void 0))] }), void 0) }), void 0) }), column.id));
                            }) }), void 0)))] }), void 0), !onlyHeader && (_jsxs("tbody", Object.assign({}, getTableBodyProps(), { children: [page.map((row, rowIndex) => {
                            prepareRow(row);
                            return (
                            // `key` property is set by the spread
                            /* eslint-disable-next-line react/jsx-key */
                            _jsx("tr", Object.assign({}, row.getRowProps(), { tabIndex: 0 }, { [ROW_INDEX_ATTRIBUTE]: rowIndex }, { children: row.cells.map((cell, columnIndex) => {
                                    var _a;
                                    const cellToRender = cell.render('Cell');
                                    const columnHeaderName = typeof ((_a = cell.column) === null || _a === void 0 ? void 0 : _a.Header) === 'string'
                                        ? cell.column.Header
                                        : '';
                                    return (_jsx(TableViewCell
                                    // eslint-disable-next-line react/no-array-index-key
                                    , Object.assign({ tooltipContent: (cellToRender || ''), showOnlyOnEllipsis: true, columnProps: Object.assign({}, getColumnProps(columns[columnIndex], headerWidthArray === null || headerWidthArray === void 0 ? void 0 : headerWidthArray[columnIndex])), cellProps: Object.assign({}, cell.getCellProps([{ className: cell.column.className }])), columnHeaderName: columnHeaderName }, { children: cellToRender }), `cell-tooltip-key-${row.id}-${rowIndex}-${columnIndex}`));
                                }) }), void 0));
                        }), page.length === 0 && (_jsx("tr", Object.assign({ className: styles.emptyRow }, { children: _jsx("td", Object.assign({ colSpan: columns.length }, { children: NO_FOUND_RECORDS_MESSAGE }), void 0) }), void 0))] }), void 0))] }), void 0) }), void 0));
};
export default memo(TableView);
