// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ExpandedText__content__2rf7p{margin-bottom:.5rem}.ExpandedText__partiallyChildren__ZgLR0{max-height:3.5rem;overflow:hidden;text-overflow:ellipsis}.ExpandedText__buttonShowMore__Jfwsd{margin:1.125rem 0 1.5rem}", "",{"version":3,"sources":["webpack://./shared/src/components/ExpandedText/ExpandedText.css"],"names":[],"mappings":"AAAA,8BACE,mBACF,CAEA,wCACE,iBAAkB,CAClB,eAAgB,CAChB,sBACF,CAEA,qCACE,wBACF","sourcesContent":[".content {\n  margin-bottom: 0.5rem;\n}\n\n.partiallyChildren {\n  max-height: 3.5rem;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.buttonShowMore {\n  margin: 1.125rem 0 1.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "ExpandedText__content__2rf7p",
	"partiallyChildren": "ExpandedText__partiallyChildren__ZgLR0",
	"buttonShowMore": "ExpandedText__buttonShowMore__Jfwsd"
};
export default ___CSS_LOADER_EXPORT___;
