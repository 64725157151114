import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { memo } from 'react';
import PlotBorders from '../components/PlotBorders/PlotBorders';
import { DEFAULT_PLOT_HEIGHT, DEFAULT_PLOT_WIDTH } from '../consts';
import { PlotAxisX, PlotAxisY } from '../components/PlotAxis';
import { useZoneConstructor } from './hooks';
import QQPlotData from './components/QQPlotData/QQPlotData';
import QQPlotLambda from './components/QQPlotLambda/QQPlotLambda';
import QQHeadersForExport from './components/QQHeadersForExport/QQHeadersForExport';
import { EXPORT_HEADERS_ADDITIONAL_HEIGHT, EXPORT_HEADERS_HEIGHT, EXPORT_PADDING, PHENOTYPE_WORD_WIDTH, } from './consts';
import { divideStringToArrayOfStringsMaxWidth } from '../../../../utils/divideStringToArrayOfStringsMaxWidth/divideStringToArrayOfStringsMaxWidth';
import { importInlineFont } from '../../../../utils/importInlineFont/importInlineFont';
import fonts from '../../../../assets/fonts/inline.json';
const QQPlot = ({ data, height = DEFAULT_PLOT_HEIGHT, width = DEFAULT_PLOT_WIDTH, maxPlotValues, axisTitles = [
    'Expected -log10 (p-value)',
    'Observed -log10 (p-value)',
], lambda, tooltipContent, forExport = false, phenotype, model, }) => {
    const { axisZone, XAxisLabelZone, YAxisLabelZone, XAxisZone, YAxisZone, dataZone, lambdaZone, maxAxisScales, } = useZoneConstructor(height, width, data, maxPlotValues, axisTitles, lambda);
    const phenotypeStrings = divideStringToArrayOfStringsMaxWidth(phenotype || '', DEFAULT_PLOT_WIDTH - PHENOTYPE_WORD_WIDTH);
    const totalHeadersHeight = EXPORT_HEADERS_HEIGHT
        + EXPORT_HEADERS_ADDITIONAL_HEIGHT * (phenotypeStrings.length - 1);
    const plotHeight = forExport ? height + totalHeadersHeight + EXPORT_PADDING : height;
    const plotWidth = forExport ? width + EXPORT_PADDING * 2 : width;
    const mainBlockTransform = `translate(${forExport ? EXPORT_PADDING : 0}, ${forExport ? totalHeadersHeight : 0})`;
    return (_jsxs("svg", Object.assign({ height: plotHeight, width: plotWidth, viewBox: `0 0 ${plotWidth} ${plotHeight}`, xmlns: "http://www.w3.org/2000/svg", style: { fontFamily: "'Roboto', arial, sans-serif" } }, { children: [forExport && (_jsxs(_Fragment, { children: [_jsx("defs", { children: _jsxs("style", Object.assign({ type: "text/css" }, { children: [importInlineFont(fonts.Roboto500), importInlineFont(fonts.Roboto400)] }), void 0) }, void 0), _jsx(QQHeadersForExport, { phenotypeStrings: phenotypeStrings, model: model }, void 0)] }, void 0)), _jsxs("g", Object.assign({ transform: mainBlockTransform }, { children: [_jsx(PlotBorders, { axisZone: axisZone }, void 0), _jsx(PlotAxisX, { axisZone: XAxisZone, axisLabelZone: XAxisLabelZone, forExport: forExport }, void 0), _jsx(PlotAxisY, { axisZone: YAxisZone, axisLabelZone: YAxisLabelZone, forExport: forExport }, void 0), _jsx(QQPlotData, { dataZone: dataZone, tooltipContent: tooltipContent, forExport: forExport, maxAxisScales: maxAxisScales }, void 0), lambdaZone && (_jsx(QQPlotLambda, { lambdaZone: lambdaZone }, void 0))] }), void 0)] }), void 0));
};
export default memo(QQPlot);
