import { getTextWidth } from '../../plots/utils';
/**
 * Divides a long string to peases that have width in pixels does not exceed the maximum value
 *
 * @param str - a string that needs to be divided
 * @param maxWidth - maximum width of substring in pixels
 * @returns array of substrings
 */
export const divideStringToArrayOfStringsMaxWidth = (str, maxWidth) => {
    const arr = str.split(' ');
    return arr.reduce((acc, chunk) => {
        if (acc.length && getTextWidth(`${acc[acc.length - 1]} ${chunk}`) < maxWidth) {
            acc[acc.length - 1] += ` ${chunk}`;
            return acc;
        }
        acc.push(chunk);
        return acc;
    }, []);
};
