const inlineStyles = {
    oddDomainLabelText: {
        fill: '#242b36',
    },
    oddDomainLabelRect: {
        fill: '#c4d600',
    },
    evenDomainLabelText: {
        fill: '#ffffff',
    },
    evenDomainLabelRect: {
        fill: '#3c1053',
    },
};
export default inlineStyles;
