import { useMemo } from 'react';
import { getTextWidth, getWrappedText } from '../../../../utils';
import { DEFAULT_LEGEND_WIDTH, LEGEND_LABEL_PADDING, LEGEND_TEXT_ROW_HEIGHT, LEGEND_TEXT_ROW_PADDING, LEGEND_MIN_LABEL_WIDTH, LEGEND_FONT, } from '../../consts';
/**
 * Hook calculates dimensions for legend components
 *
 * @param data - Legend data
 * @param maxWidth - Width limit to fit in the content
 * @returns Dimensions
 */
export default function useLegendDimensions(data, maxWidth = DEFAULT_LEGEND_WIDTH) {
    const labelWidths = data.map(({ groupLabel }) => getTextWidth(groupLabel, LEGEND_FONT));
    const labelMaxWidth = Math.max(...labelWidths, LEGEND_MIN_LABEL_WIDTH);
    const spaceForName = maxWidth - labelMaxWidth - LEGEND_LABEL_PADDING;
    const groups = useMemo(() => {
        let height = 0;
        return data.map((group) => {
            const { groupId, groupLabel, groupName: originalGroupName, } = group;
            const wrappedGroupName = getWrappedText(originalGroupName, spaceForName, LEGEND_FONT);
            const groupName = wrappedGroupName.map((wrappedName, nameIndex) => ({
                x: labelMaxWidth + LEGEND_LABEL_PADDING,
                y: height + nameIndex * LEGEND_TEXT_ROW_HEIGHT,
                height: LEGEND_TEXT_ROW_HEIGHT,
                width: spaceForName,
                name: wrappedName,
            }));
            const legendGroup = {
                x: 0,
                y: height,
                height: LEGEND_TEXT_ROW_HEIGHT,
                width: labelMaxWidth,
                groupId,
                groupLabel,
                groupName,
            };
            height += (wrappedGroupName.length * LEGEND_TEXT_ROW_HEIGHT + LEGEND_TEXT_ROW_PADDING);
            return legendGroup;
        });
    }, [data, labelMaxWidth, spaceForName]);
    const height = useMemo(() => groups.reduce((currentHeight, { groupName }) => (currentHeight + groupName.length * LEGEND_TEXT_ROW_HEIGHT + LEGEND_TEXT_ROW_PADDING), 0), [groups]);
    return useMemo(() => ({
        height,
        data: groups,
    }), [height, groups]);
}
