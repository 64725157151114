// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SectionTitle__layout__1ql_q{border-bottom:1px solid var(--content-shadow-color);height:3.375rem;justify-content:space-between;padding:0 5rem}.SectionTitle__label__1kqWP,.SectionTitle__layout__1ql_q{align-items:center;display:flex}.SectionTitle__label__1kqWP{color:var(--font-color);font-size:1rem;font-weight:700;line-height:1.125rem;margin:0}.SectionTitle__infoIcon__3MUfR{margin-left:.5rem}.SectionTitle__tooltip__1a3zl{max-width:34rem;padding:.75rem 1.125rem;white-space:pre-line}", "",{"version":3,"sources":["webpack://./shared/src/components/Section/components/SectionTitle/SectionTitle.css"],"names":[],"mappings":"AAAA,6BAME,mDAAoD,CAJpD,eAAgB,CAEhB,6BAA8B,CAH9B,cAMF,CAEA,yDAJE,kBAAmB,CAFnB,YAcF,CARA,4BAKE,uBAAwB,CAFxB,cAAe,CAGf,eAAgB,CAFhB,oBAAqB,CAGrB,QACF,CAEA,+BACE,iBACF,CAEA,8BAEE,eAAgB,CADhB,uBAAyB,CAEzB,oBACF","sourcesContent":[".layout {\n  padding: 0 5rem;\n  height: 3.375rem;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  border-bottom: 1px solid var(--content-shadow-color);\n}\n\n.label {\n  display: flex;\n  align-items: center;\n  font-size: 1rem;\n  line-height: 1.125rem;\n  color: var(--font-color);\n  font-weight: 700;\n  margin: 0;\n}\n\n.infoIcon {\n  margin-left: 0.5rem;\n}\n\n.tooltip {\n  padding: 0.75rem 1.125rem;\n  max-width: 34rem;\n  white-space: pre-line;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": "SectionTitle__layout__1ql_q",
	"label": "SectionTitle__label__1kqWP",
	"infoIcon": "SectionTitle__infoIcon__3MUfR",
	"tooltip": "SectionTitle__tooltip__1a3zl"
};
export default ___CSS_LOADER_EXPORT___;
