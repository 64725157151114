/**
 * Calculates geometry rect for axis rectangle zone
 *
 * @param XAxisZoneRect - Geometry rect of X axis zone
 * @param YAxisZoneRect - Geometry rect of Y axis zone
 * @returns Geometry rect
 */
export const calculateAxisZoneRect = (XAxisZoneRect, YAxisZoneRect) => {
    const { x, width } = XAxisZoneRect;
    const { y, height } = YAxisZoneRect;
    return {
        x,
        y,
        height,
        width,
    };
};
