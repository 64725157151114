import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useCallback, } from 'react';
import classnames from 'classnames';
import { DATA_POINT_RADIUS } from '../../../../consts';
import { EDataPointOrder } from '../../../../types';
import { inlineStyles } from '../../ManhattanPlotDataPoint.style';
import style from '../../ManhattanPlotDataPoint.css';
const ManhattanPlotCircle = ({ x, y, dataItem, radius = DATA_POINT_RADIUS, useInlineStyles, handleTooltipShow, handleTooltipHide, dataPointOrder, opacity = 100, }) => {
    const handleMouseEnter = useCallback((event) => {
        if (handleTooltipShow && opacity) {
            handleTooltipShow(event, dataItem.data);
        }
    }, [dataItem, handleTooltipShow, opacity]);
    const circleClassName = classnames(style.circle, style.dataPoint, {
        [style.odd]: dataPointOrder === EDataPointOrder.Odd,
        [style.even]: dataPointOrder === EDataPointOrder.Even,
    });
    const styles = dataPointOrder === EDataPointOrder.Odd
        ? inlineStyles.pointOdd
        : inlineStyles.pointEven;
    const pointerEvents = !opacity ? 'none' : 'initial';
    return (_jsx("circle", { style: Object.assign(Object.assign({}, (useInlineStyles ? styles : {})), { opacity: !opacity ? 0 : opacity / 100, pointerEvents }), className: circleClassName, onMouseEnter: handleMouseEnter, onMouseLeave: handleTooltipHide, cx: x, cy: y, r: radius, "data-testid": "dataPointPlot circle" }, void 0));
};
/** Since memo loses generic props */
export default memo(ManhattanPlotCircle);
