// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".getTextWidth__container__3ww-o{height:auto;margin:0;padding:0;pointer-events:none;position:absolute;-webkit-user-select:none;user-select:none;visibility:hidden;white-space:nowrap;width:auto}", "",{"version":3,"sources":["webpack://./shared/src/plots/utils/getTextWidth/getTextWidth.css"],"names":[],"mappings":"AAAA,gCAME,WAAY,CACZ,QAAS,CACT,SAAU,CAJV,mBAAoB,CAHpB,iBAAkB,CAElB,wBAAiB,CAAjB,gBAAiB,CADjB,iBAAkB,CAOlB,kBAAmB,CAJnB,UAKF","sourcesContent":[".container {\n  position: absolute;\n  visibility: hidden;\n  user-select: none;\n  pointer-events: none;\n  width: auto;\n  height: auto;\n  margin: 0;\n  padding: 0;\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "getTextWidth__container__3ww-o"
};
export default ___CSS_LOADER_EXPORT___;
