import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { CheckBoxListItem } from '../CheckBoxListItem/CheckBoxListItem';
import styles from './LevelResultsOrganizer.css';
export const LevelResultsOrganizer = ({ levelResultsCollection, levelResultMapper, onLevelResultChange, }) => {
    const handleLevelResultChange = useCallback((id, check) => {
        if (onLevelResultChange) {
            onLevelResultChange(id, check);
        }
    }, [onLevelResultChange]);
    return (_jsx("ul", Object.assign({ className: styles.container }, { children: Object.entries(levelResultsCollection).map(([id, value]) => (_jsx(CheckBoxListItem, { "data-testid": `lr_${id}`, id: `lr_${id}`, checked: value, label: levelResultMapper ? levelResultMapper[id] : id, handleChange: handleLevelResultChange }, id))) }), void 0));
};
