import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { memo } from 'react';
import { selectAreaPosition } from '../../types';
import styles from './PlotSelectRegion.css';
export const PlotSelectRegion = memo(function PlotSelectRegion({ onMouseMove, onMouseUp, isVisible = false, plot, left, top, height, width, }) {
    if (!(isVisible)) {
        return null;
    }
    return (_jsx("div", Object.assign({ className: styles.layout, onMouseUp: onMouseUp, onMouseMove: onMouseMove, style: { position: selectAreaPosition[plot] } }, { children: _jsx("div", { className: styles.region, style: {
                left,
                top,
                height,
                width,
            } }, void 0) }), void 0));
});
