import { useRef, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTooltip as usePlotTooltip, } from '../../../components/Tooltip';
export function useTooltip(tooltipIdPrefix, { showDelay, hideDelay } = {}) {
    const tooltipIdRef = useRef(`${tooltipIdPrefix !== null && tooltipIdPrefix !== void 0 ? tooltipIdPrefix : 'TOOLTIP_ID'}:${uuidv4()}`);
    const tooltipAPI = usePlotTooltip(tooltipIdRef.current, showDelay, hideDelay);
    return useMemo(() => ({
        tooltipId: tooltipIdRef.current,
        tooltipAPI,
    }), [tooltipAPI]);
}
