import { AXIS_LABEL_FONT_SIZE, THRESHOLD_AXIS_RECT_HEIGHT, THRESHOLD_AXIS_SCALE_EXTRA_SPACE, } from '../../../../consts';
import { ManhattanScaleType } from '../../../../types';
export const hasFreeSpace = (currentY, scaleItem) => {
    if (!scaleItem) {
        return true;
    }
    if (scaleItem.type === ManhattanScaleType.Regular) {
        return true;
    }
    const spaceThreshold = (THRESHOLD_AXIS_RECT_HEIGHT / 2
        + AXIS_LABEL_FONT_SIZE / 2
        + THRESHOLD_AXIS_SCALE_EXTRA_SPACE);
    if (Math.abs(currentY - scaleItem.y) > spaceThreshold) {
        return true;
    }
    return false;
};
