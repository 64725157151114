export const inlineStyles = {
    wrapper: {
        transform: 'scale(0.9)',
    },
    spliceIcon: {
        stroke: '#99afc1',
        strokeLinecap: 'round',
        strokeWidth: 1.5,
        strokeLinejoin: 'round',
        marginRight: '5px',
    },
    highImpactIcon: {
        fill: '#830051',
    },
    moderateImpactIcon: {
        fill: '#c4d600',
    },
    lowImpactIcon: {
        fill: '#68d2df',
    },
};
export default inlineStyles;
