import { PLOT_TOP_PADDING, PLOT_BOTTOM_PADDING, PLOT_LEFT_PADDING, Y_AXIS_WIDTH, X_CANVAS_PADDING, PLOT_HEADER_HEIGHT, X_AXIS_HEIGHT, DOMAINS_HEIGHT, DEFAULT_X_AXIS_TICK_INTERVALS, DEFAULT_X_AXIS_MAX_TICKS, DEFAULT_Y_AXIS_TICK_INTERVALS, DEFAULT_Y_AXIS_MAX_TICKS, Y_AXIS_BORDER_WIDTH, X_AXIS_BORDER_WIDTH, PLOT_RIGHT_PADDING, Y_CANVAS_PADDING, } from '../../consts';
import { getMaxTickValues, getTickInterval } from '../../utils';
/**
 * Hooks calculates dimensions and positions for main plot's elements
 *
 * @param data - Plot data
 * @param height - Height of the plot
 * @param width - Width of the plot
 * @param [maxTickValues] - Max values for scale ticks
 * @returns Dimensions and positions to be used in plot
 */
export default function useDimensions(data, height, width, maxTickValues) {
    var _a, _b;
    const canvasHeight = height
        - PLOT_TOP_PADDING
        - PLOT_BOTTOM_PADDING;
    const canvasWidth = width
        - PLOT_LEFT_PADDING
        - PLOT_RIGHT_PADDING;
    const scaleXLength = canvasWidth
        - Y_AXIS_WIDTH
        - X_CANVAS_PADDING;
    const scaleYLength = canvasHeight
        - PLOT_HEADER_HEIGHT
        - X_AXIS_HEIGHT
        - DOMAINS_HEIGHT
        - Y_CANVAS_PADDING;
    const axisYPositionX = (PLOT_LEFT_PADDING
        + Y_AXIS_WIDTH
        - Y_AXIS_BORDER_WIDTH / 2);
    const axisYPositionY = (PLOT_TOP_PADDING
        + PLOT_HEADER_HEIGHT);
    const axisXPositionY = (PLOT_TOP_PADDING
        + canvasHeight
        + X_AXIS_BORDER_WIDTH / 2
        - X_AXIS_HEIGHT);
    const axisXPositionX = (PLOT_LEFT_PADDING
        + Y_AXIS_WIDTH
        - Y_AXIS_BORDER_WIDTH);
    let { x: maxTickValueX = 0, y: maxTickValueY = 0, } = maxTickValues !== null && maxTickValues !== void 0 ? maxTickValues : { x: 0, y: 0 };
    // Calculate max ticks from data only if some of max tick values are not set
    if ((maxTickValues === null || maxTickValues === void 0 ? void 0 : maxTickValues.x) === undefined || (maxTickValues === null || maxTickValues === void 0 ? void 0 : maxTickValues.y) === undefined) {
        const [calculatedMaxTickValueX, calculatedMaxTickValueY] = getMaxTickValues(data);
        maxTickValueX = (_a = maxTickValues === null || maxTickValues === void 0 ? void 0 : maxTickValues.x) !== null && _a !== void 0 ? _a : calculatedMaxTickValueX;
        maxTickValueY = (_b = maxTickValues === null || maxTickValues === void 0 ? void 0 : maxTickValues.y) !== null && _b !== void 0 ? _b : calculatedMaxTickValueY;
    }
    const tickIntervalX = getTickInterval(DEFAULT_X_AXIS_TICK_INTERVALS, maxTickValueX, DEFAULT_X_AXIS_MAX_TICKS);
    if ((maxTickValues === null || maxTickValues === void 0 ? void 0 : maxTickValues.x) === undefined) {
        const ticksCountX = Math.ceil(maxTickValueX / tickIntervalX);
        maxTickValueX = ticksCountX * tickIntervalX;
    }
    const tickIntervalY = getTickInterval(DEFAULT_Y_AXIS_TICK_INTERVALS, maxTickValueY, DEFAULT_Y_AXIS_MAX_TICKS);
    if ((maxTickValues === null || maxTickValues === void 0 ? void 0 : maxTickValues.y) === undefined) {
        const ticksCountY = Math.ceil(maxTickValueY / tickIntervalY);
        maxTickValueY = ticksCountY * tickIntervalY;
    }
    return {
        scaleXLength,
        scaleYLength,
        axisXPositionX,
        axisXPositionY,
        axisYPositionX,
        axisYPositionY,
        maxTickValueX,
        maxTickValueY,
        tickIntervalX,
        tickIntervalY,
    };
}
