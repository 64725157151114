import { createContext, useCallback, useRef, useContext, useEffect, useState, } from 'react';
import { LEFT_AXIS_SCALE_WIDTH } from '../../consts';
export const ScrollContext = createContext([]);
export const NO_GROUP_INDEX = -1;
/**
 * Hook provides logic for group label to become displayed or hidden
 * depending on the current scroll position
 *
 * @param containerRef - Container to handle scrolling logic
 * @param containerWidth - Width of container where groups level should remain visible
 * @param groupsDimensions - Dimensions of the groups
 * @param [attachScrollListener = true] - Flag which allows or not to attach scroll event listener
 * @returns Array of intersection statuses for each group
 */
export function useGroupScroll({ containerRef, containerWidth, groupsDimensions, attachScrollListener = true, }) {
    const [firstVisibleGroupIndex, setFirstVisibleGroupIndex] = useState(NO_GROUP_INDEX);
    const [lastVisibleGroupIndex, setLastVisibleGroupIndex] = useState(NO_GROUP_INDEX);
    const groupsIntersectionPoolRef = useRef(groupsDimensions.map(() => false));
    const updateGroupIntersection = useCallback(() => {
        if (!containerRef || !containerRef.current) {
            setFirstVisibleGroupIndex(NO_GROUP_INDEX);
            setLastVisibleGroupIndex(NO_GROUP_INDEX);
            return;
        }
        let actualFirstVisibleGroupIndex = NO_GROUP_INDEX;
        let actualLastVisibleGroupIndex = NO_GROUP_INDEX;
        groupsIntersectionPoolRef.current = groupsDimensions.map((groupDimension, groupIndex) => {
            var _a, _b;
            const isIntersected = (groupDimension.widthOffset < (LEFT_AXIS_SCALE_WIDTH
                + (((_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.scrollLeft) || 0))) || (groupDimension.widthOffset > (containerWidth
                + (((_b = containerRef.current) === null || _b === void 0 ? void 0 : _b.scrollLeft) || 0)));
            if (!isIntersected && actualFirstVisibleGroupIndex === NO_GROUP_INDEX) {
                actualFirstVisibleGroupIndex = groupIndex;
            }
            if (!isIntersected) {
                actualLastVisibleGroupIndex = groupIndex;
            }
            return isIntersected;
        });
        if (firstVisibleGroupIndex !== actualFirstVisibleGroupIndex) {
            setFirstVisibleGroupIndex(actualFirstVisibleGroupIndex);
        }
        if (lastVisibleGroupIndex !== actualLastVisibleGroupIndex) {
            setLastVisibleGroupIndex(actualLastVisibleGroupIndex);
        }
    }, [
        containerRef,
        containerWidth,
        groupsDimensions,
        firstVisibleGroupIndex,
        lastVisibleGroupIndex,
    ]);
    const scrollHandler = useCallback(() => {
        window.requestAnimationFrame(() => {
            updateGroupIntersection();
        });
    }, [updateGroupIntersection]);
    useEffect(() => {
        updateGroupIntersection();
    }, [updateGroupIntersection]);
    useEffect(() => {
        const container = containerRef.current;
        if (container && attachScrollListener) {
            container.addEventListener('scroll', scrollHandler);
        }
        return () => {
            if (container && attachScrollListener) {
                container.removeEventListener('scroll', scrollHandler);
            }
        };
    }, [
        containerRef,
        scrollHandler,
        attachScrollListener,
    ]);
    return groupsIntersectionPoolRef.current;
}
/**
 * Helper hook which encapsulates sharing of intersection status
 * for a specific group via common context
 *
 * @param index - Group index to share intersection status with
 * @returns Intersection status
 */
export function useScrollContext(index) {
    var _a;
    const groupsIntersection = useContext(ScrollContext);
    return (_a = groupsIntersection[index]) !== null && _a !== void 0 ? _a : false;
}
