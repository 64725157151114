import { ELevelResult } from '../../../../../types';
export const PLOT_RANDOM_DATA_STATIC = [
    {
        id: '70a27cee-29a5-4692-9781-0bcc208c9b07',
        pvalue: 0.00000000097412349487,
        levelResult: ELevelResult.Gene,
        coordinates: {
            x: -0.65725174,
            y: -0.8,
        },
    },
    {
        id: '3c81d097-6b7f-4e32-b29f-332efc97a8dc',
        pvalue: 0.00002269089741234948,
        levelResult: ELevelResult.Variant,
        coordinates: {
            x: -3.258,
            y: 0.2,
        },
    },
    {
        id: 'f4969650-a9ff-4111-82cf-bfadcba19740',
        pvalue: 0.000251570655403801,
        levelResult: ELevelResult.Gene,
        coordinates: {
            x: -4.0,
            y: 1,
        },
    },
    {
        id: '88389af8-8d58-456c-a1e9-4425012ab770',
        pvalue: 0.00000000013801789745,
        levelResult: ELevelResult.Variant,
        coordinates: {
            x: -1.8456,
            y: 2.31231,
        },
    },
    {
        id: '88389af8-8d58-456c-a1e9-4425012ab777',
        pvalue: 0.00000000013801789745,
        levelResult: ELevelResult.Variant,
        coordinates: {
            x: -1.8059,
            y: 0.011231,
        },
    },
    {
        id: '88389af8-8d58-456c-a1e9-4425012ab778',
        pvalue: 0.00000000013801789745,
        levelResult: ELevelResult.Variant,
        coordinates: {
            x: -1.8159,
            y: 0.035231,
        },
    },
    {
        id: '88389af8-8d58-456c-a1e9-4425012ab779',
        pvalue: 0.00000000013801789745,
        levelResult: ELevelResult.Variant,
        coordinates: {
            x: -1.8259,
            y: 0.053231,
        },
    },
];
export const PLOT_DATA_POINTS = [
    {
        value: {
            id: 'id1',
            gene: { id: '222', name: '222' },
            collapsingModel: { id: '222', name: '222' },
            phenotypicCategory: { id: '222', name: '222' },
            pvalue: 0.002,
            pvalueLog: 2,
            samples: 100,
            levelResult: ELevelResult.Gene,
            coordinates: { x: -0.58, y: 50 },
            yesQV: null,
            noQV: null,
            caseMedian: null,
            controlMedian: null,
            beta: null,
            betaSTD: null,
            LCI: null,
            UCI: null,
        },
        rect: {
            x: -0.58,
            y: 50,
            height: 12,
            width: 12,
        },
    },
    {
        value: {
            id: 'id2',
            gene: { id: '111', name: '111' },
            collapsingModel: { id: '111', name: '111' },
            phenotypicCategory: { id: '111', name: '111' },
            pvalue: 0.002,
            pvalueLog: 2,
            samples: 100,
            levelResult: ELevelResult.Gene,
            coordinates: { x: -0.0756, y: 70 },
            yesQV: null,
            noQV: null,
            caseMedian: null,
            controlMedian: null,
            beta: null,
            betaSTD: null,
            LCI: null,
            UCI: null,
        },
        rect: {
            x: 0.0756,
            y: 70,
            height: 12,
            width: 12,
        },
    },
];
