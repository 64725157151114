import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, Fragment } from 'react';
import { LEFT_AXIS_SCALE_WIDTH, AXIS_BORDER_WIDTH } from '../../consts';
import { ManhattanScaleType } from '../../types';
import ManhattanPlotLayer from '../ManhattanPlotLayer/ManhattanPlotLayer';
import styles from './ManhattanPlotThresholds.css';
import { inlineStyles } from './ManhattanPlotThresholds.style';
const ManhattanPlotThresholds = (props) => {
    const { scale, width, useInlineStyles = false, } = props;
    return (_jsx(ManhattanPlotLayer, { children: scale
            .filter(({ type }) => type !== ManhattanScaleType.Regular)
            .map(({ value, y, axisLabel, }) => (_jsxs(Fragment, { children: [_jsx("line", Object.assign({ className: styles.thresholdScaleLine }, useInlineStyles && {
                    style: inlineStyles.thresholdScaleLine,
                }, { x1: LEFT_AXIS_SCALE_WIDTH, y1: y, x2: width, y2: y, "data-testid": "plotThresholder" }), void 0), axisLabel && (_jsx("text", Object.assign({ className: styles.thresholdAxisScaleLabel }, useInlineStyles && {
                    style: inlineStyles.thresholdAxisScaleLabel,
                }, { x: LEFT_AXIS_SCALE_WIDTH + AXIS_BORDER_WIDTH, y: y, transform: "translate(8 -9.6)" }, { children: axisLabel }), void 0))] }, `scaleLabel_threshold_${value}`))) }, void 0));
};
export default memo(ManhattanPlotThresholds);
