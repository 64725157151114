var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { escapeRegExp } from '../../../../utils';
const defaultFilterOptions = (groupedItems, search, getOptionLabel) => {
    if (!search) {
        return groupedItems;
    }
    const trimmedPattern = search.trim();
    const regex = new RegExp(escapeRegExp(trimmedPattern), 'i');
    return (groupedItems.map((_a) => {
        var { children } = _a, rest = __rest(_a, ["children"]);
        const filteredChildItems = children
            .filter((item) => regex.test(getOptionLabel(item)));
        return (Object.assign(Object.assign({}, rest), { children: filteredChildItems }));
    }));
};
export default defaultFilterOptions;
