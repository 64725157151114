// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FESLegend__wrap__1sYHv{position:relative}.FESLegend__legend__3bYDN{font-size:.75rem;font-weight:500;transform:translateY(15px)}.FESLegend__circleGLR__24zfy{fill:\"rgb(213; 174; 198)\";fill-opacity:.5;stroke:\"rgb(131; 0; 81)\";stroke-width:\"1px\"}.FESLegend__circleVLR__1OI2-{fill:\"rgb(205; 237; 241)\";fill-opacity:.5;stroke:\"rgb(104; 210; 223)\";stroke-width:\"1px\"}", "",{"version":3,"sources":["webpack://./shared/src/plots/components/ScatterPlots/FES/components/FESLegend/FESLegend.css"],"names":[],"mappings":"AAAA,wBACE,iBACF,CAEA,0BACE,gBAAkB,CAClB,eAAgB,CAChB,0BACF,CAEA,6BACE,yBAA0B,CAC1B,eAAiB,CACjB,wBAAyB,CACzB,kBACF,CAEA,6BACE,yBAA0B,CAC1B,eAAiB,CACjB,2BAA4B,CAC5B,kBACF","sourcesContent":[".wrap {\n  position: relative;\n}\n\n.legend {\n  font-size: 0.75rem;\n  font-weight: 500;\n  transform: translate(0, 15px);\n}\n\n.circleGLR {\n  fill: 'rgb(213; 174; 198)';\n  fill-opacity: 0.5;\n  stroke: 'rgb(131; 0; 81)';\n  stroke-width: '1px';\n}\n\n.circleVLR {\n  fill: 'rgb(205; 237; 241)';\n  fill-opacity: 0.5;\n  stroke: 'rgb(104; 210; 223)';\n  stroke-width: '1px';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "FESLegend__wrap__1sYHv",
	"legend": "FESLegend__legend__3bYDN",
	"circleGLR": "FESLegend__circleGLR__24zfy",
	"circleVLR": "FESLegend__circleVLR__1OI2-"
};
export default ___CSS_LOADER_EXPORT___;
