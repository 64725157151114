/**
 * Returns max tick values for both axis scales
 *
 * @param data - Plot data
 * @returns Max tick values
 */
export default function getMaxTickValues(data) {
    const variants = [
        ...data.variants.active,
        ...data.variants.inactive || [],
    ];
    if (variants.length === 0) {
        return [0, 0];
    }
    const variantsCount = variants.map(({ count }) => count);
    const variantsCodon = variants.map(({ codon }) => codon);
    const [maxVariantsCount, maxVariantsCodon] = [
        Math.max(...variantsCount),
        Math.max(...variantsCodon),
    ];
    const domainsCodon = data.domains
        .reduce((acc, { intervals }) => [
        ...acc,
        ...intervals.map(({ codonEnd }) => codonEnd),
    ], []);
    const maxDomainCodon = domainsCodon.length === 0 ? 0 : Math.max(...domainsCodon);
    const xMaxTickValue = Math.max(maxVariantsCodon, maxDomainCodon);
    const yMaxTickValue = maxVariantsCount;
    return [xMaxTickValue, yMaxTickValue];
}
