import { AXIS_LABEL_FONT_FAMILY, THRESHOLD_BORDER_WIDTH } from '../../consts';
const inlineStyles = {
    line: {
        stroke: '#335f83',
        strokeWidth: `${THRESHOLD_BORDER_WIDTH}px`,
        strokeDasharray: '6px 8px',
        strokeDashoffset: '-4px',
        userSelect: 'none',
        pointerEvents: 'none',
    },
    label: {
        font: AXIS_LABEL_FONT_FAMILY,
        fill: '#335f83',
        userSelect: 'none',
        pointerEvents: 'none',
        textTransform: 'uppercase',
        transform: 'translate(8px, -8px)',
    },
};
export default inlineStyles;
