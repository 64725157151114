// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LollipopPlotAxisY__scaleText__1DFVE{transform:translate(-.3rem,.3rem)}", "",{"version":3,"sources":["webpack://./shared/src/plots/components/LollipopPlot/components/LollipopPlotAxisY/LollipopPlotAxisY.css"],"names":[],"mappings":"AAAA,qCACE,iCACF","sourcesContent":[".scaleText {\n  transform: translate(-0.3rem, 0.3rem);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scaleText": "LollipopPlotAxisY__scaleText__1DFVE"
};
export default ___CSS_LOADER_EXPORT___;
