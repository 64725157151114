import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState, useCallback, memo, useRef, } from 'react';
import classnames from 'classnames';
import { useDropdown } from '../../hooks';
import { useMultiSelect } from './hooks';
import { defaultFilterOptions, getComponentPadding } from './utils';
import { MultiSelectFieldToolbar } from './components/MultiSelectFieldToolbar';
import { MultiSelectFieldFacade } from './components/MultiSelectFieldFacade';
import { MultiSelectFieldOptions } from './components/MultiSelectFieldOptions';
import { TagList } from '../TagList';
import { SearchField } from '../SearchField';
import styles from './MultiSelectField.css';
function MultiSelectFieldBase(props) {
    const { value, onChange, options, getOptionLabel, getOptionValue, renderDescription, filterOptions = defaultFilterOptions, valueComponent: ValueComponent = TagList, valueComponentProps = null, disabled = false, placeholder, label, maxWidth, className, dropdownClassName, optionsClassName, descriptionClassName, showLabel = true, hasSearch = true, } = props;
    const { ref: refDropdown, isOpen, toggleDropdown, closeDropdown, } = useDropdown();
    const { valueMap, handleSelect, handleSelectAll, handleRemove, handleRemoveAll, groupedItems, countOfItems, } = useMultiSelect({
        value,
        options,
        onChange,
        getOptionValue,
    });
    const valueFacadeRef = useRef(null);
    const [inputValue, setInputValue] = useState('');
    const layoutStyles = useMemo(() => ({ maxWidth: maxWidth || 'none' }), [maxWidth]);
    const filteredOptions = useMemo(() => filterOptions(groupedItems, inputValue, getOptionLabel), [filterOptions, getOptionLabel, inputValue, groupedItems]);
    const getValueComponentMaxWidth = useCallback(() => ((maxWidth && valueFacadeRef.current)
        ? maxWidth - getComponentPadding(valueFacadeRef.current)
        : maxWidth), [maxWidth]);
    const handleClear = useCallback((e) => {
        e.stopPropagation();
        handleRemoveAll();
        closeDropdown();
    }, [closeDropdown, handleRemoveAll]);
    return (_jsxs("div", Object.assign({ ref: refDropdown, className: classnames(styles.layout, className), style: layoutStyles }, { children: [_jsx(MultiSelectFieldFacade, Object.assign({ ref: valueFacadeRef, onClick: toggleDropdown, onClear: handleClear, active: isOpen, disabled: disabled, isEmpty: !valueMap.size }, { children: _jsx(ValueComponent, Object.assign({}, valueComponentProps, { value: value, onRemove: handleRemove, getOptionLabel: getOptionLabel, getOptionValue: getOptionValue, getMaxWidth: getValueComponentMaxWidth, placeholder: placeholder, disabled: disabled, label: label, showLabel: showLabel }), void 0) }), void 0), _jsxs("div", Object.assign({ className: classnames(styles.dropdown, dropdownClassName, { [styles.dropdownOpen]: isOpen }), "data-testid": "dropdown" }, { children: [hasSearch && (_jsx("div", Object.assign({ className: styles.searchContainer }, { children: _jsx(SearchField, { value: inputValue, onChange: setInputValue, customInputClassName: styles.search, disabled: disabled }, void 0) }), void 0)), _jsx(MultiSelectFieldOptions, { valueMap: valueMap, options: filteredOptions, onChange: handleSelect, getOptionLabel: getOptionLabel, getOptionValue: getOptionValue, renderDescription: renderDescription, optionsClassName: optionsClassName, descriptionClassName: descriptionClassName, disabled: disabled, isOpen: isOpen }, void 0), _jsx(MultiSelectFieldToolbar, { itemsSelected: value.length, itemsTotal: countOfItems, onSelectAll: handleSelectAll, onRemoveAll: handleRemoveAll, disableSelectAll: disabled || valueMap.size === countOfItems, disableRemoveAll: disabled || valueMap.size === 0 }, void 0)] }), void 0)] }), void 0));
}
export const MultiSelectField = memo(MultiSelectFieldBase);
