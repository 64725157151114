/**
 * Calculates step between labels on Y axis
 *
 * @param height - Plot's data height
 * @param containerHeight - Plot's data container height
 * @returns step between labels
 */
const DEFAULT_VALUE = 2;
const RATIO_THRESHOLDS = [
    { threshold: 0.1, value: 0.1 },
    { threshold: 0.25, value: 0.2 },
    { threshold: 0.5, value: 0.5 },
    { threshold: 0.75, value: 1 },
];
export function calculateYLabelsStep(height, containerHeight) {
    const ratio = containerHeight / height;
    for (let i = 0; i < RATIO_THRESHOLDS.length; i += 1) {
        const step = RATIO_THRESHOLDS[i];
        if (ratio <= step.threshold) {
            return step.value;
        }
    }
    return DEFAULT_VALUE;
}
