// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SVGLegend__title__1XFMT{font-weight:600}", "",{"version":3,"sources":["webpack://./shared/src/plots/components/SVGLegend/SVGLegend.css"],"names":[],"mappings":"AAAA,yBACE,eACF","sourcesContent":[".title {\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "SVGLegend__title__1XFMT"
};
export default ___CSS_LOADER_EXPORT___;
