export const TOOLTIP_EVENT_TYPE = 'tooltipAction';
export var ETooltipEventAction;
(function (ETooltipEventAction) {
    ETooltipEventAction[ETooltipEventAction["Show"] = 0] = "Show";
    ETooltipEventAction[ETooltipEventAction["Hide"] = 1] = "Hide";
})(ETooltipEventAction || (ETooltipEventAction = {}));
/**
 * Custom implementation of events used by tooltips
 *
 * @static
 * @class
 */
export class TooltipEvents {
    /**
     * Creates new instance of tooltip event
     *
     * @private
     * @static
     * @param action - Event's action
     * @param [tooltipId] - Tooltip's id to fire event for
     * @param [targetElement] - Target to place tooltip on
     * @param [contentArgs] - Additional arguments which will be set to tooltip's renderer
     * @returns New instance
     */
    static create(action, tooltipId, targetElement, contentArgs) {
        return new CustomEvent(TooltipEvents.getEventType(tooltipId), {
            detail: {
                action,
                targetElement,
                contentArgs,
            },
            bubbles: true,
            cancelable: true,
            composed: true,
        });
    }
    /**
     * Get's event type from tooltip's id
     *
     * @private
     * @static
     * @param [tooltipId] - Tooltip's id
     * @returns Event's type
     */
    static getEventType(tooltipId) {
        let eventType = `${TOOLTIP_EVENT_TYPE}`;
        if (tooltipId) {
            eventType = `${eventType}:${tooltipId}`;
        }
        return eventType;
    }
    /**
     * Global listener for tooltips events
     * Basically, dispatches events for all registered tooltips
     *
     * @private
     * @static
     * @param event - Fired event instance
     */
    static globalListener(event) {
        const { detail: { action: tooltipAction, targetElement: tooltipTargetElement, contentArgs: tooltipContentArgs, }, } = event;
        Array
            .from(TooltipEvents.tooltipIdsPool)
            .forEach((tooltipId) => {
            TooltipEvents.dispatchEvent(tooltipAction, tooltipId, tooltipTargetElement, tooltipContentArgs);
        });
    }
    /**
     * Dispatches event for a particular tooltip or globally for all registered tooltips
     *
     * @static
     * @param action - Event/action type (show/hide tooltip)
     * @param [tooltipId] - Tooltip's id
     * @param [targetElement] - Tooltip's target element
     * @param [contentArgs] - Arguments for tooltip's renderer
     */
    static dispatchEvent(action, tooltipId, targetElement, contentArgs) {
        window.dispatchEvent(TooltipEvents.create(action, tooltipId, targetElement, contentArgs));
    }
    /**
     * Registers event listener for a particular tooltip
     *
     * @static
     * @param listener - Tooltip's event listener
     * @param tooltipId - Tooltip's id
     */
    static addEventListener(listener, tooltipId) {
        if (TooltipEvents.eventListenersCount === 0) {
            /* eslint-disable-next-line @typescript-eslint/unbound-method */
            window.addEventListener(TooltipEvents.getEventType(), TooltipEvents.globalListener);
        }
        TooltipEvents.eventListenersCount += 1;
        TooltipEvents.tooltipIdsPool.add(tooltipId);
        window.addEventListener(TooltipEvents.getEventType(tooltipId), listener);
    }
    /**
     * Removes tooltip's event listener
     *
     * @static
     * @param listener - Tooltip's event listener
     * @param tooltipId - Tooltip's id
     */
    static removeEventListener(listener, tooltipId) {
        if (!TooltipEvents.tooltipIdsPool.has(tooltipId)) {
            return;
        }
        window.removeEventListener(TooltipEvents.getEventType(tooltipId), listener);
        TooltipEvents.eventListenersCount -= 1;
        TooltipEvents.tooltipIdsPool.delete(tooltipId);
        if (TooltipEvents.eventListenersCount === 0) {
            /* eslint-disable-next-line @typescript-eslint/unbound-method */
            window.removeEventListener(TooltipEvents.getEventType(), TooltipEvents.globalListener);
        }
    }
}
/**
 * Counter of registered event listeners
 *
 * @private
 * @static
 */
TooltipEvents.eventListenersCount = 0;
/**
 * Stores ids of all registered tooltips
 *
 * @private
 * @static
 */
TooltipEvents.tooltipIdsPool = new Set();
