// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Copyright__container__2S_bw{font-size:.875rem}", "",{"version":3,"sources":["webpack://./shared/src/components/Copyright/Copyright.css"],"names":[],"mappings":"AAAA,6BACE,iBACF","sourcesContent":[".container {\n  font-size: 0.875rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Copyright__container__2S_bw"
};
export default ___CSS_LOADER_EXPORT___;
