import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { EDataPointOrder } from '../../../ManhattanPlot/types';
import { TOP_LABEL_HEIGHT, DATA_POINT_RADIUS, DATA_POINT_STEP, DATA_GROUP_LABEL_HEIGHT, } from '../../consts';
import styles from './GPAManhattanPlotGroupBorder.css';
import inlineStyles from './GPAManhattanPlotGroupBorder.style';
const GPAManhattanPlotGroupBorder = (props) => {
    const { widthOffset, index = 0, groupCount, height, length, dataPointOrder, groupLabel, dataPointStep = DATA_POINT_STEP, useInlineStyles, } = props;
    const isLastGroup = (typeof index === 'number'
        && typeof groupCount === 'number'
        && index === groupCount - 1);
    const calculatedWidth = length * dataPointStep + 2 * DATA_POINT_RADIUS;
    const width = calculatedWidth > 0 ? calculatedWidth : 0;
    let groupStyle;
    if (useInlineStyles) {
        groupStyle = dataPointOrder === EDataPointOrder.Odd
            ? inlineStyles.groupOdd
            : inlineStyles.groupEven;
    }
    const groupClassName = dataPointOrder === EDataPointOrder.Odd
        ? styles.groupOdd
        : styles.groupEven;
    return (_jsxs("g", Object.assign({ "data-testid": "plotGroup" }, { children: [_jsx("rect", Object.assign({ className: groupClassName, x: widthOffset, y: height - DATA_GROUP_LABEL_HEIGHT, height: DATA_GROUP_LABEL_HEIGHT, width: width }, groupStyle && {
                style: Object.assign({}, groupStyle),
            }), void 0), _jsx("line", Object.assign({ className: styles.groupLine }, useInlineStyles && {
                style: inlineStyles.groupLine,
            }, { x1: widthOffset, y1: TOP_LABEL_HEIGHT, x2: widthOffset, y2: height }), void 0), _jsx("line", Object.assign({ className: styles.groupLine }, useInlineStyles && {
                style: inlineStyles.groupLine,
            }, { x1: widthOffset, y1: height, x2: widthOffset + width, y2: height }), void 0), isLastGroup && (_jsx("line", Object.assign({ className: styles.groupLine }, useInlineStyles && {
                style: inlineStyles.groupLine,
            }, { x1: widthOffset + width, y1: TOP_LABEL_HEIGHT, x2: widthOffset + width, y2: height }), void 0)), _jsx("text", Object.assign({ className: styles.groupLabelText }, useInlineStyles && {
                style: inlineStyles.groupLabelText,
            }, { x: widthOffset + (width) / 2, y: height - (DATA_GROUP_LABEL_HEIGHT) / 2, textAnchor: "middle" }, { children: groupLabel }), void 0)] }), void 0));
};
/** Since memo loses generic props */
export default memo(GPAManhattanPlotGroupBorder);
