import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import { LEGEND_TITLE_HEIGHT } from '../../consts';
import SVGLegendItem from '../SVGLegendItem/SVGLegendItem';
import SVGLegendLayer from '../SVGLegendLayer/SVGLegendLayer';
const SVGLegendList = (props) => {
    const { data, styles, useInlineStyles, } = props;
    const list = useMemo(() => data.map((group, groupIndex) => {
        const { groupId } = group;
        return (_jsx(SVGLegendItem, { index: groupIndex, data: group, styles: styles, useInlineStyles: useInlineStyles }, groupId));
    }), [
        data,
        styles,
        useInlineStyles,
    ]);
    return (_jsx(SVGLegendLayer, Object.assign({ leftPadding: 0, topPadding: LEGEND_TITLE_HEIGHT }, { children: list }), void 0));
};
export default memo(SVGLegendList);
