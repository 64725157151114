import { useCallback, useEffect, useState } from 'react';
/**
 * Hook for managing Export Settings Form
 * @param allData - set initial state of toggle all/filtered data
 * @param withQueryDescription - add queryDescription field in settings
 * @param shouldRerender - by changing this prop, the initial settings are set
 * @returns
 * - setAllData: all/filtered data switch function
 * - setTableColumnSettings: function to toggle column settings checkbox
 * - setTableDownload: table toggle switch function
 * - setPlotDownload: plot toggle switch function
 * - setQueryDescriptionDownload: plot toggle switch function
 * - settings: object of all settings
 * - resetSettings: method that will be called when changing filters when
 *  checking to see if the plot and date toggles should be turned on/off,
 * can disable toggles if corresponding data is not allowed
 */
export const useExportSettings = (allData, withQueryDescription, shouldRerender) => {
    const initialState = {
        table: { isActive: true, isDisabled: false },
        allData,
        plot: { isActive: true, isDisabled: false },
        queryDescription: withQueryDescription ? true : undefined,
        columnSettings: true,
    };
    const [settings, setSettings] = useState(initialState);
    useEffect(() => {
        setSettings((currentSettings) => (Object.assign(Object.assign({}, currentSettings), { allData })));
    }, [allData]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => setSettings(initialState), [shouldRerender]);
    const setAllData = useCallback((value) => setSettings(Object.assign(Object.assign({}, settings), { allData: value })), [settings]);
    const setTableColumnSettings = useCallback((_id, checked) => setSettings(Object.assign(Object.assign({}, settings), { columnSettings: checked })), [settings]);
    const setTableDownload = useCallback((value) => setSettings(Object.assign(Object.assign({}, settings), { table: {
            isActive: value,
            isDisabled: false,
        } })), [settings]);
    const setPlotDownload = useCallback((value) => setSettings(Object.assign(Object.assign({}, settings), { plot: {
            isActive: value,
            isDisabled: false,
        } })), [settings]);
    const setQueryDescriptionDownload = useCallback((value) => setSettings(Object.assign(Object.assign({}, settings), { queryDescription: value })), [settings]);
    const resetSettings = useCallback((disablePlot, disableTable) => setSettings((currentSettings) => (Object.assign(Object.assign({}, currentSettings), { table: {
            isActive: !disableTable && currentSettings.table.isActive,
            isDisabled: disableTable,
        }, allData, plot: {
            isActive: !disablePlot && currentSettings.plot.isActive,
            isDisabled: disablePlot,
        } }))), [allData]);
    return {
        setAllData,
        setTableColumnSettings,
        setTableDownload,
        setPlotDownload,
        setQueryDescriptionDownload: withQueryDescription ? setQueryDescriptionDownload : undefined,
        settings,
        resetSettings,
    };
};
