import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, Fragment, useMemo } from 'react';
import { ManhattanScaleType } from '../../types';
import { DEFAULT_SCALE_LETTER_WIDTH, DEFAULT_SCALE_LABEL_WIDTH } from '../../consts';
import ManhattanPlotLayer from '../ManhattanPlotLayer/ManhattanPlotLayer';
import ManhattanPlotThresholdLabel from '../ManhattanPlotThresholdLabel/ManhattanPlotThresholdLabel';
import { hasFreeSpace } from './utils';
import { inlineStyles } from './ManhattanPlotAxisScaleLabels.style';
const ManhattanPlotAxisScaleLabels = (props) => {
    const { scale, useInlineStyles = false, offset = 0, forExport, } = props;
    const maxScaleLabelLength = useMemo(() => (scale.length > 0
        ? (Math.max(...scale.map(({ value }) => `${value}`.length)))
        : undefined), [scale]);
    const thresholdWidth = maxScaleLabelLength
        ? maxScaleLabelLength * DEFAULT_SCALE_LETTER_WIDTH
        : DEFAULT_SCALE_LABEL_WIDTH;
    const plotLayerProps = !forExport && { topPadding: -offset };
    return (_jsx(ManhattanPlotLayer, Object.assign({}, plotLayerProps, { children: scale
            .map(({ value, y, type, description, }, index) => (_jsxs(Fragment, { children: [type === ManhattanScaleType.Regular
                    && hasFreeSpace(y, scale[index - 1])
                    && hasFreeSpace(y, scale[index + 1])
                    && (_jsx("text", Object.assign({ style: inlineStyles.axisScaleLabel, x: -thresholdWidth, y: y, textAnchor: "start" }, { children: value }), void 0)), type !== ManhattanScaleType.Regular && (_jsx(ManhattanPlotThresholdLabel, { x: -thresholdWidth, y: y, label: `${value}`, width: thresholdWidth, description: description, useInlineStyles: useInlineStyles }, void 0))] }, `scaleLabel_${value}`))) }), void 0));
};
export default memo(ManhattanPlotAxisScaleLabels);
