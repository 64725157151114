/**
 * Returns an array of objects with relationship of headers and columns in the table
 *
 * @param columns - columns of the table
 * @returns array of objects with relationship of headers and columns in the table
 */
export const mapColumnsToHeaders = (columns) => columns.reduce((acc, { Header: exportName, accessor: columnId, exported = true }) => {
    if (exported === true) {
        return [...acc, { columnId, exportName }];
    }
    if (exported) {
        return [...acc, { columnId: exported.accessor, exportName: exported.Header }];
    }
    return acc;
}, []);
