import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useCallback } from 'react';
import classNames from 'classnames';
import { CheckboxField } from '../../../CheckboxField';
import styles from './MultiSelectFieldItem.css';
function MultiSelectFieldItemBase(props) {
    const { value, label, checked, option, disabled = false, isActive = false, onChange, onMouseEnter, onMouseLeave, } = props;
    const handleChange = useCallback((_nextValue, nextChecked) => {
        onChange(option, nextChecked);
    }, [onChange, option]);
    const handleMouseEnter = useCallback(() => {
        onMouseEnter(option);
    }, [onMouseEnter, option]);
    const handleMouseLeave = useCallback(() => {
        onMouseLeave();
    }, [onMouseLeave]);
    return (_jsx("li", Object.assign({ className: classNames(styles.option, { [styles.active]: isActive }), onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, "aria-label": `MultiSelectFieldItem ${label}` }, { children: _jsx(CheckboxField, { id: value, label: label, checked: checked, disabled: disabled, onChange: handleChange, className: styles.checkbox, checkBoxClassName: styles.input }, void 0) }), void 0));
}
export const MultiSelectFieldItem = memo(MultiSelectFieldItemBase);
