import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback, useState, useMemo, } from 'react';
import classnames from 'classnames';
import InfoIcon from '../../assets/icons/attention.svg';
import CloseIcon from '../../assets/icons/tagClose.svg';
import ErrorIcon from '../../assets/icons/info.svg';
import styles from './Notification.css';
export var ENotificationType;
(function (ENotificationType) {
    ENotificationType[ENotificationType["Error"] = 0] = "Error";
    ENotificationType[ENotificationType["Info"] = 1] = "Info";
})(ENotificationType || (ENotificationType = {}));
export const Notification = memo(function Notification({ children, className, onClose, showNotificationIcon = false, showCloseIcon = true, type = ENotificationType.Error, title, labelClassName, }) {
    const [isNotificationVisible, setIsNotificationVisible] = useState(true);
    const handleClose = useCallback(() => {
        setIsNotificationVisible(false);
        if (onClose) {
            onClose();
        }
    }, [onClose]);
    const icon = useMemo(() => ({
        [ENotificationType.Error]: _jsx(ErrorIcon, { className: styles.errorIcon, "data-testid": "errorIcon" }, void 0),
        [ENotificationType.Info]: _jsx(InfoIcon, { className: styles.infoIcon, "data-testid": "infoIcon" }, void 0),
    }), []);
    const containerClassName = classnames(className, styles.layout, {
        [styles.errorLayout]: type === ENotificationType.Error,
        [styles.infoLayout]: type === ENotificationType.Info,
    });
    const titleClassName = classnames(styles.title, labelClassName, {
        [styles.titleInfo]: type === ENotificationType.Info,
    });
    return isNotificationVisible ? (_jsxs("div", Object.assign({ className: containerClassName }, { children: [showNotificationIcon && icon[type], _jsxs("div", Object.assign({ className: styles.children }, { children: [title && _jsx("h4", Object.assign({ className: titleClassName }, { children: title }), void 0), children] }), void 0), showCloseIcon && (_jsx(CloseIcon, { "data-testid": "closeIcon", onClick: handleClose, className: styles.closeIcon }, void 0))] }), void 0)) : null;
});
