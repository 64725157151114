/* Controlled re-export of the overwritten constant */
/* eslint-disable import/export */
export * from '../../ManhattanPlot/consts';
/**
 * Data point step for GPA version of the plot
 *
 * @override
 * @see '../../ManhattanPlot/consts'
 */
export const DATA_POINT_STEP = 5e-6;
/**
 * Height of the group label on the plot
 */
export const DATA_GROUP_LABEL_HEIGHT = 16;
