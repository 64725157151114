// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GlobalFiltersActionButtons__actionButtons__3IBHh{display:flex;gap:2rem;height:2.25rem}", "",{"version":3,"sources":["webpack://./shared/src/components/GlobalFiltersActionButtons/GlobalFiltersActionButtons.css"],"names":[],"mappings":"AAAA,kDACE,YAAa,CACb,QAAS,CACT,cACF","sourcesContent":[".actionButtons {\n  display: flex;\n  gap: 2rem;\n  height: 2.25rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionButtons": "GlobalFiltersActionButtons__actionButtons__3IBHh"
};
export default ___CSS_LOADER_EXPORT___;
