import { EDataPointShape } from '../../../../../types';
import { EDataPointType } from '../../types';
import { getDataPointType } from '../getDataPointType/getDataPointType';
export const dataPointConfig = {
    [EDataPointType.NotSignificantGLR]: {
        shape: EDataPointShape.Square,
        fill: '#d5aec6',
        stroke: '#830051',
    },
    [EDataPointType.NotSignificantVLR]: {
        shape: EDataPointShape.Square,
        fill: '#cdedf1',
        stroke: '#68d2df',
    },
    [EDataPointType.SignificantGLR]: {
        shape: EDataPointShape.Circle,
        fill: '#d5aec6',
        stroke: '#830051',
    },
    [EDataPointType.SignificantVLR]: {
        shape: EDataPointShape.Circle,
        fill: '#cdedf1',
        stroke: '#68d2df',
    },
};
/**
 * Gets a config of data point depends on levelResult and pvalue
 *
 * @param levelResult - LevelResult value of data point
 * @param pvalue - Pvalue value of data point
 * @returns The config of data point
 */
export const getDataPointConfig = (levelResult, pvalue) => {
    const dataPointType = getDataPointType(levelResult, pvalue);
    return dataPointConfig[dataPointType];
};
