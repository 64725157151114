import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import classnames from 'classnames';
import SVGLegendLayer from '../SVGLegendLayer/SVGLegendLayer';
import { inlineStyles } from './SVGLegendItem.style';
import stylesCSS from './SVGLegendItem.css';
const SVGLegendItem = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { data: { x, y, height, width, groupId, groupLabel, groupName, }, styles, useInlineStyles, index = 0, } = props;
    const nameStyle = useMemo(() => {
        if (!useInlineStyles) {
            return {};
        }
        return inlineStyles.name;
    }, [useInlineStyles]);
    let labelRectStyle;
    let labelRectClassName;
    let labelTextStyle;
    let labelTextClassName;
    if (index % 2 === 0) {
        labelRectClassName = ((_a = styles === null || styles === void 0 ? void 0 : styles.evenItemLabelRect) === null || _a === void 0 ? void 0 : _a.className) || stylesCSS.groupEven;
        labelTextClassName = (_b = styles === null || styles === void 0 ? void 0 : styles.evenItemLabelText) === null || _b === void 0 ? void 0 : _b.className;
        if (useInlineStyles) {
            labelRectStyle = ((_c = styles === null || styles === void 0 ? void 0 : styles.evenItemLabelRect) === null || _c === void 0 ? void 0 : _c.style) || inlineStyles.groupEven;
            labelTextStyle = (_d = styles === null || styles === void 0 ? void 0 : styles.evenItemLabelText) === null || _d === void 0 ? void 0 : _d.style;
        }
    }
    else {
        labelRectClassName = ((_e = styles === null || styles === void 0 ? void 0 : styles.oddItemLabelRect) === null || _e === void 0 ? void 0 : _e.className) || stylesCSS.groupOdd;
        labelTextClassName = (_f = styles === null || styles === void 0 ? void 0 : styles.oddItemLabelText) === null || _f === void 0 ? void 0 : _f.className;
        if (useInlineStyles) {
            labelRectStyle = ((_g = styles === null || styles === void 0 ? void 0 : styles.oddItemLabelRect) === null || _g === void 0 ? void 0 : _g.style) || inlineStyles.groupOdd;
            labelTextStyle = (_h = styles === null || styles === void 0 ? void 0 : styles.oddItemLabelText) === null || _h === void 0 ? void 0 : _h.style;
        }
    }
    labelTextClassName = classnames(labelTextClassName, stylesCSS.label);
    if (useInlineStyles) {
        labelTextStyle = Object.assign(Object.assign({}, labelTextStyle), inlineStyles.label);
    }
    const groupNameElements = useMemo(() => groupName.map((groupNameItem, textIndex) => {
        const { name, x: groupNameX, height: groupNameHeight, } = groupNameItem;
        return (_jsx("tspan", Object.assign({ x: groupNameX, dy: groupNameHeight }, { children: name }), `${groupId}_${textIndex}`));
    }), [
        groupName,
        groupId,
    ]);
    return (_jsxs(SVGLegendLayer, Object.assign({ leftPadding: x, topPadding: y }, { children: [_jsx("rect", { x: 0, y: 0, className: labelRectClassName, style: labelRectStyle, width: width, height: height }, void 0), _jsx("text", Object.assign({ x: 0, y: 0, className: labelTextClassName, style: labelTextStyle, transform: "translate(2 12.8)" }, { children: groupLabel }), void 0), _jsx("text", Object.assign({ x: x, y: 0, transform: "translate(0 -2)", className: stylesCSS.name, style: nameStyle }, { children: groupNameElements }), void 0)] }), void 0));
};
export default memo(SVGLegendItem);
