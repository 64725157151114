import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable react/prop-types */
import { memo, useMemo, useEffect, useCallback, } from 'react';
import { createPortal } from 'react-dom';
import FocusLock from 'react-focus-lock';
import classnames from 'classnames';
import { EKeyCode } from '../../consts';
import styles from './Modal.css';
export const Modal = memo(function Modal(props) {
    const { open, onClose, children, container = document.body, disableCloseOnEscape = false, disableCloseOnBackground = false, contentClassName: customContentClassName, backgroundClassName: customBackgroundClassName, } = props;
    const layoutClassName = useMemo(() => classnames(styles.layout, customBackgroundClassName), [customBackgroundClassName]);
    const contentClassName = useMemo(() => classnames(styles.content, customContentClassName), [customContentClassName]);
    const handleKeyDown = useCallback((e) => {
        if (!disableCloseOnEscape && onClose && e.code === EKeyCode.Esc) {
            onClose(e);
        }
    }, [onClose, disableCloseOnEscape]);
    const handleBackgroundClick = useCallback((e) => {
        const { target, currentTarget } = e;
        if (!disableCloseOnBackground && onClose && (target === currentTarget)) {
            onClose(e);
        }
    }, [onClose, disableCloseOnBackground]);
    useEffect(() => {
        const root = document.body;
        if (open) {
            root === null || root === void 0 ? void 0 : root.classList.add(styles.scrollLock);
        }
        else {
            root === null || root === void 0 ? void 0 : root.classList.remove(styles.scrollLock);
        }
        return () => {
            root === null || root === void 0 ? void 0 : root.classList.remove(styles.scrollLock);
        };
    }, [open]);
    const modal = open && (_jsx("div", Object.assign({ className: layoutClassName, onClick: handleBackgroundClick, onKeyDown: handleKeyDown, "data-testid": "modal", role: "presentation" }, { children: _jsx(FocusLock, Object.assign({ className: contentClassName }, { children: children }), void 0) }), void 0));
    return createPortal(modal, container);
});
