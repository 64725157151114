// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LollipopPlotContainer__innerContainer__3kUhg{overflow-x:auto;position:relative;width:100%}", "",{"version":3,"sources":["webpack://./shared/src/plots/components/LollipopPlot/components/LollipopPlotContainer/LollipopPlotContainer.css"],"names":[],"mappings":"AAAA,8CAEE,eAAgB,CADhB,iBAAkB,CAElB,UACF","sourcesContent":[".innerContainer {\n  position: relative;\n  overflow-x: auto;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"innerContainer": "LollipopPlotContainer__innerContainer__3kUhg"
};
export default ___CSS_LOADER_EXPORT___;
