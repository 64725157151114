import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useCallback, } from 'react';
const QQPlotDataPoint = ({ dataPointZone: { value: dataPointValue, rect: dataPointRect, }, handleTooltipShow, handleTooltipHide, }) => {
    const handleMouseEnter = useCallback((event) => {
        if (handleTooltipShow) {
            handleTooltipShow(event, dataPointValue);
        }
    }, [handleTooltipShow, dataPointValue]);
    const handleMouseLeave = useCallback(() => {
        if (handleTooltipHide) {
            handleTooltipHide();
        }
    }, [handleTooltipHide]);
    const circleRadius = dataPointRect.width / 2;
    const circleX = dataPointRect.x + circleRadius;
    const circleY = dataPointRect.y + circleRadius;
    return (_jsx("circle", { onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, cx: circleX, cy: circleY, r: circleRadius, style: {
            fill: 'rgb(131, 0, 81)',
            fillOpacity: 0.3,
            stroke: '#830051',
            strokeWidth: '1px',
            cursor: 'pointer',
        } }, void 0));
};
export default memo(QQPlotDataPoint);
