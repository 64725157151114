const inlineStyles = {
    axisLine: {
        stroke: '#cad7e1',
        strokeWidth: '4px',
    },
    axisScaleLine: {
        stroke: '#cad7e1',
    },
    axisLabel: {
        fontSize: '12px',
        fontFamily: 'Roboto500',
    },
    axisBackground: {
        fill: '#fff',
    },
};
export default inlineStyles;
