var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { memo, useCallback, } from 'react';
import classnames from 'classnames';
import { Tooltip } from '../Tooltip';
import { ETooltipPlacement, ETooltipAction, } from '../Tooltip/consts';
import InfoIcon from '../../assets/icons/info.svg';
import styles from './InfoTooltip.css';
export const InfoTooltip = memo(function InfoTooltip(props) {
    const { id, targetEvent, children, position = ETooltipPlacement.BottomCenter, className, overlayClassName, icon, description = '' } = props, otherProps = __rest(props, ["id", "targetEvent", "children", "position", "className", "overlayClassName", "icon", "description"]);
    const handleClick = useCallback((event) => {
        event.stopPropagation();
    }, []);
    const wrapperClassName = classnames(styles.wrapper, {
        [styles.wrapperHover]: targetEvent === ETooltipAction.Hover,
        [styles.withDescription]: Boolean(description),
    }, className);
    return (_jsx(Tooltip, Object.assign({}, otherProps, { id: id, action: targetEvent, tooltipContent: children, placement: position, className: overlayClassName }, { children: _jsxs("span", Object.assign({ "data-testid": "infoTooltip", className: wrapperClassName, onClick: handleClick }, { children: [icon || _jsx(InfoIcon, {}, void 0), description] }), void 0) }), void 0));
});
