import { hasQVVariantsLinks } from '../../../../utils';
const PLACEHOLDER_LABEL_TEXT = 'Variants N/A';
const DEFAULT_VIEW_VARIANTS_LABEL = 'view variants';
const getLabel = (hasVariants, variantId) => {
    if (!hasVariants)
        return PLACEHOLDER_LABEL_TEXT;
    if (!variantId)
        return DEFAULT_VIEW_VARIANTS_LABEL;
    return variantId || '';
};
export const viewVariantsSort = (rowA, rowB, isPublicPortal) => {
    const { traitsType: rowATraitsType, pvalue: rowAPvalue, cases: rowACases, variantId: rowAVariantId, } = rowA.original;
    const { traitsType: rowBTraitsType, pvalue: rowBPvalue, cases: rowBCases, variantId: rowBVariantId, } = rowB.original;
    const rowAHasVariants = hasQVVariantsLinks(rowATraitsType, rowAPvalue, isPublicPortal, rowACases);
    const rowBHasVariants = hasQVVariantsLinks(rowBTraitsType, rowBPvalue, isPublicPortal, rowBCases);
    const valA = getLabel(rowAHasVariants, rowAVariantId);
    const valB = getLabel(rowBHasVariants, rowBVariantId);
    return valA.localeCompare(valB);
};
