export const transformToLinePlotData = (data) => {
    const MTRGroups = [];
    const FDRGroups = [];
    let values = [];
    let isFDRGroup = false;
    data.forEach((dataValue, index) => {
        const { mtr, fdr } = dataValue !== null && dataValue !== void 0 ? dataValue : { mtr: 0 };
        if (!dataValue
            || (fdr && fdr > 0 && !isFDRGroup)
            || (fdr === undefined && isFDRGroup)) {
            if (values.length) {
                if (dataValue && !isFDRGroup) {
                    values.push({
                        x: index + 1,
                        y: mtr,
                        meta: fdr === undefined ? undefined : {
                            fdr,
                        },
                    });
                }
                const group = isFDRGroup ? FDRGroups : MTRGroups;
                group.push({
                    values,
                    color: isFDRGroup ? '#ff0000' : undefined,
                });
                values = (dataValue && isFDRGroup)
                    ? [values[values.length - 1]]
                    : [];
            }
            isFDRGroup = !isFDRGroup;
        }
        if (!dataValue) {
            return;
        }
        values.push({
            x: index + 1,
            y: mtr,
            meta: fdr === undefined ? undefined : {
                fdr,
            },
        });
    });
    if (values.length) {
        const group = isFDRGroup ? FDRGroups : MTRGroups;
        group.push({
            values,
            color: isFDRGroup ? '#ff0000' : undefined,
        });
        values = [];
    }
    return MTRGroups.concat(FDRGroups);
};
