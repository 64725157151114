import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, memo } from 'react';
import { EPlotOrientation } from '../../../../types';
import { DEFAULT_FONT_SIZE, DEFAULT_Y_AXIS_WIDTH, } from '../../consts';
import { inlineStyles } from '../PlotAxisScale/inlineStyles';
import styles from './ScaleLabelsY.css';
const defaultInlineStylesGetter = () => inlineStyles.defaultLine;
const ScaleLabelsY = ({ axisZone, size, inlineStylesGetter = defaultInlineStylesGetter, }) => {
    const { value: axisZoneScale, } = axisZone;
    return (_jsxs("svg", Object.assign({ height: size.height, width: DEFAULT_Y_AXIS_WIDTH, viewBox: `0 0 ${DEFAULT_Y_AXIS_WIDTH} ${size.height}`, xmlns: "http://www.w3.org/2000/svg", className: styles.axisYLabels }, { children: [_jsx("rect", { x: 0, y: 0, width: DEFAULT_Y_AXIS_WIDTH, height: size.height, className: styles.axisHiding }, void 0), axisZoneScale.map(({ label: { value: labelText, rect: labelRect, }, line: { rect: lineRect, }, }) => (_jsxs(Fragment, { children: [_jsx("line", { x1: lineRect.x, y1: lineRect.y, x2: DEFAULT_Y_AXIS_WIDTH, y2: lineRect.y, style: inlineStylesGetter(labelText, EPlotOrientation.Horizontal) }, void 0), _jsx("text", Object.assign({ x: labelRect.x, y: labelRect.y, textAnchor: "start", style: {
                            fontSize: DEFAULT_FONT_SIZE,
                            color: 'red',
                        } }, { children: labelText }), void 0)] }, `scale_${labelRect.x}_${labelRect.y}`)))] }), void 0));
};
export default memo(ScaleLabelsY);
