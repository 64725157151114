import { getMaxDataPointValues } from '../getMaxDataPointValues/getMaxDataPointValues';
/**
 * Gets min and max possible values from min and max values
 *
 * @param maxValue - maximum value
 * @param minValue - minimum value
 * @returns array of min and max values
 */
export const getAxisDataPointValues = (minValue, maxValue) => {
    if (minValue >= 0) {
        return [0, getMaxDataPointValues(maxValue)];
    }
    const absMinValue = Math.abs(minValue);
    if (maxValue <= 0) {
        return [-getMaxDataPointValues(absMinValue), 0];
    }
    return [
        -getMaxDataPointValues(absMinValue),
        getMaxDataPointValues(maxValue),
    ];
};
