import { useMemo } from 'react';
import { calculateYLabelsStep } from '../../utils';
import { filledArray, logger, } from '../../../../../utils';
import { BOTTOM_AXIS_SCALE_HEIGHT, CANVAS_PADDING_HEIGHT, TOP_LABEL_HEIGHT, } from '../../consts';
import { ManhattanScaleType, } from '../../types';
/**
 * Hook calculates plot scale based on hight and given thresholds
 *
 * @param data - Plot's data
 * @param height - Plot's actual height
 * @param containerHeight - Plot's container
 * @param [dataLimits = []] - Plot's data points limits
 * @param [thresholds = []] - Thresholds if there are any
 * @returns Array of scale values
 */
export default function useScale(data, height, containerHeight, dataLimits = [], thresholds = []) {
    var _a, _b;
    const bottomAxisPosition = height - BOTTOM_AXIS_SCALE_HEIGHT;
    const bottomCanvasPosition = bottomAxisPosition - CANVAS_PADDING_HEIGHT;
    /**
     * Finds min and max values across the data
     * `null` values indicate empty data
     */
    let [minDataValue, maxDataValue] = data.getGroups().reduce(([currentMinValue, currentMaxValue], group) => {
        const groupPoints = group.getPoints();
        const groupPointsValue = groupPoints.map((point) => point.y || 0);
        if (groupPointsValue.length === 0) {
            return [currentMinValue, currentMaxValue];
        }
        const groupMin = Math.min(...groupPointsValue);
        const groupMax = Math.max(...groupPointsValue);
        if (currentMinValue === null || currentMaxValue === null) {
            return [groupMin, groupMax];
        }
        return [
            Math.min(currentMinValue, groupMin),
            Math.max(currentMaxValue, groupMax),
        ];
    }, [null, null]);
    let [lowerLimit = null, upperLimit = null,] = dataLimits;
    if (upperLimit !== null
        && lowerLimit !== null
        && upperLimit < lowerLimit) {
        logger.warn(`Lower limit value is greater than upper limit value (${lowerLimit} > ${upperLimit}): swapping values`);
        [lowerLimit, upperLimit] = [upperLimit, lowerLimit];
    }
    let [lowerThreshold, upperThreshold] = thresholds;
    if (lowerThreshold
        && upperThreshold
        && (lowerThreshold === null || lowerThreshold === void 0 ? void 0 : lowerThreshold.value) > (upperThreshold === null || upperThreshold === void 0 ? void 0 : upperThreshold.value)) {
        logger.warn(`Lower threshold is greater than upper threshold (${lowerThreshold.value} > ${upperThreshold.value}): swapping values`);
        [lowerThreshold, upperThreshold] = [upperThreshold, lowerThreshold];
    }
    const minThreshold = (_a = lowerThreshold === null || lowerThreshold === void 0 ? void 0 : lowerThreshold.value) !== null && _a !== void 0 ? _a : null;
    const maxThreshold = (_b = upperThreshold === null || upperThreshold === void 0 ? void 0 : upperThreshold.value) !== null && _b !== void 0 ? _b : null;
    if (upperLimit !== null) {
        maxDataValue = upperLimit;
    }
    let maxScaleRawValue = null;
    if (maxDataValue !== null && maxThreshold !== null) {
        maxScaleRawValue = Math.max(maxThreshold, maxDataValue);
    }
    else if (maxDataValue !== null) {
        maxScaleRawValue = maxDataValue;
    }
    else if (maxThreshold !== null) {
        maxScaleRawValue = maxThreshold;
    }
    let maxScaleValue = 0;
    if (maxScaleRawValue !== null) {
        maxScaleValue = Number.isInteger(maxScaleRawValue)
            ? maxScaleRawValue
            : Math.floor(maxScaleRawValue) + 1;
    }
    if (lowerLimit !== null) {
        minDataValue = lowerLimit;
    }
    let minScaleRawValue = null;
    if (minDataValue !== null && minThreshold !== null) {
        minScaleRawValue = Math.min(minThreshold, minDataValue);
    }
    else if (minDataValue !== null) {
        minScaleRawValue = minDataValue;
    }
    else if (minThreshold !== null) {
        minScaleRawValue = minThreshold;
    }
    let minScaleValue = 0;
    if (minScaleRawValue !== null) {
        minScaleValue = Number.isInteger(minScaleRawValue)
            ? minScaleRawValue
            : Math.ceil(minScaleRawValue) - 1;
    }
    const actualStep = calculateYLabelsStep(height, containerHeight);
    if (maxScaleValue !== null
        && minScaleValue !== null
        && maxScaleValue % 2 !== minScaleValue % 2) {
        maxScaleValue += 1;
    }
    const scaleValuesCount = (maxScaleValue === 0 && minScaleValue === 0)
        ? 0
        : Math.ceil((maxScaleValue - minScaleValue) / actualStep) + 1;
    const scaleValuesDelta = (maxScaleValue === 0 && minScaleValue === 0)
        ? 0
        : (bottomCanvasPosition - TOP_LABEL_HEIGHT) / (scaleValuesCount - 1);
    const scale = useMemo(() => {
        let lowerThresholdInScale = false;
        let upperThresholdInScale = false;
        let lowerThresholdClosestScaleIndex = -1;
        let upperThresholdClosestScaleIndex = -1;
        const scales = filledArray(scaleValuesCount, (index) => {
            const value = Math.round((minScaleValue + actualStep * index) * 100) / 100;
            let type = ManhattanScaleType.Regular;
            let axisLabel;
            let description;
            if ((lowerThreshold === null || lowerThreshold === void 0 ? void 0 : lowerThreshold.value) === value) {
                lowerThresholdInScale = true;
                type = ManhattanScaleType.LowerThreshold;
                axisLabel = lowerThreshold.label;
                description = lowerThreshold.description;
            }
            if (lowerThreshold
                && index > 0
                && value - actualStep < lowerThreshold.value // prevValue
                && lowerThreshold.value < value) {
                lowerThresholdClosestScaleIndex = index;
            }
            if ((upperThreshold === null || upperThreshold === void 0 ? void 0 : upperThreshold.value) === value) {
                upperThresholdInScale = true;
                type = ManhattanScaleType.UpperThreshold;
                axisLabel = upperThreshold.label;
                description = upperThreshold.description;
            }
            if (upperThreshold
                && index > 0
                && value - actualStep < upperThreshold.value // prevValue
                && upperThreshold.value < value) {
                upperThresholdClosestScaleIndex = index;
            }
            return {
                value: Math.round(value * 10) / 10,
                y: bottomCanvasPosition - scaleValuesDelta * index,
                type,
                axisLabel,
                description,
            };
        });
        /**
         * Lower threshold is set but was not added into scale yet
         */
        if (!lowerThresholdInScale && lowerThreshold) {
            scales.splice(lowerThresholdClosestScaleIndex, 0, {
                value: lowerThreshold.value,
                y: bottomCanvasPosition - scaleValuesDelta * ((lowerThreshold.value - minScaleValue) / actualStep),
                type: ManhattanScaleType.LowerThreshold,
                axisLabel: lowerThreshold.label,
                description: lowerThreshold.description,
            });
            // Compensate shift caused by adding new threshold
            upperThresholdClosestScaleIndex += 1;
        }
        /**
         * Upper threshold is set but was not added into scale yet
         */
        if (!upperThresholdInScale && upperThreshold) {
            scales.splice(upperThresholdClosestScaleIndex, 0, {
                value: upperThreshold.value,
                y: bottomCanvasPosition - scaleValuesDelta * ((upperThreshold.value - minScaleValue) / actualStep),
                type: ManhattanScaleType.UpperThreshold,
                axisLabel: upperThreshold.label,
                description: upperThreshold.description,
            });
        }
        return scales;
    }, [
        actualStep,
        lowerThreshold,
        upperThreshold,
        minScaleValue,
        bottomCanvasPosition,
        scaleValuesDelta,
        scaleValuesCount,
    ]);
    const scaleRange = useMemo(() => [
        minScaleValue,
        maxScaleValue,
    ], [minScaleValue, maxScaleValue]);
    return useMemo(() => ({
        scale,
        scaleRange,
    }), [scale, scaleRange]);
}
