// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Tag__layout__4_Jz0{align-items:center;background-color:var(--tag-background-color);border-radius:2px;color:var(--font-color);display:inline-flex;font-size:.875rem;line-height:1rem;padding:.25rem .375rem}.Tag__icon__2NrGK{cursor:pointer;margin-left:.25rem;outline:none}.Tag__disabled__3ASsE{background-color:var(--background-grey-light)}.Tag__disabled__3ASsE svg{visibility:hidden}", "",{"version":3,"sources":["webpack://./shared/src/components/Tag/Tag.css"],"names":[],"mappings":"AAAA,oBAEE,kBAAmB,CACnB,4CAA6C,CAK7C,iBAAkB,CAJlB,uBAAwB,CAHxB,mBAAoB,CAIpB,iBAAmB,CACnB,gBAAiB,CACjB,sBAEF,CAEA,kBAEE,cAAe,CADf,kBAAoB,CAEpB,YACF,CAEA,sBACE,6CAKF,CAHE,0BACE,iBACF","sourcesContent":[".layout {\n  display: inline-flex;\n  align-items: center;\n  background-color: var(--tag-background-color);\n  color: var(--font-color);\n  font-size: 0.875rem;\n  line-height: 1rem;\n  padding: 0.25rem 0.375rem;\n  border-radius: 2px;\n}\n\n.icon {\n  margin-left: 0.25rem;\n  cursor: pointer;\n  outline: none;\n}\n\n.disabled {\n  background-color: var(--background-grey-light);\n\n  & svg {\n    visibility: hidden;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": "Tag__layout__4_Jz0",
	"icon": "Tag__icon__2NrGK",
	"disabled": "Tag__disabled__3ASsE"
};
export default ___CSS_LOADER_EXPORT___;
