// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SelectOption__option__15Yat{align-items:flex-start;background:none;border:none;color:var(--font-color);cursor:pointer;display:flex;font-size:.75rem;justify-content:space-between;line-height:1rem;outline:none;padding:.375rem .625rem;position:relative;text-align:left;width:100%}.SelectOption__optionWithTag__3dKfC{padding:.175rem 0}.SelectOption__optionWithTag__3dKfC .SelectOption__option__15Yat{font-size:.875rem}.SelectOption__activeOption__10xRi{background-color:var(--dropdown-hover)}.SelectOption__optionIconClassName__G9kHH{fill:var(--primary-color);margin-top:.25rem;width:10%}", "",{"version":3,"sources":["webpack://./shared/src/components/SelectField/components/SelectOption/SelectOption.css"],"names":[],"mappings":"AAAA,6BAcE,sBAAuB,CAbvB,eAAgB,CAChB,WAAY,CAGZ,uBAAwB,CAExB,cAAe,CAKf,YAAa,CANb,gBAAkB,CAOlB,6BAA8B,CAV9B,gBAAiB,CACjB,YAAa,CAMb,uBAA0B,CAC1B,iBAAkB,CAFlB,eAAgB,CADhB,UAOF,CAEA,oCACE,iBAKF,CAHE,iEACE,iBACF,CAGF,mCACE,sCACF,CAEA,0CACE,yBAA0B,CAC1B,iBAAmB,CACnB,SACF","sourcesContent":[".option {\n  background: none;\n  border: none;\n  line-height: 1rem;\n  outline: none;\n  color: var(--font-color);\n  font-size: 0.75rem;\n  cursor: pointer;\n  width: 100%;\n  text-align: left;\n  padding: 0.375rem 0.625rem;\n  position: relative;\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n}\n\n.optionWithTag {\n  padding: 0.175rem 0;\n\n  & .option {\n    font-size: 0.875rem;\n  }\n}\n\n.activeOption {\n  background-color: var(--dropdown-hover);\n}\n\n.optionIconClassName {\n  fill: var(--primary-color);\n  margin-top: 0.25rem;\n  width: 10%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"option": "SelectOption__option__15Yat",
	"optionWithTag": "SelectOption__optionWithTag__3dKfC",
	"activeOption": "SelectOption__activeOption__10xRi",
	"optionIconClassName": "SelectOption__optionIconClassName__G9kHH"
};
export default ___CSS_LOADER_EXPORT___;
