// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ManhattanPlotThresholds__thresholdScaleLine__3rEVz{stroke:var(--plot-threshold-line-color);stroke-width:2px;stroke-dasharray:6px 8px;stroke-dashoffset:-4px}.ManhattanPlotThresholds__thresholdAxisScaleLabel__3xneC,.ManhattanPlotThresholds__thresholdScaleLine__3rEVz{pointer-events:none;-webkit-user-select:none;user-select:none}.ManhattanPlotThresholds__thresholdAxisScaleLabel__3xneC{fill:#000;font-size:.625rem;text-transform:uppercase;transform:translate(.5rem,-.6rem)}", "",{"version":3,"sources":["webpack://./shared/src/plots/components/ManhattanPlot/components/ManhattanPlotThresholds/ManhattanPlotThresholds.css"],"names":[],"mappings":"AAAA,oDACE,uCAAwC,CACxC,gBAAiB,CACjB,wBAAyB,CACzB,sBAGF,CAEA,6GAHE,mBAAoB,CADpB,wBAAiB,CAAjB,gBAWF,CAPA,yDAGE,SAAW,CAFX,iBAAmB,CAKnB,wBAAyB,CAJzB,iCAKF","sourcesContent":[".thresholdScaleLine {\n  stroke: var(--plot-threshold-line-color);\n  stroke-width: 2px;\n  stroke-dasharray: 6px 8px;\n  stroke-dashoffset: -4px;\n  user-select: none;\n  pointer-events: none;\n}\n\n.thresholdAxisScaleLabel {\n  font-size: 0.625rem;\n  transform: translate(0.5rem, -0.6rem);\n  fill: black;\n  user-select: none;\n  pointer-events: none;\n  text-transform: uppercase;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"thresholdScaleLine": "ManhattanPlotThresholds__thresholdScaleLine__3rEVz",
	"thresholdAxisScaleLabel": "ManhattanPlotThresholds__thresholdAxisScaleLabel__3xneC"
};
export default ___CSS_LOADER_EXPORT___;
