import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { CheckBoxListItem } from '../CheckBoxListItem/CheckBoxListItem';
import styles from './TraitsOrganizer.css';
export const TraitsOrganizer = ({ traitsTypesCollection, traitsTypeMapper, onTraitsTypesChange, }) => {
    const handleTraitsTypesChange = useCallback((id, check) => {
        if (onTraitsTypesChange) {
            onTraitsTypesChange(id, check);
        }
    }, [onTraitsTypesChange]);
    return (_jsx("ul", Object.assign({ className: styles.container }, { children: Object.entries(traitsTypesCollection).map(([id, value]) => (_jsx(CheckBoxListItem, { "data-testid": `tr_${id}`, id: `tr_${id}`, checked: value, label: traitsTypeMapper ? traitsTypeMapper[id] : id, handleChange: handleTraitsTypesChange }, id))) }), void 0));
};
