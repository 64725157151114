import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useTooltip } from '../../../../hooks';
import { TOOLTIP_ID } from '../../consts';
import { LinePlotDataGroup } from '../LinePlotDataGroup/LinePlotDataGroup';
import { LinePlotDataPointTooltip } from '../LinePlotDataPointTooltip/LinePlotDataPointTooltip';
function LinePlotDataBase({ rect, maxScaleValue, data, tooltipContent, forExport, }) {
    const { tooltipId, tooltipAPI } = useTooltip(TOOLTIP_ID);
    const { handleTooltipShowWithDelay: handleTooltipShow, handleTooltipHideWithDelay: handleTooltipHide, } = tooltipAPI;
    const dataGroups = data.map(({ values, color }, index) => (_jsx(LinePlotDataGroup
    // eslint-disable-next-line react/no-array-index-key
    , { rect: rect, maxScaleValue: maxScaleValue, data: values, color: color, handleTooltipShow: handleTooltipShow, handleTooltipHide: handleTooltipHide, forExport: forExport }, index)));
    return (_jsxs(_Fragment, { children: [_jsx(LinePlotDataPointTooltip, { tooltipId: tooltipId, tooltipContent: tooltipContent, tooltipAPI: tooltipAPI }, void 0), dataGroups] }, void 0));
}
export const LinePlotData = memo(LinePlotDataBase);
