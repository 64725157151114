import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from 'react';
import classnames from 'classnames';
import { useIntersection } from '../../hooks';
import { ESectionType, Section } from '../Section';
import styles from './StickySection.css';
export const StickySection = function StickySection(props) {
    const { children, className, hasShadow = true, classNameShadow, type = ESectionType.Default, } = props;
    const targetRef = useRef(null);
    const intersection = useIntersection({ target: targetRef.current });
    const isMoved = (intersection === null || intersection === void 0 ? void 0 : intersection.isIntersecting) === false;
    return (_jsxs(_Fragment, { children: [_jsx("div", { ref: targetRef }, void 0), _jsx(Section, Object.assign({ className: classnames(styles.layout, className, {
                    [classNameShadow || styles.shadow]: isMoved && hasShadow,
                }), type: type }, { children: children }), void 0)] }, void 0));
};
