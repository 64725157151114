import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback, useLayoutEffect, useRef, useState, } from 'react';
import classnames from 'classnames';
import { SHOW_LABEL, HIDE_LABEL, EAngleDirection, MAX_HEIGHT_CHILDREN, } from './consts';
import { AngleButton } from './components/AngleLabel';
import styles from './ExpandedText.css';
/**
 * Creates expanded text
 *
 * @param children - Component that can be hidden (fully / partially) or opened (fully) by button
 *
 * Use only textContent or content:
 * @param textContent - Text which shows before children
 * @param content - Component which shows before children
 *
 * @param showLabel - Label for button to show children
 * @param hideLabel - Label for button to hide children
 * @param isShowPartiallyChildren - Flag for partially displaying children
 * @param isShowButton - Flag defines displaying or not button by the first render
 * @param maxHeightChildren - Maximum height for showing partial children
 * @param className - Classname for whole ExpandedText component
 * @param classNameButton - Classname for button "show more / show less"
 * @param classNamePartiallyChildren - Classname for partial children
 */
export const ExpandedText = memo(function ExpandedText({ children, textContent, content, showLabel = SHOW_LABEL, hideLabel = HIDE_LABEL, isShowPartiallyChildren = false, isShowButton = true, maxHeightChildren = MAX_HEIGHT_CHILDREN, className, classNameButton, classNamePartiallyChildren, }) {
    const [showFullChildren, setShowFullChildren] = useState(false);
    const [showButton, setShowButton] = useState(isShowButton);
    const childrenRef = useRef(null);
    const handlerButtonClick = useCallback((e) => {
        e === null || e === void 0 ? void 0 : e.stopPropagation();
        setShowFullChildren((state) => !state);
    }, []);
    useLayoutEffect(() => {
        var _a;
        if (isShowPartiallyChildren && Number((_a = childrenRef.current) === null || _a === void 0 ? void 0 : _a.scrollHeight) > maxHeightChildren) {
            setShowButton(true);
        }
    }, [isShowPartiallyChildren, maxHeightChildren]);
    const formattedTextContent = showFullChildren ? ':' : '...';
    const labelButton = showFullChildren ? hideLabel : showLabel;
    const angleDirection = showFullChildren ? EAngleDirection.UP : EAngleDirection.DOWN;
    const classNameChildren = classnames(styles.partiallyChildren, classNamePartiallyChildren);
    const classNameButtonShowMore = classnames(styles.buttonShowMore, classNameButton);
    return (_jsxs("div", Object.assign({ className: className }, { children: [textContent && _jsx("p", Object.assign({ className: styles.content }, { children: textContent + formattedTextContent }), void 0), content, (showFullChildren || isShowPartiallyChildren) && (_jsx("div", Object.assign({ ref: childrenRef, className: classnames({
                    [classNameChildren]: isShowPartiallyChildren && !showFullChildren,
                }) }, { children: children }), void 0)), showButton
                && (_jsx("div", Object.assign({ className: classNameButtonShowMore }, { children: _jsx(AngleButton, { label: labelButton, onClick: handlerButtonClick, angleDirection: angleDirection }, void 0) }), void 0))] }), void 0));
});
