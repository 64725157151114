// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".checkIfCellHasEllipsis__childOfClonedElement__2RXAw{overflow:hidden;word-break:break-word}", "",{"version":3,"sources":["webpack://./shared/src/components/Tooltip/utils/checkIfCellHasEllipsis/checkIfCellHasEllipsis.css"],"names":[],"mappings":"AAAA,qDACE,eAAgB,CAChB,qBACF","sourcesContent":[".childOfClonedElement {\n  overflow: hidden;\n  word-break: break-word;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"childOfClonedElement": "checkIfCellHasEllipsis__childOfClonedElement__2RXAw"
};
export default ___CSS_LOADER_EXPORT___;
