import ReactDOMServer from 'react-dom/server';
/**
 * Download plot data in svg format
 *
 * @param svg - SVG in string representation or SVG renderer
 */
export const transformSVGToString = (svg) => {
    let svgString;
    if (typeof svg === 'string') {
        svgString = svg;
    }
    else {
        svgString = ReactDOMServer.renderToString(typeof svg === 'function'
            ? svg()
            : svg);
    }
    return svgString;
};
