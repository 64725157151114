var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { memo, forwardRef } from 'react';
import classnames from 'classnames';
import styles from './TextField.css';
export var EInputTextType;
(function (EInputTextType) {
    EInputTextType["Search"] = "search";
    EInputTextType["Text"] = "text";
    EInputTextType["Password"] = "password";
})(EInputTextType || (EInputTextType = {}));
const TextFieldBase = forwardRef((_a, ref) => {
    var { className, type, initialValue, error } = _a, restProps = __rest(_a, ["className", "type", "initialValue", "error"]);
    return (_jsx("input", Object.assign({}, restProps, { type: type, className: classnames(styles.baseInput, className), ref: ref }), void 0));
});
TextFieldBase.displayName = 'TextField';
export const TextField = memo(TextFieldBase);
