import { ManhattanPlotDataGroup, } from '../ManhattanPlotDataGroup/ManhattanPlotDataGroup';
/**
 * Class which is used by Manhattan plot
 * It stores all the data used by plot and provides API for working with it
 *
 * @implements IManhattanPlotData<T>
 * @implements ISerializable<TSerializedManhattanPlotData<T>>
 */
export class ManhattanPlotData {
    /**
     * Creates new instance
     *
     * @param groups - Array of Manhattan plot's data groups
     * @returns New instance
     */
    constructor(groups) {
        this._groups = groups;
    }
    /**
     * Returns empty instance
     *
     * @static
     */
    static get empty() {
        return new ManhattanPlotData([]);
    }
    /**
     * Creates new instance from serialized JSON
     *
     * @static
     * @param rawData - Serialized JSON data
     * @returns New instance
     */
    static fromJSON(rawData) {
        return new ManhattanPlotData(rawData.map((rawGroupData) => (ManhattanPlotDataGroup.fromJSON(rawGroupData))));
    }
    /**
     * Serializes instance into JSON
     *
     * @returns Serialized data
     */
    toJSON() {
        return this._groups.map((group) => group.toJSON());
    }
    /**
     * Adds array of groups into plot's collection
     *
     * @param groups - Array of group instancens which should be added
     * @returns Updated instance
     */
    addGroups(groups) {
        this._groups = [...this._groups, ...groups];
        return this;
    }
    /**
     * Adds a single group into plot's collection
     *
     * @param group - Group instance which should be added
     * @returns Updated instance
     */
    addGroup(group) {
        return this.addGroups([group]);
    }
    /**
     * Returns group instance by it's index in collection
     *
     * @throws Error if provided index is out of collection's range
     *
     * @param index - Index inside the collection
     * @returns Group instance
     */
    getGroup(index) {
        if (index < 0 || index > this._groups.length - 1) {
            throw new Error(`Provided index is out of range: ${index}. Valid range: [0, ${this._groups.length - 1}]`);
        }
        return this._groups[index];
    }
    /**
     * Returns array of stored group instances
     *
     * @returns Array of groups
     */
    getGroups() {
        return this._groups;
    }
    /**
     * Plot's legend data
     *
     * Currently, just returns groups array since their interfaces are compatible
     */
    get legendData() {
        return this.getGroups();
    }
    /**
     * Number of groups which are visible (should be displayed) on the plot
     */
    get visibleGroupsCount() {
        return this._groups.length;
    }
    /**
     * Number of points which are visible (should be displayed) on the plot
     */
    get visiblePointsCount() {
        return this._groups.reduce((currentTotalCount, group) => (currentTotalCount + group.getPoints().length), 0);
    }
}
