export const getDataPointOpacity = (dataset, datasets, levelResultsCollection, traitsTypesCollection, levelResult, traitsType) => {
    var _a;
    if (traitsTypesCollection && traitsType && !traitsTypesCollection[traitsType]) {
        return 0;
    }
    if (levelResultsCollection && levelResult && !levelResultsCollection[levelResult]) {
        return 0;
    }
    if (datasets) {
        return (_a = datasets[dataset]) !== null && _a !== void 0 ? _a : 100;
    }
    return 100;
};
