export const SIGNIFICANT_DATA_POINT_RADIUS = 10;
export const SIGNIFICANT_DATA_POINT_PVALUE_INDICATOR = 1e-8;
export const SIGNIFICANT_ASSOCIATIONS_PVALUE_INDICATOR = '2 X 10-9';
export const TOOLTIP_ID = 'FESPlotTooltip';
export const DEFAULT_LEGEND_FONT_SIZE = 16;
export const DEFAULT_LEGEND_FONT_FAMILY = `bold ${DEFAULT_LEGEND_FONT_SIZE}px Roboto`;
export const LEGEND_OFFSET = 40;
export const LEGEND_LINE_HEIGHT = 24;
export const MIN_SCIENTIFIC_VALUE_THRESHOLD = 0.001;
export const DEFAULT_AXIS = {
    x: { min: 0, max: 100, step: 10 },
    y: { min: 0, max: 100, step: 10 },
};
export const PLOT_LEFT_PADDING = 40;
export const SCROLLABLE_PLOT_LEFT_PADDING = 58;
export const SCROLLABLE_PLOT_BOTTOM_PADDING = 50;
export const SCROLLBAR_MARGIN = 18;
export const SCROLLABLE_PLOT_TOP_PADDING = 0;
export const FES_PLOT_X_AXIS_TITLE = 'Minor allele frequency';
