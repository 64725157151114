import { logger } from '../..';
/**
 * Formats value as an integer
 *
 * Adds delimiters by thousands and converts value to string
 * If original value is not an integer, the fraction part remains as is
 *
 * @param value - Formatted number or string value
 * @returns Formatted integer string
 */
export const integerFormatter = ({ value }) => {
    if (value === null) {
        return null;
    }
    if (value === '') {
        logger.warn('Integer formatter can not format an empty strings!');
        return null;
    }
    let valueNumber = value;
    if (typeof value === 'string') {
        valueNumber = parseInt(value, 10);
    }
    if (typeof valueNumber !== 'number' || Number.isNaN(valueNumber)) {
        const valueToReturn = Array.isArray(value) ? '' : `${value} `;
        logger.warn(`Value ${valueToReturn}for formatting is invalid. Integer formatter can not format such kind of values!`);
        return null;
    }
    if (!Number.isInteger(valueNumber)) {
        const valueToReturn = Array.isArray(value) ? '' : `${value} `;
        logger.warn(`Integer formatter should be used only for integer values! ${valueToReturn}is not an integer`);
        valueNumber = Math.round(valueNumber);
    }
    const formattedNumber = valueNumber.toLocaleString('en-GB', {
        maximumFractionDigits: 0,
    });
    return formattedNumber;
};
