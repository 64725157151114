// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HighlightedLabel__highlighted__3veDf{background:var(--highlight-color);border:1px solid var(--accent-color);border-radius:4px;box-sizing:border-box;height:1.5rem}", "",{"version":3,"sources":["webpack://./shared/src/components/HighlightedText/components/HighlightedLabel/HighlightedLabel.css"],"names":[],"mappings":"AAAA,sCAGE,iCAAkC,CADlC,oCAAqC,CADrC,iBAAkB,CAGlB,qBAAsB,CACtB,aACF","sourcesContent":[".highlighted {\n  border-radius: 4px;\n  border: 1px solid var(--accent-color);\n  background: var(--highlight-color);\n  box-sizing: border-box;\n  height: 1.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"highlighted": "HighlightedLabel__highlighted__3veDf"
};
export default ___CSS_LOADER_EXPORT___;
