import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useCallback } from 'react';
import ManhattanScrollablePlot from '../ManhattanPlot/ManhattanScrollablePlot';
import GPAManhattanPlotGroupBorder from './components/GPAManhattanPlotGroupBorder/GPAManhattanPlotGroupBorder';
import { DATA_POINT_STEP } from './consts';
const GPAManhattanScrollablePlot = (props) => {
    const renderGroupBorder = useCallback((groupBorderProps) => (_jsx(GPAManhattanPlotGroupBorder, Object.assign({}, groupBorderProps), void 0)), []);
    return (_jsx(ManhattanScrollablePlot, Object.assign({}, props, { dataPointStep: DATA_POINT_STEP, renderGroupBorder: renderGroupBorder, withScrollListener: true, withZoomReset: true }), void 0));
};
/** Since memo loses generic props */
export default memo(GPAManhattanScrollablePlot);
