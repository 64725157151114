import { useMemo } from 'react';
import { transformScaleValueToString } from '../../utils';
import { getTextWidth } from '../../../../utils';
import { PLOT_TOP_PADDING, PLOT_RIGHT_PADDING, PLOT_BOTTOM_PADDING, PLOT_LEFT_PADDING, AXIS_BORDER_WIDTH, AXIS_LABEL_FONT_SIZE, SCALE_LABEL_FONT_FAMILY, Y_AXIS_LABEL_PADDING, Y_SCALE_LABEL_PADDING, Y_AXIS_BORDER_MIN_PADDING, Y_SCALE_TICK_MIN_WIDTH, X_AXIS_BORDER_MIN_PADDING, SCALE_LABEL_FONT_SIZE, X_SCALE_LABEL_PADDING, X_AXIS_LABEL_PADDING, X_SCALE_TICK_MIN_WIDTH, X_CANVAS_PADDING, Y_CANVAS_PADDING, THRESHOLD_ICON_MIN_WIDTH, } from '../../consts';
export const useZoneConstructor = (height, width, YScaleValues, axisLabels) => {
    const YScaleValuesMaxWidth = Math.max(...YScaleValues.map((value) => getTextWidth(transformScaleValueToString(value), SCALE_LABEL_FONT_FAMILY)));
    const XAxisLabelOffset = (axisLabels === null || axisLabels === void 0 ? void 0 : axisLabels[0])
        ? AXIS_LABEL_FONT_SIZE + X_AXIS_LABEL_PADDING
        : 0;
    let XAxisBorderPadding = X_AXIS_BORDER_MIN_PADDING;
    let XScaleTickWidth = X_AXIS_BORDER_MIN_PADDING
        - SCALE_LABEL_FONT_SIZE
        - X_SCALE_LABEL_PADDING
        - XAxisLabelOffset;
    if (XScaleTickWidth < X_SCALE_TICK_MIN_WIDTH) {
        XAxisBorderPadding += (X_SCALE_TICK_MIN_WIDTH - XScaleTickWidth);
        XScaleTickWidth = X_SCALE_TICK_MIN_WIDTH;
    }
    let YAxisBorderPadding = Y_AXIS_BORDER_MIN_PADDING;
    let YScaleTickWidth = Y_AXIS_BORDER_MIN_PADDING - YScaleValuesMaxWidth + Y_SCALE_LABEL_PADDING;
    if (YScaleTickWidth < Y_SCALE_TICK_MIN_WIDTH) {
        YAxisBorderPadding += (Y_SCALE_TICK_MIN_WIDTH - YScaleTickWidth);
        YScaleTickWidth = Y_SCALE_TICK_MIN_WIDTH;
    }
    const YAxisLabelZoneY = PLOT_TOP_PADDING + AXIS_LABEL_FONT_SIZE;
    const YAxisZoneY = (axisLabels === null || axisLabels === void 0 ? void 0 : axisLabels[1])
        ? YAxisLabelZoneY + Y_AXIS_LABEL_PADDING
        : PLOT_TOP_PADDING;
    const YAxisZoneRect = useMemo(() => ({
        x: PLOT_LEFT_PADDING,
        y: YAxisZoneY,
        height: height - YAxisZoneY - PLOT_BOTTOM_PADDING - XAxisBorderPadding,
        width: YAxisBorderPadding + AXIS_BORDER_WIDTH,
    }), [XAxisBorderPadding, YAxisZoneY, YAxisBorderPadding, height]);
    const YAxisLabelZoneRect = useMemo(() => ((axisLabels === null || axisLabels === void 0 ? void 0 : axisLabels[1]) ? {
        x: YAxisZoneRect.x + YAxisZoneRect.width,
        y: YAxisLabelZoneY,
        height: 0,
        width: 0, // unused
    } : null), [axisLabels, YAxisLabelZoneY, YAxisZoneRect]);
    const YScaleZoneRect = useMemo(() => ({
        x: YAxisZoneRect.x,
        y: YAxisZoneRect.y,
        width: YScaleValuesMaxWidth,
        height: YAxisZoneRect.height - Y_CANVAS_PADDING - AXIS_BORDER_WIDTH,
    }), [YAxisZoneRect, YScaleValuesMaxWidth]);
    const XAxisZoneX = YAxisZoneRect.x + YAxisZoneRect.width - AXIS_BORDER_WIDTH;
    const XAxisZoneRect = useMemo(() => ({
        x: XAxisZoneX,
        y: YAxisZoneRect.y + YAxisZoneRect.height - AXIS_BORDER_WIDTH,
        height: XAxisBorderPadding + AXIS_BORDER_WIDTH,
        width: width - XAxisZoneX - PLOT_RIGHT_PADDING,
    }), [XAxisZoneX, XAxisBorderPadding, YAxisZoneRect, width]);
    const XScaleZoneRect = useMemo(() => ({
        x: XAxisZoneRect.x + X_CANVAS_PADDING + AXIS_BORDER_WIDTH,
        y: XAxisZoneRect.y + XAxisZoneRect.height - AXIS_LABEL_FONT_SIZE,
        width: XAxisZoneRect.width - X_CANVAS_PADDING - AXIS_BORDER_WIDTH,
        height: AXIS_LABEL_FONT_SIZE,
    }), [XAxisZoneRect]);
    const XAxisLabelZoneRect = useMemo(() => ((axisLabels === null || axisLabels === void 0 ? void 0 : axisLabels[0]) ? {
        x: XAxisZoneRect.x + XAxisZoneRect.width,
        y: XAxisZoneRect.y + XAxisLabelOffset + AXIS_BORDER_WIDTH,
        height: 0,
        width: 0, // unused
    } : null), [axisLabels, XAxisZoneRect, XAxisLabelOffset]);
    const dataZoneRect = useMemo(() => ({
        x: XScaleZoneRect.x,
        y: YScaleZoneRect.y,
        width: XScaleZoneRect.width,
        height: YScaleZoneRect.height,
    }), [XScaleZoneRect, YScaleZoneRect]);
    const thresholdsZoneRect = useMemo(() => ({
        x: XAxisZoneRect.x,
        y: YAxisZoneRect.y,
        width: XAxisZoneRect.width,
        height: YAxisZoneRect.height,
    }), [XAxisZoneRect, YAxisZoneRect]);
    const thresholdIconWidth = Math.max(YAxisZoneRect.width / 2, THRESHOLD_ICON_MIN_WIDTH);
    const thresholdIconsZoneRect = useMemo(() => ({
        x: YAxisZoneRect.x,
        y: YAxisZoneRect.y,
        width: thresholdIconWidth,
        height: thresholdIconWidth,
    }), [YAxisZoneRect, thresholdIconWidth]);
    return {
        XAxisZoneRect,
        YAxisZoneRect,
        XScaleZoneRect,
        YScaleZoneRect,
        XAxisLabelZoneRect,
        YAxisLabelZoneRect,
        dataZoneRect,
        thresholdsZoneRect,
        thresholdIconsZoneRect,
    };
};
