/*
 * Default width of SVG legend container
 */
export const DEFAULT_LEGEND_WIDTH = 2480;
/**
 * Legend paddings for SVG image
 */
export const LEGEND_LEFT_PADDING = 20;
export const LEGEND_TOP_PADDING = 20;
/**
 * Padding between SVG legend item label and detailed name
 */
export const LEGEND_LABEL_PADDING = 10;
/**
 * Min width of the label
 */
export const LEGEND_MIN_LABEL_WIDTH = 30;
/**
 * Padding around SVG legend content
 */
export const LEGEND_PADDING = 20;
/**
 * Height of SVG legend title
 */
export const LEGEND_TITLE_HEIGHT = 20;
/**
 * Height and padding of a single item row in SVG legend
 */
export const LEGEND_TEXT_ROW_HEIGHT = 16;
export const LEGEND_TEXT_ROW_PADDING = 16;
/**
 * Font which is used for drawing SVG legend
 */
export const LEGEND_FONT = `${LEGEND_TEXT_ROW_HEIGHT}px Roboto`;
