import { v4 as uuid4 } from 'uuid';
import { ManhattanPlotDataPoint, } from '../ManhattanPlotDataPoint/ManhattanPlotDataPoint';
/**
 * Class stores group related data for Manhattan plot
 *
 * @implements IManhattanPlotDataGroup<T>
 * @implements ISerializable<TSerializedManhattanPlotDataGroup<T>>
 */
export class ManhattanPlotDataGroup {
    /**
     * Creates new instance
     *
     * @param groupName - Group's full name
     * @param [groupLabel = groupName] - Group's short label
     * @param [length = 0] - Group's length parameter
     * @param [points = []] - Array of data points
     * @param [groupId = uuid4()] - Unique id
     * @returns New instance
     */
    constructor(groupName, groupLabel = groupName, length = 0, points = [], groupId = uuid4()) {
        this.groupId = groupId;
        this.groupName = groupName;
        this.groupLabel = groupLabel;
        this.length = length;
        this._points = points;
    }
    /**
     * Creates new instance from serialized JSON
     *
     * @static
     * @param rawData - Serialized JSON data
     * @returns New instance
     */
    static fromJSON(rawData) {
        var _a;
        const points = ((_a = rawData.points) === null || _a === void 0 ? void 0 : _a.map((pointJSON) => (ManhattanPlotDataPoint.fromJSON(pointJSON)))) || [];
        return new ManhattanPlotDataGroup(rawData.groupName, rawData.groupLabel, rawData.length, points, rawData.groupId);
    }
    /**
     * Serializes instance into JSON
     *
     * @returns Serialized JSON
     */
    toJSON() {
        return {
            groupId: this.groupId,
            groupName: this.groupName,
            groupLabel: this.groupLabel,
            length: this.length,
            points: this._points.map((point) => point.toJSON()),
        };
    }
    /**
     * Returns stored points
     *
     * @returns Group's points
     */
    getPoints() {
        return this._points;
    }
}
