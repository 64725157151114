import { useRef } from 'react';
import { PLOT_LEFT_PADDING } from '../../consts';
const useScroll = (props) => {
    var _a, _b;
    const { selectRegionStart, axisWidth, zoomIndex, containerRef, selectRegionTopRef, innerContainerRef, left, top, } = props;
    const prevWidthRef = useRef(axisWidth);
    const zoomXStartRef = useRef(0);
    const zoomYStartRef = useRef(0);
    const prevZoomIndex = useRef(zoomIndex);
    if (axisWidth === prevWidthRef.current) {
        return {
            zoomXStart: zoomXStartRef.current,
            zoomYStart: zoomYStartRef.current,
        };
    }
    if (zoomIndex <= 1) {
        prevWidthRef.current = axisWidth;
        prevZoomIndex.current = zoomIndex;
        zoomXStartRef.current = 0;
        zoomYStartRef.current = 0;
        return {
            zoomXStart: zoomXStartRef.current,
            zoomYStart: zoomYStartRef.current,
        };
    }
    const xAxisValueOnPlot = (((_a = innerContainerRef.current) === null || _a === void 0 ? void 0 : _a.scrollLeft) || 0)
        + selectRegionStart - left - PLOT_LEFT_PADDING;
    const zoomCoeff = zoomIndex / prevZoomIndex.current;
    zoomXStartRef.current = xAxisValueOnPlot * zoomCoeff;
    zoomYStartRef.current = ((((_b = containerRef.current) === null || _b === void 0 ? void 0 : _b.scrollTop) || 0)
        + selectRegionTopRef.current - top) * zoomCoeff;
    prevWidthRef.current = axisWidth;
    prevZoomIndex.current = zoomIndex;
    return {
        zoomXStart: zoomXStartRef.current,
        zoomYStart: zoomYStartRef.current,
    };
};
export default useScroll;
