import { useMemo } from 'react';
import { getMaxDataPointValues, calculateScaleValues, calculateDataPoints, calculateLambdaRect, getLambdaText, } from '../../utils';
import { createZone, calculateXAxisZoneRect, calculateYAxisZoneWidth, calculateXAxisScale, calculateYAxisScale, calculateYAxisLabelRect, calculateYAxisZoneRect, calculateXAxisLabelRect, calculateDataZoneRect, calculateAxisZoneRect, } from '../../../utils';
import { getTextWidth } from '../../../../../utils';
import { DEFAULT_FONT_FAMILY } from '../../../consts';
/**
 * Creates zones for plot's elements
 *
 * @param plotHeight - Height of the plot
 * @param plotWidth - Width of the plot
 * @param data - Plot's data
 * @param maxPlotValues - Max possible plot values
 * @param axisTitles - Titles for axis
 * @param lambda - Lambda value
 * @returns Plot's zones
 */
export const useZoneConstructor = (plotHeight, plotWidth, data, maxPlotValues, axisTitles, lambda) => {
    const [XAxisTitle, YAxisTitle] = axisTitles !== null && axisTitles !== void 0 ? axisTitles : [];
    const maxDataPointValues = useMemo(() => maxPlotValues !== null && maxPlotValues !== void 0 ? maxPlotValues : getMaxDataPointValues(data), [data, maxPlotValues]);
    const axisScaleValuesX = useMemo(() => calculateScaleValues(maxDataPointValues.x), [maxDataPointValues]);
    const axisScaleValuesY = useMemo(() => calculateScaleValues(maxDataPointValues.y), [maxDataPointValues]);
    const maxAxisScaleValueX = axisScaleValuesX[axisScaleValuesX.length - 1];
    const maxAxisScaleValueY = axisScaleValuesY[axisScaleValuesY.length - 1];
    const maxAxisScaleValuesWidthX = getTextWidth(`${maxAxisScaleValueX}`, DEFAULT_FONT_FAMILY);
    const maxAxisScaleValuesWidthY = getTextWidth(`${maxAxisScaleValueY}`, DEFAULT_FONT_FAMILY);
    const YAxisZoneWidth = calculateYAxisZoneWidth(maxAxisScaleValuesWidthY);
    const YAxisLabelRect = useMemo(() => calculateYAxisLabelRect(YAxisZoneWidth, YAxisTitle), [YAxisTitle, YAxisZoneWidth]);
    const YAxisLabelZone = useMemo(() => {
        if (!YAxisLabelRect || typeof YAxisTitle !== 'string') {
            return null;
        }
        return createZone(YAxisLabelRect, YAxisTitle);
    }, [YAxisLabelRect, YAxisTitle]);
    const XAxisZoneRect = useMemo(() => calculateXAxisZoneRect(plotHeight, plotWidth, YAxisZoneWidth, maxAxisScaleValuesWidthX), [
        plotHeight,
        plotWidth,
        YAxisZoneWidth,
        maxAxisScaleValuesWidthX,
    ]);
    const YAxisZoneRect = useMemo(() => calculateYAxisZoneRect(plotHeight, XAxisZoneRect, YAxisLabelRect, YAxisZoneWidth), [
        plotHeight,
        XAxisZoneRect,
        YAxisLabelRect,
        YAxisZoneWidth,
    ]);
    const XAxisLabelRect = useMemo(() => calculateXAxisLabelRect(XAxisZoneRect, XAxisTitle), [XAxisTitle, XAxisZoneRect]);
    const XAxisLabelZone = useMemo(() => {
        if (!XAxisLabelRect || typeof XAxisTitle !== 'string') {
            return null;
        }
        return createZone(XAxisLabelRect, XAxisTitle);
    }, [XAxisLabelRect, XAxisTitle]);
    const XAxisScale = useMemo(() => calculateXAxisScale(axisScaleValuesX, XAxisZoneRect, YAxisZoneRect, plotHeight), [
        axisScaleValuesX,
        XAxisZoneRect,
        YAxisZoneRect,
        plotHeight,
    ]);
    const YAxisScale = useMemo(() => calculateYAxisScale(axisScaleValuesY, YAxisZoneRect, plotWidth), [
        axisScaleValuesY,
        YAxisZoneRect,
        plotWidth,
    ]);
    const XAxisZone = useMemo(() => createZone(XAxisZoneRect, XAxisScale), [XAxisZoneRect, XAxisScale]);
    const YAxisZone = useMemo(() => createZone(YAxisZoneRect, YAxisScale), [YAxisZoneRect, YAxisScale]);
    const axisZoneRect = useMemo(() => calculateAxisZoneRect(XAxisZoneRect, YAxisZoneRect), [XAxisZoneRect, YAxisZoneRect]);
    const axisZone = useMemo(() => createZone(axisZoneRect, null), [axisZoneRect]);
    const dataZoneRect = useMemo(() => calculateDataZoneRect(XAxisZoneRect, YAxisZoneRect), [
        XAxisZoneRect,
        YAxisZoneRect,
    ]);
    const dataPoints = useMemo(() => calculateDataPoints(dataZoneRect, data, maxAxisScaleValueX, maxAxisScaleValueY), [
        dataZoneRect,
        data,
        maxAxisScaleValueX,
        maxAxisScaleValueY,
    ]);
    const dataZone = useMemo(() => createZone(dataZoneRect, dataPoints), [dataZoneRect, dataPoints]);
    const lambdaValue = typeof lambda === 'number'
        ? getLambdaText(lambda)
        : null;
    const lambdaZoneRect = useMemo(() => {
        if (lambdaValue === null) {
            return null;
        }
        return calculateLambdaRect(YAxisZoneRect, lambdaValue);
    }, [
        YAxisZoneRect,
        lambdaValue,
    ]);
    const lambdaZone = useMemo(() => {
        if (!lambdaZoneRect || lambdaValue === null) {
            return null;
        }
        return createZone(lambdaZoneRect, lambdaValue);
    }, [
        lambdaZoneRect,
        lambdaValue,
    ]);
    return useMemo(() => ({
        axisZone,
        XAxisLabelZone,
        YAxisLabelZone,
        XAxisZone,
        YAxisZone,
        dataZone,
        lambdaZone,
        maxAxisScales: [maxAxisScaleValueX, maxAxisScaleValueY],
    }), [
        axisZone,
        XAxisLabelZone,
        YAxisLabelZone,
        XAxisZone,
        YAxisZone,
        dataZone,
        lambdaZone,
        maxAxisScaleValueX,
        maxAxisScaleValueY,
    ]);
};
