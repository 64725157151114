import { filledArray, getRandomFloat } from '../../../../../utils';
const dotsQuantity = 100;
const maxPrecisionX = 8;
const maxPrecisionY = 14;
const expectedPValuesArray = filledArray(dotsQuantity, () => -Math.log10(getRandomFloat(maxPrecisionX))).sort((a, b) => a - b);
const observedPValuesArray = filledArray(dotsQuantity, () => -Math.log10(getRandomFloat(maxPrecisionY))).sort((a, b) => a - b);
export const PLOT_RANDOM_DATA = filledArray(dotsQuantity, (i) => ({
    expectedPValueLog: expectedPValuesArray[i],
    observedPValueLog: observedPValuesArray[i],
}));
