import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback } from 'react';
import classnames from 'classnames';
import SelectIcon from '../../../../assets/icons/select.svg';
import styles from './DefaultOption.css';
const DefaultOptionBase = function DefaultOption(props) {
    const { id, option, getOptionLabel, onClick, onMouseOver, isSelected, isDisabled, isHighlighted, className, selectedClassName, highlightedClassName, disabledClassName, } = props;
    const handleClick = useCallback(() => {
        if (!isDisabled) {
            onClick(option);
        }
    }, [isDisabled, onClick, option]);
    const handleMouseOver = useCallback(() => {
        if (!isDisabled) {
            onMouseOver(option);
        }
    }, [isDisabled, onMouseOver, option]);
    return (_jsxs("li", Object.assign({ id: id, role: "presentation", onClick: handleClick, onMouseOver: handleMouseOver, onFocus: handleMouseOver, className: classnames(styles.layout, className, {
            [selectedClassName]: isSelected,
            [highlightedClassName]: isHighlighted,
            [disabledClassName]: isDisabled,
        }) }, { children: [getOptionLabel(option), isSelected && _jsx(SelectIcon, {}, void 0)] }), void 0));
};
export default memo(DefaultOptionBase);
