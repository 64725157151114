import { ROMAN_NUMERALS } from '../../consts';
/**
 * Converts roman to number
 *
 * @param romanInteger - Roman numeral
 * @returns Decimal number
 * return 0 for all invalid roman numbers provided
 */
export const convertRomanToNumber = (romanInteger) => {
    const splittedRomanNumerals = romanInteger.split('');
    return splittedRomanNumerals.reduce((acc, element, index) => {
        const currentValue = ROMAN_NUMERALS[element];
        const nextValue = ROMAN_NUMERALS[splittedRomanNumerals[index + 1]];
        return (currentValue < nextValue)
            ? (acc - currentValue)
            : (acc + currentValue);
    }, 0);
};
