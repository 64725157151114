// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LollipopPlotLegend__oddDomainLabel__F2lZJ{background-color:var(--lollipop-plot-odd-domain-color);color:var(--lollipop-plot-odd-domain-label-color)}.LollipopPlotLegend__evenDomainLabel__9IyTc{background-color:var(--lollipop-plot-even-domain-color);color:var(--lollipop-plot-even-domain-label-color)}.LollipopPlotLegend__container__2sl3M{border-top:1px solid var(--border-color)}", "",{"version":3,"sources":["webpack://./shared/src/plots/components/LollipopPlot/components/LollipopPlotLegend/LollipopPlotLegend.css"],"names":[],"mappings":"AAAA,2CAEE,sDAAuD,CADvD,iDAEF,CAEA,4CAEE,uDAAwD,CADxD,kDAEF,CAEA,sCACE,wCACF","sourcesContent":[".oddDomainLabel {\n  color: var(--lollipop-plot-odd-domain-label-color);\n  background-color: var(--lollipop-plot-odd-domain-color);\n}\n\n.evenDomainLabel {\n  color: var(--lollipop-plot-even-domain-label-color);\n  background-color: var(--lollipop-plot-even-domain-color);\n}\n\n.container {\n  border-top: 1px solid var(--border-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"oddDomainLabel": "LollipopPlotLegend__oddDomainLabel__F2lZJ",
	"evenDomainLabel": "LollipopPlotLegend__evenDomainLabel__9IyTc",
	"container": "LollipopPlotLegend__container__2sl3M"
};
export default ___CSS_LOADER_EXPORT___;
