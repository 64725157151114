import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { DATA_POINT_RADIUS } from '../../consts';
import { getDataPointCoords } from '../../utils';
import { LinePlotDataPath } from '../LinePlotDataPath/LinePlotDataPath';
import styles from './LinePlotDataGroup.css';
import inlineStyles from './LinePlotDataGroup.styles';
function LinePlotDataGroupBase({ rect, data, maxScaleValue, color, handleTooltipShow, handleTooltipHide, forExport, }) {
    const handleMouseLeave = () => {
        if (handleTooltipHide) {
            handleTooltipHide();
        }
    };
    const dataPoints = data.map((dataPoint) => {
        const { x, y } = getDataPointCoords(dataPoint, maxScaleValue, rect);
        const handleMouseEnter = (event) => {
            if (handleTooltipShow) {
                handleTooltipShow(event, dataPoint);
            }
        };
        if (x > rect.width + rect.x) {
            return null;
        }
        return (_jsx("circle", { cx: x, cy: y, r: DATA_POINT_RADIUS, className: forExport ? undefined : styles.point, style: forExport ? inlineStyles.point : undefined, onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave }, `point_${x}_${y}`));
    });
    return (_jsxs(_Fragment, { children: [_jsx(LinePlotDataPath, { rect: rect, data: data, color: color, maxScaleValue: maxScaleValue }, void 0), dataPoints] }, void 0));
}
export const LinePlotDataGroup = memo(LinePlotDataGroupBase);
