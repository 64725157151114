export const TOOLTIP_ID = 'QQPlotTooltip';
export const DEFAULT_LAMBDA_BORDER_WIDTH = 1;
export const DEFAULT_LAMBDA_FONT_SIZE = 16;
export const DEFAULT_LAMBDA_FONT_FAMILY = `bold ${DEFAULT_LAMBDA_FONT_SIZE}px Roboto`;
export const DEFAULT_LAMBDA_OUTER_LEFT_PADDING = 14;
export const DEFAULT_LAMBDA_OUTER_TOP_PADDING = 14;
export const DEFAULT_LAMBDA_INNER_HORIZONTAL_PADDING = 8;
export const DEFAULT_LAMBDA_INNER_VERTICAL_PADDING = 4;
export const EXPORT_PADDING = 20;
export const EXPORT_HEADERS_HEIGHT = 100;
export const EXPORT_HEADERS_ADDITIONAL_HEIGHT = 20;
export const EXPORT_HEADERS_TOP_PADDING = 10;
export const EXPORT_HEADERS_FONT_SIZE = 16;
export const PHENOTYPE_WORD_WIDTH = 88;
export const MODEL_WORDS_WIDTH = 137;
