import { FIGURE_SIZE, SYMBOL_SIZE, PADDING_ELEMENT, CIRCLE_RADIUS, PADDING_ELEMENT_COMBINED, SYMBOL_SIZE_COMBINED, FIGURE_SIZE_COMBINED, } from '../../consts';
import { ELabelsConfig } from '../../../../types';
export const getOffset = (labelsConfig, isCombined) => {
    const paddingElement = isCombined ? PADDING_ELEMENT_COMBINED : PADDING_ELEMENT;
    const symbolSize = isCombined ? SYMBOL_SIZE_COMBINED : SYMBOL_SIZE;
    const figureSize = isCombined ? FIGURE_SIZE_COMBINED : FIGURE_SIZE;
    const labelsPadding = (Object.keys(labelsConfig).length - 1) * paddingElement;
    const lengthLegend = Object.values(labelsConfig).reduce((acc, label) => { var _a; return acc + ((_a = label === null || label === void 0 ? void 0 : label.length) !== null && _a !== void 0 ? _a : 0) * symbolSize + figureSize; }, labelsPadding);
    let cornerValue = -lengthLegend;
    const offset = Object.entries(labelsConfig).reduce((acc, curr) => {
        var _a;
        const [key, value] = curr;
        const radiusFactor = key === ELabelsConfig.circle ? CIRCLE_RADIUS : 0;
        const accumulator = Object.assign(Object.assign({}, acc), { [`${key}Icon`]: cornerValue + radiusFactor, [`${key}Text`]: cornerValue + FIGURE_SIZE });
        cornerValue += paddingElement + figureSize + ((_a = value === null || value === void 0 ? void 0 : value.length) !== null && _a !== void 0 ? _a : 0) * symbolSize;
        return accumulator;
    }, {});
    return offset;
};
