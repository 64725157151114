import { logger } from '../../../../../../../utils';
const ZOOM_MIN_VALUE_DEFAULT = 0.15;
const checkAxisConfig = ({ min, max, step, axisName, }) => {
    if ((Math.abs(max) + Math.abs(min)) % step) {
        logger.warn(`"${axisName}" axis must be split into equal parts, otherwise it can lead to unexpected issues. Please, take a look at the axis config.`);
    }
};
const getMinValue = (params) => {
    const { step, max, min } = params;
    return Math.min(step / Math.max(Math.abs(max), Math.abs(min)), ZOOM_MIN_VALUE_DEFAULT);
};
const getZoomMinValue = (axis) => {
    const { y, x } = axis;
    checkAxisConfig({
        min: y.min, max: y.max, step: y.step, axisName: 'Y',
    });
    checkAxisConfig({
        min: x.min, max: x.max, step: x.step, axisName: 'X',
    });
    if (y.step > x.step) {
        return getMinValue({ step: axis.y.step, max: axis.y.max, min: axis.y.min });
    }
    return getMinValue({ step: axis.x.step, max: axis.x.max, min: axis.x.min });
};
export default getZoomMinValue;
