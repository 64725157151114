// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InfoTooltip__wrapper__2SsjH{display:inline-flex}.InfoTooltip__withDescription__Sx7st>svg{margin-right:.5rem}.InfoTooltip__wrapperHover__3TuQd:hover>svg{fill:var(--link-color)}", "",{"version":3,"sources":["webpack://./shared/src/components/InfoTooltip/InfoTooltip.css"],"names":[],"mappings":"AAAA,6BACE,mBACF,CAEA,yCACE,kBACF,CAEA,4CACE,sBACF","sourcesContent":[".wrapper {\n  display: inline-flex;\n}\n\n.withDescription > svg {\n  margin-right: 0.5rem;\n}\n\n.wrapperHover:hover > svg {\n  fill: var(--link-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "InfoTooltip__wrapper__2SsjH",
	"withDescription": "InfoTooltip__withDescription__Sx7st",
	"wrapperHover": "InfoTooltip__wrapperHover__3TuQd"
};
export default ___CSS_LOADER_EXPORT___;
