import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { LoadingIcon } from '../LoadingIcon';
import { DisplayValues } from './components/DisplayValues';
import { Delimiter } from './components/Delimiter';
import { DAYS_TITLE, DEFAULT_INTERVAL_MS, HOURS_TITLE, MINUTES_TITLE, SECONDS_TITLE, } from './consts';
import { getFormattedTime } from './utils/getFormattedTime/getFormattedTime';
import styles from './CountdownTimer.css';
export const CountdownTimer = ({ text, endDate, autoExtension, delimiter, className, delayMs = DEFAULT_INTERVAL_MS, descriptionClassName, timerValueClassName, timerTagClassName, }) => {
    const [formattedTime, setFormattedTime] = useState();
    useEffect(() => {
        const timer = setInterval(() => {
            setFormattedTime(getFormattedTime(endDate, autoExtension));
        }, delayMs);
        if ((formattedTime === null || formattedTime === void 0 ? void 0 : formattedTime.totalMs) === 0) {
            clearInterval(timer);
        }
        return () => clearInterval(timer);
    }, [autoExtension, endDate, formattedTime === null || formattedTime === void 0 ? void 0 : formattedTime.totalMs, delayMs]);
    const DisplayValuesFormatted = ({ value, titleOfValue, showDelimiter, }) => (_jsxs(_Fragment, { children: [_jsx(DisplayValues, { value: value, titleOfValue: titleOfValue, timerTagClassName: timerTagClassName, timerValueClassName: timerValueClassName }, void 0), showDelimiter && _jsx(Delimiter, { delimiter: delimiter }, void 0)] }, void 0));
    const { days, hours, minutes, seconds, } = formattedTime || {};
    return (_jsxs("section", Object.assign({ className: classnames(styles.content, className) }, { children: [_jsx("h3", Object.assign({ className: classnames(styles.description, descriptionClassName) }, { children: text }), void 0), _jsxs("section", Object.assign({ className: styles.countdown }, { children: [days && days !== '0' && (DisplayValuesFormatted({ value: days, titleOfValue: DAYS_TITLE, showDelimiter: true })), hours && minutes && seconds ? (_jsxs(_Fragment, { children: [DisplayValuesFormatted({
                                value: hours,
                                titleOfValue: HOURS_TITLE,
                                showDelimiter: true,
                            }), DisplayValuesFormatted({
                                value: minutes,
                                titleOfValue: MINUTES_TITLE,
                                showDelimiter: true,
                            }), DisplayValuesFormatted({ value: seconds, titleOfValue: SECONDS_TITLE })] }, void 0)) : _jsx(LoadingIcon, { width: 164, height: 82 }, void 0)] }), void 0)] }), void 0));
};
