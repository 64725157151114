// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TextField__baseInput__2Vlta{background-color:initial;border-radius:4px;color:var(--font-color);font-size:.875rem;line-height:1rem;outline:none;position:relative}.TextField__baseInput__2Vlta:disabled{-webkit-text-fill-color:var(--text-disabled-color);border-color:var(--text-disabled-color);color:var(--text-disabled-color)}input[type=search]::-webkit-search-cancel-button{display:none}input[type=search]::-ms-clear{display:none}", "",{"version":3,"sources":["webpack://./shared/src/components/TextField/TextField.css"],"names":[],"mappings":"AAAA,6BAEE,wBAA6B,CAG7B,iBAAkB,CAJlB,uBAAwB,CAExB,iBAAmB,CACnB,gBAAiB,CAEjB,YAAa,CACb,iBAYF,CAVE,sCAQE,kDAAmD,CANnD,uCAAwC,CADxC,gCAQF,CAGF,iDACE,YACF,CAEA,8BACE,YACF","sourcesContent":[".baseInput {\n  color: var(--font-color);\n  background-color: transparent;\n  font-size: 0.875rem;\n  line-height: 1rem;\n  border-radius: 4px;\n  outline: none;\n  position: relative;\n\n  &:disabled {\n    color: var(--text-disabled-color);\n    border-color: var(--text-disabled-color);\n\n    /**\n     * Safari fix\n     * Read more about: https://github.com/jgthms/bulma/issues/2355\n     */\n    -webkit-text-fill-color: var(--text-disabled-color);\n  }\n}\n\ninput[type=\"search\"]::-webkit-search-cancel-button {\n  display: none;\n}\n\ninput[type=\"search\"]::-ms-clear {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"baseInput": "TextField__baseInput__2Vlta"
};
export default ___CSS_LOADER_EXPORT___;
