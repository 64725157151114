import { useState, useCallback, useRef, } from 'react';
const useScrolledIndicator = () => {
    const fixedHeaderRef = useRef(null);
    const [isScrolled, setIsScrolled] = useState(false);
    const handleScroll = useCallback((event) => {
        const target = event.currentTarget;
        if (fixedHeaderRef.current) {
            fixedHeaderRef.current.scrollLeft = target.scrollLeft;
        }
        setIsScrolled(target.scrollLeft > 0);
    }, []);
    return {
        fixedHeaderRef,
        isScrolled,
        handleScroll,
    };
};
export default useScrolledIndicator;
