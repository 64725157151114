// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ResetFiltersIconButton__resetFiltersIconButton__1t5mn{background-color:initial;border:none;color:var(--link-color);font-weight:500;gap:.5rem;padding:0;white-space:nowrap}.ResetFiltersIconButton__resetFiltersIconButton__1t5mn:disabled{color:var(--disabled-element-background-color);cursor:default}.ResetFiltersIconButton__icon__3DtmC[disabled] path{stroke:var(--disabled-element-background-color)}", "",{"version":3,"sources":["webpack://./shared/src/components/ResetFiltersIconButton/ResetFiltersIconButton.css"],"names":[],"mappings":"AAAA,uDACE,wBAA6B,CAC7B,WAAY,CAEZ,uBAAwB,CADxB,eAAgB,CAEhB,SAAW,CACX,SAAU,CACV,kBACF,CAEA,gEACE,8CAA+C,CAC/C,cACF,CAEA,oDACE,+CACF","sourcesContent":[".resetFiltersIconButton {\n  background-color: transparent;\n  border: none;\n  font-weight: 500;\n  color: var(--link-color);\n  gap: 0.5rem;\n  padding: 0;\n  white-space: nowrap;\n}\n\n.resetFiltersIconButton:disabled {\n  color: var(--disabled-element-background-color);\n  cursor: default;\n}\n\n.icon[disabled] path {\n  stroke: var(--disabled-element-background-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resetFiltersIconButton": "ResetFiltersIconButton__resetFiltersIconButton__1t5mn",
	"icon": "ResetFiltersIconButton__icon__3DtmC"
};
export default ___CSS_LOADER_EXPORT___;
